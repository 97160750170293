/**
 * Capitalize the first letter of a string
 *
 * @param {string} string
 * @return {string}
 */
const toSentenceCase = string =>
    typeof string === 'string' ? string.replace(/./, char => char.toUpperCase()) : '';

export default toSentenceCase;
