/* eslint-disable no-param-reassign */
import {isEmpty} from 'lodash';
import * as mapDataUtils from 'Utils/data/mapDataUtils';
import {NAV_MAP_DEFAULT_CONFIG} from 'Utils/constants';

/**
 * Get the matching location for a feature
 *
 * @param {object} props
 * @param {Feature} props.feature
 * @param {Array<Feature>} props.features
 * @param {Map} props.locationsMap
 * @return {{returnNow: boolean, location: *}}
 */
const getLocationToMix = ({feature, features, locationsMap}) => {
    let returnNow = false;

    let location = locationsMap.get(feature.id);

    /* if feature is part of group, and group has an event override */
    const groupLocation = locationsMap.get(feature?.properties?.groupId);

    if (feature?.properties?.groupId && groupLocation?.events?.length > 0) {
        location = {
            ...location,
            events: groupLocation.events,
            navMapLightIconUrl: groupLocation.navMapLightIconUrl,
            navMapDarkIconUrl: groupLocation.navMapDarkIconUrl,
            mapLabelText: groupLocation.mapLabelText,
        };
    }

    // if no location is found, add this as plain feature
    if (!location) {
        // used in testing
        feature.properties.featureId = feature.id.toString();
        // A venue can be selected, but have no dictionary item
        // In which case it needs a numeric ID.
        feature.id = feature.properties.numericId;
        delete feature.properties.numericId;

        features.push(feature);

        returnNow = true;
    }

    return {
        location,
        returnNow,
    };
};

/**
 * Add and remove some IDs
 *
 * @param {object} props
 * @param {Feature} props.feature
 * @param {object} props.location
 */
const handleLocationId = ({feature, location}) => {
    // Keep a reference to the location ID
    feature.properties.locationId = location.id;

    // Remove some props, for prettier data
    delete feature.properties.dictionaryItemId;
    delete feature.properties.locked;
};

/**
 * Add a numeric ID, which Mapbox requires when using setFeatureState()
 *
 * @param {Feature} feature
 */
const addMapboxNumericId = feature => {
    feature.id = feature.properties.numericId;
    delete feature.properties.numericId;
};

/**
 * This mixes the details of locations into the nav map feature collection
 * All properties of a location are added to the 'properties' object on the GeoJSON feature
 * This mutates the passed in navMap
 *
 * @param props
 * @param {FeatureCollection} props.navMap
 * @param {Array<object>} props.locations
 * @param {Function} featureHandler
 * @returns {FeatureCollection}
 */
export const mixLocationsIntoNavMap = ({clientId, navMap, locations, featureHandler}) => {
    const locationsMap = new Map();
    locations.forEach(location => {
        locationsMap.set(location.featureId, location);
    });

    const features = [];
    const childrenMap = new Map();
    const featureMap = new Map();
    let explorerIdCounter = 1; // We check for truthiness of ID elsewhere, so start at one.

    // We loop through navMap.features twice.
    // In this loop, we mix in IMDF-related details for each feature
    // This mutates navMap, so the changes are there in the second loop
    // We also create a Map for parent/child relationships, and a Map of all features
    navMap.features.forEach(feature => {
        feature.properties.numericId = explorerIdCounter++;

        mapDataUtils.handleIds(feature);

        mapDataUtils.convertImdfFeatureType(feature);

        mapDataUtils.handleParents({feature, childrenMap});

        mapDataUtils.handleOpenings(feature);

        featureMap.set(feature.id, feature);
    });

    // The second loop. In this loop, we handle features with relationships to other features
    // e.g. buildings, groups, display points
    // And also merge in location data to the feature
    navMap.features.forEach(feature => {
        feature.properties.childrenIds = childrenMap.get(feature.id) || [];

        const discardBuildingFeature = mapDataUtils.handleBuilding({feature, navMap});
        if (discardBuildingFeature) return;

        mapDataUtils.handleGroups({feature, featureMap});

        const {location, returnNow} = getLocationToMix({feature, features, locationsMap});
        if (returnNow) {
            return;
        }

        let sortKey = feature.properties?.sortKeyOverride;
        if (!sortKey) {
            sortKey = location?.prominence ? (11 - location.prominence) * 10 : 10;
        }

        const parsedFeature = {
            ...feature,
            clientId: feature.properties.clientId,
            properties: {
                ...feature.properties,
                ...location,
                isMyOwnFeature: clientId && clientId === feature.properties.clientId,
                sortKey,
                hasAboutDetails:
                    location.description || location?.phone || location?.website || false,
                isContentSource:
                    feature.properties.isContentSource ||
                    feature.properties.featureType?.imdfFeatureType
                        ?.defaultEnabledAsContentSource ||
                    false,
                canForcePamRoutes: feature.properties.featureType?.canForcePamRoutes,
            },
        };

        handleLocationId({feature: parsedFeature, location});

        if (featureHandler) {
            featureHandler(parsedFeature, locationsMap);
        }

        const displayPointFeature = mapDataUtils.getDisplayPointFeature({
            feature: parsedFeature,
            labeledItem: location,
            displayPointId: feature.properties.numericId,
            locationId: location.id,
        });

        if (displayPointFeature) {
            features.push(displayPointFeature);
        }

        addMapboxNumericId(parsedFeature);

        features.push(parsedFeature);
    });

    return {
        ...navMap,
        config: {
            ...NAV_MAP_DEFAULT_CONFIG,
            ...navMap.config,
        },
        features,
    };
};

/**
 * This mixes the details of tenants into the nav map feature collection
 * All properties of a tenant are put to the 'properties.tenant' object on the GeoJSON feature
 * Tenant's mapLabel or name overwrites feature's mapLabelText, displayText and searchText
 * This mutates the passed in navMap
 *
 * @param props
 * @param {FeatureCollection} props.imdfCategories
 * @param {FeatureCollection} props.navMap
 * @param {Array<object>} props.tenants
 * @returns {FeatureCollection}
 */
export const mixTenantsIntoNavMap = ({imdfCategories, navMap, tenants}) => {
    const features = [];

    navMap.features.forEach(feature => {
        const changedFeature = feature;
        if (feature.properties.tenantId) {
            const tenant = tenants.find(t => t.uuid === feature.properties.tenantId);
            if (tenant && tenant.isShowInDirectory) {
                changedFeature.properties.tenant = tenant;
                ['darkModeColor', 'lightModeColor'].forEach(colorPropName => {
                    if (tenant[colorPropName]) {
                        changedFeature.properties[colorPropName] = tenant[colorPropName];
                    }
                });

                if (tenant.imdfCategoryUuid && imdfCategories[tenant.imdfCategoryUuid]) {
                    changedFeature.properties.tenant.imdfCategory =
                        imdfCategories[tenant.imdfCategoryUuid];
                }
                changedFeature.properties.mapLabelText = tenant.mapLabel
                    ? tenant.mapLabel
                    : tenant.name;
                changedFeature.properties.locationName = changedFeature.properties.displayText;
                changedFeature.properties.displayText = tenant.name;

                changedFeature.properties.searchText = feature.properties.hideFromSearch
                    ? tenant.name
                    : `${tenant.name} ${changedFeature.properties?.locationName} ${
                          changedFeature.properties?.searchText
                      }`;
                if (tenant.searchText) {
                    changedFeature.properties.searchText = `${
                        changedFeature.properties.searchText
                    } ${tenant.searchText}`;
                }

                if (tenant.name === changedFeature.properties.locationName) {
                    changedFeature.properties.locationName = '';
                }

                changedFeature.properties.hideFromSearch = false;

                changedFeature.properties.hasAboutDetails =
                    tenant?.description ||
                    tenant?.phone ||
                    tenant?.website ||
                    !!tenant?.hours ||
                    false;
                if (tenant.imageUrls && !isEmpty(tenant.imageUrls)) {
                    changedFeature.properties.imageUrls = tenant.imageUrls;
                }
            }
        }
        features.push(changedFeature);
    });

    return {
        ...navMap,
        features,
    };
};

export const mixPromotionsWithTenants = ({promotions, tenantFeatures, tenants}) => {
    const _tenants = tenants?.reduce(
        (result, tenant) => ({
            ...result,
            [tenant.uuid]: tenant,
        }),
        {}
    );

    const tenantFeaturesMap = new Map();
    tenantFeatures.forEach(f => {
        if (!tenantFeaturesMap.has(f.properties.tenantId)) {
            tenantFeaturesMap.set(f.properties.tenantId, []);
        }
        const featureList = tenantFeaturesMap.get(f.properties.tenantId);
        featureList.push(f.properties.id);
        tenantFeaturesMap.set(f.properties.tenantId, featureList);
    });
    return _tenants
        ? promotions
              ?.filter(
                  ({tenantId}) =>
                      _tenants[tenantId] &&
                      _tenants[tenantId]?.isShowInDirectory &&
                      tenantFeaturesMap.has(tenantId)
              )
              .map(promotion => ({
                  ...promotion,
                  tenantName: _tenants[promotion.tenantId]?.name,
              }))
        : [];
};
