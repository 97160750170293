import {ACTION_TYPES, DSM_TABS} from 'Utils/constants';

/**
 * The config for a particular sign or layout, for a particular scene
 *
 * @typedef SceneConfig
 * @property {object} [config] - the actual config, different shape for each layout
 * @property {string} [layoutId] - only signs have a layoutId
 */

/**
 * @typedef Layout
 * @property {string} id
 * @property {string} name
 * @property {object<string, SceneConfig>} sceneConfigs
 */

/**
 * Note that a sign as it exists in the store is slightly different to the
 * sign sent to components (same for layout). See SignPropType for the difference
 *
 * @typedef Sign
 * @property {string} id
 * @property {LngLat} lngLat
 * @property {string} name
 * @property {number} rotation
 * @property {object<string, SceneConfig>} sceneConfigs
 */

/**
 * @typedef DsmState
 * @property {Array<Layout>} layouts
 * @property {Array<Sign>} signs
 * @property {string} selectedTab
 * @property {?string} selectedLayoutId
 * @property {?string} selectedSignId
 */

/** @type {DsmState} */
const initialState = {
    autocompleteLocations: [],
    contentSources: [],
    messagingDictionary: [],
    hasEnabledMapCenterPositioning: false,
    layouts: [],
    signs: [],
    selectedTab: DSM_TABS.SIGN_MAP,
    selectedLayoutId: null,
    selectedSignId: null,
    selectedSceneId: null,
    downloadUrl: '',
    readOnly: true,
    previewData: {},
    previewIsReady: false,
    previewReactiveContent: false,
    saveDisabled: false,
    icons: [],
};

/**
 * @param {DsmState} state
 * @param {object} action
 * @return {DsmState}
 */
const dsmPageStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.DSM.SET_PREVIEW_REACTIVE_CONTENT:
            return {
                ...state,
                previewReactiveContent: action.data,
            };
        case ACTION_TYPES.DSM.SET_PREVIEW_IS_READY:
            return {
                ...state,
                previewIsReady: action.data,
            };
        case ACTION_TYPES.DSM.SET_PREVIEW_DATA:
            return {
                ...state,
                previewData: action.data,
            };
        case ACTION_TYPES.DSM.SET_AUTOCOMPLETE_LOCATIONS:
            return {
                ...state,
                autocompleteLocations: action.data,
            };
        case ACTION_TYPES.DSM.SET_CONTENT_SOURCES:
            return {
                ...state,
                contentSources: action.data,
            };
        case ACTION_TYPES.DSM.SET_ADVISORY_MESSAGING_DICTIONARY:
            return {
                ...state,
                messagingDictionary: action.data,
            };
        case ACTION_TYPES.DSM.SET_PAGE_STATE:
            return {
                ...state,
                ...action.data,
                hasEnabledMapCenterPositioning: false,
            };
        case ACTION_TYPES.DSM.UPDATE_SIGN_PROPS:
            return {...state, signs: action.data};
        case ACTION_TYPES.DSM.SET_SELECTED_LAYOUT:
            // Selects a layout on the layout tab
            return {
                ...state,
                selectedLayoutId: action.data,
            };
        case ACTION_TYPES.DSM.UPDATE_LAYOUT:
            return {
                ...state,
                changedLayoutConfigs: action.data.changedLayoutConfigs,
                layouts: state.layouts.map(
                    layout => (layout.id === action.data.config.id ? action.data.config : layout)
                ),
            };
        case ACTION_TYPES.DSM.UPDATE_SIGN:
            return {
                ...state,
                changedLayoutConfigs: action.data.changedLayoutConfigs,
                signs: state.signs.map(
                    sign => (sign.id === action.data.config.id ? action.data.config : sign)
                ),
            };
        case ACTION_TYPES.SET_SELECTED_SCENE: {
            return {
                ...state,
                selectedSceneId: action.data,
                hasEnabledMapCenterPositioning: false,
            };
        }
        case ACTION_TYPES.DSM.TOGGLE_MAP_CONFIG_POSITIONING:
            return {
                ...state,
                hasEnabledMapCenterPositioning: action.data,
            };
        case ACTION_TYPES.DSM.SET_READONLY:
            return {
                ...state,
                readOnly: action.data,
            };
        case ACTION_TYPES.DSM.SET_SAVE_DISABLED:
            return {
                ...state,
                saveDisabled: action.data,
            };
        default:
            return state;
    }
};

export default dsmPageStateReducer;
