import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import ErrorBoundary from 'Components/common/ErrorBoundary/ErrorBoundary';
import store from 'Data/store';
import {fetchIP} from 'Data/services/fetchLocationByIp';
import {ACTION_TYPES, DEVICE_TYPES, MAP_THEMES} from 'Utils/constants';
import isPreviewMode from 'Utils/isPreviewMode';
import config from 'config.json';
import 'Styles/base.scss';
import init from 'Data/init';
import initLayout from 'Data/initLayout';
import ExplorerPreloaderContainer from 'Components/Explorer/common/ExplorerLoader/ExplorerPreloaderContainer';
import {scheduleRequestQueueProcessing, scheduleTrackJourneyPing} from 'Utils/insights';

const Theme = lazy(() => import('Components/common/Theme'));
const ExplorerThemeContainer = lazy(() => import('Components/common/ExplorerThemeContainer'));

const url = new URL(window.location);
const {pathname} = url;
init(url);
const isPreview = isPreviewMode();

// we lazy load initFunctions as it has lots of dependencies
// and isn't required at load time, but only after we've identified which page to load
let initDsm;
let initParkingManager;
let initMapEditor;

const isDsm = pathname.startsWith('/dsm');
const isParkingManager = pathname.startsWith('/parking-manager');
const isMapEditor = pathname.startsWith('/map-editor');
const isSigns = pathname.startsWith('/layout');
const isExplorer = !(isDsm || isParkingManager || isMapEditor || isSigns);

const loaderFunction = async () => {
    if (pathname.startsWith('/dsm')) {
        initDsm = await import('Data/initDsm');
    } else if (pathname.startsWith('/parking-manager')) {
        initParkingManager = await import('Data/initParkingManager');
    } else if (pathname.startsWith('/map-editor')) {
        initMapEditor = await import('Data/initMapEditor');
    }
};

const layoutType = isExplorer ? 'explorer' : pathname.split('/')[1];
// Add layoutType as a data attribute to the HTML element so we can style globally
// eg: html[data-layout-type="dsm"] .some-class {}
document.documentElement.setAttribute('data-layout-type', layoutType);

if (module.hot) {
    module.hot.accept();
}

// this is used to reset window height when android soft keyboard
// reduces the portview.
window.initialHeight = window.innerHeight;

// This is used for debugging
window.getKioskUiConfigs = () => ({
    // getReleaseVersion() comes from index.html
    // eslint-disable-next-line no-undef
    version: getReleaseVersion(),
    config,
});

const LayoutWrapperContainer = lazy(() =>
    import('Components/layouts/LayoutWrapper/LayoutWrapperContainer')
);
const DsmContainer = lazy(() => import('Components/Dsm/DsmContainer'));
const ParkingManagerContainer = lazy(() =>
    import('Components/ParkingManager/ParkingManagerContainer')
);
const MapEditorContainer = lazy(() => import('Components/MapEditor/MapEditorContainer'));
const ExplorerContainer = lazy(() => import('Components/Explorer/ExplorerContainer'));

const LoadIP = async isDevice => {
    const IP = isDevice ? await fetchIP().then(response => response.ip) : 'User';
    store.dispatch({type: ACTION_TYPES.SET_DEVICE_DATA, data: {IP}});
};

window.__isIOS__ =
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);

const render = component => {
    ReactDOM.render(
        <Provider store={store}>
            <ErrorBoundary>
                <Suspense fallback={<div />}>{component}</Suspense>
                {isExplorer && <ExplorerPreloaderContainer />}
            </ErrorBoundary>
        </Provider>,
        document.getElementById('app')
    );
};

// invoke method at global level, this is called when this file is parsed after its loaded
if (isExplorer) {
    // const bodyElement = document.getElementById('body');
    // bodyElement.className = 'loading loading-bg bg-image';
    // 360 Explorer
    // Start mounting immediately, so Mapbox can get warmed up
    LoadIP(false);

    if (!isPreview) {
        store.dispatch({
            type: ACTION_TYPES.SET_DEVICE_DATA,
            data: {deviceType: DEVICE_TYPES.USER},
        });
    }

    render(
        <ExplorerThemeContainer>
            <ExplorerContainer url={url} />
        </ExplorerThemeContainer>
    );

    initLayout({url, isExplorer: true});
    scheduleRequestQueueProcessing();
    scheduleTrackJourneyPing();
} else {
    loaderFunction().then(() => {
        if (pathname === '/layout') {
            if (!isPreview) {
                LoadIP(true);
                store.dispatch({
                    type: ACTION_TYPES.SET_DEVICE_DATA,
                    data: {deviceType: DEVICE_TYPES.CONNECT},
                });
            }
            initLayout({url}).then(() => {
                render(
                    <Theme>
                        <LayoutWrapperContainer />
                    </Theme>
                );
                scheduleRequestQueueProcessing();
            });
        } else if (pathname.startsWith('/dsm')) {
            initDsm.default().then(() => {
                render(
                    <Theme themeType={MAP_THEMES.DSM}>
                        <DsmContainer />
                    </Theme>
                );
            });
        } else if (pathname.startsWith('/parking-manager')) {
            initParkingManager.default().then(() => {
                render(
                    <Theme themeType={MAP_THEMES.PM}>
                        <ParkingManagerContainer />
                    </Theme>
                );
            });
        } else if (pathname.startsWith('/map-editor')) {
            initMapEditor.default().then(() => {
                // For simplicity, we load all data before mounting the component
                // in edit mode, where performance matters less.
                render(
                    <Theme themeType={MAP_THEMES.MAP_EDITOR}>
                        <MapEditorContainer />
                    </Theme>
                );
            });
        }
    });
}
