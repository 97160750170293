import {MAP_THEMES} from 'Utils/constants';

class ThemeButton {
    constructor({map, mapTheme, clickHandler}) {
        this.mapTheme = mapTheme;
        this.map = map;
        this.clickHandler = clickHandler;
    }

    onAdd() {
        const _this = this;
        this._btn = document.createElement('button');
        const buttonClass = _this.mapTheme === MAP_THEMES.EXPLORER_DARK ? 'light' : 'dark';

        this._btn.className = `mapboxgl-ctrl-icon map-box-theme-switch`;
        this._btn.type = 'button';
        this._btn.setAttribute('aria-label', `Use the ${buttonClass} theme`);
        this._btn.onclick = () => {
            _this.mapTheme =
                _this.mapTheme === MAP_THEMES.EXPLORER_DARK
                    ? MAP_THEMES.EXPLORER_LIGHT
                    : MAP_THEMES.EXPLORER_DARK;
            const _buttonClass = _this.mapTheme === MAP_THEMES.EXPLORER_DARK ? 'light' : 'dark';
            this._btn.setAttribute('aria-label', `Use the ${_buttonClass} theme`);
            _this.clickHandler();
        };

        this._container = document.createElement('div');
        this._container.className = `mapboxgl-ctrl mapboxgl-ctrl-group`;
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this.map = undefined;
    }
}

export default ThemeButton;
