import {
    ACTION_TYPES,
    JOURNEY_STAGES,
    NAV_MODES,
    TRAVEL_MODES,
    MENU_DEFAULT_LABELS,
    ROUTE_OPTIONS,
    CONFIG_DATA_BASE_KEYS,
} from 'Utils/constants';

const initialState = {
    navMode: NAV_MODES.SEARCH,
    userSharedLocation: false,
    journey: {
        stage: null,
        stops: [],
        travelTimeMins: null,
        distanceMeters: null,
        routePath: null,
    },
    routeParameters: {
        travelMode: TRAVEL_MODES.WALKING,
        wheelchairOnly: false,
        safeOnly: false,
    },
    searchLocation: null,
    scanLocation: null,
    editMode: false,
    activeScene: null,
    navigationHistory: [{key: null, options: {}}],
    selectedMenu: MENU_DEFAULT_LABELS.EXPLORE,
    landingPage: 'explore',
    navigation: {
        key: null,
        item: null,
    },
    query: '',
    selectedQuickLink: null,
    selectedRouteOption: ROUTE_OPTIONS.WALK,
    pathFound: false,
    nightSafePathSearch: false,
    isSearchingRoute: false,
    takeMeThereEventId: null,
    locationTooFar: false,
    explorerLoaded: false,
};

const RESET_STATE = {
    NAVIGATION: {
        navigation: {
            key: null,
            item: null,
        },
    },
    SEARCH: {
        query: '',
        selectedQuickLink: null,
    },
    SCAN_LOCATION: {
        scanLocation: null,
    },
};

const explorerPageStateReducer = (state = initialState, action) => {
    switch (action.type) {
        // TODO (davidg): remove all these bespoke "SET_NAV_MODE" and instead
        //  just SET_EXPLORER_DATA?
        case ACTION_TYPES.PUSH_NAVIGATION_HISTORY:
            return {...state, navigationHistory: [...state.navigationHistory, action.data]};
        case ACTION_TYPES.POP_NAVIGATION_HISTORY:
            state.navigationHistory.pop();
            return {...state, navigationHistory: state.navigationHistory};
        case ACTION_TYPES.SET_IS_SEARCHING_ROUTE:
            return {...state, isSearchingRoute: action.data};
        case ACTION_TYPES.SET_NAV_MODE:
            return {...state, navMode: action.data};
        case ACTION_TYPES.SET_EDIT_MODE:
            return {...state, editMode: action.data};
        case ACTION_TYPES.SET_SEARCH_LOCATION:
            return {...state, searchLocation: action.data};
        case ACTION_TYPES.SET_JOURNEY_STAGE:
            return {...state, journey: {...state.journey, stage: action.data}};
        case ACTION_TYPES.SET_ROUTE_PARAMETERS:
            return {...state, routeParameters: {...state.routeParameters, ...action.data}};
        case ACTION_TYPES.SET_TRAVEL_TIME:
            return {...state, journey: {...state.journey, travelTimeMins: action.data}};
        case ACTION_TYPES.EXPLORER.SET_LOCATION_TOO_FAR:
            return {...state, locationTooFar: action.data};
        case ACTION_TYPES.EXPLORER.SET_EXPLORER_LOADED:
            return {...state, explorerLoaded: action.data};
        case ACTION_TYPES.EXPLORER.USER_LOCATION_AVAILABLE:
            return {...state, userSharedLocation: action.data};
        case ACTION_TYPES.SET_DISTANCE:
            return {...state, journey: {...state.journey, distanceMeters: action.data}};
        case ACTION_TYPES.SET_ROUTE_LINESTRING:
            return {...state, journey: {...state.journey, routePath: action.data}};
        case ACTION_TYPES.SET_JOURNEY_STOPS: {
            const stops = action.data;
            let nextJourneyStage = null;
            let nextTravelTimeMins = null;
            let nextdistance = null;

            if (stops[0] && stops[1]) {
                nextJourneyStage = JOURNEY_STAGES.VIEW;
                nextTravelTimeMins = state.journey.travelTimeMins;
                nextdistance = state.journey.distanceMeters;
            }

            return {
                ...state,
                journey: {
                    ...state.journey,
                    stops,
                    stage: nextJourneyStage,
                    travelTimeMins: nextTravelTimeMins,
                    distanceMeters: nextdistance,
                },
            };
        }
        case ACTION_TYPES.RESET_JOURNEY: {
            return {...state, journey: initialState.journey, searchLocation: null};
        }
        case ACTION_TYPES.SET_PROP_ON_STOP: {
            const {index, prop, value} = action.data;
            const nextStops = state.journey.stops.slice();
            nextStops[index][prop] = value;

            return {...state, journey: {...state.journey, stops: nextStops}};
        }
        case ACTION_TYPES.SET_STOP_VISITED: {
            const nextStops = state.journey.stops.slice();
            nextStops[action.data].visited = true;

            const nextStage =
                action.data.index === state.journey.stops.length - 1
                    ? JOURNEY_STAGES.FINISHED
                    : state.journey.stage;

            return {...state, journey: {...state.journey, stage: nextStage, stops: nextStops}};
        }
        case ACTION_TYPES.SET_ACTIVE_SCENE: {
            return {...state, activeScene: action.data};
        }

        case ACTION_TYPES.NAVIGATE:
            return {
                ...state,
                navigation: {
                    key: action.data.key,
                    item: action.data.options?.item,
                    showCloseButton: action.data.options?.showCloseButton,
                },
                ...(!action.data.options?.saveSearchState ? RESET_STATE.SEARCH : {}),
                directionsJourneyIndex: action.data.options?.directionsJourneyIndex,
            };
        case ACTION_TYPES.SET_SEARCH:
            return {...state, query: action.data.query};
        case ACTION_TYPES.SET_SCAN_LOCATION:
            return {...state, scanLocation: action.data};
        case ACTION_TYPES.HIDE_CARD:
            return {
                ...state,
                ...RESET_STATE.SEARCH,
                ...RESET_STATE.NAVIGATION,
                navigation: {
                    key: 'explore',
                    item: null,
                },
            };
        case ACTION_TYPES.SET_QUICKLINK:
            return {...state, selectedQuickLink: action.data.quickLink, query: ''};
        case ACTION_TYPES.RESET_BY_TIMEOUT:
            return {
                ...state,
                ...RESET_STATE.NAVIGATION,
                ...RESET_STATE.SEARCH,
            };
        case ACTION_TYPES.SELECT_ROUTE_OPTION:
            return {
                ...state,
                selectedRouteOption: action.data.selectedRouteOption,
                safeOnly: action.data.safeOnly,
            };
        case ACTION_TYPES.SET_PATH_FOUND:
            return {...state, pathFound: action.data.pathFound};
        case ACTION_TYPES.SET_NIGHT_SAFE_PATH_SEARCH:
            return {...state, nightSafePathSearch: action.data.nightSafePathSearch};
        case ACTION_TYPES.SET_TAKE_ME_THERE_EVENT_ID:
            return {...state, takeMeThereEventId: action.data};
        case ACTION_TYPES.LOADED_LAYOUT_CONFIG:
            return {
                ...state,
                landingPage:
                    action.data.config.signConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE].menuTab
                        ?.landingPage || 'explore',
            };
        default:
            return state;
    }
};

export default explorerPageStateReducer;
