import uuid from 'uuid/v4';
import store from 'Data/store';
import config from 'config.json';
import {TRAVEL_MODES} from 'Utils/constants';
import {VIEWPORT_BREAKPOINTS} from 'Utils/viewportConstants';
import * as md5 from 'md5';
import {LAYOUT_IDS} from './constants';

export const INSIGHTS_REQUEST_QUEUE_KEY = 'insights_request_queue';
const INSIGHTS_DEVICE_ID_KEY = 'insights_device_id';
const INSIGHTS_REQUEST_TTL = 172800000; // 48 hours
const INSIGHTS_REQUEST_INTERVAL_BASE = 15000; // 15 seconds
const INSIGHTS_REQUEST_INTERVAL_MAX = 3600000; // 1 hour
const INSIGHTS_REQUEST_INTERVAL_SCALE_FACTOR = 2.0;
const INSIGHTS_REQUEST_FAILURES_TO_INCREASE_INTERVAL = 3;

export const ACTION = {
    JOURNEY: {
        START: 'start',
        PING: 'ping',
        END: 'end',
        ARRIVED_POPUP: 'arrived_popup',
        ARRIVED_POPUP_YES: 'arrived_popup_yes',
        ARRIVED_POPUP_NO: 'arrived_popup_no',
    },
    VIEW: 'view',
    IMPRESSION: 'impression',
};

export const TYPE = {
    JOURNEY: 'journey',
    EXPERIENCE: 'experience',
    TENANT: 'tenant',
    PROMOTION: 'promotion',
    LOCATION: 'location',
};

export const SOURCE = {
    EXPLORER_MOBILE: 'explorerMobile',
    EXPLORER_DESKTOP: 'explorerDesktop',
    KIOSK: 'kiosk',
    DIRECTIONAL_SIGN: 'directionalSign',
    FULL_SCREEN_EXPERIENCES: 'fullScreenExperiences',
};

const INSIGHTS_API_PARAMS = {
    url: `${config.insightsApiUrl}/events`,
    method: 'POST',
    headers: [
        ['Content-Type', 'application/json'],
        ['Authorization', `Bearer ${config.insightsApiKey}`],
    ],
};

const INSIGHTS_TRAVEL_MODES = {
    VEHICULAR: 'VEHICULAR',
    PEDESTRIAN: 'PEDESTRIAN',
    CYCLING: 'CYCLING',
    ACCESSIBLE: 'ACCESSIBLE',
};

const INSIGHTS_TRAVEL_OPTIONS = {
    NIGHT_SAFE: 'NIGHT-SAFE',
};

let _journey = null;
let _routeParameters = null;
let _scenes = null;
let _settings = null;
let _store = null;

/**
 * Retrieves state from the redux store.
 */
export function updateStoreVariables() {
    _store = store.getState();
    _scenes = _store?.pageState?.layout?.config?.scenes;
    _settings = _store?.pageState?.layout?.config?.settings;
    _journey = _store?.pageState?.explorerMap?.journey;
    _routeParameters = _store?.pageState?.explorerMap?.routeParameters;
    _store = null;
}

/**
 * Generates and returns a unique identifier for a user's device.
 * @returns A unique identifier for a given device.
 */
export function getDeviceId() {
    if (!localStorage.getItem(INSIGHTS_DEVICE_ID_KEY)) {
        localStorage.setItem(INSIGHTS_DEVICE_ID_KEY, uuid());
    }
    return localStorage.getItem(INSIGHTS_DEVICE_ID_KEY);
}

/**
 * Returns the insights-api request queue.
 * @returns array
 */
export function getRequestQueue() {
    return JSON.parse(localStorage.getItem(INSIGHTS_REQUEST_QUEUE_KEY) || '[]');
}

/**
 * Sets the insights-api request queue.
 * @param {*} queue
 */
export function setRequestQueue(queue) {
    localStorage.setItem(INSIGHTS_REQUEST_QUEUE_KEY, JSON.stringify(queue));
}

function getInsightsApiParams() {
    return INSIGHTS_API_PARAMS;
}

/**
 * Sends an insights tracking request.
 * @param {*} request
 */
export function enqueueRequest(request) {
    const queue = getRequestQueue();
    request.processing = false;
    queue.push(request);
    setRequestQueue(queue);
}

/**
 * Attempts to empty the request queue by sending fetch requests.
 * If any fetch fails the dequeuing process stops.
 * @returns
 *  true - if queue is empty.
 *  false - if a request is processing.
 *  Error - if a request has failed.
 */
export function dequeueRequest(callback) {
    const queue = getRequestQueue();

    if (!queue.length) {
        return callback(false);
    }

    const next = {...queue[0]};
    const {url, method, headers} = getInsightsApiParams();
    const {body, processing} = next;
    const bodyObject = JSON.parse(body);

    // Discard expired tracking events.
    if (Date.parse(bodyObject.timestamp) < Date.now() - INSIGHTS_REQUEST_TTL) {
        queue.shift();
        setRequestQueue(queue);
        return dequeueRequest(callback);
    }

    if (processing) {
        return callback(false);
    }

    queue[0].processing = true;
    setRequestQueue(queue);

    const requestHeaders = new Headers();
    (headers || []).forEach(header => requestHeaders.append(header[0], header[1]));

    const requestOptions = {
        method,
        headers: requestHeaders,
        body,
        redirect: 'follow',
    };

    return fetch(url, requestOptions)
        .then(response => {
            if (response.ok) {
                return response.text();
            }
            throw Error({status: response.status, body: response.text()});
        })
        .then(() => {
            queue.shift();
            setRequestQueue(queue);
            return callback(true);
        })
        .catch(error => {
            // eslint-disable-next-line no-console
            queue[0].processing = false;
            setRequestQueue(queue);
            return callback(error);
        });
}

/**
 * @param {string} action one of ACTION.*
 * @param {string} [actionId]
 * @param {string} type one of TYPE.*
 * @param {string} typeId
 * @param {string} source one of SOURCE.*
 * @param {message, sourceDeviceId, [journey]} additionalParams
 */
export function trackEvent({action, actionId, type, typeId, source, additionalParams}) {
    updateStoreVariables();
    if (!_scenes) {
        return;
    }
    const {id} = _scenes.find(scene => scene.isActive);
    const sceneId = id;

    let body = {
        clientId: _settings.clientId,
        action,
        actionId: actionId ?? action,
        type,
        typeId: String(typeId),
        sceneId,
        source,
        timestamp: new Date().toISOString(),
    };

    if (additionalParams) {
        body = {...body, ...additionalParams};
    }

    if (window.currentUserLocation) {
        const {
            lng,
            lat,
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            speed,
        } = window.currentUserLocation;

        body.gpsCoordinates = {
            longitude: lng,
            latitude: lat,
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            speed,
        };
    }

    enqueueRequest({body: JSON.stringify(body)});
}

/**
 * Generates a short version for a given journey.
 * @param {*} journey
 * @param {*} routeParameters
 * @returns
 */
function journeyToShortObject(journey, routeParameters) {
    let travelMode = INSIGHTS_TRAVEL_MODES.PEDESTRIAN;
    if (routeParameters.wheelchairOnly) {
        travelMode = INSIGHTS_TRAVEL_MODES.ACCESSIBLE;
    } else if (routeParameters.travelMode === TRAVEL_MODES.CYCLING) {
        travelMode = INSIGHTS_TRAVEL_MODES.CYCLING;
    } else if (routeParameters.travelMode === TRAVEL_MODES.DRIVING) {
        travelMode = INSIGHTS_TRAVEL_MODES.VEHICULAR;
    }

    const travelOptions = [];
    if (routeParameters.safeOnly) {
        travelOptions.push(INSIGHTS_TRAVEL_OPTIONS.NIGHT_SAFE);
    }
    const shortObject = {
        journey: {
            travelTimeMins: journey.travelTimeMins,
            travelMode,
            travelOptions,
            start: {
                id: journey.stops[0]?.id,
                properties: {
                    featureId: journey.stops[0]?.properties?.featureId,
                    locationId: journey.stops[0]?.properties?.locationId,
                    locationType: journey.stops[0]?.properties?.locationType,
                    tenantId: journey.stops[0]?.properties?.tenantId,
                },
                geometry: journey.stops[0]?.properties?.geometry,
            },
            end: {
                id: journey.stops[1]?.id,
                properties: {
                    featureId: journey.stops[1]?.properties?.featureId,
                    locationId: journey.stops[1]?.properties?.locationId,
                    locationType: journey.stops[1]?.properties?.locationType,
                    tenantId: journey.stops[1]?.properties?.tenantId,
                },
                geometry: journey.stops[1]?.geometry,
            },
        },
    };
    shortObject.hash = md5(JSON.stringify(shortObject));
    return shortObject;
}

export const detectEventSource = state => {
    if (!state || state.device.deviceType === 'EXPLORER') {
        return window.innerWidth >= VIEWPORT_BREAKPOINTS.DESKTOP
            ? SOURCE.EXPLORER_DESKTOP
            : SOURCE.EXPLORER_MOBILE;
    }
    const layoutId = state.pageState.layout.layoutId;
    switch (layoutId) {
        case LAYOUT_IDS.KIOSK:
            return SOURCE.KIOSK;
        case LAYOUT_IDS.HPK_DDS:
            return SOURCE.DIRECTIONAL_SIGN;
        case LAYOUT_IDS.EXPERIENCES:
            return SOURCE.FULL_SCREEN_EXPERIENCES;
        default:
            return layoutId;
    }
};

export const detectUtmParams = state => {
    if (state.device.deviceType === 'EXPLORER') {
        const url = new URL(window.location);
        const utmParamNames = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_id', 'utm_content'];
        const utmParams = {};
        let isUtmParamExists = false;
        utmParamNames.forEach(param => {
            const value = url.searchParams.get(param);
            if (value) {
                utmParams[param] = value;
                isUtmParamExists = true;
            }
        });
        if (isUtmParamExists) {
            return {
                utmParamSource: utmParams,
            };
        }
    }
    return null;
};

/**
 * Records a journey event for analytical purposes.
 * @param {string} action
 * @param {*} message
 * @returns
 */
export function trackJourney(action, message) {
    const source = detectEventSource();

    let typeId = 'sharingLocation';
    let journey = null;
    if (_journey.stops.length > 1 && _journey.stops[0] && _journey.stops[1]) {
        journey = journeyToShortObject(_journey, _routeParameters);
        typeId = journey.hash;
    } else if (!window.currentUserLocation) {
        typeId = 'notSharingLocation';
    }

    const params = {
        action,
        type: TYPE.JOURNEY,
        typeId,
        source,
        additionalParams: {message, actionId: action, sourceDeviceId: getDeviceId()},
    };

    if (journey) {
        params.additionalParams.journey = journey.journey;
    }

    trackEvent(params);
}

export function scheduleRequestQueueProcessing() {
    let consecutiveFailures = 0;
    let requestInterval = 0;

    const nextRequestInterval = () => {
        const nextInterval = requestInterval * INSIGHTS_REQUEST_INTERVAL_SCALE_FACTOR;
        return Math.min(nextInterval, INSIGHTS_REQUEST_INTERVAL_MAX);
    };

    const resetRequestInterval = () => {
        requestInterval = INSIGHTS_REQUEST_INTERVAL_BASE;
    };

    resetRequestInterval();

    let dequeueRequestTimeoutFunction = () => {};

    const dequeueRequestCallback = result => {
        if (result === true) {
            consecutiveFailures = 0;
            resetRequestInterval();
            // Keep dequeuing requests until a failure occurs or the queue is empty.
            setTimeout(() => {
                dequeueRequest(dequeueRequestCallback);
            }, 100);
        } else if (result === false) {
            setTimeout(dequeueRequestTimeoutFunction, requestInterval);
        } else if (result instanceof Error) {
            consecutiveFailures += 1;
            if (consecutiveFailures >= INSIGHTS_REQUEST_FAILURES_TO_INCREASE_INTERVAL) {
                consecutiveFailures = 0;
                requestInterval = nextRequestInterval();
            }
            setTimeout(dequeueRequestTimeoutFunction, requestInterval);
        }
    };

    /**
     * This interval dequeues journey ping requests and is
     * independent of the enqueing interval.
     */
    dequeueRequestTimeoutFunction = () => {
        dequeueRequest(dequeueRequestCallback);
    };
    setTimeout(dequeueRequestTimeoutFunction, requestInterval);
}

/**
 * Sends a location ping to the insights API on a regular interval.
 * If a request fails three times consecutively, we increase the ping interval.
 */
export function scheduleTrackJourneyPing() {
    /**
     * This interval enqueues journey ping requests and is
     * independent of the dequeuing interval.
     */
    setInterval(() => {
        if (
            window.geolocateControl?._localState !== 'OFF' &&
            window.currentUserLocation?.lat &&
            localStorage.getItem('hasAllowedLocation')
        ) {
            trackJourney(ACTION.JOURNEY.PING, 'User is sharing their location.');
        }
    }, INSIGHTS_REQUEST_INTERVAL_BASE);
}
