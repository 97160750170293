import setJourneyStop from 'Data/actionCreators/journey/setJourneyStop';
import setPathFound from 'Data/actionCreators/journey/setPathFound';
import setNavMode from 'Data/actionCreators/setNavMode';
import setTakeMeThereEventId from 'Data/actionCreators/explorer/setTakeMeThereEventId';
import {ACTION_TYPES, NAV_MODES} from 'Utils/constants';
import {isThereExternalLocations} from 'Utils/routingUtils';
import * as mapManager from 'Utils/explorer/mapManager';

const hideCard = () => (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.HIDE_CARD,
    });

    dispatch(setTakeMeThereEventId(null));
    // clear route when card is closed
    const isExplorer = getState().device.deviceType !== 'CONNECT';
    if (isExplorer) {
        const isInternalLocation = !isThereExternalLocations(
            getState().pageState.explorerMap.journey.stops
        );

        // if internal location refocus on the location
        if (isInternalLocation) {
            setJourneyStop({index: 0, feature: null})(dispatch, getState);
        } else {
            // if there is external location refocus on map center
            setJourneyStop({reset: true})(dispatch, getState);
            mapManager.getMap().centerMapToPam();
        }
        setPathFound({pathFound: false})(dispatch, getState);
        setNavMode(NAV_MODES.SEARCH)(dispatch, getState);
    }
    setJourneyStop({index: 1, feature: null})(dispatch, getState);
};

export default hideCard;
