import theme from '@mapbox/mapbox-gl-draw/src/lib/theme';
import lodashFlatten from 'lodash/flatten';
import {GEOMETRY_TYPES, MAP_LAYERS, MAP_THEMES, SOURCES} from 'Utils/constants';
import FEATURE_TYPES from 'Utils/featureTypes';
import store from 'Data/store';
import hasPamOsEnabledFeatures from 'Utils/hasPamOsEnabledFeatures';
import {DEFAULT_LABEL_COLOR} from 'Utils/distanceRingUtil';
import IMDF_FEATURE_TYPES from 'Utils/imdfFeatureTypes';
import {getVenueLayers} from 'Styles/mapEditorLayers';

// We want to transition to PAM locations at different zooms based on device width
// E.g. zoomed so one city block fills the screen might be 15 on mobile, 18 on desktop
// This number will range from about 0.3 on phones up to 3.0 on desktops and is added to zoom values
const zoomAdjust = Math.max(window.innerWidth, window.innerHeight) / 1300;

export const getLabelSizeSpecRule = labelSizeMultiplier => {
    const fontSizes = [11, 11, 12, 12, 14, 14, 16, 16, 18, 18];
    return [
        'step',
        ['coalesce', ['get', 'prominence'], 5],
        11 * labelSizeMultiplier,
        ...lodashFlatten(
            fontSizes.map((fontSize, prominence) => [
                prominence + 1,
                fontSize * labelSizeMultiplier,
            ])
        ),
    ];
};

/**
 * Returns an array of Mapbox layers for the given theme
 * @param {object} params
 * @param {string} params.mapTheme
 * @param {string} params.labelSize
 * @return {Array<object>} The layer objects
 */
export const getLabelLayers = ({mapTheme, labelSize}) => {
    const {props, creatorFunction} = FEATURE_TYPES.labels.style[mapTheme];
    const currentClientId = store.getState().clientRelationship.id;

    if ([MAP_THEMES.DSM, MAP_THEMES.PM, MAP_THEMES.MAP_EDITOR].includes(mapTheme)) {
        return creatorFunction({...props, currentClientId});
    }

    const labelConfig = store.getState().pageState?.layout?.config?.settings?.mapConfig?.label;
    if (labelConfig) {
        props.textColor =
            mapTheme === MAP_THEMES.EXPLORER_DARK
                ? labelConfig.colors.darkMode.text
                : labelConfig.colors.lightMode.text;
        props.haloColor =
            mapTheme === MAP_THEMES.EXPLORER_DARK
                ? labelConfig.colors.darkMode.stroke
                : labelConfig.colors.lightMode.stroke;
        props.haloWidth = labelConfig.halo.width;
        props.labelSizeSpecRule = getLabelSizeSpecRule(labelConfig.size[labelSize]);
    } else {
        props.labelSizeSpecRule = getLabelSizeSpecRule(1);
    }

    return creatorFunction({
        ...props,
        currentClientId,
        navMapShowZoom: store.getState().mapLayers.navMap.config.navMapShowZoom + zoomAdjust,
    });
};

// Below are the layers specific to editing maps, including semi-transparent shapes,
// snap lines, edit-mode outlines, etc.
const getMapboxDrawLayers = () => {
    const layersToExclude = [
        'gl-draw-polygon-fill-inactive',
        'gl-draw-polygon-stroke-inactive',
        'gl-draw-line-inactive',
        'gl-draw-point-point-stroke-inactive',
        'gl-draw-point-inactive',
    ];

    const mapboxDrawLayers = theme.filter(
        defaultStyle => !layersToExclude.includes(defaultStyle.id)
    );
    // prettier-ignore

    mapboxDrawLayers.push(
        // scene mode highlight layers
        {
            id: `pam-highlighted-features-${GEOMETRY_TYPES.POLYGON}`,
            type: 'fill',
            filter: ['==', ['get', 'id'], 'NEVER MATCH'], // overridden in sceneModeProperties
            paint: {
                'fill-color': '#0fcf7c',
                'fill-opacity': 0.4,
            },
        },
        {
            id: `pam-highlighted-features-${GEOMETRY_TYPES.LINE_STRING}`,
            type: 'line',
            filter: ['==', ['get', 'id'], 'NEVER MATCH'], // overridden in sceneModeProperties
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': '#098952',
                'line-width': 2,
                'line-dasharray': [1, 2],
            },
        },
        {
            id: `pam-highlighted-features-${GEOMETRY_TYPES.POINT}`,
            type: 'circle',
            filter: ['==', ['get', 'id'], 'NEVER MATCH'], // overridden in SceneModeProperties
            paint: {
                'circle-radius': 7,
                'circle-color': '#0fcf7c',
                'circle-stroke-width': 2,
                'circle-stroke-color': '#098952',
            },
        },
        // Layers for locked objects, when they are selected
        {
            id: `pam-locked-line-active`,
            type: 'line',
            filter: [
                'all',
                ['==', ['get', 'active'], 'true'],
                ['==', ['get', 'user_locked'], true],
            ],
            paint: {
                'line-color': '#1e9ec2',
                'line-width': 1,
            },
        },
        {
            id: `pam-locked-vertex-active`,
            type: 'circle',
            filter: [
                'all',
                ['==', ['get', 'active'], 'true'],
                ['==', ['get', 'user_locked'], true],
            ],
            paint: {
                'circle-radius': 3,
                'circle-color': '#1e9ec2',
            },
        }
    );

    return mapboxDrawLayers;
};

// The order of props in an object can't be guaranteed when iterating with Object.values(),
// so we also have this array to ensure the layers are ordered correctly
const orderedFeatureTypes = [
    FEATURE_TYPES.stadium,
    FEATURE_TYPES.university,
    FEATURE_TYPES.retailStore,
    FEATURE_TYPES.shoppingCenter,
    FEATURE_TYPES.village,
    FEATURE_TYPES.area,
    FEATURE_TYPES.building,
    FEATURE_TYPES.multiLevelCarpark,
    FEATURE_TYPES.level,
    FEATURE_TYPES.parking,
    FEATURE_TYPES.sectionSeating,
    FEATURE_TYPES.fitnessComplex,
    FEATURE_TYPES.retail,
    FEATURE_TYPES.checkin,
    FEATURE_TYPES.exhibit,
    FEATURE_TYPES.foodCourt,
    FEATURE_TYPES.road,
    FEATURE_TYPES.natureStrip,
    FEATURE_TYPES.sportsField,
    FEATURE_TYPES.lawn,
    FEATURE_TYPES.planterBox,
    FEATURE_TYPES.water,
    FEATURE_TYPES.walkway,
    FEATURE_TYPES.elevator,
    FEATURE_TYPES.escalator,
    FEATURE_TYPES.stairs,
    FEATURE_TYPES.concrete,
    FEATURE_TYPES.glass,
    FEATURE_TYPES.wood,
    FEATURE_TYPES.auditorium,
    FEATURE_TYPES.library,
    FEATURE_TYPES.foodOutlet,
    FEATURE_TYPES.room,
    FEATURE_TYPES.kiosk,
    FEATURE_TYPES.bathroom,
    FEATURE_TYPES.shower,
    FEATURE_TYPES.void,
    FEATURE_TYPES.gym,
    FEATURE_TYPES.movieTheatre,
    FEATURE_TYPES.column,
    FEATURE_TYPES.backOfHouse,
    FEATURE_TYPES.freeForm,
    FEATURE_TYPES.wall,
    FEATURE_TYPES.carEntrance,
    FEATURE_TYPES.emergencyExit,
    FEATURE_TYPES.doorway,
    FEATURE_TYPES.venueEntrance,
    FEATURE_TYPES.displayPoint,
    FEATURE_TYPES.object3d,
    FEATURE_TYPES.point,
    FEATURE_TYPES.group,
    FEATURE_TYPES.routeSegment,
    FEATURE_TYPES.digitalSign,
    FEATURE_TYPES.threshold,
    FEATURE_TYPES.halfwayPoint,
    FEATURE_TYPES.osmBuildings,
    FEATURE_TYPES.snapLine,
    FEATURE_TYPES.snapPoint,
    FEATURE_TYPES.importedPoint,
    FEATURE_TYPES.importedLine,
    FEATURE_TYPES.importedPolygon,
];

export const getNavMapLayers = mapTheme => {
    const {navMapShowZoom, worldHideZoom} = store.getState().mapLayers.navMap.config;
    const currentClientId = store.getState().clientRelationship.id;

    const layers = orderedFeatureTypes
        .map(featureType => {
            const layerDefinition = featureType.style[mapTheme];

            if (!layerDefinition) return null; // This feature type isn't rendered for this theme

            if (
                (featureType.code === FEATURE_TYPES.routeSegment.code ||
                    featureType.code === FEATURE_TYPES.threshold.code) &&
                (mapTheme === MAP_THEMES.EXPLORER_DARK || mapTheme === MAP_THEMES.EXPLORER_LIGHT)
            ) {
                const mapConfig = store.getState().pageState?.layout?.config?.settings?.mapConfig;

                if (mapConfig) {
                    if (featureType.code === FEATURE_TYPES.routeSegment.code) {
                        const routeColors =
                            mapTheme === MAP_THEMES.EXPLORER_DARK
                                ? mapConfig.route.colors.darkMode
                                : mapConfig.route.colors.lightMode;
                        const routeWidth = mapConfig.route.width;
                        layerDefinition.props.route = {
                            colors: routeColors,
                            width: routeWidth,
                        };

                        const nightSafeRouteColors =
                            mapTheme === MAP_THEMES.EXPLORER_DARK
                                ? mapConfig.nightSafeRoute.colors.darkMode
                                : mapConfig.nightSafeRoute.colors.lightMode;
                        layerDefinition.props.nightSafeRoute = {
                            colors: nightSafeRouteColors,
                        };
                    } else {
                        layerDefinition.props.borderColor =
                            mapTheme === MAP_THEMES.EXPLORER_DARK
                                ? mapConfig.route.colors.darkMode.route
                                : mapConfig.route.colors.lightMode.route;
                    }
                }
            }

            const layerFuncProps = {
                ...featureType,
                ...layerDefinition.props,
                navMapShowZoom: navMapShowZoom + zoomAdjust,
                worldHideZoom: worldHideZoom + zoomAdjust,
                zoomAdjust,
                mapTheme,
                hasPamOsEnabledFeatures,
                currentClientId,
            };

            return layerDefinition.creatorFunction(layerFuncProps);
        })
        .flat()
        .filter(Boolean);

    if (mapTheme === MAP_THEMES.MAP_EDITOR) {
        layers.push(...getMapboxDrawLayers());
        layers.push(...getVenueLayers(IMDF_FEATURE_TYPES.venue));
    } else if (mapTheme === MAP_THEMES.EXPLORER_DARK || mapTheme === MAP_THEMES.EXPLORER_LIGHT) {
        const distanceRing = store.getState().pageState?.layout?.config?.settings?.distanceRing;
        if (distanceRing?.distances && distanceRing?.distances.length) {
            distanceRing.distances.forEach(distance => {
                let opacity = distance.opacity;
                if (distance.fadeOutZoom) {
                    opacity = [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        distance.minZoom,
                        0,
                        distance.fadeOutZoom,
                        distance.opacity,
                        distance.maxZoom,
                        distance.opacity,
                    ];
                }
                layers.push({
                    id: `pam-distance-ring-zoom-${distance.minZoom}`,
                    type: 'line',
                    source: SOURCES.DISTANCE_RING,
                    minzoom: distance.minZoom,
                    maxzoom: distance.maxZoom,
                    filter: ['==', ['get', 'minZoom'], distance.minZoom],
                    paint: {
                        'line-color':
                            distanceRing.colors[
                                mapTheme === MAP_THEMES.EXPLORER_DARK ? 'darkMode' : 'lightMode'
                            ]?.label || DEFAULT_LABEL_COLOR,
                        'line-opacity': opacity,
                        'line-width': 3,
                    },
                });
            });
        }

        layers.push({
            id: MAP_LAYERS.SEARCH_BOUNDARY,
            type: 'line',
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': '#FA8632',
                'line-width': 2,
                'line-dasharray': [2, 2],
                'line-opacity': 0.5,
            },
            source: SOURCES.SEARCH_BOUNDARY,
        });

        layers.push({
            id: MAP_LAYERS.SEARCH_BOUNDARY_LABEL,
            type: 'symbol',
            source: SOURCES.SEARCH_BOUNDARY,
            layout: {
                'symbol-placement': 'line',
                'text-font': ['DIN Pro Bold'],
                'text-field': '{title}', // part 2 of this is how to do it
                'text-size': 14,
            },
            paint: {
                'text-color': mapTheme === MAP_THEMES.EXPLORER_LIGHT ? '#5a4332' : '#fff',
                'text-halo-color': mapTheme === MAP_THEMES.EXPLORER_LIGHT ? '#fff' : '#2a2a2a',
                'text-halo-width': 0.5,
                'text-halo-blur': 0.2,
            },
        });

        const supportsExtrusionFilter = [
            'has',
            'defaultExtrusion',
            ['get', 'featureType', ['properties']],
        ];
        const unsupportsExtrusionFilter = ['!', supportsExtrusionFilter];

        const mapConfig = store.getState().pageState?.layout?.config?.settings?.mapConfig;
        const startColor = mapConfig ? mapConfig.startPin.colors.background : '#1492FF';
        const destinationColor = mapConfig ? mapConfig.destinationPin.colors.background : '#F50E55';
        const selectedOpacity = 0.6;
        // add these layers last so it appear above other features
        layers.push({
            id: MAP_LAYERS.SELECTED_FEATURE_LINE,
            source: SOURCES.NAV_MAP,
            type: 'line',
            filter: unsupportsExtrusionFilter,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': [
                    'case',
                    ['to-boolean', ['feature-state', 'isStartLocation']],
                    startColor,
                    destinationColor,
                ],
                'line-width': ['case', ['to-boolean', ['feature-state', 'selected']], 3, 0],
            },
        });
        layers.push({
            id: MAP_LAYERS.SELECTED_FEATURE_FILL,
            source: SOURCES.NAV_MAP,
            type: 'fill',
            filter: unsupportsExtrusionFilter,
            paint: {
                'fill-color': [
                    'case',
                    ['to-boolean', ['feature-state', 'isStartLocation']],
                    startColor,
                    destinationColor,
                ],
                'fill-opacity': [
                    'case',
                    ['to-boolean', ['feature-state', 'selected']],
                    selectedOpacity,
                    0,
                ],
            },
        });
        layers.push({
            id: MAP_LAYERS.SELECTED_FEATURE_FILL_EXTRUSION,
            source: SOURCES.SELECTED_FEATURES,
            type: 'fill-extrusion',
            filter: supportsExtrusionFilter,
            paint: {
                'fill-extrusion-opacity': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    // fade in over a 0.5 zoom range
                    navMapShowZoom + zoomAdjust,
                    0, // not visible zoomed out
                    navMapShowZoom + zoomAdjust + 0.5,
                    selectedOpacity, // quickly become visible
                ],
                'fill-extrusion-color': [
                    'case',
                    ['to-boolean', ['feature-state', 'isStartLocation']],
                    startColor,
                    destinationColor,
                ],
                'fill-extrusion-base': ['coalesce', ['get', 'styleExtrusionBase'], 0],
                'fill-extrusion-height': [
                    '+',
                    [
                        'coalesce',
                        ['get', 'styleSelectHeight'],
                        ['get', 'styleExtrusionHeight'],
                        ['get', 'defaultExtrusion', ['get', 'featureType', ['properties']]],
                    ],
                    ['coalesce', ['get', 'styleExtrusionBase'], 0],
                ],
            },
        });
    }

    return layers;
};
