import lodashCloneDeep from 'lodash/cloneDeep';
import lodashFpGet from 'lodash/fp/get';
import lodashIsEqual from 'lodash/isEqual';
import lodashIsEmpty from 'lodash/isEmpty';
import {NO_ARROW_VALUE} from 'Components/common/DirectionalArrow/directionalArrowUtils';
import getOtherSlotConfigurations from 'Utils/layout/getOtherSlotConfigurations';
import {CONFIG_DATA_BASE_KEYS} from 'Utils/constants';
import getKey from './getKey';

const isLocationArrayEmpty = locationArray =>
    locationArray.reduce((result, item) => result && lodashIsEmpty(item), true);

/**
 * In the config, strip all the location information but id, clientId and slot item type (location or message)
 * that's all we needed in the DB
 *
 * @param config - sign or layout configuration
 * @returns {{slots: *}|*}
 */
export const depopulateLocationDetails = config => {
    /**
     * Remove all location details but id
     * @param locationArray
     * @returns {{}}
     */
    function removeLocationDetails(locationArray) {
        let newLocationArray = [{}];
        if (locationArray?.[0]) {
            // No ID means empty string is supplied
            // but we still need to put something in the ID for the override to work properly
            newLocationArray = locationArray.map(({id, clientId, type}) => ({
                id: id ?? '',
                clientId,
                type,
            }));
        }

        return newLocationArray;
    }

    /**
     * Remove location details but id from array of slots
     *
     * @param slots
     * @returns {{}}
     */
    function removeContentDetailsFromSlots(slots) {
        const configWithContent = {};
        // Loop thru all the slots in the locationArray
        for (const [slotName, slotConfig] of Object.entries(slots)) {
            let slotConfigWithContent = {};
            const locationArray = removeLocationDetails(slotConfig.locationArray);
            if (slotConfig?.locationArray && !isLocationArrayEmpty(locationArray)) {
                slotConfigWithContent.locationArray = locationArray;
            }

            slotConfigWithContent = {
                ...slotConfigWithContent,
                ...getOtherSlotConfigurations(slotConfig),
            };

            if (
                slotConfigWithContent?.locationArray &&
                Object.keys(slotConfigWithContent).length !== 0
            ) {
                configWithContent[slotName] = slotConfigWithContent;
            }

            if (
                slotConfigWithContent.arrowDirection &&
                slotConfigWithContent.arrowDirection === NO_ARROW_VALUE
            ) {
                delete slotConfigWithContent.arrowDirection;
            }

            if (!slotConfigWithContent.hasLine) {
                delete slotConfigWithContent.hasLine;
            }

            if (!slotConfigWithContent.lineBreak) {
                delete slotConfigWithContent.lineBreak;
            }
        }

        return configWithContent;
    }

    // We can't return immediately because there are locationArray that exists in multiple levels.
    // Other levels will be skipped if we return immediately, this is why we need a new variable.
    let newConfig = config;

    if (config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.parkingsLocationArray) {
        // This is top-level locationArray for parking config
        newConfig = {
            ...newConfig,
            [CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]: {
                ...newConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE],
                parkingsLocationArray: removeLocationDetails(
                    newConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE].parkingsLocationArray
                ),
            },
        };
    }

    if (newConfig?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.suggestedLocations?.locationArray) {
        newConfig = {
            ...newConfig,
            [CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]: {
                ...newConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE],
                suggestedLocations: {
                    ...newConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE].suggestedLocations,
                    locationArray: removeLocationDetails(
                        newConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE].suggestedLocations
                            .locationArray
                    ),
                },
            },
        };
    }

    if (newConfig?.[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]?.slots) {
        return {
            ...newConfig,
            [CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]: {
                ...newConfig[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE],
                slots: removeContentDetailsFromSlots(
                    newConfig[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE].slots
                ),
            },
        };
    }

    if (newConfig?.[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]?.header) {
        let _config = newConfig;
        if (_config[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE].header?.directions) {
            _config = {
                ..._config,
                [CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]: {
                    ..._config[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE],
                    header: {
                        ..._config[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE].header,
                        directions: removeContentDetailsFromSlots(
                            _config[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE].header.directions
                        ),
                    },
                },
            };
        }

        if (_config[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE].header?.location) {
            _config = {
                ..._config,
                [CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]: {
                    ..._config[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE],
                    header: {
                        ..._config[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE].header,
                        location: {
                            locationArray: removeLocationDetails(
                                _config[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE].header.location
                            ),
                        },
                    },
                },
            };
        }

        newConfig = _config;
    }

    return newConfig;
};

const getChangedLayoutConfig = ({
    sign,
    changedLayoutConfigs,
    sceneId,
    rawLayoutConfigs,
    selectedLayoutId,
    selectedSignId,
}) => {
    let changedLayoutConfigsCloned = lodashCloneDeep(changedLayoutConfigs);
    const sceneConfigs = lodashFpGet(`sceneConfigs.${sceneId}`, sign);
    const layoutId = selectedLayoutId || sceneConfigs.layoutId;
    const digitalSignId = selectedLayoutId ? null : selectedSignId;
    const key = getKey({digitalSignId, layoutId, sceneId});

    // convert sign into layoutConfig
    const layoutConfig = {
        layoutId,
        sceneId,
        digitalSignId,
        config: depopulateLocationDetails(sceneConfigs.config),
    };

    if (!lodashIsEqual(layoutConfig.config, rawLayoutConfigs[key])) {
        changedLayoutConfigsCloned = {...changedLayoutConfigsCloned, [key]: layoutConfig};
    } else {
        delete changedLayoutConfigsCloned[key];
    }
    return changedLayoutConfigsCloned;
};

export default getChangedLayoutConfig;
