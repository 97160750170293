/* global gtag */
import store from 'Data/store';
import {KIOSK_NAVIGATION} from 'Components/layouts/kiosk/kioskLayoutConstants';
import {DEVICE_TYPES} from 'Utils/constants';
import {EXPLORER_NAVIGATION} from 'Utils/explorer/explorerConstants';
import isPreviewMode from 'Utils/isPreviewMode';
import log from './log';

const CATEGORIES = {
    PAGE_LOAD: 'PAGE LOAD',
    DATA_LOAD: 'DATA LOAD',
    DEVICE: 'Device',
    SEARCH: 'Search',
    SEARCH_TERM: 'Search Term',
    EVENTS: 'Events',
    MAP: 'Map',
    DIRECTORY: 'Directory',
    PROMOTION: 'Promotion',
};

export const QR_CODE_SOURCES = {
    DIGITAL_SIGN: 1,
    PAPER: 2,
    EXPLORER: 3,
};

export const ACTIONS = {
    PAGE_LOAD: 'Page Load',
    DATA_UPDATE: 'Data Update',
    TOUCH: 'Touch',
    IDLE: 'Idle',
    SUGGESTIONS: 'Suggestions',
    QUICKLINKS: 'QuickLinks',
    KEYBOARD: 'Keyboard',
    RESULTS: 'Results',
    LOCATION: 'Location',
    EVENT: 'Event',
    PINCH: 'Pinch',
    DOUBLE_TAP: 'Double Tap',
    QR_CODE_SCANNED: 'QR Scan',
    GEOLOCATE_USER: 'Geolocate User',
    NO_RESULTS: 'No results',
    BACK: 'back',
    CLEAR: 'clear',
    TENANT: 'Tenant',
};

const isExplorerOrSigns = () => {
    return window.location.pathname === '/' || window.location.pathname === '/layout';
};

let navMapId;
let deviceId;
let layoutName;
let deviceType;

/**
 * Send an event to Google Analytics. Mix in the client and device type custom dimensions
 *
 * @param props
 * @param {string} props.action
 * @param {string} props.category
 * @param {string|object} [props.label]
 */
const sendEvent = ({action, category, label, ...rest}) => {
    if (isPreviewMode()) return;
    if (deviceType === DEVICE_TYPES.PREVIEW) {
        return;
    }

    // this analytics is only for explorer and signs
    if (!isExplorerOrSigns()) {
        return;
    }

    // getReleaseVersion() comes from index.html
    // eslint-disable-next-line no-undef
    const releaseVersion = getReleaseVersion();

    const _store = store.getState();

    const {scenes, settings} = _store.pageState.layout.config;
    const {id, name} = scenes.find(scene => scene.isActive);
    const sceneId = id;
    const sceneName = name;
    const client = settings.clientId; // TODO: add to API response
    const scanLocation = _store.pageState.explorerMap?.scanLocation?.id
        ? `LocationId: ${
              _store.pageState.explorerMap?.scanLocation?.properties.locationId
          } - Side: ${_store.pageState.explorerMap?.scanLocation?.properties.side} - Name: ${
              _store.pageState.explorerMap?.scanLocation?.properties.displayText
          }`
        : '';

    if (deviceId) {
        navMapId = settings.navMapId;
    } else if (navMapId) {
        deviceId = '';
    }

    const payload = {
        event_category: category,
        Client: client,
        navMapId,
        DeviceType: deviceType,
        SceneId: sceneId,
        SceneName: sceneName,
        DeviceId: deviceId,
        ReleaseVersion: releaseVersion,
        ScanLocation: scanLocation,
        IP: deviceType !== DEVICE_TYPES.USER ? _store.device?.IP : 'user',
        ...rest,
    };

    if (label) {
        payload.event_label = typeof label === 'string' ? label : JSON.stringify(label);
    }
    log(action, payload);

    gtag('event', action, payload);
};

/**
 * We send this 'Page Load' event because the default 'pageview' event won't contain
 * the client and device type information.
 * if the page is loaded via a scanned QR code, we send additional QR data too
 *
 * @param {bool|object} [props.fromQrType]
 * @param {bool|object} [props.fromQrData]
 */
export const sendPageLoadEvent = (type, data, layoutId) => {
    const {searchParams} = new URL(document.location);
    navMapId = searchParams.get('navmapId');
    deviceId = searchParams.get('signId');

    layoutName = store
        .getState()
        .pageState?.layout?.config?.layouts?.find(({id}) => layoutId === id)?.name;
    const deviceName = store.getState().device.deviceType;

    if (deviceName === DEVICE_TYPES.PREVIEW) {
        return;
    }
    if (deviceName === DEVICE_TYPES.CONNECT) {
        deviceType = layoutName;
    } else {
        deviceType = deviceName;
    }

    sendEvent({
        action: type || 'blank',
        category: `${deviceType}: ${CATEGORIES.PAGE_LOAD}`,
        label: (type && JSON.stringify(data)) || 'blank',
        non_interaction: true,
    });
};

export const sendDataUpdateEvent = () => {
    sendEvent({
        action: 'blank',
        category: CATEGORIES.DATA_LOAD,
        non_interaction: true,
    });
};

export const sendDeviceEvent = (action, label, extraFields) => {
    sendEvent({action, category: `${deviceType}: ${CATEGORIES.DEVICE}`, label, ...extraFields});
};

export const sendPromotionEvent = (action, label) => {
    sendEvent({
        action,
        category: CATEGORIES.PROMOTION,
        label,
    });
};

export const sendMapEvent = (action, label, extraFields) => {
    sendEvent({
        action,
        category: CATEGORIES.MAP,
        label,
        ...extraFields,
    });
};

export const sendSearchEvent = (action, label, extraFields) => {
    sendEvent({action, category: `${deviceType}: ${CATEGORIES.SEARCH}`, label, ...extraFields});
};

export const sendSearchTermEvent = (action, label, extraFields) => {
    sendEvent({
        action,
        category: `${deviceType}: ${CATEGORIES.SEARCH_TERM}`,
        label,
        ...extraFields,
    });
};

export const sendCampaignEventsEvent = (action, label, extraFields) => {
    sendEvent({action, category: `${deviceType}: ${CATEGORIES.EVENTS}`, label, ...extraFields});
};

export const sendDirectoryEvent = (action, label, extraFields) => {
    sendEvent({
        action,
        category: `${deviceType}: ${CATEGORIES.DIRECTORY}`,
        label,
        ...extraFields,
    });
};

export const sendGeolocateUserEvent = () => {
    sendEvent({
        action: ACTIONS.GEOLOCATE_USER,
        category: CATEGORIES.GENERAL,
    });
};

export const sendMenuAnalyticsEvent = selectedMenuItemId => {
    const action = ACTIONS.TOUCH;

    if (selectedMenuItemId === KIOSK_NAVIGATION.explore.id) {
        sendDeviceEvent(action, 'Explore');
    } else if (selectedMenuItemId === KIOSK_NAVIGATION.search.id) {
        sendSearchEvent(action, 'Open');
    } else if (selectedMenuItemId === KIOSK_NAVIGATION.events.id) {
        sendCampaignEventsEvent(action, 'Open');
    } else if (selectedMenuItemId === KIOSK_NAVIGATION.tenants.id) {
        sendDirectoryEvent(action, 'Open');
    }
};

export const sendPamFeatureTrackingEvent = (event, label, navKey, selectedItem) => {
    const isTenant = selectedItem?.properties?.tenant;

    // is event or location
    let analyticsExtraFields =
        navKey === EXPLORER_NAVIGATION.eventDetails.id
            ? {
                  EventId: selectedItem.id,
                  EventName: selectedItem.name,
              }
            : {
                  LocationId: selectedItem?.properties?.locationId,
                  LocationName: selectedItem?.properties?.displayText,
              };

    // is tenant
    analyticsExtraFields = isTenant
        ? {
              TenantId: selectedItem?.properties?.tenant?.uuid,
              TenantName: selectedItem?.properties?.tenant?.name,
          }
        : analyticsExtraFields;

    if (navKey === EXPLORER_NAVIGATION.eventDetails.id) {
        sendCampaignEventsEvent(event, label, analyticsExtraFields);
    } else if (isTenant) {
        sendDirectoryEvent(ACTIONS.TENANT, label, analyticsExtraFields);
    } else {
        sendSearchEvent(event, label, analyticsExtraFields);
    }
};

export const sendQRScanEvent = signId => {
    sendEvent({
        action: ACTIONS.QR_CODE_SCANNED,
        signId,
    });
};
