import {ACTION_TYPES} from 'Utils/constants';

const dictionaryItemsReducer = (state = [], action) => {
    switch (action.type) {
        case ACTION_TYPES.LOADED_DICTIONARY_ITEMS:
            return action.data;
        default:
            return state;
    }
};

export default dictionaryItemsReducer;
