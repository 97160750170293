import store from 'Data/store';

const svgIcon = `
<div class="select-map-icon">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.58203 5.49996V14.8333L6.66536 12.5L11.332 14.8333L15.4154 12.5V3.16663L11.332 5.49996L6.66536 3.16663L2.58203 5.49996Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.66797 3.16663V12.5" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.332 5.5V14.8333" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div>Select Map</div>
</div>
`;

class ClientSwitcherButton {
    constructor({isVisible, clickHandler}) {
        this.clickHandler = clickHandler;
        const welcomeScreenColors = store.getState()?.pageState?.layout?.config?.settings
            ?.brandingConfig?.welcomeScreen?.colors;
        this.textColor = welcomeScreenColors?.text ?? '#FFFFFF';
        this.backgroundColor = welcomeScreenColors?.background ?? '#000000';
        this.isVisible = isVisible;
    }

    onAdd() {
        const _this = this;

        this._btn = document.createElement('button');
        this._btn.innerHTML = svgIcon;
        this._btn.className = `mapboxgl-ctrl-icon map-box-map`;
        this._btn.style.color = this.textColor;
        this._btn.style.backgroundColor = this.backgroundColor;
        if (!this.isVisible) {
            this._btn.style.display = 'none';
        }
        this._btn.type = 'button';
        this._btn.onclick = _this.clickHandler;

        this._container = document.createElement('div');
        this._container.className = `mapboxgl-ctrl mapboxgl-ctrl-group`;
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this.map = undefined;
    }
}

export default ClientSwitcherButton;
