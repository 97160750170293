import {EXPLORER_PARKING_STATUS_COLOURS} from 'Utils/constants';

// draw the image on the canvas
const drawImage = async (context, imageUrl) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = imageUrl;
    return new Promise(resolve => {
        image.onload = () => {
            context.drawImage(image, 0, 0);
            resolve(image);
        };
    });
};

// use canvas to draw a circle on the right size of the icon
export const makeParkingStatusIcon = async ({imageUrl, parkingStatus}) => {
    const rightPadding = 10;
    const radius = 5; // its a small dot
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const {naturalHeight, naturalWidth} = await drawImage(context, imageUrl);
    const imageHeight = naturalHeight;

    // calculate the x and y coordinates the center of the circle.
    const x = naturalWidth + (EXPLORER_PARKING_STATUS_COLOURS[parkingStatus] ? rightPadding : 0); // right of the icon
    const y = imageHeight / 2; // half height of the icon
    const width = x + radius;

    // only add the circle when the parking status exist
    if (EXPLORER_PARKING_STATUS_COLOURS[parkingStatus]) {
        context.fillStyle = EXPLORER_PARKING_STATUS_COLOURS[parkingStatus];
        context.arc(x, y, radius, 0, 2 * Math.PI);
        context.fill();
    }
    return {
        width,
        height: imageHeight,
        data: new Uint8Array(width * imageHeight * 4),
        render() {
            // update this image's data with data from the canvas
            this.data = context.getImageData(0, 0, this.width, this.height).data;
            return true;
        },
    };
};

export const addImageParkingIcon = async ({map, imageUrl}) => {
    const parkingIcon = await makeParkingStatusIcon({imageUrl});

    if (!map.hasImage(imageUrl)) {
        // add the parking icon with no status
        map.addImage(imageUrl, parkingIcon);
    }

    // generate a parking icon for each status
    for (const parkingStatus of Object.keys(EXPLORER_PARKING_STATUS_COLOURS)) {
        makeParkingStatusIcon({imageUrl, parkingStatus}).then(image => {
            if (!map.hasImage(`${parkingStatus}-${imageUrl}`)) {
                map.addImage(`${parkingStatus}-${imageUrl}`, image);
            }
        });
    }
};
