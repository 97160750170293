import config from 'config.json';
import {MAPBOX_STYLES} from 'Utils/constants';
import log from 'Utils/log';

const safeFetch = async url => {
    try {
        const response = await fetch(url, {cache: 'no-store'});
        if (response.ok) {
            const body = await response.json();
            return {url, lastModifiedTime: body?.modified};
        }
    } catch (e) {
        log.warn(e);
    }
    return {url: undefined, lastModifiedTime: null};
};

export default async (style, clientId) => {
    const cacheBreaker =
        process.env.NODE_ENV === 'test' ? '' : Math.round(Math.random() * 10000, 10);

    const response = await safeFetch(
        new URL(
            `${clientId}/mapbox-styles/${style}-mapbox-style.json?${cacheBreaker}`,
            config.cloudFront
        ).href
    );

    return typeof response.url !== 'undefined'
        ? response
        : {
              url: MAPBOX_STYLES[style],
              lastModifiedTime: null,
          };
};
