import React from 'react';
import ReactDom from 'react-dom';
import DestinationMarker from 'Components/common/MapboxMap/DestinationMarker';
import {connect, Provider} from 'react-redux';
import store from 'Data/store';

const mapStateToProps = (state, ownProps) => {
    let feature;
    if (ownProps.feature) {
        feature = ownProps.feature;
    } else {
        feature = state.pageState.explorerMap?.journey?.stops[1]
            ? state.pageState.explorerMap?.journey?.stops[1]
            : state.pageState.explorerMap.searchLocation;
    }
    const displayText = ownProps.showLabel
        ? feature?.properties?.tenant?.mapLabel || feature?.properties?.mapLabelText
        : '';

    let colors = state.pageState.layout.config?.settings?.mapConfig?.destinationPin?.colors;
    if (!colors) {
        colors = {text: '#FFFFFF', background: '#F50E55'};
    }
    return {
        displayText,
        feature,
        textColor: colors.text,
        backgroundColor: colors.background,
    };
};

const createDestinationMarker = ({selectResultItem, showLabel}) => ({feature}) => {
    const DestinationMarkerContainer = connect(mapStateToProps)(DestinationMarker);
    const destinationMarker = document.createElement('div');

    ReactDom.render(
        <Provider store={store}>
            <DestinationMarkerContainer
                feature={feature}
                showLabel={showLabel}
                selectResultItem={selectResultItem}
            />
        </Provider>,
        destinationMarker
    );

    return destinationMarker;
};

export default createDestinationMarker;
