/**
 * OrderedQueue manages a list of items, sorted by the provided property
 * Items must have the passed in property and an id property
 */
class OrderedQueue {
    #queue = [];

    #scoreProp = '';

    constructor(scoreProp) {
        if (!scoreProp) throw Error('You must provide a score prop name');

        this.#scoreProp = scoreProp;
    }

    upsert(item) {
        const prop = this.#scoreProp;
        const queue = this.#queue;

        if (!queue.length) {
            queue.push(item);
        } else {
            let insertIndex = -1;
            let deleteIndex = -1;

            for (let i = 0; i < queue.length; i++) {
                const queuedNode = queue[i];
                if (queuedNode.id === item.id) {
                    deleteIndex = i;
                }

                if (insertIndex === -1 && queuedNode[prop] <= item[prop]) {
                    insertIndex = i;
                }

                // Once we know these we can finish looping
                if (deleteIndex > 0 && insertIndex > 0) break;
            }

            if (deleteIndex >= 0) {
                queue.splice(deleteIndex, 1);
                // If we're deleting something before the insertion point, adjust it
                if (deleteIndex < insertIndex) {
                    insertIndex--;
                }
            }

            if (insertIndex < 0 || insertIndex === queue.length) {
                // or index is length of the array
                queue.push(item);
            } else {
                queue.splice(insertIndex, 0, item);
            }
        }
    }

    takeNext() {
        return this.#queue.pop();
    }

    get(id) {
        return this.#queue.find(item => item.id === id);
    }
}

export default OrderedQueue;
