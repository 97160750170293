import * as turfHelpers from '@turf/helpers';
import {GEOMETRY_TYPES, LOCATION_TYPES, SELECTED_LOCATION_DISPLAY_NAME} from 'Utils/constants';
import FEATURE_TYPES from 'Utils/featureTypes';

/**
 * Converts a GeoJSON feature to a minimal object
 * @param {Feature} feature
 * @return {object|null}
 */
export const convertFullToMinimal = feature => {
    const minimalLocation = {type: feature.properties.locationType};

    if (feature.properties.locationType === LOCATION_TYPES.PAM) {
        if (feature.properties.locationId) {
            minimalLocation.id = feature.properties.locationId;
        } else {
            // Place-on-Map scenario
            minimalLocation.coords = feature.geometry.coordinates;
            // we copy feature id of PAM venue to result,
            // to use it when we convert it back to full feature
            minimalLocation.id = feature.id;
        }
    } else if (feature.properties.locationType === LOCATION_TYPES.MAPBOX) {
        minimalLocation.coords = feature.geometry.coordinates;
        minimalLocation.ancestors = feature.properties?.ancestors;
        minimalLocation.displayText =
            feature.properties.shortName || feature.properties.displayText;
    } else {
        console.warn('This location does not have a type. That is probably a mistake.');
        return null;
    }

    return minimalLocation;
};

/**
 * Converts a minimal object to a GeoJSON feature representing a PAM or Mapbox location
 * @param {object} minimalLocation
 * @param {FeatureCollection} navMap
 * @return {{geometry: {coordinates, type: string}, id: string, type: string, properties: {displayText, locationType, ancestors}}|null}
 */
export const convertMinimalToFull = ({minimalLocation, navMap}) => {
    if (minimalLocation.type === LOCATION_TYPES.PAM) {
        let fullFeature;
        if (!minimalLocation.coords) {
            // @TODO need add a clientId check here but it seems like it's not used anymore
            fullFeature = navMap.features.find(
                navMapFeature =>
                    navMapFeature.properties.locationId === minimalLocation.id &&
                    navMapFeature.properties.featureType.code !== FEATURE_TYPES.displayPoint.code
            );
        }
        // if minimalLocation has coords, it's Place-On-Map scenario.
        else {
            fullFeature = {
                ...turfHelpers.point(minimalLocation.coords),
                id: minimalLocation.id,
                properties: {
                    displayText: SELECTED_LOCATION_DISPLAY_NAME,
                    locationType: minimalLocation.type,
                },
            };
        }

        if (!fullFeature) {
            // The link could be old and the location removed, so handle the error gracefully
            console.error('An ID was provided for a location that does not exist');
            return null;
        }

        return fullFeature;
    }

    if (minimalLocation.type === LOCATION_TYPES.MAPBOX) {
        const ancestors = Array.isArray(minimalLocation?.ancestors)
            ? {ancestors: minimalLocation.ancestors}
            : {};
        return {
            id: minimalLocation.id ?? minimalLocation.coords.toString(),
            type: 'Feature',
            geometry: {
                type: GEOMETRY_TYPES.POINT,
                coordinates: minimalLocation.coords,
            },
            properties: {
                displayText: minimalLocation.displayText,
                locationType: minimalLocation.type,
                ...ancestors,
            },
        };
    }

    return null;
};

export const getAncestorsDisplayText = ancestors =>
    Array.isArray(ancestors)
        ? ancestors
              .map(
                  ancestor =>
                      typeof ancestor === 'string' ? ancestor : ancestor?.displayText || ''
              )
              ?.filter(ancestor => typeof ancestor === 'string' && ancestor !== '')
              ?.join(', ')
        : null;
