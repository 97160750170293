export const DRAW_MODES = {
    SIMPLE_SELECT: 'simple_select',
    DIRECT_SELECT: 'direct_select',
    SNAP_POINT: 'snap_point',
    SNAP_POLYGON: 'snap_polygon',
    SNAP_LINE: 'snap_line',
    SCENE_SELECT: 'scene_select',
    READ_ONLY: 'read_only',
};

export const GUIDES = {
    VERTICAL: 'VERTICAL',
    HORIZONTAL: 'HORIZONTAL',
    POINT: 'POINT',
};

export const MARKER_IMAGE_SOURCE_ID = 'marker-image-source';

export const MARKER_IMAGE_LAYER_ID = 'marker-image-layer';

export const SNAP_PX = 10;

export const DOOR_WIDTH_KM = 0.9 / 1000; // 90cm door, in km
export const DRIVEWAY_WIDTH_KM = 2.4 / 1000; // 2.4m door, in km
export const ONE_MM = 0.000001; // of 1 km

export const WALL_THICKNESS = 0.1 / 1000; // 10cm wall, in km

export const HISTORY_EVENTS = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    CHANGE_SCENE: 'CHANGE_SCENE',
};

export const EDITOR_MODES = {
    BASE_NAV_MAP: 'BASE_NAV_MAP',
    SCENES_EDITOR: 'SCENES_EDITOR',
};

export const ROUTE_PROPERTIES = [
    'routeAllowsPedestrians',
    'routeAllowsVehicles',
    'routeIsSteps',
    'routeIsNightSafe',
    'routeIsWheelchairAccessible',
    'oneWay',
    'reverseOneWay',
];

export const PREVIEW_SEARCH_FIELDS = {
    DESTINATION: 'searchDestination',
    START: 'searchStart',
};

export const ZOOM_RANGE = [0, 24];
export const ZOOM_SLIDER_RANGE_LABELS = ['Min Zoom', 'Default', 'Max Zoom'];
