import {ACTION_TYPES, NAV_MODES} from 'Utils/constants';
import setJourneyStop from './journey/setJourneyStop';

const setNavMode = navMode => (dispatch, getState) => {
    // When going in to directions mode, if there's a search location, put it in the 'to' field
    if (navMode === NAV_MODES.DIRECTIONS) {
        const currentSearchFeature = getState().pageState.explorerMap.searchLocation;

        if (currentSearchFeature) {
            setJourneyStop({index: 1, feature: currentSearchFeature, zoomToResult: false})(
                dispatch,
                getState
            );
        }
    }

    dispatch({
        type: ACTION_TYPES.SET_NAV_MODE,
        data: navMode,
    });
};

export default setNavMode;
