/**
 * This file has control over a single instance of a Mapbox map.
 * The 'map' object is global in here so any function can be accessed externally and
 * used to perform operations on the map
 * Having everything map related in here also makes mocking for tests easier
 */
import mapboxGl from 'mapbox-gl';
import turfBbox from '@turf/bbox';
import turfBboxPolygon from '@turf/bbox-polygon';
import turfPointInPolygon from '@turf/boolean-point-in-polygon';
import * as turfHelpers from '@turf/helpers';
import turfCenter from '@turf/center';
import cleanCoords from '@turf/clean-coords';
import lodashIsEmpty from 'lodash/isEmpty';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as analytics from 'Utils/analytics';
import {
    ACTION_TYPES,
    CONFIG_DATA_BASE_KEYS,
    EDIT_ELEMENT_ID,
    ERRORS,
    GEOMETRY_TYPES,
    LABEL_DISPLAY_TYPES,
    LABEL_SIZES,
    LOCATION_TYPES,
    MAP_ELEMENT_ID,
    MAP_LAYERS,
    MAP_THEMES,
    MAPBOX_ACCESS_TOKEN,
    MAPBOX_MAX_PITCH,
    NAV_MAP_DEFAULT_CONFIG,
    RENDER_NAV_MAP_TO_MAP_ACTIONS,
    SOURCES,
} from 'Utils/constants';
import {SNAP_PX} from 'Utils/mapEditor/mapEditorConstants';
import ClientSwitcherButton from 'Components/common/MapboxMap/Controls/ClientSwitcherButton';
import CompassButton from 'Components/common/MapboxMap/Controls/CompassButton';
import GeocoderButton from 'Components/common/MapboxMap/Controls/GeocoderButton';
import LabelSizeButton from 'Components/common/MapboxMap/Controls/LabelSizeButton';
import PitchToggle from 'Components/common/MapboxMap/Controls/PitchToggle';
import ThemeButton from 'Components/common/MapboxMap/Controls/ThemeButton';
import * as mapUtils from 'Utils/explorer/mapUtils';
import * as mapboxLayers from 'Styles/mapboxLayers';
import * as map3dManager from 'Utils/explorer/map3dManager';
import log from 'Utils/log';
import {addImageParkingIcon} from 'Utils/parkingStatusIcon';
import {hideMapboxAttribution} from 'Utils/preventAttributionClicks';
import {prependDigitalAssetsUrl} from 'Utils/prependDigitalAssetsUrl';
import pruneObject from 'Utils/pruneObject';
import setupTwoFingerPitch from 'Utils/setupTwoFingerPitch';
import storage from 'Utils/storage';
import {EXPLORER_NAVIGATION, LOCATION_TOO_FAR_MODES} from 'Utils/explorer/explorerConstants';
import * as routeManager from 'Utils/routeManager';
import IMDF_FEATURE_TYPES from 'Utils/imdfFeatureTypes';
import store from 'Data/store';
import {getFeaturesInGroup} from 'Utils/routeManager';

// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxGl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

let map;
let lastZoom = NAV_MAP_DEFAULT_CONFIG.defaultNavMapZoom;
let lastZoomTimeStamp;

export const getMap = () => map;

const mapDataCache = new Map();

const markerCache = {};

let mapToggleControl;

let geolocateControl;

const onLoadQueue = [];
// Mapbox's map.loaded() is not reliable so we track load status manually
let mapLoaded = false;

const selectedFeatures = new Map();

/**
 * Add a function to be called when the map loads, or called immediately
 * if the map has already loaded
 *
 * @param func
 */
export const whenReady = func => {
    if (mapLoaded) {
        func();
    } else {
        onLoadQueue.push(func);
    }
};

export const removeMarkers = markerLayer => {
    if (markerCache[markerLayer] && markerCache[markerLayer].length) {
        markerCache[markerLayer].forEach(marker => marker.remove());
        markerCache[markerLayer].length = 0;
    }
};

/**
 * This adds images to the map so that they can be referenced
 * as 'icon-image' in the label layer
 * For now (March 2019) icons should live in /public/mocks/images. In DEV-4465 they will be defined
 * as fully qualified URLs in PAM OS
 *
 * @param {FeatureCollection} featureCollection
 * @returns {Promise<any>}
 */
const addImagesToMap = async featureCollection => {
    const addImagePromise = ({imageUrl, options}) =>
        new Promise(resolve => {
            map.loadImage(imageUrl, (err, image) => {
                if (err) {
                    log.warn(`Could not load "${imageUrl}"`);
                    resolve(null);
                } else {
                    if (!map.hasImage(imageUrl)) {
                        map.addImage(imageUrl, image, options && options);
                    }
                    resolve(image);
                }
            });
        });

    // Gets a list of location icons
    // Group the icons as parking and non-parking
    const icons = featureCollection.features.filter(feature => feature.properties.iconUrl).reduce(
        (result, feature) => ({
            parkingIcons: {
                ...result.parkingIcons,
                ...(feature.properties.parkingLotConfig && {
                    [feature.properties.iconUrl]: {
                        imageUrl: feature.properties.iconUrl,
                        parkingStatus: feature.properties.parkingLotConfig?.status,
                    },
                }),
            },
            nonParkingIcons: {
                ...result.nonParkingIcons,
                ...(!feature.properties.parkingLotConfig &&
                    // not already in the map
                    !map.listImages().includes(feature.properties.iconUrl) && {
                        [feature.properties.iconUrl]: {
                            imageUrl: feature.properties.iconUrl,
                        },
                    }),
                ...(!feature.properties.parkingLotConfig &&
                    feature.properties.events?.[0]?.navMapIconUrl &&
                    !map.listImages().includes(feature.properties.events[0].navMapIconUrl) && {
                        [feature.properties.events[0].navMapIconUrl]: {
                            imageUrl: feature.properties.events[0].navMapIconUrl,
                        },
                    }),
            },
        }),
        {nonParkingIcons: {}, parkingIcons: {}}
    );

    // add the invisible spacer
    icons.nonParkingIcons['/images/icon-spacer-bg.png'] = {
        imageUrl: '/images/icon-spacer-bg.png',
    };

    // make sure the two icons groups are mutually exclusive
    // parking icons should not exist in none parking icons
    // otherwise it will be loaded twice
    Object.keys(icons.parkingIcons).forEach(parkingIcon => {
        delete icons.nonParkingIcons[parkingIcon];
    });

    // generate and add the parking icons
    Object.values(icons.parkingIcons).forEach(({imageUrl, parkingStatus}) =>
        addImageParkingIcon({map, imageUrl, parkingStatus})
    );

    const nonParkingIcons = Object.values(icons.nonParkingIcons);

    // fallback image.
    ['', 'null'].forEach(fallback => {
        if (!map.listImages().includes(fallback)) {
            map.addImage(fallback, {width: 1, height: 1, data: new Uint8Array(4)});
        }
    });

    [
        // These are only used in Map Editor, but are harmless to Explorer
        {imageUrl: 'images/single-arrow-32.png'},
        {imageUrl: 'images/double-arrow-32.png'},
        {imageUrl: 'images/dot.png', options: {sdf: 'true'}}, // set as SDF image for icon-color to work
        {imageUrl: 'images/icon-link-white-72.png', options: {sdf: true}},
    ].forEach(
        icon =>
            // add icon only it does not exist in the map
            !map.listImages().includes(icon.imageUrl) && nonParkingIcons.push(icon)
    );

    // and returns when everything is finished loading
    return Promise.all(nonParkingIcons.map(addImagePromise));
};

/**
 * Add a label layer to the map, and the associated images
 *
 * @param {object} props
 * @param {FeatureCollection} props.featureCollection
 * @param {string} props.mapTheme
 * @param {string} props.labelSize
 * @return {Promise<*>}
 */
export const addLabelLayer = ({featureCollection, mapTheme, labelSize}) =>
    addImagesToMap(featureCollection).finally(() => {
        // Labels are bound to icons, so we add images first
        // We use 'finally' because we don't care if it resolves or rejects (when a bad image
        // URL is provided, we still want to add the label layer).
        mapboxLayers.getLabelLayers({mapTheme, labelSize}).forEach(layer => {
            if (!map.getLayer(layer.id) && layer && map.getSource(SOURCES.LABELS)) {
                map.addLayer(layer);
            }
        });

        // move road labels layer to the top
        ['road-network', 'road-label'].forEach(
            layerName => map.getLayer(layerName) && map.moveLayer(layerName)
        );
    });

export const setLabelSize = labelSizeMultiplier =>
    getMap().setLayoutProperty(
        MAP_LAYERS.LABELS,
        'text-size',
        mapboxLayers.getLabelSizeSpecRule(labelSizeMultiplier)
    );

/**
 *
 * @param {Point|Polygon|LngLat} pointOrLngLat
 * @param customMarker
 * @returns {mapboxgl.Marker}
 */
export const addMarkerToMap = (pointOrLngLat, customMarker) => {
    let lngLat;
    let offset = [0, 0]; // MAPBOX locations don't need an offset, so we start with that

    if (pointOrLngLat.lng && pointOrLngLat.lat) {
        lngLat = [pointOrLngLat.lng, pointOrLngLat.lat];
    } else if (pointOrLngLat.geometry && pointOrLngLat.geometry.type === GEOMETRY_TYPES.POINT) {
        lngLat = pointOrLngLat.geometry.coordinates;
    } else if (
        pointOrLngLat.geometry &&
        [GEOMETRY_TYPES.POLYGON, GEOMETRY_TYPES.LINE_STRING].includes(pointOrLngLat.geometry.type)
    ) {
        lngLat = turfCenter(pointOrLngLat).geometry.coordinates;
    } else if (Array.isArray(pointOrLngLat) && pointOrLngLat.length === 2) {
        lngLat = pointOrLngLat;
    } else {
        console.error('You must provide a Point or a LngLat object. Got this:', pointOrLngLat);
    }

    if (pointOrLngLat.properties) {
        // This is a feature, so might need some offset
        offset = [0, -12];

        if (pointOrLngLat.properties.icon) {
            if (pointOrLngLat.properties.labelDisplayType === LABEL_DISPLAY_TYPES.ICON) {
                // only icon needs to be shown
                offset = [0, -10];
            } else if (pointOrLngLat.properties.labelDisplayType === LABEL_DISPLAY_TYPES.BOTH) {
                // both icon and text need to be shown
                offset = [0, -24];
            } else {
                // only text needs to be shown
                offset = [0, 0];
            }
        }
    }

    const markerOptions = {
        anchor: 'bottom',
        offset,
    };
    if (customMarker) {
        markerOptions.element = customMarker;
    } else {
        markerOptions.color = '#ec332e'; // TODO (davidg): not hard coded
    }
    return new mapboxGl.Marker(markerOptions).setLngLat(lngLat).addTo(map);
};

const fitMultiPointToMap = ({multiPoint, maxZoom}) => {
    const bbox = turfBbox(multiPoint);

    // We want to zoom/pan to fit all markers on the map.
    // But if the points are close to each other,
    // we don't want to zoom all the way in. So we restrict the zoom, then
    // pan/zoom, then remove the zoom restriction
    map.setMaxZoom(maxZoom);
    map.fitBounds(bbox, {
        padding: window.innerWidth / 8,
    });
    map.setMaxZoom(Infinity);
};

/**
 * Adds markers to the map and holds a reference so that they can be removed later.
 * Also, zooms to fit the markers
 * @param {object} props
 * @param {Array<*>} props.points
 * @param {Number} [props.maxZoom]
 * @param {String} [props.markerLayer] - provide a name if you want to remove the markers later
 */
export const addPointsToMap = ({
    points,
    maxZoom = 16,
    markerLayer = 'misc-markers',
    zoomToResult = true,
    customMarker,
    markerFeature,
}) => {
    const markers = markerCache[markerLayer] || [];

    const pointsToAdd = new Map();

    points.forEach(point => {
        if (point.properties?.routeViaGroupId) {
            const featuresInGroup = getFeaturesInGroup(point.properties.routeViaGroupId);
            if (featuresInGroup) {
                featuresInGroup.forEach(featureInGroup => {
                    pointsToAdd.set(featureInGroup.id, featureInGroup);
                });
            }
        } else {
            pointsToAdd.set(point.id, point);
        }
    });

    pointsToAdd.forEach(pointToAdd => {
        markers.push(
            addMarkerToMap(
                pointToAdd,
                customMarker instanceof Function
                    ? customMarker({feature: markerFeature || pointToAdd})
                    : customMarker
            )
        );
    });

    if (zoomToResult) {
        // For simplicity, rather that pan/zoom based on the passed in points (which could be
        // GeoJSON objects or LngLat coordinates, we instead get the lngLat from the markers we just
        // created
        if (markers.length === 1) {
            map.easeTo({
                center: markers[0].getLngLat(),
                zoom: maxZoom,
            });
        } else {
            // Get the bounding box for the multiple markers
            const multiPoint = turfHelpers.multiPoint(
                markers.map(marker => marker.getLngLat().toArray())
            );
            fitMultiPointToMap({multiPoint, maxZoom});
        }
    }

    markerCache[markerLayer] = markers;
    return markers;
};

/**
 * rotates the map in a loop for idle screen
 *
 * @param {Number} timestamp
 */
export const rotate = timestamp => {
    if (mapLoaded) map.rotateTo((timestamp / 200) % 360, {duration: 0});
};

/**
 * Sets the feature state of a feature
 *
 * @param {object} props
 * @param {Feature} props.feature - the feature to change
 * @param {object} props.state - the state to pass to Mapbox setFeatureState
 */
export const setFeatureState = ({feature, ...state}) => {
    if (!feature?.id || feature.properties.locationType !== LOCATION_TYPES.PAM) {
        return;
    }
    map.setFeatureState({source: SOURCES.LABELS, id: feature.id}, state);
    map.setFeatureState({source: SOURCES.NAV_MAP, id: feature.id}, state);

    if (feature.properties.featureType && 'defaultExtrusion' in feature.properties.featureType) {
        if (state.selected) {
            // leave properties required for rendering MAP_LAYERS.SELECTED_FEATURE_FILL_EXTRUSION layer
            const reducedFeature = {
                id: feature.id,
                type: feature.type,
                geometry: feature.geometry,
                properties: {
                    featureType: {
                        defaultExtrusion: feature.properties.featureType.defaultExtrusion,
                    },
                    styleExtrusionBase: feature.properties.styleExtrusionBase,
                    styleExtrusionHeight: feature.properties.styleExtrusionHeight,
                    styleSelectHeight: feature.properties.styleSelectHeight,
                },
            };
            selectedFeatures.set(reducedFeature.id, {feature: reducedFeature, state});
        } else {
            selectedFeatures.delete(feature.id);
        }
        // we cannot change data in the data source until the Mapbox map is loaded so use whenReady callback
        whenReady(() => {
            map.getSource(SOURCES.SELECTED_FEATURES).setData(
                turfHelpers.featureCollection(
                    Array.from(selectedFeatures.values()).map(featureState => featureState.feature)
                )
            );
            for (const selectedFeatureState of selectedFeatures.values()) {
                map.setFeatureState(
                    {source: SOURCES.SELECTED_FEATURES, id: selectedFeatureState.feature.id},
                    selectedFeatureState.state
                );
            }
        });
    }
};

export const setLabelSource = featureCollection => {
    const labelData = turfHelpers.featureCollection(
        featureCollection.features.filter(
            feature =>
                feature.properties.isDigitalSign || // We want an icon label for digital signs
                (feature.properties.mapLabelText &&
                    !feature.properties.displayPoint &&
                    feature.properties.labelDisplayType !== LABEL_DISPLAY_TYPES.NONE)
        )
    );

    mapDataCache.set(SOURCES.LABELS, labelData);
    map?.getSource(SOURCES.LABELS)?.setData(labelData);
};

export const setDistanceRingSource = featureCollection => {
    mapDataCache.set(SOURCES.DISTANCE_RING, featureCollection);
    map.getSource(SOURCES.DISTANCE_RING).setData(featureCollection);
};

export const setLayerLineColor = (layerId, color) => {
    map.setPaintProperty(layerId, 'line-color', color);
};

export const project = lngLat => map.project(lngLat);

/**
 * To simplify code that passes GeoJSON objects to functions in this module, we convert
 * many combinations of GeoJSON objects in a single FeatureCollection
 * @param {FeatureCollection|Feature|Array<FeatureCollection>|Array<Feature>} someGeoJson
 * @return {FeatureCollection}
 */
const someGeoJsonToFeatureCollection = someGeoJson => {
    if (Array.isArray(someGeoJson)) {
        const features = [];

        someGeoJson.forEach(geoJson => {
            if (geoJson.type === 'FeatureCollection') {
                features.push(...geoJson.features);
            } else if (geoJson.type === 'Feature') {
                features.push(geoJson);
            }
        });

        return turfHelpers.featureCollection(features);
    }
    if (someGeoJson.type === 'FeatureCollection') {
        return someGeoJson;
    }
    if (someGeoJson.type === 'Feature') {
        return turfHelpers.featureCollection([someGeoJson]);
    }

    console.error('This is not valid GeoJSON:', someGeoJson);
    return null;
};

/**
 * @param {FeatureCollection|Feature|Array<FeatureCollection>|Array<Feature>} someGeoJson
 */
export const renderRoute = someGeoJson => {
    const cleanedGeoJson = someGeoJson.map(feature => cleanCoords(feature));
    const data = someGeoJsonToFeatureCollection(cleanedGeoJson);
    mapDataCache.set(SOURCES.ROUTES, data);
    if (map.getSource(SOURCES.ROUTES)) {
        map.getSource(SOURCES.ROUTES).setData(data);
    }
};

export const clearRoutes = () => {
    if (!map) {
        return;
    }
    // We don't remove the route source, this would error since we have a layer tied to it
    // Instead we just empty out the feature collection
    if (map.getSource(SOURCES.ROUTES)) {
        map.getSource(SOURCES.ROUTES).setData(turfHelpers.featureCollection([]));
    }
    mapDataCache.delete(SOURCES.ROUTES);
};

const setNavMapIcons = ({navMap, mapTheme}) => {
    navMap.features.forEach(feature => {
        if (feature.properties.navMapDarkIconUrl) {
            // eslint-disable-next-line no-param-reassign
            feature.properties.iconUrl =
                mapTheme === MAP_THEMES.EXPLORER_DARK
                    ? prependDigitalAssetsUrl(feature.properties.navMapDarkIconUrl)
                    : prependDigitalAssetsUrl(feature.properties.navMapLightIconUrl);
        }
        if (feature.properties.tenant?.iconUrls) {
            const iconUrls = feature.properties.tenant.iconUrls;
            if (iconUrls.darkMapUrl && mapTheme === MAP_THEMES.EXPLORER_DARK) {
                // eslint-disable-next-line no-param-reassign
                feature.properties.iconUrl = prependDigitalAssetsUrl(iconUrls.darkMapUrl);
            } else if (iconUrls.lightMapUrl && mapTheme === MAP_THEMES.EXPLORER_LIGHT) {
                // eslint-disable-next-line no-param-reassign
                feature.properties.iconUrl = prependDigitalAssetsUrl(iconUrls.lightMapUrl);
            }
        }
        if (feature.properties.events?.[0]?.navMapDarkIconUrl) {
            // eslint-disable-next-line no-param-reassign
            feature.properties.events[0].navMapIconUrl =
                mapTheme === MAP_THEMES.EXPLORER_DARK
                    ? prependDigitalAssetsUrl(feature.properties.events[0].navMapDarkIconUrl)
                    : prependDigitalAssetsUrl(feature.properties.events[0].navMapLightIconUrl);
        }
    });
};

/**
 * Adds the navMap source and layer, including labels. This is called by DSM and Explorer.
 * Map Editor uses Mapbox Draw to add the navMap
 *
 * @param {object} props
 * @param {FeatureCollection} props.navMap
 * @param {string} props.mapTheme
 */
export const renderNavMapToMap = ({
    navMap,
    mapTheme,
    action = RENDER_NAV_MAP_TO_MAP_ACTIONS.UPDATE,
    labelSize = LABEL_SIZES.REGULAR,
}) => {
    map?.getSource(SOURCES.NAV_MAP)?.setData(navMap);
    mapDataCache.set(SOURCES.NAV_MAP, navMap);
    setNavMapIcons({navMap, mapTheme});
    addLabelLayer({featureCollection: navMap, mapTheme, labelSize});

    if (![MAP_THEMES.DSM, MAP_THEMES.PM].includes(mapTheme)) {
        if (action === RENDER_NAV_MAP_TO_MAP_ACTIONS.INITIALISE) {
            map3dManager.maybeInitializeAndLoad3dModels({featureCollection: navMap, mapTheme});
        } else if (action === RENDER_NAV_MAP_TO_MAP_ACTIONS.UPDATE) {
            map3dManager.update3dModels({featureCollection: navMap, mapTheme});
        }
    }

    setLabelSource(navMap);
};

/**
 * Returns all features under a user-clicked mouse pointer
 *
 * @param {object} point
 * @return {Array<Feature>}
 */
export const getFeaturesUnderMouse = point => {
    const bbox = [[point.x - 5, point.y - 5], [point.x + 5, point.y + 5]];
    return map.queryRenderedFeatures(bbox);
};

export const getIsMovedFromSearchResults = () => {
    return map.moveFromSearchResults;
};

export const getIsMoving = () => {
    return map.isMoving();
};

export const setIsMovedFromSearchResults = val => {
    map.moveFromSearchResults = val;
};

/**
 * Zoom the map to fit a give set of GeoJSON objects
 * @param {FeatureCollection|Feature|Array<FeatureCollection>|Array<Feature>} someGeoJson
 * @param mapConfig
 * @param [options]
 * @param [options.topPadding]
 * @param [options.maxZoom]
 * @param [options.substituteRouteViaGroupFeatures]
 */
export const fitToGeoJson = (
    someGeoJson,
    {
        topPadding = 300,
        bottomPadding = 50,
        leftPadding = 0,
        rightPadding = 0,
        maxZoom = 19,
        substituteRouteViaGroupFeatures = false,
    } = {},
    mapConfig = {}
) => {
    const featureCollection = someGeoJsonToFeatureCollection(someGeoJson);
    if (
        substituteRouteViaGroupFeatures &&
        featureCollection &&
        featureCollection.features.length > 0
    ) {
        const featureMap = new Map();
        let featureCollectionShouldBeUpdated = false;
        featureCollection.features.forEach(feature => {
            if (feature.properties?.routeViaGroupId) {
                const featuresInGroup = getFeaturesInGroup(feature.properties.routeViaGroupId);
                if (featuresInGroup) {
                    featuresInGroup.forEach(featureInGroup => {
                        featureMap.set(featureInGroup.id, featureInGroup);
                    });
                }
                featureCollectionShouldBeUpdated = true;
            } else {
                featureMap.set(feature.id, feature);
            }
        });

        if (featureCollectionShouldBeUpdated) {
            featureCollection.features = [];
            featureMap.forEach(feature => {
                featureCollection.features.push(feature);
            });
        }
    }

    // For a fresh new feature collection, don't try anything
    if (!featureCollection.features.length && !featureCollection.bbox) return;

    const bbox = featureCollection.bbox || turfBbox(featureCollection);

    setIsMovedFromSearchResults(true);

    // We often have UI elements at the top of the page
    // leaving extra padding at the top is reasonable on phones, negligible on desktop
    map.fitBounds(bbox, {
        padding: {
            top: topPadding,
            bottom: bottomPadding,
            left: leftPadding,
            right: rightPadding,
        },
        linear: false,
        duration: 300,
        maxZoom, // in case the FC is a point, we don't want to zoom to 24
        ...mapConfig,
    });
};

/**
 * Check whether the provided bbox is within the current viewport
 * @param {Array<Number>} bbox - a GeoJSON bbox
 */
export const mapContainsBbox = ([west, south, east, north]) => {
    const mapBounds = map.getBounds();

    const westIsIn = west > mapBounds.getWest() && west < mapBounds.getEast();
    const eastIsIn = east > mapBounds.getWest() && east < mapBounds.getEast();
    const southIsIn = south > mapBounds.getSouth() && south < mapBounds.getNorth();
    const northIsIn = north > mapBounds.getSouth() && north < mapBounds.getNorth();

    return (westIsIn || eastIsIn) && (southIsIn || northIsIn);
};

/**
 * Check whether the provided lat/lng is within the current viewport
 * @param {Array<Number>} coordinates - a lat lng coordinates
 */
export const mapContainsCoordinates = coordinates => map.getBounds().contains(coordinates);

export const userLocationInNavMap = point => {
    const navMapBbox =
        map.getSource(SOURCES.NAV_MAP)?.bbox || map.getSource(SOURCES.NAV_MAP)?._data?.bbox;
    if (navMapBbox) {
        const navMapBboxPolygon = turfBboxPolygon(navMapBbox);
        return turfPointInPolygon(point, navMapBboxPolygon);
    }

    return false;
};

export const mapVenueContainsPoint = point => {
    const venue = map
        .getSource(SOURCES.NAV_MAP)
        ?._data?.features?.find(
            feature =>
                feature.properties?.featureType?.imdfFeatureType?.code ===
                IMDF_FEATURE_TYPES.venue.code
        );
    if (venue) {
        return turfPointInPolygon(point, venue);
    }
    return false;
};
/**
 * Returns true if two coordinates are within snapping distance of each other
 *
 * @param {Array<number>} coords1
 * @param {Array<number>} coords2
 * @return {boolean}
 */
export const areSimilarCoords = (coords1, coords2) => {
    if (mapUtils.areSameCoords(coords1, coords2)) return true; // faster than projecting

    const point1 = map.project(coords1);
    const point2 = map.project(coords2);
    return Math.abs(point1.x - point2.x) < SNAP_PX && Math.abs(point1.y - point2.y) < SNAP_PX;
};

const addSourcesAndLayers = ({editMode, mapTheme}) => {
    // You can't have a layer without the matching source.
    // So we add some placeholder sources, then the layers

    // need to be the first layer & source
    if (!editMode) {
        map.addSource(SOURCES.SEARCH_BOUNDARY, {
            type: 'geojson',
            data: turfHelpers.featureCollection([]),
        });
    }

    map.addSource(SOURCES.NAV_MAP, {
        type: 'geojson',
        data: turfHelpers.featureCollection([]),
    });

    map.addSource(SOURCES.LABELS, {
        type: 'geojson',
        data: turfHelpers.featureCollection([]),
    });

    // Some sources/layers aren't applied in Map Editor
    if (!editMode) {
        map.addSource(SOURCES.ROUTES, {
            type: 'geojson',
            data: turfHelpers.featureCollection([]),
        });

        map.addSource(SOURCES.DISTANCE_RING, {
            type: 'geojson',
            data: turfHelpers.featureCollection([]),
        });

        map.addSource(SOURCES.SELECTED_FEATURES, {
            type: 'geojson',
            data: turfHelpers.featureCollection([]),
        });

        mapboxLayers.getNavMapLayers(mapTheme).forEach(layer => {
            map.addLayer(layer);
        });
    } else {
        map.addSource(SOURCES.MAP_BOUNDARY, {
            type: 'geojson',
            data: turfHelpers.featureCollection([]),
        });

        map.addLayer({
            id: MAP_LAYERS.MAP_BOUNDARY,
            type: 'fill',
            source: SOURCES.MAP_BOUNDARY,
            layout: {},
            paint: {
                'fill-color': '#999',
                'fill-opacity': 0.7,
            },
        });
    }
};

// TODO (davidg): this fails if you click the theme icon immediately after it appears
export const setStyle = ({navMap, mapboxStyles, mapTheme, labelSize}) =>
    new Promise(resolve => {
        const labelLayerPromises = [];
        const featureStates = [];

        // map.setStyle() will remove all features states. Save the feature
        // states now and add them back later
        navMap.features.forEach(feature => {
            const state = pruneObject(
                map.getFeatureState({source: SOURCES.NAV_MAP, id: feature.id})
            );
            if (state) {
                featureStates.push({feature, state});
            }
        });

        // map.setStyle() will remove all sources and layers. So we must add them
        // back once the new style has loaded
        map.once('style.load', () => {
            addSourcesAndLayers({editMode: false, mapTheme});
            setNavMapIcons({navMap, mapTheme});
            map3dManager.maybeInitializeAndLoad3dModels({featureCollection: navMap, mapTheme});

            for (const entry of mapDataCache) {
                const [sourceId, sourceData] = entry;
                map.getSource(sourceId).setData(sourceData);

                if (sourceId === SOURCES.NAV_MAP) {
                    labelLayerPromises.push(
                        addLabelLayer({featureCollection: navMap, mapTheme, labelSize})
                    );
                }
            }
            // store only light or dark theme
            if ([MAP_THEMES.EXPLORER_DARK, MAP_THEMES.EXPLORER_LIGHT].includes(mapTheme)) {
                storage.setItem('mapTheme', mapTheme);
            }

            // add feature states from the previous map style
            featureStates.forEach(({feature, state}) => setFeatureState({feature, ...state}));
            Promise.all(labelLayerPromises).then(resolve);
        });

        map.setStyle(mapboxStyles[mapTheme], {diff: false});
    });

export const updateToggleControl = mapConfig => mapToggleControl?.updateMapConfig(mapConfig);

export const toggleThemeControl = displayMapStyleControl => {
    if (mapboxGl.supported() && map) {
        // make sure the map is already loaded as well as the controls
        const themeControlElement =
            document.getElementsByClassName('map-box-theme-switch-light') ||
            document.getElementsByClassName('map-box-theme-switch-dark');

        if (themeControlElement.length > 0) {
            if (displayMapStyleControl) {
                themeControlElement[0].style.display = 'unset';
            } else {
                themeControlElement[0].style.display = 'none';
            }
        }
    }
};

export const resize = () => {
    // use a timeout as we give containers a tick to
    // resize before we fire map resize to fit them.
    setTimeout(() => map?.resize(), 10);
};

export const initMap = ({
    mapboxStyles,
    editMode = false,
    hideAttribution = false,
    mapTheme,
    mapConfig = {},
    controls,
    outOfBoundsHandler,
    customAttributionText: customAttribution,
    showExplorerAttribution,
}) => {
    let locationSetFlag = false;
    if (mapboxGl.supported()) {
        try {
            map = new mapboxGl.Map({
                accessToken: MAPBOX_ACCESS_TOKEN,
                container: !editMode ? MAP_ELEMENT_ID : EDIT_ELEMENT_ID,
                style: mapboxStyles[mapTheme],
                hash: true,
                keyboard: !editMode, // Don't allow keyboard shortcuts in Map Editor
                attributionControl: false, // supplied below
                pitchWithRotate: !editMode,
                antialias: true,
                ...mapConfig,
                zoom: mapConfig?.zoom ?? NAV_MAP_DEFAULT_CONFIG.defaultNavMapZoom,
                maxZoom: mapConfig?.maxZoom ?? NAV_MAP_DEFAULT_CONFIG.maxNavMapZoom,
                minZoom: mapConfig?.minZoom ?? NAV_MAP_DEFAULT_CONFIG.minNavMapZoom,
                maxPitch: MAPBOX_MAX_PITCH,
            });

            // On kiosks, we will disable external links
            if (hideAttribution) {
                // So we hide this and add our own in <Explorer />
                hideMapboxAttribution();
            }

            if (showExplorerAttribution) {
                map.addControl(
                    new mapboxGl.AttributionControl({
                        compact: true,
                        customAttribution,
                    }),
                    'bottom-left'
                );
            }
        } catch (e) {
            log.error(e);
        }

        map.showPadding = storage.getIsDebug();

        // For debugging
        window.pamMap = map;

        if (!lodashIsEmpty(mapConfig)) {
            controls.forEach(control => {
                switch (control.name) {
                    case 'clientSwitcher':
                        map.addControl(
                            new ClientSwitcherButton({
                                isVisible: control.isVisible,
                                clickHandler: control.clickHandler,
                            }),
                            'top-right'
                        );
                        break;
                    case 'zoom':
                        map.addControl(
                            new mapboxGl.NavigationControl({
                                showCompass: false,
                            }),
                            'top-right'
                        );
                        break;
                    case 'pitch':
                        // Remove the native mapbox compass because we can't disable its dragging functionality
                        // We only want it to be clickable, no drag, no touch-start
                        mapToggleControl = new PitchToggle({
                            map,
                            mapConfig,
                            minPitchZoom: 11,
                        });
                        map.addControl(mapToggleControl, 'top-right');
                        break;
                    case 'theme':
                        map.addControl(
                            new ThemeButton({
                                map,
                                mapTheme,
                                clickHandler: control.clickHandler,
                            }),
                            'top-right'
                        );
                        break;
                    case 'labelSize':
                        map.addControl(new LabelSizeButton(control.clickHandler), 'top-right');
                        break;
                    case 'compass':
                        map.addControl(
                            new CompassButton({
                                map,
                                mapBearing: map.getBearing(),
                                clickHandler: control.clickHandler,
                            }),
                            'top-right'
                        );
                        map.centerMapToPam = control.clickHandler;
                        break;
                    case 'geoLocation':
                        geolocateControl = new GeocoderButton({
                            positionOptions: {
                                enableHighAccuracy: true,
                                timeout: 5000,
                            },
                            trackUserLocation: true,
                            showAccuracyCircle: true,
                            fitBoundsOptions: {
                                maxZoom: control.zoom,
                            },
                            clickHandler: control.clickHandler,
                            errorHandler: control.errorHandler,
                        });
                        map.addControl(geolocateControl, 'top-right');

                        geolocateControl.on('outofmaxbounds', () => {
                            if (!geolocateControl.outOfBounds) {
                                outOfBoundsHandler({mode: LOCATION_TOO_FAR_MODES.MY_LOCATION});
                                geolocateControl.outOfBounds = true;
                                geolocateControl._watchState = 'OFF';
                                geolocateControl._localState = 'OFF';
                                geolocateControl._geolocateButton.className =
                                    'mapboxgl-ctrl-geolocate';
                            }
                        });
                        geolocateControl.on('geolocate', evt => {
                            window.currentUserLocation = {
                                lat: evt.coords.latitude,
                                lng: evt.coords.longitude,
                                accuracy: evt.coords.accuracy,
                                altitude: evt.coords.altitude,
                                altitudeAccuracy: evt.coords.altitudeAccuracy,
                                heading: evt.coords.heading,
                                speed: evt.coords.speed,
                            };
                            // just do this once
                            if (!locationSetFlag) {
                                locationSetFlag = true;
                                store.dispatch({
                                    type: ACTION_TYPES.EXPLORER.USER_LOCATION_AVAILABLE,
                                    data: true,
                                });
                            }
                        });
                        geolocateControl.on('trackuserlocationstart', () => {
                            window.userMovedMap = false;
                            log('tracking geo location start');
                        });
                        geolocateControl.on('trackuserlocationend', () => {
                            log('tracking geo location end');
                        });
                        geolocateControl.on('error', e => {
                            log('e', e);
                        });

                        if (
                            control.auto &&
                            'geolocation' in navigator &&
                            storage.getItem('hasAllowedLocation')
                        ) {
                            map.on('load', () => {
                                geolocateControl.trigger();
                            });
                        }

                        window.geolocateControl = geolocateControl;

                        break;
                    default: // do nothing
                }
            });
        }

        map.on('load', () => {
            mapLoaded = true;
            map.resize();

            setupTwoFingerPitch(map);
            addSourcesAndLayers({editMode, mapTheme});

            if (editMode && map.getPitch() > 0) {
                map.easeTo({
                    pitch: 0,
                    duration: 10,
                });
            }

            map.on('zoomstart', () => {
                lastZoom = map.getZoom();
            });

            map.on('zoomend', e => {
                const currentTimeStamp = Math.floor(e.originalEvent?.timeStamp / 1000);
                if (lastZoomTimeStamp === currentTimeStamp) return;
                const type = lastZoom > map.getZoom() ? 'Zoom Out' : 'Zoom In';

                // on touch screens zoom end event gets called twice for each finger
                // this check ensures we dont sent 2 analytics events in such cases
                lastZoomTimeStamp = currentTimeStamp;

                if (e.originalEvent?.type === 'click') {
                    analytics.sendDeviceEvent(analytics.ACTIONS.TOUCH, type);
                }
                if (e.originalEvent?.type === 'touchend' || e.originalEvent?.type === 'wheel') {
                    analytics.sendMapEvent(analytics.ACTIONS.PINCH, type);
                }
                if (e.originalEvent?.type === 'dblclick') {
                    analytics.sendMapEvent(analytics.ACTIONS.DOUBLE_TAP, type);
                }
            });
            // Fire functions queued elsewhere in the app.
            onLoadQueue.forEach(func => func());

            // only explorer and kiosk have mapConfig
            if (Object.keys(mapConfig)) {
                // Create a tooltip popup, but don't add it to the map yet.
                const popup = new mapboxGl.Popup({
                    closeButton: false,
                    closeOnClick: false,
                });

                map.on('mouseenter', 'pam-route-transition-point', e => {
                    const {tooltipText} = e.features[0].properties;

                    // only show tooltip if there is description
                    if (tooltipText?.length > 0) {
                        // Change the cursor style as a UI indicator.
                        map.getCanvas().style.cursor = 'pointer';

                        const coordinates = e.features[0].geometry.coordinates.slice();
                        // Ensure that if the map is zoomed out such that multiple
                        // copies of the feature are visible, the popup appears
                        // over the copy being pointed to.
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }

                        // Populate the popup and set its coordinates
                        // based on the feature found.
                        popup
                            .setLngLat(coordinates)
                            .setHTML(tooltipText)
                            .addTo(map);
                    }
                });

                map.on('mouseleave', 'pam-route-transition-point', () => {
                    map.getCanvas().style.cursor = '';
                    popup.remove();
                });
            }
        });
    } else {
        throw Error(ERRORS.WEB_GL_NOT_SUPPORTED);
    }

    return map;
};

// Required only to reset this between tests
export const resetMapLoaded = () => {
    mapLoaded = false;
    onLoadQueue.length = 0;
};

export const getMapCenter = (feature, signConfig) => {
    const [lng, lat] = feature.geometry.coordinates;

    // Prioritise the sign's mapConfig.centerCoordinates whenever it's available
    return signConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE].mapConfig?.centerCoordinates
        ? signConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE].mapConfig.centerCoordinates
        : {lng, lat};
};

export const updateMap = ({
    navigation,
    setJourneyStop,
    navigate,
    mapDataForKiosk,
    setIsMapUpdateAvailable,
}) => {
    let selectedItemForCard = {};

    if (navigation.item) {
        if (navigation.item.type === 'Feature') {
            if (navigation.item.properties.locationType === LOCATION_TYPES.MAPBOX) {
                selectedItemForCard = navigation.item;
            } else {
                // find the location selected
                selectedItemForCard = mapDataForKiosk.navMap.features.find(
                    ({properties: {isDisplayPoint, hideFromSearch, id}}) =>
                        !isDisplayPoint && !hideFromSearch && id === navigation.item.properties.id
                );
            }
        } else {
            // find the event selected
            selectedItemForCard = mapDataForKiosk.events.find(({id}) => id === navigation.item.id);
            if (selectedItemForCard && selectedItemForCard?.feature?.properties) {
                selectedItemForCard.feature.properties.events = [];
            }
        }
    }

    let key = navigation.key;
    let item = selectedItemForCard;
    if (
        selectedItemForCard === undefined &&
        [
            null,
            EXPLORER_NAVIGATION.featureSummary.id,
            EXPLORER_NAVIGATION.eventDetails.id,
            EXPLORER_NAVIGATION.searchEvent.id,
        ].includes(navigation.key)
    ) {
        key = null;
        item = {};
        setJourneyStop({index: 1, feature: null});
    }
    navigate(key, {item, saveSearchState: true});

    setIsMapUpdateAvailable(false);

    if (routeManager.isReady() && mapDataForKiosk.routingFeatures) {
        routeManager.cleanup();
        routeManager.init(mapDataForKiosk.routingFeatures, mapDataForKiosk.navMap.features);
        if (lodashIsEmpty(selectedItemForCard) && !navigation.key === 'directions') {
            setJourneyStop({index: 1, feature: null});
        }
    }
};
