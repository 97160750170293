import isPlainObject from 'Utils/isPlainObject';

const isNullish = item => typeof item === 'undefined' || item === null;
const isNotNullish = item => !isNullish(item);

/**
 * Removes values/properties that are null, undefined, empty arrays or empty objects.
 * Object properties with a nullish value are removed.
 *
 * @param {*} [item]
 * @param makeEmptyArrayUndefined - in some cases like slot.locationArray, we want an empty array instead of undefined
 */
const pruneObject = (item, makeEmptyArrayUndefined = true) => {
    // Return nullish items as undefined
    if (isNullish(item)) return undefined;

    // Handle arrays
    if (Array.isArray(item)) {
        const resultArray = [];
        let didModifyArray = false;

        // Parse each item in the array
        item.forEach(arrayItem => {
            const parsedItem = pruneObject(arrayItem, makeEmptyArrayUndefined);

            // If the parsed value isn't nullish, add it to the result array
            if (isNotNullish(parsedItem)) {
                resultArray.push(parsedItem);

                if (parsedItem !== arrayItem) didModifyArray = true;
            } else {
                didModifyArray = true;
            }
        });

        // If the result is an empty array, return undefined
        if (!resultArray.length) {
            return makeEmptyArrayUndefined ? undefined : [];
        }

        // If nothing changed, return the original array
        if (!didModifyArray) return item;

        // Return the new array
        return resultArray;
    }

    // Handle plain objects
    if (isPlainObject(item)) {
        const resultObject = {};
        let didModifyObject = false;

        // Parse each entry in the object
        Object.entries(item).forEach(([key, value]) => {
            const parsedItem = pruneObject(value, makeEmptyArrayUndefined);

            // If the parsed value isn't nullish, add it to the result object
            if (isNotNullish(parsedItem)) {
                resultObject[key] = parsedItem;

                if (parsedItem !== value) didModifyObject = true;
            } else {
                didModifyObject = true;
            }
        });

        // If the result is an empty array, return undefined
        if (!Object.keys(resultObject).length) return undefined;

        // If nothing changed, return the original object
        if (!didModifyObject) return item;

        // Return the new object
        return resultObject;
    }

    // For everything else (string, Map, RegExp, function, etc.) return it as is
    return item;
};

export default pruneObject;
