import turfCircle from '@turf/circle';
import turfLineString from 'turf-linestring';
import {featureCollection as turfFeatureCollection} from '@turf/helpers';
import turfPoint from 'turf-point';
import turfDestination from '@turf/destination';

const WALK_SPEED_KPH = 5;
const LABEL_ANGLE = 45;

// Maximum Mapbox zoom level
const MAX_ZOOM = 22;

// Number of lines to form a circle for distance ring
const RING_STEPS = 50;

const FADE_OUT_ZOOM_DIFF = 2;

const toArrayCoords = ({lat, lng}) => [lng, lat];

export const DEFAULT_LABEL_COLOR = '#ff0000';
export const DEFAULT_TEXT_COLOR = '#000000';

const getFadeOutZoom = (maxZoom, minZoom) => {
    let fadeOutZoom = minZoom + FADE_OUT_ZOOM_DIFF;
    if (fadeOutZoom > maxZoom) {
        // fadeOutZoom MUST be strictly less than maxZoom
        fadeOutZoom = minZoom + (maxZoom - minZoom) * 0.9;
    }

    return fadeOutZoom;
};

export const transformDistanceRingDistancesForStore = distances => {
    const data = [];
    let prevZoom = MAX_ZOOM;
    distances.forEach(({time, zoom}, index) => {
        const fadeOutZoom =
            index === distances.length - 1 ? getFadeOutZoom(prevZoom, zoom) : undefined;
        const radius = (WALK_SPEED_KPH * time) / 60;
        data.push({
            fadeOutZoom,
            maxZoom: prevZoom,
            minZoom: zoom,
            opacity: 0.8,
            radius,
            time,
        });
        prevZoom = zoom;
    });

    return data;
};

export const getDistanceRings = (distances, center) => {
    const rings = distances.map(({radius, maxZoom, minZoom}) => {
        const options = {steps: RING_STEPS, units: 'kilometers'};
        const circle = turfCircle(toArrayCoords(center), radius, options);

        return turfLineString(circle.geometry.coordinates[0], {
            minZoom,
            maxZoom,
        });
    });

    return turfFeatureCollection(rings);
};

export const getLabelData = (distances, center, zoom, bearing) => {
    const distanceIndex = distances.findIndex(
        ({maxZoom, minZoom}) => zoom >= minZoom && zoom <= maxZoom
    );
    if (distanceIndex === -1) {
        return null;
    }
    const distance = distances[distanceIndex];

    const position = turfDestination(
        turfPoint(toArrayCoords(center)),
        distance.radius,
        (bearing + LABEL_ANGLE) % 360
    ).geometry.coordinates;

    const secondPosition = turfDestination(
        turfPoint(toArrayCoords(center)),
        distance.radius,
        (bearing + LABEL_ANGLE + 180) % 360
    ).geometry.coordinates;

    const text = `${distance.time} min`;

    let opacity = 1;

    if (distanceIndex === distances.length - 1) {
        const fadeOutZoom = getFadeOutZoom(distance.maxZoom, distance.minZoom);
        if (zoom < fadeOutZoom) {
            opacity = (zoom - distance.minZoom) / (fadeOutZoom - distance.minZoom);
        }
    }

    return {opacity, position, secondPosition, text};
};
