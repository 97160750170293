import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {PAM_OS_MESSAGE_TYPES} from 'Utils/constants';
import rootReducer from './reducers/rootReducer';

const STAY_LOGGED_IN_INTERVAL = 300000; // 5 minutes
let stayLoggedInShouldBeCalled = false;
setInterval(() => {
    if (stayLoggedInShouldBeCalled) {
        stayLoggedInShouldBeCalled = false;
        window.parent.postMessage({type: PAM_OS_MESSAGE_TYPES.STAY_LOGGED_IN}, '*');
    }
}, STAY_LOGGED_IN_INTERVAL);

// eslint-disable-next-line no-unused-vars
const stayLoggedInMiddleware = storeAPI => next => async action => {
    stayLoggedInShouldBeCalled = true;
    return next(action);
};

const middlewares = [thunk, stayLoggedInMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * @typedef {function: StoreState} GetState
 */

/**
 * @typedef {function(dispatch: function, getState: GetState)} ThunkFunction
 *
 * @example Use "@return {ThunkFunction}" for async action creators to get types in that function
 */

/**
 * @typedef {object} PageState
 * @property {DsmState} dsm
 * @property {object} explorer
 * @property {LayoutPageState} layout
 * @property {object} mapEditor
 * @property {SharedPageState} shared
 */

/**
 * @typedef {object} StoreState
 * @property {Array<object>} locations
 * @property {Array<object>} dictionaryItems
 * @property {Array<Scene>} scenes
 * @property {object} device
 * @property {object} mapLayers
 * @property {PageState} pageState
 */

/**
 * @typedef {object} store
 * @property {function(): StoreState} getState
 */
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
