const featureParentsIterator = ({featureId, getFeatureById, getParentId}) => ({
    toArray() {
        const ret = [];
        for (const parentFeature of this) {
            ret.push(parentFeature);
        }
        return ret;
    },
    find(callback) {
        for (const parentFeature of this) {
            if (callback(parentFeature)) return parentFeature;
        }
        return null;
    },
    filter(callback) {
        const ret = [];
        for (const parentFeature of this) {
            if (callback(parentFeature)) {
                ret.push(parentFeature);
            }
        }
        return ret;
    },
    [Symbol.iterator]() {
        let current = getParentId(featureId);
        return {
            next() {
                if (current) {
                    const parentFeature = getFeatureById(current);
                    current = getParentId(current);
                    return {
                        done: false,
                        value: parentFeature,
                    };
                }
                return {
                    done: true,
                };
            },
        };
    },
});

export const memFeatureParentsIterator = mapEditorManager => feature =>
    featureParentsIterator({
        featureId: feature.id,
        getFeatureById: featureId => mapEditorManager.getFeature(featureId),
        getParentId: featureId => mapEditorManager.getParentFeatureId(featureId),
    });

export const meFeatureParentsIterator = features => {
    const parents = {};
    const index = {};
    features.forEach(feature => {
        index[feature.id] = feature;
        if (feature.properties.parentId) {
            parents[feature.id] = feature.properties.parentId;
        }
        feature.properties.childrenIds?.forEach(childId => {
            parents[childId] = feature.id;
        });
    });

    return feature =>
        featureParentsIterator({
            featureId: feature.id,
            getFeatureById: featureId => index[featureId],
            getParentId: featureId => parents[featureId],
        });
};

export const dsmFeatureParentsIterator = features => {
    const parents = {};
    const index = {};
    features.forEach(feature => {
        index[feature.properties.featureId] = feature;
        feature.properties.childrenIds?.forEach(childId => {
            parents[childId] = feature.properties.featureId;
        });
    });

    return feature =>
        featureParentsIterator({
            featureId: feature.properties.featureId,
            getFeatureById: featureId => index[featureId],
            getParentId: featureId => parents[featureId],
        });
};

export default featureParentsIterator;
