import turfDistance from '@turf/distance';
import turfBbox from '@turf/bbox';
import {GEOMETRY_TYPES, IMPERIAL, SEARCH_BOUNDARY_RADIUS_RATIO} from 'Utils/constants';
import toSentenceCase from 'Utils/toSentenceCase';
import * as turfHelpers from '@turf/helpers';
import turfBboxPolygon from '@turf/bbox-polygon';
import {difference as turfDifference} from '@turf/turf';
import turfBuffer from '@turf/buffer';

/**
 * Removes duplicate points at the start or end of a line string,
 * these can cause rendering issues like the line disappearing at certain zoom levels
 * @param {LineString|MultiLineString} lineOrMultiLine
 * @returns {null|LineString|MultiLineString}
 */
export const removeDuplicatePoints = lineOrMultiLine => {
    const getCleanCoordinates = coordinates => {
        const newCoordinates = coordinates.slice();

        // Check if the first and second coordinates are the same
        if (
            coordinates[0][0] === coordinates[1][0] && // lng
            coordinates[0][1] === coordinates[1][1] // lat
        ) {
            // We have a duplicate point at the start.
            // If there's only two points, we would no longer have a line
            if (coordinates.length <= 2) return null;

            // Otherwise remove the first coordinate
            newCoordinates.shift();
        }

        // Check if the last and second last coordinates are the same
        const lastIndex = coordinates.length - 1;
        const secondLastIndex = lastIndex - 1;

        if (
            coordinates[lastIndex][0] === coordinates[secondLastIndex][0] && // lng
            coordinates[lastIndex][1] === coordinates[secondLastIndex][1] // lat
        ) {
            // We have a duplicate point at the end.
            // If there's only two points, we no longer have a line
            // if (result.geometry.coordinates.length <= 2) return null;
            if (newCoordinates.length <= 2) return null;

            // Otherwise remove the final coordinate
            // const newCoordinates = result.geometry.coordinates.slice();
            newCoordinates.pop();
        }

        return newCoordinates;
    };

    let newCoordinates = [];

    // For MultiLineStrings, we dedupe each line individually
    if (lineOrMultiLine.geometry.type === GEOMETRY_TYPES.MULTI_LINE_STRING) {
        lineOrMultiLine.geometry.coordinates.forEach(coordinates => {
            newCoordinates.push(getCleanCoordinates(coordinates));
        });
    } else {
        newCoordinates = getCleanCoordinates(lineOrMultiLine.geometry.coordinates);
    }

    return {
        ...lineOrMultiLine,
        geometry: {
            ...lineOrMultiLine.geometry,
            coordinates: newCoordinates,
        },
    };
};

export const getBboxByRadiusFromPoint = (center, radius, unit = IMPERIAL) => {
    if (radius) {
        const buffer = turfBuffer(center, radius, {
            units: unit === IMPERIAL ? 'miles' : 'kilometers',
        });
        return turfBbox(buffer);
    }
    return null;
};

export const getMapBoundaryPolygon = (centerCoords, radius, unit = IMPERIAL) => {
    const centerPoint = turfHelpers.point(centerCoords);
    const bboxByRadius = getBboxByRadiusFromPoint(centerPoint, radius, unit);
    const bboxPolygon = turfBboxPolygon(bboxByRadius);
    const worldPolygon = turfHelpers.polygon([
        [[-180, 90], [180, 90], [180, -90], [-180, -90], [-180, 90]],
    ]);
    return turfDifference(worldPolygon, bboxPolygon);
};

export const getSearchBoundaryPolygon = (centerCoords, radius, unit = IMPERIAL) => {
    const centerPoint = turfHelpers.point(centerCoords);
    const bboxByRadius = getBboxByRadiusFromPoint(centerPoint, radius, unit);
    return turfBboxPolygon(bboxByRadius);
};

export const getSearchBoundaryRadius = mapBoundaryRadius => {
    return mapBoundaryRadius * SEARCH_BOUNDARY_RADIUS_RATIO;
};

export const areSameCoords = (coordsOrPoint1, coordsOrPoint2) => {
    const coords1 = coordsOrPoint1.geometry ? coordsOrPoint1.geometry.coordinates : coordsOrPoint1;
    const coords2 = coordsOrPoint2.geometry ? coordsOrPoint2.geometry.coordinates : coordsOrPoint2;

    return coords1[0] === coords2[0] && coords1[1] === coords2[1];
};

/**
 * Check for a Mapbox-style hash in the URL that defines the location
 *
 * @returns {boolean}
 */
export const urlLocationHash = url => {
    if (!url.hash) return false;

    const hashParams = url.hash.replace('#', '').split('/');

    if (hashParams.length < 3) return false;

    // if any of them aren't numbers, it isn't a proper set of coordinates
    if (!hashParams.every(param => !Number.isNaN(param))) return false;

    return hashParams;
};

/**
 * Returns a convenient boolean variant of the feature type. E.g. a stadium will be isStadium
 *
 * @param {object} featureType
 * @return {string}
 */
export const makeFeatureTypeBool = featureType => `is${toSentenceCase(featureType.code)}`;

/**
 * For a given point, find the closest point in an array of points
 *
 * @param {Feature} point
 * @param {Array<Feature>} points
 * @return {Feature}
 */
export const closestPointInPoints = (point, points) => {
    let shortestDistance = Infinity;
    let closestPoint = null;

    points.forEach(thisPoint => {
        const distance = turfDistance(point, thisPoint);
        if (distance < shortestDistance) {
            closestPoint = thisPoint;
            shortestDistance = distance;
        }
    });

    return closestPoint;
};

/**
 * Ensures that a number, in degrees, where north is zero,
 * it returned as a number between 0 and 360
 *
 * @param {number} num - any number, in degrees, where zero represents North
 * @returns {number} rotation - a number between 0 (North) and 360 (also North)
 */
export const convertToRotation = num => {
    if (Number.isNaN(Number(num))) return 0;
    if (typeof num !== 'number') return 0;

    return ((num % 360) + 360) % 360;
};

export const sortByDisplayText = (a, b) => {
    const displayTextA = a.properties?.displayText || '';
    const displayTextB = b.properties?.displayText || '';
    if (displayTextA < displayTextB) {
        return -1;
    }
    if (displayTextA > displayTextB) {
        return 1;
    }
    return 0;
};
