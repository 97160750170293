/** keep this file light, and dont use/import other files
 * this file is needed to be loaded
 *  and initialized before rest of the application
 */

import React from 'react';
import '../../scss/preloader.scss';
import PreloaderComponent from './PreloaderComponent';

const ExplorerPreloader = () => {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const animationInterval = setInterval(() => {
            setProgress(_progress => _progress + 8);
        }, 500);

        return () => {
            clearInterval(animationInterval);
        };
    }, []);

    return (
        <div className="preloader-wrapper-container">
            <div className="preloader-wrapper">
                <div className="preloader-body-wrapper">
                    <PreloaderComponent progress={progress} />
                </div>
            </div>
        </div>
    );
};

export default ExplorerPreloader;
