import {ACTION_TYPES} from 'Utils/constants';

const sessionReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_SESSION_EXPIRED:
            return action.data || {};
        default:
            return state;
    }
};

export default sessionReducer;
