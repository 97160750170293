import mapboxgl from 'mapbox-gl';
import storage from 'Utils/storage';

// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

class GeocoderButton extends mapboxgl.GeolocateControl {
    constructor(props) {
        super(props);
        this.clickHandler = props.clickHandler;
        this.errorHandler = props.errorHandler;
        this._localState = 'OFF';
    }

    _setupUI(props) {
        super._setupUI(props);
        // it relies on the title of the button, as we cant check upfront,
        // if location sharing is disabled from a previous session, without asking for it,
        // and we want to show custom UI before asking on load.
        // in case its disabled, the text could be different of different browsers, so we check
        // if its enabled / ask.

        this.isDisabled = this._geolocateButton.title !== 'Find my location';
        this.outOfBounds = false;
        this._geolocateButton.addEventListener('click', () => {
            this.clickHandler();
        });
    }

    _onSuccess(props) {
        if (!this.outOfBounds) {
            storage.setItem('hasAllowedLocation', true);
            super._onSuccess(props);
        }
    }

    trigger(props) {
        if (props?.showUserHeading) {
            this.options.showUserHeading = true;
        }
        window.moveMapOnLoad = !(props?.isAutoLoad || props?.welcomePageLoad);

        this.outOfBounds = false;
        switch (this._watchState) {
            case 'OFF':
                this._localState = 'ON';
                this._watchState = 'BACKGROUND';
                super.trigger(props);
                break;
            case 'BACKGROUND':
                this._localState = 'ON';
                super.trigger(props);
                break;
            default:
                break;
        }
    }

    _onError(error) {
        super._onError(error);
        const _this = this;

        if (this.options.trackUserLocation) {
            if (error.code === 1) {
                storage.setItem('hasAllowedLocation', false);
                if (navigator.geolocation) {
                    _this._geolocateButton.disabled = false;
                    // shop pop up
                    if (this.isDisabled) {
                        this.errorHandler();
                    }
                    // since we are here... isDisabled should now be true
                    this.isDisabled = true;
                }
            }
        }
    }
}

export default GeocoderButton;
