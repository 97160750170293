import log from 'Utils/log';

async function fetchGeo() {
    try {
        const location = await fetch('https://freegeoip.app/json/').then(response =>
            response.json()
        );
        return location;
    } catch (error) {
        log.error(error.message);
        return {error: true};
    }
}

export async function fetchLocation() {
    try {
        const location = await fetchGeo();
        if (!location || !location.latitude || !location.longitude) return {error: true};

        return {
            lng: location.longitude,
            lat: location.latitude,
        };
    } catch (error) {
        log.error(error.message);
        return {error: true};
    }
}

export async function fetchIP() {
    try {
        const location = await fetchGeo();
        if (!location || !location.ip) return {ip: 'false'};

        return {
            ip: location.ip,
        };
    } catch (error) {
        log.error(error.message);
        return {ip: 'false'};
    }
}
