import {combineReducers} from 'redux';
import undoableEnhancer from 'Data/reducers/undoableEnhancer';
import {groupByActionTypes} from 'Utils/undoableUtils';
import {ACTION_TYPES} from 'Utils/constants';
import clientRelationshipReducer from './clientRelationshipReducer';
import locationsReducer from './locationsReducer';
import dictionaryItemsReducer from './dictionaryItemsReducer';
import tenantsReducer from './tenantsReducer';
import scenesReducer from './scenesReducer';
import deviceReducer from './deviceReducer';
import mapLayersReducer from './mapLayersReducer';
import dsmPageStateReducer from './dsmPageStateReducer';
import explorerMapStateReducer from './explorerMapStateReducer';
import layoutPageStateReducer from './layoutPageStateReducer';
import mapEditorStateReducer from './mapEditorPageStateReducer';
import pamOsEnabledFeaturesReducer from './pamOsEnabledFeaturesReducer';
import parkingLotConfigsReducer from './parkingLotConfigsReducer';
import parkingManagerPageStateReducer from './parkingManagerPageStateReducer';
import sharedPageStateReducer from './sharedPageStateReducer';
import explorerPromotionsReducer from './explorerPromotionsReducer';
import explorerContentSourcesReducer from './explorerContentSourcesReducer';
import searchFiltersReducer from './searchFiltersReducer';
import sessionReducer from './sessionReducer';
import commonSignDataReducer from './commonSignDataReducer';
import digitalSignDataReducer from './digitalSignDataReducer';

export default combineReducers({
    clientRelationship: clientRelationshipReducer,
    locations: locationsReducer,
    dictionaryItems: dictionaryItemsReducer,
    tenants: tenantsReducer,
    searchFilters: searchFiltersReducer,
    scenes: scenesReducer,
    device: deviceReducer,
    mapLayers: mapLayersReducer,
    parkingLotConfigs: parkingLotConfigsReducer,
    explorer: combineReducers({
        promotionsState: explorerPromotionsReducer,
        contentSourcesState: explorerContentSourcesReducer,
    }),
    pamOsEnabledFeatures: pamOsEnabledFeaturesReducer,
    session: sessionReducer,
    pageState: combineReducers({
        dsm: undoableEnhancer(
            dsmPageStateReducer,
            groupByActionTypes([
                ACTION_TYPES.DSM.UPDATE_SIGN,
                ACTION_TYPES.DSM.UPDATE_LAYOUT,
                ACTION_TYPES.DSM.SET_PAGE_STATE,
                ACTION_TYPES.DSM.SET_READONLY,
            ])
        ),
        parkingManager: parkingManagerPageStateReducer,
        explorerMap: explorerMapStateReducer,
        layout: layoutPageStateReducer,
        mapEditor: mapEditorStateReducer,
        shared: sharedPageStateReducer,
        commonSignData: commonSignDataReducer,
        digitalSignData: digitalSignDataReducer,
    }),
});
