import {ACTION_TYPES} from 'Utils/constants';

const commonSignDataReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_COMMON_SIGN_DATA:
            return action.data || {};
        default:
            return state;
    }
};

export default commonSignDataReducer;
