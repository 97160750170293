import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ERRORS} from 'Utils/constants';

class ErrorBoundary extends React.Component {
    state = {error: null};

    static getDerivedStateFromError(error) {
        return {error};
    }

    componentDidUpdate(prevProps, prevState) {
        // We want to reset the error state if the props change, to give
        // the child components another chance to render.
        // This must only be called when there's an error and the state hasn't changed
        if (
            this.state.error &&
            this.state === prevState &&
            this.props.reduxStore !== prevProps.reduxStore
        ) {
            this.setState({error: null});
        }
    }

    render() {
        const {children} = this.props;

        if (this.state.error?.message === ERRORS.WEB_GL_NOT_SUPPORTED) {
            return 'Browser not compatible';
        }

        if (this.state.error?.message === ERRORS.INVALID_SIGN_DATA) {
            return (
                <div
                    data-testid="error-page"
                    style={{
                        background: '#000',
                        borderTop: '3px solid #0FCF7C',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    Invalid Sign
                </div>
            );
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
    reduxStore: PropTypes.object,
};

const mapStateToProps = state => ({
    reduxStore: state,
});

export default connect(mapStateToProps)(ErrorBoundary);
