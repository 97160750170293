/* eslint-disable no-param-reassign */
import turfArea from '@turf/area';
import turfCentroid from '@turf/centroid';
import {GEOMETRY_TYPES, LABEL_DISPLAY_TYPES} from 'Utils/constants';
import FEATURE_TYPES from 'Utils/featureTypes';
import IMDF_FEATURE_TYPES from 'Utils/imdfFeatureTypes';
import * as mapEditorUtils from 'Utils/mapEditor/mapEditorUtils';
import {makeFeatureTypeBool} from 'Utils/explorer/mapUtils';
import log from '../log';

const getImdfFeatureCategoryKey = (featureCode, categoryCode = null) =>
    `${featureCode}/${categoryCode}`;

// We create a map of the featureTypes, since lookup will be faster than a find() for every feature
const featureTypesByImdfKey = new Map();

Object.values(FEATURE_TYPES).forEach(featureType => {
    if (featureType.imdfFeatureType) {
        const key = getImdfFeatureCategoryKey(
            featureType.imdfFeatureType.code,
            featureType.imdfCategory && featureType.imdfCategory.code
        );

        featureTypesByImdfKey.set(key, featureType);
    }
});

/**
 *  if the provided feature has a display point, creates & returns a new display point feature to add
 *
 * @param {object} props
 * @param {Feature} props.feature
 * @param {object} props.labeledItem - a dictionary item or location
 * @param {string} props.displayPointId
 * @param {string} [props.dictionaryItemId]
 * @param {string} [props.locationId]
 *
 * @return {(boolean|Feature)} - either returns the display point feature or false
 */
export const getDisplayPointFeature = ({
    feature,
    labeledItem,
    dictionaryItemId,
    locationId,
    displayPointId,
    doNotChangeDisplayPointId,
}) => {
    // If a feature has a displayPoint property, we add a new displayPoint feature
    if (feature.properties.displayPoint) {
        const displayPoint = mapEditorUtils.makeFeature({
            featureType: FEATURE_TYPES.displayPoint,
            id: displayPointId,
        });

        let sortKey = feature.properties?.sortKeyOverride;
        if (!sortKey) {
            sortKey = labeledItem?.prominence ? (11 - labeledItem.prominence) * 10 : 10;
        }

        const displayPointFeature = {
            ...displayPoint,
            properties: {
                ...displayPoint.properties,
                ...labeledItem,
                disabled: feature.properties.disabled,
                disableDescendants: feature.properties.disableDescendants,
                parentFeatureId: feature.id,
                boundaryGeometry: feature.geometry,
                dictionaryItemId,
                locationId,
                sortKey,
                labelDisplayType: feature.properties.labelDisplayType,
                tenantId: feature.properties.tenantId,
            },
            geometry: feature.properties.displayPoint.geometry,
        };

        if (!doNotChangeDisplayPointId) {
            // This will set the id to a numeric ID for Explorer
            feature.properties.displayPoint.id = displayPoint.id;
        }
        return displayPointFeature;
    }
    return false;
};

/**
 * Set the PAM featureType based on the IMDF feature_type/category
 *
 * @param {Feature} feature
 */
export const convertImdfFeatureType = feature => {
    feature.properties.featureType = featureTypesByImdfKey.get(
        getImdfFeatureCategoryKey(feature.feature_type, feature.properties.category)
    );

    // We don't need the IMDF props any more
    delete feature.feature_type;
    delete feature.properties.category;

    // An old navMap may not have a labelDisplayType
    if (
        (feature.properties.dictionaryItemId || feature.properties.groupId) &&
        !feature.properties.labelDisplayType
    ) {
        feature.properties.labelDisplayType = LABEL_DISPLAY_TYPES.TEXT;
    }

    // Add a helper boolean like 'isRoom' and searchIcon
    if (feature.properties.featureType) {
        feature.properties[makeFeatureTypeBool(feature.properties.featureType)] = true;
    }
};

/**
 * Changes line geometry of Opening features to point.
 * This is done so that the label is always visible,
 * as with line features, label placement + visibility is an issue
 *
 * @param {Feature} feature
 */
export const handleOpenings = feature => {
    const {isDoorway, isCarEntrance, isEmergencyExit, isVenueEntrance} = feature.properties;
    if (isDoorway || isCarEntrance || isEmergencyExit || isVenueEntrance) {
        feature.geometry = {
            type: GEOMETRY_TYPES.POINT,
            coordinates: feature.geometry.coordinates[1],
        };
    }
};

/**
 * For building features, get their geometry from their associated footprint
 *
 * @param {object} props
 * @param {Feature} props.feature
 * @param {FeatureCollection} props.navMap
 * @return {boolean} - should this feature be removed
 */
export const handleBuilding = ({feature, navMap}) => {
    // If a feature is of BUILDING type, copy footprint's geometry back to building.
    // This is done for both explorer & ME.
    // Note: we check the IMDF type, not the PAM featureType
    let discardBuildingFeature = false;
    if (
        feature.properties.featureType &&
        feature.properties.featureType.imdfFeatureType.code === IMDF_FEATURE_TYPES.building.code
    ) {
        const footprint = navMap.features.find(
            footprintFeature =>
                footprintFeature.properties.isFootprint &&
                footprintFeature.properties.building_ids.includes(feature.id)
        );

        if (footprint) {
            feature.geometry = footprint.geometry;
        } else {
            // if corresponding footprint is not found,
            // don't add building to features as map editor will throw error on empty geometry
            discardBuildingFeature = true;
        }
    }

    return discardBuildingFeature;
};

/**
 * An older navMap might not have dictionaryItemId on a group member, so add that
 *
 * @param {object} props
 * @param {Feature} props.feature
 * @param {Map} props.featureMap
 */
export const handleGroups = ({feature, featureMap}) => {
    // TODO (davidg): remove this in the future
    if (feature.properties.groupId && !feature.properties.dictionaryItemId) {
        const group = featureMap.get(feature.properties.groupId);
        if (group?.properties?.dictionaryItemId) {
            log.warn(
                'group not found - ',
                feature.properties.dictionaryItemId,
                feature.properties.groupId
            );
            feature.properties.dictionaryItemId = group.properties.dictionaryItemId;
        }
    }
};

/**
 * Feature can be disabled by parent feature.
 * The disabledByParent option is used to store calculated value for this dependency.
 *
 * @param {object} feature
 * @param {function} featureParentsIterator
 * @param {boolean} isBaseNavMap
 */
export const handleDisablingByParent = (feature, featureParentsIterator, isBaseNavMap = true) => {
    // set disabledByParent
    feature.properties.disabledByParent = featureParentsIterator(feature).find(
        f => f?.properties?.disableDescendants
    )
        ? true
        : undefined;

    // set isSceneOverride if parent has isSceneOverride
    if (!isBaseNavMap) {
        feature.properties.isSceneOverride =
            feature.properties.isSceneOverride ||
            !!featureParentsIterator(feature).find(f => f.properties.isSceneOverride);
    } else {
        feature.properties.isSceneOverride = undefined;
    }
};

/**
 * In the navMap, a feature has a reference to its parent, but not its children.
 * This populates childrenMap so that it can be used to store an
 * array of children against a parent feature.
 *
 * @param {object} props
 * @param {Feature} props.feature
 * @param {Map} props.childrenMap
 */
export const handleParents = ({feature, childrenMap}) => {
    // In Map Editor we treat double-sided signs as a single feature, so drop side B
    if (feature.properties.isDoubleSidedSign && feature.properties.side === 'B') return;
    // we only consider features with parent ID.
    if (feature.properties.parentId) {
        if (childrenMap.has(feature.properties.parentId)) {
            const currChildren = childrenMap.get(feature.properties.parentId);
            currChildren.push(feature.id);
            childrenMap.set(feature.properties.parentId, currChildren);
        } else {
            childrenMap.set(feature.properties.parentId, [feature.id]);
        }
    }
};

/**
 * Turn numbers into strings
 *
 * @param {Feature} feature
 */
export const handleIds = feature => {
    if (feature.properties.dictionaryItemId) {
        feature.properties.dictionaryItemId = String(feature.properties.dictionaryItemId);
    }

    if (feature.properties.bSideDictionaryItemId) {
        feature.properties.bSideDictionaryItemId = String(feature.properties.bSideDictionaryItemId);
    }
};

/**
 * Sort features.
 * Used when saving a navMap to influence the order in which labels are rendered
 *
 * @param {Feature} a
 * @param {Feature} b
 * @return {number}
 */
export const sortFeatures = (a, b) => {
    // We only bother sorting to get the labels right.
    // So if there's no name, no label, no need to sort
    if (!a.properties.displayText || !b.properties.displayText) return 0;

    if (!a.properties.prominence) return 1;
    if (!b.properties.prominence) return -1;

    const aProminence = a.properties.prominence;
    const bProminence = b.properties.prominence;

    if (aProminence !== bProminence) return bProminence - aProminence;

    if (b.geometry.type !== GEOMETRY_TYPES.POLYGON) return 0;

    // TODO (davidg): perf: this calculates several times for area for every item
    return turfArea(b) - turfArea(a);
};

export const compareDistance = (a, b) => a?.distance - b?.distance;

export const getCenterPoint = feature => {
    if (feature?.properties?.displayPoint) {
        return feature.properties.displayPoint.geometry.coordinates;
    }
    if (!feature.geometry?.type) {
        return [];
    }
    if (feature.geometry.type === GEOMETRY_TYPES.POLYGON) {
        const geometry = turfCentroid(feature.geometry).geometry;
        return geometry.coordinates;
    }
    if (feature.geometry.type === GEOMETRY_TYPES.LINE_STRING) {
        return feature.geometry.coordinates[0];
    }
    // else it's a point
    return feature.geometry.coordinates;
};
