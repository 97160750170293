import {ACTION_TYPES, MAP_THEMES, MAPBOX_STYLES_TYPES} from 'Utils/constants';
import getMapboxStyles from 'Utils/getMapboxStyles';

const setMapboxStyles = ({mapboxStyles, clientId, type}) => async dispatch => {
    if (mapboxStyles) {
        dispatch({
            type: ACTION_TYPES.SET_MAPBOX_STYLES,
            data: mapboxStyles,
        });
    } else {
        const data = {};
        if (type === MAP_THEMES.DSM || type === MAP_THEMES.PM || type === MAP_THEMES.MAP_EDITOR) {
            const editorStyle = await getMapboxStyles(MAPBOX_STYLES_TYPES.EDITOR, clientId);
            data[type] = editorStyle.url;
            data[`${type}_LAST_MODIFIED_TIME`] = editorStyle.lastModifiedTime;
        }

        const [mapLight, mapDark] = await Promise.all([
            getMapboxStyles(MAPBOX_STYLES_TYPES.LIGHT, clientId),
            getMapboxStyles(MAPBOX_STYLES_TYPES.DARK, clientId),
        ]);
        data[MAP_THEMES.EXPLORER_LIGHT] = mapLight.url;
        data[`${MAP_THEMES.EXPLORER_LIGHT}_LAST_MODIFIED_TIME`] = mapLight.lastModifiedTime;
        data[MAP_THEMES.EXPLORER_DARK] = mapDark.url;
        data[`${MAP_THEMES.EXPLORER_DARK}_LAST_MODIFIED_TIME`] = mapDark.lastModifiedTime;

        dispatch({
            type: ACTION_TYPES.SET_MAPBOX_STYLES,
            data,
        });
    }
};
export default setMapboxStyles;
