import lodashIsPlainObject from 'lodash/isPlainObject';
import store from 'Data/store';
import log from 'Utils/log';

const fetchFromApi = (path, options = {}) => {
    let url = path[0] === '/' ? path : new URL(path);
    const requestOptions = {headers: {'Cache-Control': 'max-age=5'}};
    const signId = /[^/]*$/.exec(path)[0];
    if (options.sendNewRelicParams && url.searchParams) {
        // if its not mocks
        const state = store.getState();
        const config = state.pageState?.layout?.config;
        const signFeature = config?.navMapWithLocations?.features?.find(
            feature => feature.properties.featureId === signId
        );
        const sceneId = config?.scenes.find(scene => scene.isActive)?.id;

        const params = {
            ipa: state.device?.IP,
            activeScene: sceneId,
            clientId: config?.settings?.clientId,
            signName:
                state.device.deviceType === 'EXPLORER'
                    ? 'EXPLORER'
                    : signFeature?.properties?.displayText,
            // getReleaseVersion() comes from index.html
            // eslint-disable-next-line no-undef
            version: getReleaseVersion(),
        };

        if (!options.etag) {
            params.newPageLoad = 'y';
        }
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    }
    if (options.etag) {
        requestOptions.headers['If-None-Match'] = options.etag;
    }

    // TODO: we have some tests running using `useMocks`,
    // for which I have to add this hack (as I removed href from Line 5)
    // I'll fix the tests in DEV-6404
    url = url?.href || url;

    return fetch(url, requestOptions)
        .then(async response => {
            if (!response.ok && response.status !== 304) {
                throw Error(`${response.status} - ${response.statusText}`);
            }
            return {
                headers: {
                    etag: response.headers.get('ETag'),
                },
                body: response.status !== 304 ? await response.json() : null,
            };
        })
        .catch(err => {
            // Errors like fetch TypeError will return a string and not a JSON
            const errorMessage = lodashIsPlainObject(err) ? JSON.stringify(err) : err;
            log.error(`${errorMessage} - ${new URL(url).pathname}`);
            return {};
        });
};

export default fetchFromApi;
