// parseActions helper: takes a string (or array) and makes it an array if it isn't yet
import {UNDOABLE_PROP_PATH_KEY} from 'Utils/constants';

export function parseActions(rawActions) {
    if (Array.isArray(rawActions)) {
        return rawActions;
    }
    if (typeof rawActions === 'string') {
        return [rawActions];
    }
    return [];
}

// Try to group redux actions
export function groupByActionTypes(rawActions) {
    const actions = parseActions(rawActions);
    return action => (actions.indexOf(action.type) >= 0 ? action.type : null);
}

// New undoable history
export function newHistory(past, present, future, group = null, propPath = null) {
    return {
        past,
        // propPath in present indicates which propPath value was used on the change
        present: {...present, [UNDOABLE_PROP_PATH_KEY]: propPath},
        future,
        group,
        _latestUnfiltered: present,
    };
}

// Insert `state` into history, which means adding the current state
// into `past`, setting the new `state` as `present` and erasing the `future`.
export function insert(history, state, group, propPath) {
    const {past, present, future, _latestUnfiltered} = history;

    const pastSliced = past.slice(0);
    let newPast = _latestUnfiltered != null ? [...pastSliced, _latestUnfiltered] : pastSliced;

    // _latestUnfiltered = undefined means the previous action is an UNDO or REDO
    // @see overwriting-future-after-undo-redo in undoableEnhancer.test.js
    if (typeof _latestUnfiltered === 'undefined' && future.length === 0) {
        // If no future, merge the past and present on the action after UNDO/REDO
        newPast = [...past, present];
    }

    return newHistory(newPast, state, [], group, propPath);
}

export function presentStateValidator(state) {
    if (typeof state.present === 'undefined') {
        throw new Error('There is no present state');
    }

    return state;
}
