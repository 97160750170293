import {CONFIG_DATA_BASE_KEYS} from 'Utils/constants';

export const KIOSK_CONFIG_COMPONENT_LABELS = {
    ACCESSIBLE: {
        CONTENT_POSITION: 'Content vertical position in pixels',
        ENABLE_ADJUST_MENU: 'Enable adjustable menu',
    },
    HEADER: {
        LOCATION_BACKGROUND: 'Location Background',
        LOCATION_TEXT: 'Location Text',
        SHOW_CLIENT_LOGO: 'Show Client Logo',
        SHOW_DIRECTIONS: 'Show Directions',
        SHOW_LOCATIONS: 'Show Location',
        USE_LOCATION_COLORS: 'Use Location Colours',
        USE_LOCATION_LOGO: 'Use Location Logo',
    },
    MAP_CONFIGS: {
        BEARING: 'Map display heads-up rotation',
        MAP_STYLE: 'Default Map Style',
        PITCH: 'Pitch for 3D perspective Button(0-60). Pitch is 0 for 2D',
        COMPASS_PITCH: 'Default Pitch',
        DISPLAY_MAP_STYLE_CONTROL: 'Enable switching between map styles',
        CENTER_COORDINATES: 'Map display center',
        ZOOM: 'Default Zoom Level (Between Min and Max Zoom)',
    },
    MENU_TAB: {
        ENABLE_EXPERIENCES: 'Enable',
        ENABLE_DIRECTORY_WITH_KIOSK: 'Enable',
        ENABLE_PROMOTIONS_WITH_KIOSK: 'Enable',
        ENABLE_DIRECTIONS_ON_EXPLORER: 'Enable',
        ENABLE_DIRECTIONS_ON_EXPLORER_HELPER_TEXT: '* Only available on desktop explorer',
        MENU_COLOUR: 'Colour',
        DIRECTORY_CONTENT_SOURCE: 'Location',
        MENU_LANDING_PAGE: 'Landing Page',
        MENU_LABEL: 'Label',
        SHOW_LABELS_ON_KIOSK: 'Show on Kiosk',
        SHOW_LABELS_AS_INLINE_ON_KIOSK: 'Inline Labels',
        SHOW_LABELS_BG_ON_KIOSK: 'Coloured Labels',
        LABEL_COLOUR_LIGHT: 'Label Colour Light Map',
        LABEL_COLOUR_DARK: 'Label Colour Dark Map',
    },
    SCREEN_SAVER: {
        TIMEOUT: 'Start after (seconds)',
    },
    IDLE_SCREEN_TAB: {
        CTA_PROMPT_TEXT: 'Cta Prompt Text',
        CTA_HIGHLIGHT_COLOR: 'Cta Highlight Color',
        CTA_COLOUR: 'CTA HIGHLIGHT COLOUR',
        QR_PROMPT_TEXT: 'Prompt Text',
        SCREENSAVER_MODE: 'Screen Saver mode',
        ADVERTISING_MODE: 'Advertising mode',
        MODE: 'Mode',
        OPTIONS: 'Options',
        CALL_TO_ACTION: 'CALL TO ACTION',
        WELCOME_IMAGE: 'WELCOME IMAGE',
        TOUCH_TO_ACTION: 'TOUCHLESS EXPERIENCE',
        SHOW_EXPLORER_QR: 'Show QR code to explorer',
        CTA_TITLE_TEXT: 'Cta Title',
        DISABLE_MAP_ANIMATION: {
            LABEL: 'Disable background map animation',
            HELPER_TEXT: 'This will reduce processor usage when in idle mode',
        },
    },
    INTERNAL_LOCATIONS: {
        RESULT_LIMIT: 'Search Result Limit (Max: 99)',
    },
    EXTERNAL_LOCATIONS: {
        ENABLED_LONG: 'Include external locations in search results and routing',
        ACTIVATE_ON_CHAR_COUNT: 'Activate external search on character count',
        ENABLED: 'Include in search results and routing',
        RESULT_LIMIT: 'Search result limit',
        SEARCH_BOUNDARY: {
            COUNTRIES: 'Search boundary by countries',
        },
        DISABLE_PEDESTRIAN_AND_CYCLING_DIRECTIONS: {
            LABEL: 'Disable Mapbox to Mapbox pedestrian/cycling directions',
            HELPER_TEXT:
                'Allow users to get direction between 2 external locations when walking or cycling',
        },
    },
    QUICK_LINKS: {PARKING: 'Parking', RESTROOMS: 'Restrooms', ENTRANCES: 'Entrances'},
    WIDGETS: {SHOW_CLOCK: 'Show Clock'},
    SUGGESTED_LOCATIONS: {TITLE: 'Add New Location'},
    SCREEN_ORIENTATION: 'Screen Orientation',
};

export const KIOSK_PROP_PATHS_MAP_CONFIG = 'mapConfig';
export const KIOSK_PROP_PATHS_MAP_CONFIG_CENTER_COORDINATES = 'centerCoordinates';
export const KIOSK_ORIENTATION = {PORTRAIT: 'Portrait', LANDSCAPE: 'Landscape'};

// @TODO Add propPaths for DDS and ParkingLot https://mediabankpam.atlassian.net/browse/DEV-6620
export const KIOSK_PROP_PATHS = {
    ACCESSIBLE: {
        CONTENT_POSITION: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menu.position`,
        ENABLE_ADJUST_MENU: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menu.adjustable`,
    },
    CONCAT_GROUP: {
        P_4_2: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.concatGroup.P_4_2`,
    },
    HEADER: {
        LOCATION: `${CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE}.header.location`,
        LOCATION_BACKGROUND: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.header.locationBackground`,
        LOCATION_TEXT: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.header.locationText`,
        SHOW_CLIENT_LOGO: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.header.showClientLogo`,
        SHOW_LOCATION: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.header.showLocation`,
        DIRECTIONS: {
            _ROOT: `${CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE}.header.directions`,
            SLOT0: `${CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE}.header.directions.slot0`,
            SLOT1: `${CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE}.header.directions.slot1`,
            MESSAGING_SLOT0: `${
                CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE
            }.header.directions.messagingSlot0`,
            MESSAGING_SLOT1: `${
                CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE
            }.header.directions.messagingSlot1`,
        },
        SHOW_DIRECTIONS: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.header.showDirections`,
        USE_LOCATION_COLORS: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.header.useLocationColors`,
        USE_LOCATION_LOGO: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.header.useLocationLogo`,
    },
    MAP_CONFIG: {
        BEARING: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.${KIOSK_PROP_PATHS_MAP_CONFIG}.bearing`,
        CENTER_COORDINATES: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.${KIOSK_PROP_PATHS_MAP_CONFIG}.${KIOSK_PROP_PATHS_MAP_CONFIG_CENTER_COORDINATES}`,
        PITCH: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.${KIOSK_PROP_PATHS_MAP_CONFIG}.pitch`,
        COMPASS_PITCH: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.${KIOSK_PROP_PATHS_MAP_CONFIG}.compassPitch`,
        DISPLAY_MAP_STYLE_CONTROL: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.${KIOSK_PROP_PATHS_MAP_CONFIG}.displayMapStyleControl`,
        ZOOM: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.${KIOSK_PROP_PATHS_MAP_CONFIG}.zoom`,
        MAP_STYLE: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.${KIOSK_PROP_PATHS_MAP_CONFIG}.mapStyle`,
    },
    QUICK_LINKS: {
        ENTRANCES: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.quickLinks.entrances`,
        PARKING: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.quickLinks.parking`,
        RESTROOMS: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.quickLinks.restrooms`,
    },
    MENU_TAB: {
        ENABLE_EXPERIENCES: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menuTab.enableExperiences`,
        ENABLE_DIRECTORY_WITH_KIOSK: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.enableDirectoryWithKiosk`,
        ENABLE_PROMOTIONS_WITH_KIOSK: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.enablePromotionsWithKiosk`,
        ENABLE_DIRECTIONS_ON_EXPLORER: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.enableDirectionsOnExplorer`,
        MENU_COLOUR_EXPLORE: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menuTab.menuColourExplore`,
        MENU_COLOUR_SEARCH: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menuTab.menuColourSearch`,
        MENU_COLOUR_EXPERIENCES: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.menuColourExperiences`,
        MENU_COLOUR_DIRECTORY: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.menuColourDirectory`,
        MENU_COLOUR_PROMOTIONS: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.menuColourPromotions`,
        MENU_COLOUR_DIRECTIONS: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.menuColourDirections`,
        DIRECTORY_CONTENT_SOURCE: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.directoryContentSource`,
        MENU_LANDING_PAGE: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menuTab.landingPage`,
        MENU_LABEL_EXPLORE: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menuTab.menuLabelExplore`,
        MENU_LABEL_SEARCH: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menuTab.menuLabelSearch`,
        MENU_LABEL_EXPERIENCES: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.menuLabelExperiences`,
        MENU_LABEL_DIRECTORY: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.menuLabelDirectory`,
        MENU_LABEL_PROMOTIONS: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.menuLabelPromotions`,
        MENU_LABEL_DIRECTIONS: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.menuLabelDirections`,
        SHOW_LABELS_ON_KIOSK: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menuTab.showLabelsOnKiosk`,
        SHOW_LABELS_BG_ON_KIOSK: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.showLabelsBgOnKiosk`,
        SHOW_LABELS_AS_INLINE_ON_KIOSK: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.menuTab.showLabelsAsInlineOnKiosk`,
        LABEL_COLOUR_DARK: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menuTab.labelColourDark`,
        LABEL_COLOUR_LIGHT: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.menuTab.labelColourLight`,
    },
    SUGGESTED_LOCATIONS: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.suggestedLocations`,
    SCREEN_SAVER_TIMEOUT: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.screenSaverTimeout`,
    WIDGETS: {
        SHOW_CLOCK: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.widgets.showClock`,
    },
    IDLE_SCREEN: {
        CTA_COLOUR: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.idleScreen.ctaColour`,
        CTA_IMAGE: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.idleScreen.ctaImage`,
        CTA_PROMPT_TEXT: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.idleScreen.ctaPrompt`,
        CTA_TITLE_TEXT: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.idleScreen.ctaTitleText`,
        MODE: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.idleScreen.mode`,
        QR_PROMPT_TEXT: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.idleScreen.qrPrompt`,
        SCREEN_SAVER_TIMEOUT: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.idleScreen.screenSaverTimeout`,
        SHOW_EXPLORER_QR: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.idleScreen.showExplorerQR`,
        WELCOME_IMAGE: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.idleScreen.welcomeImage`,
        DISABLE_MAP_ANIMATION: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.idleScreen.disableMapAnimation`,
    },
    SEARCH: {
        SEARCH_FILTERS: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.search.searchFilters`,
    },
    INTERNAL_LOCATIONS: {
        RESULT_LIMIT: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.internalLocations.resultLimit`,
    },
    EXTERNAL_LOCATIONS: {
        ENABLED: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.externalLocations.enabled`,
        DISABLE_PEDESTRIAN_AND_CYCLING_DIRECTIONS: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.externalLocations.disablePedestrianAndCyclingDirections`,
        RESULT_LIMIT: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.externalLocations.resultLimit`,
        ACTIVATE_ON_CHAR_COUNT: `${
            CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
        }.externalLocations.activateOnCharCount`,
        SEARCH_BOUNDARY: {
            RADIUS: `${
                CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
            }.externalLocations.searchBoundary.radius`,
            COUNTRIES: `${
                CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE
            }.externalLocations.searchBoundary.countries`,
        },
    },
    ORIENTATION: `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.orientation`,
};

/**
 * !!!! Prop paths here should match the fields in KioskLayoutGeneralTabConfig.js
 * @TODO Linking this array and the form fields in KioskLayoutGeneralTabConfig
 *
 * -==- Special case alert -==-
 * bearing and center coordinates are taken from the signs navMap feature, they have need no form fields
 *
 * @type {(string)[]}
 */

export const KIOSK_LAYOUT_GENERAL_TAB_PROP_PATHS = [
    KIOSK_PROP_PATHS.CONCAT_GROUP.P_4_2,
    KIOSK_PROP_PATHS.EXTERNAL_LOCATIONS.ENABLED,
    KIOSK_PROP_PATHS.HEADER.SHOW_LOCATION,
    KIOSK_PROP_PATHS.HEADER.SHOW_DIRECTIONS,
    KIOSK_PROP_PATHS.HEADER.SHOW_CLIENT_LOGO,
    KIOSK_PROP_PATHS.WIDGETS.SHOW_CLOCK,
    KIOSK_PROP_PATHS.MAP_CONFIG.BEARING,
    KIOSK_PROP_PATHS.MAP_CONFIG.CENTER_COORDINATES,
    KIOSK_PROP_PATHS.MAP_CONFIG.PITCH,
    KIOSK_PROP_PATHS.MAP_CONFIG.COMPASS_PITCH,
    KIOSK_PROP_PATHS.MAP_CONFIG.ZOOM,
    KIOSK_PROP_PATHS.MAP_CONFIG.MAP_STYLE,
    KIOSK_PROP_PATHS.MENU_TAB.ENABLE_EXPERIENCES,
    KIOSK_PROP_PATHS.MENU_TAB.ENABLE_DIRECTORY_WITH_KIOSK,
    KIOSK_PROP_PATHS.MENU_TAB.ENABLE_DIRECTIONS_ON_EXPLORER,
    KIOSK_PROP_PATHS.MENU_TAB.DIRECTORY_CONTENT_SOURCE,
    KIOSK_PROP_PATHS.MENU_TAB.MENU_LANDING_PAGE,
    KIOSK_PROP_PATHS.MENU_TAB.ENABLE_PROMOTIONS_WITH_KIOSK,
    KIOSK_PROP_PATHS.SCREEN_SAVER_TIMEOUT,
    KIOSK_PROP_PATHS.SUGGESTED_LOCATIONS,
    KIOSK_PROP_PATHS.IDLE_SCREEN.MODE,
    KIOSK_PROP_PATHS.IDLE_SCREEN.CTA_PROMPT_TEXT,
    KIOSK_PROP_PATHS.IDLE_SCREEN.CTA_COLOUR,
    KIOSK_PROP_PATHS.IDLE_SCREEN.SHOW_EXPLORER_QR,
    KIOSK_PROP_PATHS.IDLE_SCREEN.QR_PROMPT_TEXT,
    KIOSK_PROP_PATHS.IDLE_SCREEN.CTA_TITLE_TEXT,
    KIOSK_PROP_PATHS.IDLE_SCREEN.CTA_IMAGE,
    KIOSK_PROP_PATHS.IDLE_SCREEN.WELCOME_IMAGE,
    KIOSK_PROP_PATHS.SEARCH.SEARCH_FILTERS,
];
