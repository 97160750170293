import {FEATURE_TYPE_GROUPS} from 'Utils/featureTypes';
import {
    TRAVEL_MODES,
    ROUTE_OPTIONS,
    CARD_NAV_LOCATION,
    MENU_DEFAULT_LABELS_BG_COLOURS,
} from 'Utils/constants';
import {KIOSK_PROP_PATHS} from './KioskConfigPanel/ConfigComponentsConstants';

/**
 * navKey: {
 *     id: navKey, // should match navKey value
 *     menuItemId: string, // must match an id in KIOSK_ICONS,
 *     card: {
 *         key: string, // must match a key in CARD_COMPONENTS, didn't use component here as it will throw dependency cycle for more complex use
 *         isTitleHidden: bool, // hide card title section
 *         title: string // name of card
 *     },
 * }
 */
export const KIOSK_NAVIGATION = {
    search: {card: {key: 'searchCard'}, id: 'search', menuItemId: 'search'},
    searchEvent: {
        card: {isTitleHidden: true, key: 'eventDetailsCard'},
        id: 'searchEvent',
        menuItemId: 'events',
    },
    explore: {id: 'explore', menuItemId: 'explore'},
    tenants: {
        card: {key: 'tenantsList'},
        id: 'tenants',
        menuItemId: 'tenants',
    },
    featureSummary: {
        card: {isTitleHidden: true, key: 'featureSummaryCard'},
        id: CARD_NAV_LOCATION,
        menuItemId: 'explore',
    },
    featureDetailsCard: {
        card: {isTitleHidden: true, key: 'featureDetailsCard'},
        id: 'featureDetailsCard',
        menuItemId: 'tenants',
    },
    promotions: {
        card: {key: 'promotionsList'},
        id: 'promotions',
        menuItemId: 'promotions',
    },
    promotionsDetailsCard: {
        card: {isTitleHidden: true, key: 'promotionsDetailsCard'},
        id: 'promotionsDetailsCard',
        menuItemId: 'promotions',
    },
    eventDetails: {
        card: {isTitleHidden: true, key: 'eventDetailsCard'},
        id: 'eventDetails',
        menuItemId: 'events',
    },
    events: {card: {key: 'eventsList', title: 'Experiences'}, id: 'events', menuItemId: 'events'},
};

export const KIOSK_ICONS = [
    {
        id: 'explore',
        title: 'Explore',
        icon: {
            src: '/images/icon-explore.svg',
            backgroundColor: MENU_DEFAULT_LABELS_BG_COLOURS.EXPLORE,
            size: '100%',
        },
        type: 'kiosk-menu',
        labelPropPath: KIOSK_PROP_PATHS.MENU_TAB.MENU_LABEL_EXPLORE,
    },
    {
        id: 'tenants',
        title: 'Directory',
        icon: {
            src: '/images/icon-directory.svg',
            backgroundColor: MENU_DEFAULT_LABELS_BG_COLOURS.TENANTS,
        },
        type: 'kiosk-menu',
        labelPropPath: KIOSK_PROP_PATHS.MENU_TAB.MENU_LABEL_DIRECTORY,
    },
    {
        id: 'promotions',
        title: 'Promotions',
        icon: {
            src: '/images/icon-promotions.svg',
            backgroundColor: MENU_DEFAULT_LABELS_BG_COLOURS.PROMOTIONS,
        },
        type: 'kiosk-menu',
        labelPropPath: KIOSK_PROP_PATHS.MENU_TAB.MENU_LABEL_PROMOTIONS,
    },
    {
        id: 'events',
        title: 'Experiences',
        icon: {
            src: '/images/icon-event.svg',
            backgroundColor: MENU_DEFAULT_LABELS_BG_COLOURS.EVENTS,
        },
        type: 'kiosk-menu',
        labelPropPath: KIOSK_PROP_PATHS.MENU_TAB.MENU_LABEL_EXPERIENCES,
    },
    {
        id: 'search',
        title: 'Search',
        icon: {
            src: '/images/icon-search.svg',
            backgroundColor: MENU_DEFAULT_LABELS_BG_COLOURS.SEARCH,
        },
        type: 'kiosk-menu',
        labelPropPath: KIOSK_PROP_PATHS.MENU_TAB.MENU_LABEL_SEARCH,
    },
    {
        id: 'quick-links-entrances',
        title: 'Entrances',
        icon: {
            src: '/images/icon-entrance.svg',
            backgroundColor: '#FF8643',
        },
        type: 'quick-links',
        featureTypes: FEATURE_TYPE_GROUPS.entrances,
    },
    {
        id: 'quick-links-parking',
        title: 'Parking',
        icon: {
            src: '/images/icon-parking.svg',
            backgroundColor: '#075DA8',
        },
        type: 'quick-links',
        featureTypes: FEATURE_TYPE_GROUPS.parking,
    },
    {
        id: 'quick-links-restrooms',
        title: 'Restrooms',
        icon: {
            src: '/images/icon-restroom.svg',
            backgroundColor: '#7D3C8C',
        },
        type: 'quick-links',
        featureTypes: FEATURE_TYPE_GROUPS.restrooms,
    },
];

export const KIOSK_SCREEN_SAVER_DEFAULT_TIMEOUT = 20; // seconds

export const KIOSK_DIRECTIONS_AVAILABLE_SLOTS = 2;

export const KIOSK_ROUTE_MAP = {
    [ROUTE_OPTIONS.CYCLE]: {
        safeOnly: false,
        travelerMode: TRAVEL_MODES.CYCLING,
        travelMode: TRAVEL_MODES.CYCLING,
        wheelchairOnly: false,
    },
    [ROUTE_OPTIONS.DRIVE]: {
        safeOnly: false,
        travelerMode: TRAVEL_MODES.DRIVING,
        travelMode: TRAVEL_MODES.DRIVING,
        wheelchairOnly: false,
    },
    [ROUTE_OPTIONS.WALK]: {
        safeOnly: false,
        travelerMode: TRAVEL_MODES.WALKING,
        travelMode: TRAVEL_MODES.WALKING,
        wheelchairOnly: false,
    },
    [ROUTE_OPTIONS.WHEELCHAIR]: {
        safeOnly: false,
        travelerMode: TRAVEL_MODES.WALKING,
        travelMode: TRAVEL_MODES.WALKING,
        wheelchairOnly: true,
    },
};

export const PARKING_STATUSES = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    FULL: 'FULL',
    RESERVED: 'RESERVED',
    HIDDEN: 'HIDDEN/DISABLED',
};

export const PARKING_STATUS_DEFAULT = PARKING_STATUSES.HIDDEN;

export const PARKING_STATUSES_DEFAULT_TEXT = {
    [PARKING_STATUSES.OPEN]: 'OPEN',
    [PARKING_STATUSES.CLOSED]: 'CLOSED',
    [PARKING_STATUSES.FULL]: 'FULL',
    [PARKING_STATUSES.RESERVED]: 'RESERVED ONLY',
    [PARKING_STATUSES.HIDDEN]: 'N/A',
};

export const PARKING_STATUSES_MAX_WORD_LENGTH = 9;

export const PARKING_STATUSES_HINTS = {
    DEFAULT: `Max ${PARKING_STATUSES_MAX_WORD_LENGTH} Characters`,
    OPEN: `Max ${PARKING_STATUSES_MAX_WORD_LENGTH} Characters`,
    CLOSED: `Max ${PARKING_STATUSES_MAX_WORD_LENGTH} Characters`,
    FULL: `Max ${PARKING_STATUSES_MAX_WORD_LENGTH} Characters`,
    RESERVED: `Max 2 Words With a Max of ${PARKING_STATUSES_MAX_WORD_LENGTH} Characters Each`,
    HIDDEN: `Max ${PARKING_STATUSES_MAX_WORD_LENGTH} Characters`,
};

export const KIOSK_DIMENSIONS = {
    width: 1080,
    height: 1920,
};

export const KIOSK_LAYOUT_TABS = {
    GENERAL: 'GENERAL',
    IDLE_SCREEN: 'IDLE SCREEN',
    MENU: 'MENU',
    SEARCH: 'SEARCH',
};

export const KIOSK_MODES = {
    KIOSK: 'Kiosk',
    IDLE_SCREEN_SAVER: 'screensaver',
    IDLE_ADVERTISMENT: 'advertising',
};

export const ACCESSIBLE_KIOSK_MENU = {MIN: 65, MAX: 1100, DEFAULT: 336};
