import React from 'react';
import * as PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import {prependDigitalAssetsUrl} from 'Utils/prependDigitalAssetsUrl';
import EventIcon from 'Components/common/Icons/EventIcon';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles(() => ({
    assetImageUnderlay: {
        position: 'absolute',
        width: '61px',
        height: '43px',
        borderRadius: '12px',
        backgroundSize: 'cover',
    },
    assetImage: {
        position: 'absolute',
        backgroundImage: `url('images/icon-destination-marker.svg')`,
        width: '61px',
        height: '55px',
    },
    badge: {
        backgroundColor: '#988961',
        borderRadius: '50%',
        height: '20px',
        position: 'absolute',
        right: '-10px',
        top: '-10px',
    },
    textColor: {
        fill: props => props.textColor,
    },
    backgroundColor: {
        fill: props => props.backgroundColor,
    },
}));

const DestinationMarker = ({
    displayText,
    feature,
    textColor,
    backgroundColor,
    selectResultItem,
}) => {
    const classes = useStyles({textColor, backgroundColor});
    const image =
        feature?.properties?.tenant?.imageUrls?.thumb || feature?.properties?.imageUrls?.thumb;

    const hasEvents = feature?.properties?.events?.length > 0;
    return (
        <div
            id="destination-marker"
            onClick={() => {
                if (typeof selectResultItem === 'function') {
                    selectResultItem(feature);
                }
            }}
        >
            {image ? (
                <div
                    className={classes.assetImageUnderlay}
                    style={{
                        backgroundImage: `url("${prependDigitalAssetsUrl(image)}")`,
                    }}
                />
            ) : (
                <div className={classes.assetImageUnderlay}>
                    <SvgIcon viewBox="0 0 61 72" style={{width: 61, height: 72}} fill="none">
                        <rect
                            className={classes.backgroundColor}
                            x="1"
                            y="1"
                            width="59"
                            height="40.71"
                            rx="6"
                        />
                        <path
                            className={classes.textColor}
                            d="M29.9116 17.0137C29.4249 17.0259 28.9616 17.228 28.6174 17.5782C28.2732 17.9284 28.0746 18.3998 28.0626 18.8949C28.049 19.4098 28.2355 19.9094 28.5816 20.2853C28.9278 20.6612 29.4057 20.883 29.9116 20.9026C30.4174 20.883 30.8953 20.6612 31.2415 20.2853C31.5876 19.9094 31.7741 19.4098 31.7605 18.8949C31.7485 18.3998 31.5499 17.9284 31.2057 17.5782C30.8615 17.228 30.3982 17.0259 29.9116 17.0137ZM29.9116 14.7562C30.7161 14.7562 31.5025 14.9989 32.1715 15.4537C32.8404 15.9085 33.3618 16.5548 33.6697 17.3111C33.9775 18.0674 34.0581 18.8995 33.9011 19.7024C33.7442 20.5052 33.3568 21.2427 32.7879 21.8215C32.219 22.4003 31.4942 22.7945 30.7051 22.9542C29.9161 23.1139 29.0982 23.0319 28.3549 22.7186C27.6116 22.4054 26.9763 21.8749 26.5293 21.1943C26.0824 20.5137 25.8438 19.7135 25.8438 18.8949C25.8531 17.8002 26.2846 16.7529 27.0455 15.9788C27.8063 15.2047 28.8356 14.7656 29.9116 14.7562ZM29.9116 11.8711C27.076 11.8726 22.8854 14.255 22.8854 18.5187C22.8854 22.0299 27.4458 27.5487 29.9116 30.1825C32.3773 27.5487 36.9377 22.0299 36.9377 18.6436C36.9377 14.255 32.7471 11.8726 29.9116 11.8726V11.8711ZM29.9116 9.73999C33.7323 9.73999 39.1564 12.8749 39.1564 18.6451C39.1564 23.7877 32.1303 31.1863 30.7739 32.4415C30.6626 32.5604 30.5287 32.655 30.3803 32.7197C30.2319 32.7843 30.0722 32.8177 29.9108 32.8177C29.7494 32.8177 29.5897 32.7843 29.4413 32.7197C29.293 32.655 29.1591 32.5604 29.0477 32.4415L28.8022 32.1901C27.076 30.4338 20.6667 23.5364 20.6667 18.6451C20.6667 12.75 26.0908 9.73999 29.9116 9.73999Z"
                        />
                    </SvgIcon>
                </div>
            )}
            <div className={classes.assetImage} />
            {hasEvents && (
                <div className={classes.badge}>
                    <EventIcon />
                </div>
            )}
            <p> {displayText} </p>
        </div>
    );
};

DestinationMarker.propTypes = {
    displayText: PropTypes.string,
    feature: PropTypes.object,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    selectResultItem: PropTypes.func,
};

export default DestinationMarker;
