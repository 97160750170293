// TODO: this can go and we can use what we do in loadLayout.js
export const mixParkingLotConfigs = ({
    parkingLotConfigs,
    activeSceneId,
    defaultSceneId,
    useActiveOverrides = false,
}) => location => {
    if (location.category !== 'parking') {
        return false;
    }
    const sceneSwitch = sceneId => {
        switch (sceneId) {
            case activeSceneId:
                return 'activeScene';
            case defaultSceneId:
                return 'defaultScene';
            case null:
                return 'activeOverrides';
            default:
                return 'other';
        }
    };
    const configs = parkingLotConfigs
        ?.filter(
            // find active scene and active override configs
            parking =>
                `${parking.locationId}` === `${location.id}` && // @TODO need add a clientId check here
                // select the active scene and default scene
                ((activeSceneId
                    ? [activeSceneId, defaultSceneId].includes(parking.sceneId)
                    : true) ||
                    // select active overrides
                    (!parking.sceneId && // active overrides have NULL scene id
                        Object.keys(parking.config).length > 0))
        )
        .reduce(
            // transform the result into an object for easy access
            (configResult, parking) => ({
                ...configResult,
                ...{[sceneSwitch(parking.sceneId)]: parking.config},
            }),
            {}
        );
    const parkingLotConfig = {
        ...configs.defaultScene,
        ...configs.activeScene,
        ...(useActiveOverrides && configs.activeOverrides),
    };

    return parkingLotConfig && {parkingLotConfig};
};

export const mixLocations = locations => mixers =>
    locations
        ? locations.map(location =>
              mixers.reduce((mixedLocation, mixer) => ({...mixedLocation, ...mixer(location)}), {})
          )
        : [];
