import lodashIsNull from 'lodash/isNull';
import lodashIsUndefined from 'lodash/isUndefined';
import fetchFromApi from 'Data/services/fetchFromApi';
import log from 'Utils/log';
import config from 'config.json';

export default async function fetchNavMapIdBySignId({digitalSignId}) {
    try {
        const response = await fetchFromApi(
            `${config.nodeApiUrl}/navMapId-by-signId/${digitalSignId}`,
            {sendNewRelicParams: false}
        );

        if (lodashIsNull(response?.body) || lodashIsUndefined(response?.body)) {
            log.error(`api error - navMapId-by-signId - ${JSON.stringify(response)}`);
            return null;
        }

        return {
            data: response.body,
        };
    } catch (error) {
        return {error: error.message || error};
    }
}
