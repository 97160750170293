import {ACTION_TYPES} from 'Utils/constants';

const initialState = {
    selectedContentSourceId: null,
    error: null,
};

const explorerContentSourcesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.EXPLORER.SET_SELECTED_CONTENT_SOURCE: {
            return {
                ...state,
                selectedContentSourceId: action.data,
            };
        }
        case ACTION_TYPES.EXPLORER.RESET_SELECTED_CONTENT_SOURCE: {
            return {
                ...state,
                selectedContentSourceId: null,
            };
        }
        default:
            return state;
    }
};

export default explorerContentSourcesReducer;
