import storage from './storage';

const log = (...args) => {
    if (storage.getIsDebug()) {
        // eslint-disable-next-line no-console
        console.log(...args);
    }
};

log.warn = (...args) => {
    if (storage.getIsDebug()) {
        console.warn(...args);
    }
};

/**
 * Log an error to the console and to New Relic
 * @param error
 * @param {object} [args] - custom arguments to be sent to New Relic
 */
log.error = (error, args) => {
    console.error(error, args);
    if (storage.getIsDebug()) {
        // Trace isn't shown in tests so we do it manually
        // eslint-disable-next-line no-console
        console.trace();
    }

    const errorObject = error instanceof Error ? error : Error(error);
    if (window.newrelic) window.newrelic.noticeError(errorObject, args);
};

log.trace = (...args) => {
    if (storage.getIsDebug()) {
        // eslint-disable-next-line no-console
        console.trace(...args);
    }
};

log.debug = (...args) => {
    if (storage.getIsDebug()) {
        // eslint-disable-next-line no-console
        console.debug(...args);
    }
};

export default log;
