import {
    EXPLORER_PARKING_STATUS_COLOURS,
    LABEL_DISPLAY_TYPES,
    MAP_LAYERS,
    MAP_THEMES,
    PAM_OS_ENABLED_FEATURES_KEYS,
    ROUTE_STYLES,
    SOURCES,
} from 'Utils/constants';
import {makeFeatureTypeBool} from 'Utils/explorer/mapUtils';
import storage from 'Utils/storage';

// prettier-ignore
export const makeLabelLayers = props => [
    {
        id: MAP_LAYERS.LABELS,
        type: 'symbol',
        source: SOURCES.LABELS,
        filter: ['all',
            ['!=', ['has', 'routeViaGroupId'], true],
            ['!=', ['get', 'isGroup'], true],
        ],
        // labels show up a bit after other features
        minzoom: props.navMapShowZoom + 0.5,
        layout: {
            'icon-anchor': 'bottom',
            'icon-image':
                [
                    'case',

                    ['all',
                        ['any',
                            ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.ICON],
                            ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.BOTH],
                        ],
                        ['has', 'events'],
                        ['>', ['length', ['get', 'events']], 0],
                        ['==', ['get', 'overrideLocationName', ['at', 0, ['get', 'events']]], 1],
                        ['!=', ['get', 'navMapIconUrl', ['at', 0, ['get', 'events']]], null],
                    ],
                    ['string', ['get', 'navMapIconUrl', ['at', 0, ['get', 'events']]]],

                    ['all',
                        ['boolean', ['has', 'parkingLotConfig'], false],
                        ['has', 'iconUrl'],
                        // parkingLotConfig.status should exist in PARKING_STATUS
                        ['in', ['get', 'status', ['get', 'parkingLotConfig']], ['literal', Object.keys(EXPLORER_PARKING_STATUS_COLOURS)]]
                    ],
                    ['concat', ['get', 'status', ['get', 'parkingLotConfig']], '-', ['get', 'iconUrl']],

                    ['any',
                        ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.TEXT],
                        ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.NONE],
                        ['all',
                            ['any',
                                ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.ICON],
                                ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.BOTH],
                            ],
                            ['has', 'events'],
                            ['>', ['length', ['get', 'events']], 0],
                            ['==', ['get', 'overrideLocationName', ['at', 0, ['get', 'events']]], 1],
                            ['==', ['get', 'navMapIconUrl', ['at', 0, ['get', 'events']]], null],
                        ],
                    ],
                    '/images/icon-spacer-bg.png',

                    ['get', 'iconUrl'],
                ],
            'icon-optional': false,
            'icon-offset': [
                'case',
                ['all',
                    ['boolean', ['has', 'parkingLotConfig'], false],
                    ['has', 'iconUrl'],
                    // parkingLotConfig.status should exist in PARKING_STATUS
                    ['in', ['get', 'status', ['get', 'parkingLotConfig']], ['literal', Object.keys(EXPLORER_PARKING_STATUS_COLOURS)]]
                ],
                ['literal',[0, 0]],
                ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.TEXT],
                ['literal',[0, 21]],
                ['literal',[0, 0]],
            ],
            'symbol-z-order': 'source',
            'symbol-sort-key': [
                'case',
                [
                    'all',
                    ['boolean', ['has', 'sortKey'], false]
                ],
                ['get', 'sortKey'],
                99999
            ],
            'text-field': [
                'case',
                ['all',
                    ['has', 'events'],
                    ['>', ['length', ['get', 'events']], 0]
                ],
                [
                    'format',

                    ['case',
                        ['any',
                            ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.NONE],
                            ['all',
                                ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.ICON],
                                [
                                    'any',
                                    ['==', ['get', 'overrideLocationName', ['at', 0, ['get', 'events']]], 0],
                                    ['all',
                                        ['==', ['get', 'overrideLocationName', ['at', 0, ['get', 'events']]], 1],
                                        ['!=', ['get', 'navMapIconUrl', ['at', 0, ['get', 'events']]], null],
                                    ],
                                ],
                            ],
                        ],
                        '',
                        ['match',
                            ['get', 'overrideLocationName', ['at', 0, ['get', 'events']]],
                            1, ['concat', ['get', 'name', ['at', 0, ['get', 'events']]], '\n'],
                            ['concat', ['get', 'mapLabelText'], '\n'],
                        ],
                    ],
                    {
                        'text-font': [
                            'literal',
                            ['DIN Offc Pro Bold', 'Arial Unicode MS Regular']
                        ]
                    },

                    ['match',
                        ['get', 'overrideLocationName', ['at', 0, ['get', 'events']]],
                        0, ["concat", ['get', 'name', ['at', 0, ['get', 'events']]], '\n'],
                        ['string', ''],
                    ],
                    {
                        'font-scale': 0.8,
                    },

                    ['concat', ['get', 'timeDisplayOnMap', ['at', 0, ['get', 'events']]], '\n'],
                    {
                        'font-scale': 0.8,
                    },

                    ['case',
                        ['any',
                            ['all',
                                ['==', ['get', 'overrideLocationName', ['at', 0, ['get', 'events']]], 1],
                                ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.ICON],
                            ],
                            ['==', ['get', 'overrideLocationName', ['at', 0, ['get', 'events']]], 0],
                        ],
                        '',
                        ['concat', ['get', 'mapLabelText'], '\n']
                    ],
                    {
                        'font-scale': 0.8, 'text-font': [
                            'literal',
                            ['DIN Offc Pro Bold', 'Arial Unicode MS Regular']
                        ]
                    },
                ],

                ['any',
                    ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.BOTH],
                    ['==', ['get', 'labelDisplayType'], LABEL_DISPLAY_TYPES.TEXT],
                ],
                ['get', 'mapLabelText'],

                '',
            ],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
            'text-letter-spacing': 0.1,
            'text-max-width': 14,
            'text-padding': 5,
            'text-size': props.labelSizeSpecRule,
            'text-anchor': [
                'case',
                ['has', 'iconUrl'], 'top',
                'center',
            ],
        },
        paint: {
            'text-color': props.textColor,
            'text-halo-color': props.haloColor,
            'text-halo-width': props.haloWidth,
            'text-halo-blur': 0.2,
            'text-opacity': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                0,
                ['all',
                    ['has', 'events'],
                    ['>', ['length', ['get', 'events']], 0]
                ], 1,
                ['match',
                    ['get', 'labelDisplayType'],
                    LABEL_DISPLAY_TYPES.ICON, 0,
                    LABEL_DISPLAY_TYPES.NONE, 0,
                    1
                ]
            ],
            'icon-opacity': [
                'case',
                ['boolean', ['feature-state', 'selected'], false], // if selected
                0,
                [
                    'match',
                    ['get', 'labelDisplayType'],
                    LABEL_DISPLAY_TYPES.TEXT, 1,
                    LABEL_DISPLAY_TYPES.NONE, 0,
                    1,
                ],
            ]
        },
    },
];

// prettier-ignore
export const makePolygonLayers = props => {
    const filter = [
        'all',
        ['==', ['get', makeFeatureTypeBool(props)], true],
        ['!=', ['get', 'displayAsBoundary'], true],
    ];
    const layers = [];
    const colorPropName = props.mapTheme === MAP_THEMES.EXPLORER_LIGHT ? 'lightModeColor' : 'darkModeColor';

    // if defaultExtrusion is available i.e. layer can be extruded
    if ('defaultExtrusion' in props) {
        layers.push({
            id: `pam-${props.code}`,
            source: SOURCES.NAV_MAP,
            type: 'fill-extrusion',
            filter,
            paint: {
                'fill-extrusion-opacity': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    // fade in over a 0.5 zoom range
                    props.navMapShowZoom,
                    0, // not visible zoomed out
                    props.navMapShowZoom + 0.5,
                    props.extrudeOpacity ?? 1, // quickly become visible
                ],
                'fill-extrusion-color': ['coalesce', ['get', colorPropName], props.color],
                'fill-extrusion-base': [
                    'case',
                    ['to-boolean', ['feature-state', 'selected']],
                    0,
                    ['coalesce', ['get', 'styleExtrusionBase'], 0],
                ],
                'fill-extrusion-height': [
                    'case',
                    ['to-boolean', ['feature-state', 'selected']],
                    0,
                    [
                        '+',
                        ['coalesce',
                            ['get', 'styleExtrusionHeight'],
                            props.defaultExtrusion
                        ],
                        ['coalesce', ['get', 'styleExtrusionBase'], 0],
                    ],
                ],
            },
        });
    } else {
        layers.push({
            id: `pam-${props.code}`,
            source: SOURCES.NAV_MAP,
            type: 'fill',
            minzoom: props.navMapShowZoom,
            filter,
            paint: {
                'fill-color': ['coalesce', ['get', colorPropName], props.color],
                'fill-opacity': [
                    'case',
                    ['to-boolean', ['feature-state', 'selected']],
                    0,
                    props.fillOpacity ?? 1
                ],
            },
        });

        if (props.borderColor) {
            layers.push({
                id: `pam-${props.code}-line`,
                source: SOURCES.NAV_MAP,
                type: 'line',
                minzoom: props.navMapShowZoom,
                filter,
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                paint: {
                    'line-color': [
                        'coalesce',
                        ['get', 'styleLineColor'],
                        props.borderColor,
                    ],
                    'line-width': [
                        'case',
                        ['boolean', ['feature-state', 'selected'], false],
                        0,
                        props.borderWidth ?? 2
                    ],
                },
            });
        }
    }

    if (storage.getIsDebug() && props.canForcePamRoutes) {
        layers.push({
            id: `pam-mapbox-route-toggle-${props.code}-line`,
            source: SOURCES.NAV_MAP,
            type: 'line',
            minzoom: props.navMapShowZoom,
            filter,
            paint: {
                'line-color': [
                    'case',
                    ['==', ['get', 'forcePamRoutes'], false],
                    '#00ff48',
                    '#f50528',
                ],
                'line-width': 2,
            },
        });
    }

    return layers;
};

export const makeRouteLayers = props => {
    const routeWidth = props.route?.width ? props.route.width : 3;
    const routeColor = props.route?.colors?.route ? props.route.colors.route : '#0074D5';
    const routeBaselineColor = props.route?.colors?.baseline
        ? props.route.colors.baseline
        : '#CCCCCC';
    const nightSafeRouteBaselineColor = props.nightSafeRoute?.colors?.baseline
        ? props.nightSafeRoute.colors.baseline
        : '#FDCE31';

    const layers = [
        {
            id: `${props.code}-walking-night-safe-baseline`,
            source: SOURCES.ROUTES,
            type: 'line',
            filter: [
                'all',
                ['==', ['get', 'routeStyle'], ROUTE_STYLES.WALKING_ROUTE],
                ['==', ['get', 'isNightSafe'], true],
            ],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': nightSafeRouteBaselineColor,
                'line-width': routeWidth,
            },
        },
        {
            id: `${props.code}-walking-baseline`,
            source: SOURCES.ROUTES,
            type: 'line',
            filter: [
                'all',
                ['==', ['get', 'routeStyle'], ROUTE_STYLES.WALKING_ROUTE],
                ['!=', ['get', 'isNightSafe'], true],
            ],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': routeBaselineColor,
                'line-width': routeWidth + 2,
            },
        },
        {
            id: `${props.code}-walking`,
            source: SOURCES.ROUTES,
            type: 'line',
            filter: ['==', ['get', 'routeStyle'], ROUTE_STYLES.WALKING_ROUTE],
            layout: {
                'line-cap': 'butt',
                'line-join': 'miter',
            },
            paint: {
                'line-color': routeColor,
                'line-width': routeWidth,
                'line-dasharray': [1.5, 1.5],
            },
        },
        {
            id: `${props.code}-driving`,
            source: SOURCES.ROUTES,
            type: 'line',
            filter: ['==', ['get', 'routeStyle'], ROUTE_STYLES.DRIVING_ROUTE],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': routeColor,
                'line-width': routeWidth,
            },
        },
    ];

    if (storage.getIsDebug()) {
        const definitions = [
            {
                id: 'default',
                filters: ['==', ['get', 'routeAllowsPedestrians'], true],
                lineWidth: 2,
                lineDashArray: [2, 2],
            },
            {
                id: 'vehicles-allowed',
                filters: ['==', ['get', 'routeAllowsVehicles'], true],
                lineWidth: 4,
                lineDashArray: [4, 4],
            },
            {
                id: 'steps',
                filters: ['==', ['get', 'routeIsSteps'], true],
                lineWidth: 10,
                lineDashArray: [0.4, 0.4],
            },
        ];

        definitions.forEach(definition => {
            layers.push({
                id: `${definition.id}-base-line`,
                source: SOURCES.NAV_MAP,
                type: 'line',
                filter: ['all', ['==', ['get', 'isRouteSegment'], true], definition.filters],
                paint: {
                    'line-color': '#ccc',
                    'line-width': definition.lineWidth,
                    'line-opacity': 0.5,
                },
            });
            layers.push({
                id: `${definition.id}-line`,
                type: 'line',
                source: SOURCES.NAV_MAP,
                filter: ['all', ['==', ['get', 'isRouteSegment'], true], definition.filters],
                paint: {
                    'line-color': '#bbb',
                    'line-width': definition.lineWidth,
                    'line-dasharray': definition.lineDashArray,
                    'line-opacity': 0.75,
                },
            });
        });
        layers.push({
            id: `threshold-point`,
            type: 'circle',
            source: SOURCES.NAV_MAP,
            filter: ['==', ['get', 'isThreshold'], true],
            paint: {
                'circle-radius': 6,
                'circle-color': '#ddd',
                'circle-stroke-width': 3,
                'circle-stroke-color': [
                    'case',
                    ['==', ['get', 'blockExternalRouting'], true],
                    '#f11A27',
                    '#0088ff',
                ],
            },
        });
    }

    return layers;
};

// prettier-ignore
export const makeOSMBuildingLayer = props => ({
    id: '3d-buildings',
    source: 'composite',
    type: 'fill-extrusion',
    'source-layer': 'building',
    filter: props.hasPamOsEnabledFeatures(PAM_OS_ENABLED_FEATURES_KEYS.MAPBOX_EXTRUDE_BUILDINGS_LAYER) ? [
        '==',
        ['get', 'extrude'],
        'true'
    ] : ['boolean', false],
    paint: {
        'fill-extrusion-opacity': [
            'interpolate',
            ['linear'], ['zoom'],
            // TODO verify this is the desire behaviour
            ...[
                // fade out over a 0.5 zoom range, based on navMap config
                props.worldHideZoom - 1, 0.85,
                props.worldHideZoom - 0.5, 0.7, // almost solid at moderate zoom
                props.worldHideZoom, 0, // fading out when zooming in to set level
            ]
        ],
        'fill-extrusion-color': props.color,
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-vertical-gradient': true,
    },
});

export const makePointLayer = props => ({
    source: SOURCES.ROUTES,
    id: `pam-route-transition-point`,
    type: 'circle',
    filter: ['==', ['get', 'isRouteTransition'], true],
    paint: {
        'circle-radius': props.radius,
        'circle-color': props.color,
        'circle-stroke-width': props.borderRadius,
        'circle-stroke-color': props.borderColor,
    },
});
