import loadLayout from 'Data/actionCreators/loadLayout';
import store from './store';

/**
 * @param {URL} url
 * @param isExplorer
 */
const initLayout = async ({url, isExplorer = false}) => {
    await loadLayout({url, isExplorer})(store.dispatch);
};

export default initLayout;
