import {ACTION_TYPES} from 'Utils/constants';
import store from './store';

/**
 * Initialise settings based on query parameters and local storage
 * This is synchronous and must run before the other init* functions that fetch data
 *
 * @param {URL} url - a URL object instance
 */
const init = url => {
    const {searchParams, pathname} = url;
    const isDsm = pathname.startsWith('/dsm');
    const isMapEditor = pathname.startsWith('/map-editor');
    const isParkingManager = pathname.startsWith('/parking-manager');

    // For future use
    // const isConnectLayout = pathname.startsWith('/layout');
    // const isExplorer = !isDsm && !isMapEditor && !isConnectLayout;

    if (isDsm) {
        document.title = 'PAM OS - Digital Signs';
    }

    if (isMapEditor) {
        document.title = 'PAM OS - Map Editor';
    }

    if (isParkingManager) {
        document.title = 'PAM OS - Parking';
    }

    store.dispatch({
        type: ACTION_TYPES.SET_EDIT_MODE,
        data: isMapEditor,
    });

    store.dispatch({
        type: ACTION_TYPES.SET_DEVICE_DATA,
        data: {
            useMocks: searchParams.get('useMocks') === 'y',
        },
    });

    if (isDsm || isMapEditor || isParkingManager) {
        if (!localStorage.PAM_DISABLE_SAVE_PROMPT) {
            window.addEventListener('beforeunload', e => {
                if (
                    store.getState().pageState.shared.hasUnsavedChanges &&
                    !store.getState().session.sessionExpired
                ) {
                    e.returnValue = 'You have unsaved changes'; // For Chrome
                    e.preventDefault(); // For everyone else
                }
            });
        }
    }
};

export default init;
