import {ACTION_TYPES} from 'Utils/constants';

const digitalSignDataReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_DIGITAL_SIGN_DATA:
            return action.data || {};
        default:
            return state;
    }
};

export default digitalSignDataReducer;
