import {LngLatBounds} from 'mapbox-gl';
import lodashOmitBy from 'lodash/omitBy';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsNull from 'lodash/isNull';
import lodashIsArray from 'lodash/isArray';
import {configMergeWith} from 'Data/actionCreators/dsm/updateConfig';
import setMapboxStyles from 'Data/actionCreators/setMapboxStyles';
import fetchDigitalSignData from 'Data/services/fetchDigitalSignData';
import fetchNavMapIdBySignId from 'Data/services/fetchNavMapIdBySignId';
import fetchLayoutDataByNavmapId from 'Data/services/fetchLayoutDataByNavmapId';
import fetchMocks from 'Data/services/fetchMocks';
import parseLocation from 'Data/services/parseLocation';
import store from 'Data/store';
import * as analytics from 'Utils/analytics';
import {
    ACTION_TYPES,
    CONFIG_DATA_BASE_KEYS,
    DEVICE_TYPES,
    LAYOUT_IDS,
    MOCK_CLIENT_ID,
    MOCK_CLIENT_NAME,
    PAM_OS_MESSAGE_TYPES,
    POLL_TIMEOUT,
} from 'Utils/constants';
import {
    mixLocationsIntoNavMap,
    mixPromotionsWithTenants,
    mixTenantsIntoNavMap,
} from 'Utils/data/explorerDataUtils';
import {getCenterPoint} from 'Utils/data/mapDataUtils';
import {getHeadsUpSignRotation, populateContentDetails} from 'Utils/data/dsmDataUtils';
import {getParkingDetails} from 'Utils/parkingManager/parkingManager';
import {mixLocations} from 'Utils/locationMixers';
import log from 'Utils/log';
import {isAngle} from 'Utils/math';
import getPrimaryDefaultConfig from 'Components/layouts/common/getPrimaryDefaultConfig';
import IMDF_FEATURE_TYPES from 'Utils/imdfFeatureTypes';
import {transformDistanceRingDistancesForStore} from 'Utils/distanceRingUtil';
import fetchPromotions from 'Data/services/fetchPromotions';
import {KIOSK_NAVIGATION} from 'Components/layouts/kiosk/kioskLayoutConstants';
import hideCard from 'Data/actionCreators/kiosk/hideCard';
import {transformSearchFiltersForStore} from 'Utils/searchFiltersUtil';
import storage from 'Utils/storage';
import {updateStoreVariables} from 'Utils/insights';

let layoutDataEtag = null;
let digitalSignDataEtag = null;
let commonSignDataEtag = null;
let promotionsEtag = null;

// Required only to reset this between tests
export const resetLoadLayout = () => {
    layoutDataEtag = null;
    digitalSignDataEtag = null;
    commonSignDataEtag = null;
    promotionsEtag = null;
};

const getNavMapOverride = ({scenes, navMapOverrides, mockActiveSceneId}) => {
    // get the active scene
    let activeScene = scenes.find(scene => scene.isActive);

    // override active scene with mockActiveSceneId, if it's a valid sceneId
    if (mockActiveSceneId) {
        const mockActiveScene = scenes.find(scene => scene.id === mockActiveSceneId);
        if (mockActiveScene) {
            activeScene = mockActiveScene;
        } else {
            log.warn("couldn't find scene with provided mock Id");
        }
    }
    return activeScene && navMapOverrides.find(sceneData => sceneData.sceneId === activeScene.id);
};

export const overrideNavMap = ({navMap, scenes, navMapOverrides, mockActiveSceneId}) => {
    const activeScene = getNavMapOverride({scenes, navMapOverrides, mockActiveSceneId});
    // if we are not able to find an active scene... we resort to base one
    if (!activeScene) {
        log.warn("couldn't find an active scene");
        return navMap;
    }
    return {
        ...navMap,
        features: navMap.features.map(feature => {
            const activeFeature =
                activeScene.overrides.find(feat => feat.featureId === feature.id) || {};
            if (!activeFeature) {
                // no matching feature in active scene
                return feature;
            }

            // check if geometry needs to be reversed
            let coordinates = feature.geometry && feature.geometry.coordinates;
            if (activeFeature?.properties?.reverseOneWay && coordinates) {
                coordinates = feature.geometry.coordinates.reverse();
            }
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    ...activeFeature.properties,
                },
                geometry: {
                    ...feature.geometry,
                    coordinates,
                },
            };
        }),
    };
};

// XXXXXX need to check this
export const getMergedLayoutConfig = ({
    layouts,
    layoutConfigs,
    layoutId,
    activeSceneId,
    defaultSceneId,
    isExplorer,
}) => {
    // Layout manager config is the mother of all the configuration,
    // it should be added depending on layout's isLayoutTheBaseConfig
    const layoutManagerConfig = {config: getPrimaryDefaultConfig(layoutId)};
    const layout = layouts.find(l => l.id === layoutId);

    // Find the config for this sign (contains overrides only)
    // |               | Default scene | Selected scene |
    // |---------------|---------------|----------------|
    // | Layout config | 1             | 2              |
    // | Sign config   | 3             | 4*            |
    const activeSceneConfig = layoutConfigs.find(
        layoutConfig =>
            layoutConfig.layoutId === layoutId &&
            layoutConfig.digitalSignId === null &&
            layoutConfig.sceneId === activeSceneId
    );

    // |               | Default scene | Selected scene |
    // |---------------|---------------|----------------|
    // | Layout config | 1             | 2              |
    // | Sign config   | 3*            | 4             |
    const defaultSceneConfig = layoutConfigs.find(
        layoutConfig =>
            layoutConfig.layoutId === layoutId &&
            layoutConfig.digitalSignId === null &&
            layoutConfig.sceneId === defaultSceneId
    );

    const baseConfig = configMergeWith(layoutManagerConfig, defaultSceneConfig);

    // global parking status mapping
    const statusMappingConfig = layoutConfigs.find(
        layoutConfig =>
            layoutConfig.layoutId === layoutId &&
            layoutConfig.digitalSignId === null &&
            layoutConfig.sceneId === defaultSceneId
    );

    const parkingStatusMapping = lodashOmitBy(
        statusMappingConfig?.config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.parkingStatusMapping,
        lodashIsEmpty
    );

    const baseAndActiveSceneConfig = configMergeWith(baseConfig.config, activeSceneConfig?.config);

    // activeSceneConfig sign and concatGroup might not have a scene config yet, therefore it will be {}
    const config = {
        // global configs should always come first before any other configs
        ...baseAndActiveSceneConfig,
        parkingStatusMapping,
    };

    // explorer will always use global external locations config
    if (layout.id === LAYOUT_IDS.KIOSK && isExplorer) {
        config.externalLocations = baseAndActiveSceneConfig.externalLocations;
    }

    // Smoosh them all together
    return {config, layoutId};
};

export const getMergedSignConfig = ({
    layouts,
    layoutConfigs,
    digitalSignId,
    activeSceneId,
    defaultSceneId,
    isExplorer,
}) => {
    // Change the variable names based on the document
    // @see https://mediabankpam.atlassian.net/wiki/spaces/BR/pages/804257840/Layout+Management#LayoutManagement-Inheritance

    // Find the config for this sign (contains overrides only)
    // |               | Default scene | Selected scene |
    // |---------------|---------------|----------------|
    // | Layout config | 1             | 2              |
    // | Sign config   | 3             | 4*            |
    const selectedSceneSignConfig = layoutConfigs.find(
        layoutConfig =>
            layoutConfig.digitalSignId === digitalSignId && layoutConfig.sceneId === activeSceneId
    );

    // |               | Default scene | Selected scene |
    // |---------------|---------------|----------------|
    // | Layout config | 1             | 2              |
    // | Sign config   | 3*            | 4             |
    const defaultSceneSignConfig = layoutConfigs.find(
        layoutConfig =>
            layoutConfig.digitalSignId === digitalSignId && layoutConfig.sceneId === defaultSceneId
    );

    if (!defaultSceneSignConfig) {
        throw Error(
            `The sign with id "${digitalSignId}" does not have a default scene sign config defined`
        );
    }

    // Base config's layoutId is the single source of truth and always will have for a sign
    const layoutId = defaultSceneSignConfig.layoutId;

    // for DDS, PS & FSX
    if (
        [LAYOUT_IDS.HPK_DDS, LAYOUT_IDS.HPK_PARKING_LOT, LAYOUT_IDS.EXPERIENCES].includes(
            layoutId
        ) &&
        document.getElementById('app')
    ) {
        document.getElementById('app').style.background = '#000';
    }
    // Layout manager config is the mother of all the configuration,
    // it should be added depending on layout's isLayoutTheBaseConfig
    const layoutManagerConfig = {config: getPrimaryDefaultConfig(layoutId)};
    const layout = layouts.find(l => l.id === layoutId);

    let layoutsGlobalConfig = {};

    let parkingStatusMapping;
    let signTemplateSize;
    let bannerSlides;

    // @TODO If this feature is made available in other layouts, have a flag like isLayoutTheBaseConfig to get the concatGroupConfig
    if (layout.id === LAYOUT_IDS.HPK_DDS || layout.id === LAYOUT_IDS.HPK_PARKING_LOT) {
        layoutsGlobalConfig = layoutConfigs.find(
            layoutConfig =>
                layoutConfig.layoutId === layoutId &&
                layoutConfig.digitalSignId === null &&
                layoutConfig.sceneId === defaultSceneId
        )?.config;

        signTemplateSize =
            layoutsGlobalConfig?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.signTemplateSize;
        bannerSlides = layoutsGlobalConfig?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.bannerSlides;
    }

    let baseConfig;

    if (layout.id === LAYOUT_IDS.HPK_PARKING_LOT) {
        /**
         * For parking layout sign we need to follow config merge/override order:
         * - global parking layout config overrides defaults from layoutManagerConfig
         * - default scene config for the parking sign overrides global parking layout config
         */
        const baseGlobalConfig = configMergeWith(layoutManagerConfig, {
            config: {
                [CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]: {
                    parkingIcon:
                        layoutsGlobalConfig?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.parkingIcon,
                    availableSpaces:
                        layoutsGlobalConfig?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]
                            ?.availableSpaces,
                    rates: layoutsGlobalConfig?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.rates,
                },
            },
        });
        baseConfig = configMergeWith(baseGlobalConfig, defaultSceneSignConfig);
    } else {
        // if (!layout.isLayoutTheBaseConfig)
        // Incorporate the layoutManagerConfig immediately since we know defaultSceneLayoutConfig (2*) is the baseConfig
        // |               | Default scene | Selected scene |
        // |---------------|---------------|----------------|
        // | Layout config | N/A           | 2*             |
        // | Sign config   | 3             | 4              |
        baseConfig = configMergeWith(layoutManagerConfig, defaultSceneSignConfig);
    }

    // Layout config scene is the base config
    if (layout.isLayoutTheBaseConfig) {
        // We don't incorporate the layoutManagerConfig here yet
        const selectedSceneLayoutConfig = layoutConfigs.find(
            layoutConfig =>
                layoutConfig.layoutId === layoutId &&
                layoutConfig.digitalSignId === null &&
                layoutConfig.sceneId === activeSceneId
        );
        // Find the config for the layout that this sign uses.
        baseConfig = configMergeWith(defaultSceneSignConfig, selectedSceneLayoutConfig);
    }

    // global parking status mapping
    const statusMappingConfig = layoutConfigs.find(
        layoutConfig =>
            layoutConfig.layoutId === layoutId &&
            layoutConfig.digitalSignId === null &&
            layoutConfig.sceneId === defaultSceneId
    );

    if (
        layout.id === LAYOUT_IDS.HPK_PARKING_LOT &&
        defaultSceneSignConfig?.config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.signTypeId &&
        statusMappingConfig?.config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.settings &&
        statusMappingConfig?.config[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.settings[
            defaultSceneSignConfig.config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.signTypeId
        ]
    ) {
        parkingStatusMapping =
            statusMappingConfig.config[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.settings[
                defaultSceneSignConfig.config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.signTypeId
            ];
    } else {
        parkingStatusMapping = lodashOmitBy(
            statusMappingConfig?.config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]
                ?.parkingStatusMapping,
            lodashIsEmpty
        );
    }
    let externalLocationsConfig;
    if (layout.id === LAYOUT_IDS.KIOSK) {
        // |               | Default scene | Selected scene |
        // |---------------|---------------|----------------|
        // | Layout config | 1*            | 2              |
        // | Sign config   | 3             | 4              |
        const kioskDefaultSceneLayoutConfig = layoutConfigs.find(
            layoutConfig =>
                layoutConfig.layoutId === layoutId &&
                layoutConfig.digitalSignId === null &&
                layoutConfig.sceneId === defaultSceneId
        );

        // We're incorporating layoutManagerConfig here once we're got the kioskDefaultSceneLayoutConfig (1*)
        const kioskGlobalConfig = configMergeWith(
            layoutManagerConfig,
            kioskDefaultSceneLayoutConfig
        );

        // Merging global default scene config and default scene config, this is will used by any active scene
        layoutsGlobalConfig = configMergeWith(kioskGlobalConfig, defaultSceneSignConfig)?.config;
        externalLocationsConfig =
            layoutsGlobalConfig?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.externalLocations;
    }

    // If the layout.isLayoutTheBaseConfig=true,
    // then you must incorporate layoutManagerConfig with the defaultSceneLayoutConfig to get globalConfig
    if (layout.id === LAYOUT_IDS.EXPERIENCES) {
        // |               | Default scene | Selected scene |
        // |---------------|---------------|----------------|
        // | Layout config | 1*            | 2              |
        // | Sign config   | 3             | 4              |
        const experiencesDefaultSceneLayoutConfig = layoutConfigs.find(
            layoutConfig =>
                layoutConfig.layoutId === layoutId &&
                layoutConfig.digitalSignId === null &&
                layoutConfig.sceneId === defaultSceneId
        );

        // We're incorporating layout.isLayoutTheBaseConfig here once we're got the experiencesDefaultSceneLayoutConfig (1*)
        const experiencesGlobalConfig = configMergeWith(
            layoutManagerConfig,
            experiencesDefaultSceneLayoutConfig
        );

        // Merging global default scene config and default scene config, this is will used by any active scene
        layoutsGlobalConfig = configMergeWith(experiencesGlobalConfig, defaultSceneSignConfig)
            ?.config;
    }

    const baseAndSelectedSignConfig = configMergeWith(baseConfig, selectedSceneSignConfig);

    const selectedSignConfig = configMergeWith(baseAndSelectedSignConfig.config, {
        [CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]: {
            parkingStatusMapping,
            signTemplateSize: {...baseAndSelectedSignConfig.signTemplateSize, ...signTemplateSize},
            bannerSlides: {...baseAndSelectedSignConfig.bannerSlides, ...bannerSlides},
        },
    });

    // activeSceneConfig sign and concatGroup might not have a scene config yet, therefore it will be {}
    const config = {
        // global configs should always come first before any other configs
        ...configMergeWith(layoutsGlobalConfig, selectedSignConfig),
        [CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]:
            selectedSceneSignConfig?.config?.[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE],
    };

    // explorer will always use global external locations config
    if (isExplorer) {
        config[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE].externalLocations = externalLocationsConfig;
    }
    // Smoosh them all together
    return {
        config,
        layoutId,
        isParkingLotLayout: layoutId === LAYOUT_IDS.HPK_PARKING_LOT,
    };
};

const mergeLocationAndFeaturesInEvents = ({events, navMapWithLocations, sceneId}) => {
    if (lodashIsArray(events)) {
        return events
            .map(event => {
                if (!event.locationId) {
                    return {...event};
                }
                const feature = navMapWithLocations.features.find(
                    feat =>
                        !feat.properties.disabled &&
                        !feat.properties.isDisplayPoint &&
                        feat.properties.locationId === event.locationId.toString()
                );
                return feature ? {...event, feature} : false;
            })
            .filter(event => !!event && (!event.sceneId || event.sceneId === sceneId));
    }

    return [];
};

const filterAndSortFeaturesWithTenants = features => {
    return features
        .filter(feature => !!feature.properties.tenant && !feature.properties.isDisplayPoint)
        .sort((featureA, featureB) => {
            const nameA = featureA.properties.tenant.name.toUpperCase();
            const nameB = featureB.properties.tenant.name.toUpperCase();
            return nameA.localeCompare(nameB);
        });
};

export const mergeLocationsAndParkingStatusAndEvents = ({
    activeSceneId,
    defaultSceneId,
    locations,
    parkingLotConfigs,
    events = [],
}) =>
    locations.map(location => {
        const mergedLocationData =
            location.category === 'parking'
                ? {
                      ...location,
                      parkingLotConfig: getParkingDetails({
                          activeSceneId,
                          defaultSceneId,
                          parkingLocationId: location.id,
                          parkingLotConfigs,
                      })?.config,
                  }
                : location;
        if (location.iconUrl) {
            // iconUrl will be overwritten by navMapLightIconUrl or navMapDarkIconUrl
            mergedLocationData.svgIconUrl = location.iconUrl;
        }
        mergedLocationData.events = events
            .filter(
                event => event.locationId === location.id && event.clientId === location.clientId
            )
            .map(event => ({...event, timeDisplayOnMap: event.displayTime}));
        // mergedLocationData.events?.length && console.log(mergedLocationData.events);
        return mergedLocationData;
    });

let newPageLoad = true;

/*     1) get center from navMapConfig
       2) Or pick the first venue feature and its centroid
       3) Or center of bbox of the entire navmap
    */
const getMapCenterForBounds = navMap => {
    if (navMap?.config?.center?.lng) {
        return navMap.config.center;
    }

    const mainVenueFeature = navMap.features.find(
        f => f.properties?.featureType?.imdfFeatureType?.code === IMDF_FEATURE_TYPES.venue.code
    );

    if (mainVenueFeature) {
        const center = getCenterPoint(mainVenueFeature);
        return {lng: center[0], lat: center[1]};
    }

    const bbox = navMap.bbox;
    const bounds = new LngLatBounds([bbox[0], bbox[1]], [bbox[2], bbox[3]]);
    return bounds.getCenter();
};

export const transformDataForStore = ({
    clientId,
    digitalSignId,
    signConfig,
    bannerEvents,
    events,
    layoutConfigs,
    layouts,
    navMapWithLocations,
    noScreenSaver,
    scenes,
    settings,
    debug,
    fitWidth,
    layoutId,
    previewSignConfig,
    tenantFeatures,
    imdfCategories,
    tenantCategories,
    promotions,
    searchFiltersConfig,
    isExplorer,
    activeSceneId,
}) => {
    const mapConfig = {
        ...signConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE].mapConfig,
    };
    if (!isAngle(mapConfig?.bearing) && navMapWithLocations && layoutId === LAYOUT_IDS.KIOSK) {
        // bearing should be checked as an angle, otherwise 0 angle will go inside this condition
        // If mapConfig.bearing is not available, use the signs heads-up rotation in the navMap
        mapConfig.bearing = getHeadsUpSignRotation(
            navMapWithLocations.features.filter(f => f.properties?.featureId === digitalSignId)?.[0]
                ?.properties?.rotation
        );
    }

    // this is used in the transition tooltip from external to internal route.
    const rootVenueFeature = navMapWithLocations.features.find(
        ({properties}) =>
            properties?.ancestors?.length === 0 && // no ancestors
            // venues only
            properties?.featureType?.imdfFeatureType?.code === IMDF_FEATURE_TYPES.venue.code &&
            properties?.childrenIds?.length // must have children
    );

    const distanceRing =
        settings.distanceRing && settings.distanceRing.distances
            ? {
                  ...settings.distanceRing,
                  distances: transformDistanceRingDistancesForStore(
                      settings.distanceRing.distances
                  ),
              }
            : settings.distanceRing;

    const searchFilters = signConfig?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.search
        ?.searchFilters
        ? transformSearchFiltersForStore(
              searchFiltersConfig,
              signConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE].search.searchFilters
          )
        : [];

    const mapCenter = getMapCenterForBounds(navMapWithLocations);

    return {
        type: ACTION_TYPES.LOADED_LAYOUT_CONFIG,
        data: {
            config: {
                digitalSignId,
                signConfig: previewSignConfig || {
                    ...signConfig,
                    [CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]: {
                        ...signConfig[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE],
                        mapConfig,
                        search: {searchFilters},
                    },
                    [CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]: {
                        ...signConfig[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE],
                    },
                },
                bannerEvents,
                events,
                layoutConfigs,
                layouts,
                navMapWithLocations,
                scenes,
                settings: {
                    ...settings,
                    distanceRing,
                    searchFilters,
                    mapCenter,
                },
                tenantFeatures,
                tenantCategories,
                imdfCategories,
                promotions,
                activeSceneId,
            },
            noScreenSaver,
            debug,
            fitWidth,
            layoutId,
            rootVenueDisplayText: rootVenueFeature?.properties?.displayText || '',
            clientId,
            isExplorer,
        },
    };
};

export const saveLayoutDataToStore = ({
    clientId,
    isExplorer,
    activeSceneId,
    debug,
    defaultSceneId,
    digitalSignId,
    layoutIdentifier,
    bannerEvents = [],
    events = [],
    fitWidth,
    layouts,
    layoutConfigs,
    locations,
    pamOsEnabledFeatures,
    parkingLotConfigs,
    navMap,
    navMapOverrides,
    noScreenSaver,
    scenes,
    settings,
    searchFiltersConfig,
    tenants,
    promotions,
    imdfCategories,
    tenantCategories,
    messagingDictionary,
    isUpdate = false,
    previewSignConfig,
    useMocks,
}) => {
    const locationWithParkingDetailsAndEvents = mergeLocationsAndParkingStatusAndEvents({
        activeSceneId,
        defaultSceneId,
        locations,
        parkingLotConfigs,
        events,
    });
    let navMapWithLocationsEventsParkingInfo = {};
    let eventsWithMergedFeatures = [];
    let bannerEventsWithMergedFeatures = [];
    if (navMap && navMapOverrides) {
        navMapWithLocationsEventsParkingInfo = mixLocationsIntoNavMap({
            clientId,
            navMap: overrideNavMap({navMap, scenes, navMapOverrides}),
            locations: mixLocations(locationWithParkingDetailsAndEvents)([parseLocation]),
        });
        eventsWithMergedFeatures = mergeLocationAndFeaturesInEvents({
            events,
            navMapWithLocations: navMapWithLocationsEventsParkingInfo,
            sceneId: activeSceneId,
        });
        bannerEventsWithMergedFeatures = mergeLocationAndFeaturesInEvents({
            events: bannerEvents,
            navMapWithLocations: navMapWithLocationsEventsParkingInfo,
            sceneId: activeSceneId,
        });
        if (tenants) {
            navMapWithLocationsEventsParkingInfo = mixTenantsIntoNavMap({
                imdfCategories,
                navMap: navMapWithLocationsEventsParkingInfo,
                tenants,
            });
        }
    }

    const tenantFeatures = filterAndSortFeaturesWithTenants(
        navMapWithLocationsEventsParkingInfo.features
    );

    let promotionsWithTenants = [];
    if (!isExplorer || useMocks) {
        promotionsWithTenants = mixPromotionsWithTenants({promotions, tenantFeatures, tenants});
    }

    let signConfig;
    let layoutId;

    if (previewSignConfig) {
        signConfig = previewSignConfig;
        layoutId = previewSignConfig?.layoutId;
    } else {
        let unpopulatedConfig;
        // if navmapId was used on explorer, layoutID is kiosk layout UUID, else undefined
        if (layoutIdentifier) {
            unpopulatedConfig = getMergedLayoutConfig({
                activeSceneId,
                defaultSceneId,
                layoutId: layoutIdentifier,
                layouts,
                layoutConfigs,
                isExplorer,
            });
        } else {
            // this is when signId is used for explorer or any digital sign
            unpopulatedConfig = getMergedSignConfig({
                activeSceneId,
                defaultSceneId,
                digitalSignId,
                layouts,
                layoutConfigs,
                isExplorer,
            });
        }

        signConfig = populateContentDetails({
            config: unpopulatedConfig.config,
            locationFeatures: navMapWithLocationsEventsParkingInfo.features,
            messagingDictionary,
            events,
        });
        layoutId = unpopulatedConfig.layoutId;
    }
    const {
        pageState: {
            explorerMap: {navigation},
        },
    } = store.getState();

    // When there are no promotions close promotions cards
    if (!promotionsWithTenants?.length) {
        if (navigation.key === KIOSK_NAVIGATION.promotionsDetailsCard.id) {
            hideCard()(store.dispatch, store.getState);
        }
        if (navigation.key === KIOSK_NAVIGATION.promotions.id) {
            store.dispatch({
                type: ACTION_TYPES.NAVIGATE,
                data: {
                    key: KIOSK_NAVIGATION.explore.menuItemId,
                },
            });
        }
    }

    store.dispatch(
        transformDataForStore({
            clientId,
            digitalSignId,
            signConfig,
            bannerEvents: bannerEventsWithMergedFeatures,
            events: eventsWithMergedFeatures,
            layoutConfigs,
            layouts,
            navMapWithLocations: navMapWithLocationsEventsParkingInfo,
            noScreenSaver,
            scenes,
            settings,
            debug,
            fitWidth,
            layoutId,
            previewSignConfig,
            tenantFeatures,
            imdfCategories,
            tenantCategories,
            promotions: promotionsWithTenants,
            searchFiltersConfig,
            isExplorer,
            activeSceneId,
        })
    );

    if (isExplorer) {
        store.dispatch({
            type: ACTION_TYPES.EXPLORER.ADD_PROMOTIONS,
            data: {data: promotionsWithTenants},
        });
        updateStoreVariables();
    }

    // TODO: we dont need to load navmap to redux
    // right now there's some code to read navmapzoomlevel from this
    // redux state that needs to be cleaned up
    store.dispatch({type: ACTION_TYPES.LOADED_NAV_MAP, data: navMap});

    store.dispatch({
        type: ACTION_TYPES.LOADED_PAM_OS_ENABLED_FEATURES,
        data: pamOsEnabledFeatures,
    });

    if (isUpdate) {
        store.dispatch({type: ACTION_TYPES.SET_IS_MAP_UPDATE_AVAILABLE, data: true});
        analytics.sendDataUpdateEvent();
    }
    if (newPageLoad) {
        const url = new URL(window.location);
        const searchParams = new URLSearchParams(url.search);
        let hash = url.hash;
        const fromQr = searchParams.get('fromQr');
        const fromShare = searchParams.get('share');
        const scanLocation = searchParams.get('scanLocation');
        let type = false;
        let data = false;

        if (fromQr) {
            if (fromQr === analytics.QR_CODE_SOURCES.DIGITAL_SIGN.toString()) {
                type = 'FROM_QR: DIGITAL_SIGN';
            } else if (fromQr === analytics.QR_CODE_SOURCES.PAPER.toString()) {
                type = 'FROM_QR: PAPER';
            }
            data = {
                routeOption: searchParams.get('routeOption') || undefined,
                destinationType: searchParams.get('destinationType') || undefined,
                signId: searchParams.get('signId'),
                eventId: searchParams.get('eventId') || undefined,
            };
            searchParams.delete('fromQr');
        } else if (fromShare) {
            searchParams.delete('share');
            type = 'FROM_SHARE';
            data = {
                routeOption: searchParams.get('routeOption') || undefined,
                destinationType: searchParams.get('destinationType') || undefined,
                signId: searchParams.get('signId') || undefined,
                navMapId: searchParams.get('navmapId') || undefined,
                eventId: searchParams.get('eventId') || undefined,
                tenantId: searchParams.get('tenantId') || undefined,
            };
        }

        if (scanLocation) {
            const scanFeature = navMapWithLocationsEventsParkingInfo.features.find(
                f => f.properties.locationId === scanLocation
            );
            data = {
                ...data,
                signId: scanFeature?.id,
            };
            if (!hash) {
                hash = `#${navMapWithLocationsEventsParkingInfo.config.defaultNavMapZoom}/${
                    scanFeature.geometry.coordinates[1]
                }/${scanFeature.geometry.coordinates[0]}/${scanFeature.properties.rotation + 180}/${
                    navMapWithLocationsEventsParkingInfo.config.defaultNavMapPitch
                }`;
            }

            store.dispatch({
                type: ACTION_TYPES.SET_SCAN_LOCATION,
                data: scanFeature,
            });

            searchParams.delete('scanLocation');
        }

        window.history.replaceState(null, '', `?${searchParams}${hash}`);
        analytics.sendPageLoadEvent(type, data, layoutId);
        newPageLoad = false;
    }
};

const loadPreviewData = () =>
    new Promise(resolve => {
        window.addEventListener('message', event => {
            if (event.data.type === PAM_OS_MESSAGE_TYPES.DSM_PREVIEW) {
                setMapboxStyles({mapboxStyles: event.data.data.mapboxStyles, type: 'MAP'})(
                    store.dispatch
                );
                saveLayoutDataToStore({
                    ...event.data.data,
                    isUpdate: false,
                    navMap: JSON.parse(event.data.data.navMap),
                    events: JSON.parse(event.data.data.events),
                    bannerEvents: JSON.parse(event.data.data.bannerEvents),
                });
                resolve();
            }
        });
    });

const loadLayout = ({url, isExplorer}) => async dispatch => {
    const isPreview = url.searchParams.get(DEVICE_TYPES.PREVIEW.toLowerCase()) === 'y';
    const digitalSignId = url.searchParams.get('signId') || window.selectedSignId;
    const useMocks = store.getState().device.useMocks || url.searchParams.get('useMocks') === 'y';
    let navmapId = window.__navMapId || url.searchParams.get('navmapId');

    if (isPreview) {
        if (!window.previewListner) {
            window.previewListner = true;
            await loadPreviewData();
        }
    } else {
        const debug = storage.getIsDebug();
        const noScreenSaver = url.searchParams.get('noScreenSaver') !== null;
        const fitWidth = url.searchParams.has('fitWidth');

        if (useMocks) {
            const urls = [
                `/mocks/${MOCK_CLIENT_NAME}/events.json`,
                `/mocks/${MOCK_CLIENT_NAME}/imdfCategories.json`,
                `/mocks/${MOCK_CLIENT_NAME}/layoutConfigs.json`,
                `/mocks/${MOCK_CLIENT_NAME}/layouts.json`,
                `/mocks/${MOCK_CLIENT_NAME}/locations.json`,
                `/mocks/${MOCK_CLIENT_NAME}/locationsOtherClient.json`,
                `/mocks/${MOCK_CLIENT_NAME}/messagingDictionary.json`,
                `/mocks/${MOCK_CLIENT_NAME}/navMap.json`,
                `/mocks/${MOCK_CLIENT_NAME}/navMapOtherClient.json`,
                `/mocks/${MOCK_CLIENT_NAME}/navMapOverrides.json`,
                `/mocks/${MOCK_CLIENT_NAME}/pamOsEnabledFeatures.json`,
                `/mocks/${MOCK_CLIENT_NAME}/parkingLotConfigs.json`,
                `/mocks/${MOCK_CLIENT_NAME}/scenes.json`,
                `/mocks/${MOCK_CLIENT_NAME}/settings.json`,
                `/mocks/${MOCK_CLIENT_NAME}/tenants.json`,
                `/mocks/${MOCK_CLIENT_NAME}/tenantCategories.json`,
                `/mocks/${MOCK_CLIENT_NAME}/messagingDictionaryOtherClient.json`,
                `/mocks/${MOCK_CLIENT_NAME}/promotions.json`,
                `/mocks/${MOCK_CLIENT_NAME}/searchFilters.json`,
            ];
            const {responses, etags} = await fetchMocks(urls, layoutDataEtag);

            layoutDataEtag = etags;

            if (responses) {
                // For mocks, we do what the backend does:
                // mix together the sign config and the layout config
                const [
                    events,
                    imdfCategories,
                    layoutConfigs,
                    layouts,
                    locations,
                    locationsOtherClient,
                    messagingDictionary,
                    navMap,
                    navMapOtherClient,
                    navMapOverrides,
                    pamOsEnabledFeatures,
                    parkingLotConfigs,
                    scenes,
                    settings,
                    tenants,
                    tenantCategories,
                    messagingDictionaryOtherClient,
                    promotions,
                    searchFiltersConfig,
                ] = [
                    responses[0].body,
                    responses[1].body,
                    responses[2].body,
                    responses[3].body,
                    responses[4].body,
                    responses[5].body,
                    responses[6].body,
                    responses[7].body,
                    responses[8].body,
                    responses[9].body,
                    responses[10].body,
                    responses[11].body,
                    responses[12].body,
                    responses[13].body,
                    responses[14].body,
                    responses[15].body,
                    responses[16].body,
                    responses[17].body,
                    responses[18].body,
                ];

                const sceneId =
                    url.searchParams.get('sceneId') || scenes.find(({isDefault}) => isDefault).id;

                const activeSceneId = url.searchParams.get('activeSceneId') || sceneId;
                const defaultSceneId = url.searchParams.get('defaultSceneId') || sceneId;

                log('%c ⚠️ You are viewing mock data ⚠️', 'background: #222; color: yellow');

                await setMapboxStyles({clientId: MOCK_CLIENT_ID, type: 'MAP'})(store.dispatch);

                saveLayoutDataToStore({
                    clientId: MOCK_CLIENT_ID,
                    isExplorer,
                    activeSceneId,
                    debug,
                    defaultSceneId,
                    layoutIdentifier: navmapId ? LAYOUT_IDS.KIOSK : undefined,
                    digitalSignId,
                    events,
                    bannerEvents: events,
                    fitWidth,
                    layouts,
                    layoutConfigs,
                    locations: locations.concat(locationsOtherClient),
                    navMap: {
                        ...navMap,
                        features: navMap.features.concat(navMapOtherClient.features),
                    },
                    navMapOverrides,
                    noScreenSaver,
                    pamOsEnabledFeatures,
                    parkingLotConfigs,
                    scenes,
                    settings,
                    searchFiltersConfig,
                    tenants,
                    tenantCategories,
                    imdfCategories,
                    promotions,
                    messagingDictionary: messagingDictionary.concat(messagingDictionaryOtherClient),
                    url,
                    isUpdate: false,
                    useMocks,
                });
            }
        } else {
            let response;
            if (isExplorer) {
                if (!digitalSignId && !navmapId) {
                    // show error page
                    log.error('please provide valid signId or NavMapId');
                    return;
                }
                if (!navmapId && digitalSignId) {
                    // get navmapId first
                    response = await fetchNavMapIdBySignId({
                        digitalSignId,
                    });
                    navmapId = response?.data['0']?.id;
                }
                if (
                    !window.__INITIAL_STATE__?.locations ||
                    window.__INITIAL_STATE__ === 'API_ERROR'
                ) {
                    response = await fetchLayoutDataByNavmapId({
                        navmapId,
                        layoutId: LAYOUT_IDS.KIOSK,
                        oldLayoutDataEtag: layoutDataEtag,
                    });
                } else {
                    response = window.__INITIAL_STATE__;
                }
            } else {
                response = await fetchDigitalSignData({
                    digitalSignId,
                    oldDigitalSignDataEtag: digitalSignDataEtag,
                    oldCommonSignDataEtag: commonSignDataEtag,
                });
            }
            if (response && !response.error && !response.data?.error) {
                // When cache is refreshed, fetch the promotions
                let promotions;
                let newPromotionsEtag = null;
                if (!isExplorer) {
                    const {data, promoEtag} = await fetchPromotions(
                        response.data.settings.clientId
                    );
                    promotions = data;
                    newPromotionsEtag = promoEtag;
                }

                const isContentUpdated =
                    (isExplorer &&
                        (layoutDataEtag === null || layoutDataEtag !== response.layoutDataEtag)) ||
                    (!isExplorer &&
                        (digitalSignDataEtag === null ||
                            digitalSignDataEtag !== response.digitalSignDataEtag ||
                            commonSignDataEtag === null ||
                            commonSignDataEtag !== response.commonSignDataEtag ||
                            promotionsEtag === null ||
                            promotionsEtag !== newPromotionsEtag));

                if (isContentUpdated) {
                    const {
                        bannerEvents,
                        events,
                        imdfCategories,
                        layouts,
                        layoutConfigs,
                        locations,
                        navMap,
                        navMapOverrides,
                        pamOsEnabledFeatures,
                        parkingLotConfigs,
                        scenes,
                        settings,
                        tenants,
                        messagingDictionary,
                        searchFiltersConfig,
                        clientId,
                        tenantCategories,
                    } = response.data;

                    const activeScene = scenes.find(s => s.isActive);
                    const defaultScene = scenes.find(s => s.isDefault);

                    log(
                        '%c ✅ You are viewing live data ✅',
                        'background: #222; color: greenyellow',
                        JSON.stringify({
                            activeScene: {
                                name: activeScene.name,
                                id: activeScene.id,
                            },
                            defaultScene: {
                                name: defaultScene.name,
                                id: defaultScene.id,
                            },
                        })
                    );

                    await setMapboxStyles({clientId, type: 'MAP'})(store.dispatch);

                    const isUpdate =
                        !layoutDataEtag &&
                        !digitalSignDataEtag &&
                        !commonSignDataEtag &&
                        !promotionsEtag
                            ? false
                            : isContentUpdated;

                    saveLayoutDataToStore({
                        clientId,
                        isExplorer,
                        activeSceneId: activeScene.id,
                        debug,
                        defaultSceneId: defaultScene.id,
                        layoutIdentifier: navmapId ? LAYOUT_IDS.KIOSK : undefined,
                        digitalSignId,
                        events,
                        bannerEvents,
                        fitWidth,
                        layouts,
                        layoutConfigs,
                        locations,
                        pamOsEnabledFeatures,
                        parkingLotConfigs,
                        navMap,
                        navMapOverrides,
                        noScreenSaver,
                        scenes,
                        settings,
                        tenants,
                        imdfCategories,
                        messagingDictionary,
                        searchFiltersConfig,
                        isUpdate,
                        tenantCategories,
                        promotions,
                        useMocks,
                    });

                    if (isExplorer) {
                        layoutDataEtag = response.layoutDataEtag;
                    } else {
                        digitalSignDataEtag = response.digitalSignDataEtag;
                        commonSignDataEtag = response.commonSignDataEtag;
                        promotionsEtag = newPromotionsEtag;
                    }
                }
            } else if (!lodashIsNull(response) && response?.error) {
                log.error(JSON.stringify(response?.error));
            }
        }

        if (!useMocks) {
            setTimeout(() => {
                loadLayout({url, isExplorer})(dispatch);
            }, POLL_TIMEOUT);
        }
    }
};

export default loadLayout;
