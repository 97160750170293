import {omit} from 'lodash/fp';
import {LOCATION_TYPES} from 'Utils/constants';

const parseLocation = location => ({
    // We don't need the featureType and category
    ...omit(['featureType', 'category'], location),
    ancestors: location.ancestors,
    id: String(location.id),
    locationType: LOCATION_TYPES.PAM,
    object3dUrl: location.object3dUrl,
    searchText: location.searchText || location.displayText,
});

export default parseLocation;
