export const EXPERIENCES = {
    ORIENTATION: {PORTRAIT: 'Portrait', LANDSCAPE: 'Landscape'},
    DEFAULT_SPEED_IN_SECONDS: 10,
};

export const EXPERIENCES_PREVIEW_DIMENSIONS = {
    Portrait: {
        width: 1080,
    },
    Landscape: {
        width: 1920,
    },
};
