import {
    ACTION_TYPES,
    CONFIG_DATA_BASE_KEYS,
    MARKER_LAYERS,
    NAV_MAP_DEFAULT_CONFIG,
    ROUTE_OPTIONS,
} from 'Utils/constants';
import * as turf from '@turf/turf';
import * as turfHelpers from '@turf/helpers';
import * as turfMeta from '@turf/meta';
import * as mapManager from 'Utils/explorer/mapManager';
import store from 'Data/store';
import {isAngle} from 'Utils/math';
import turfBooleanContains from '@turf/boolean-contains/index';
import turfBboxPolygon from '@turf/bbox-polygon/index';
import * as routingUtils from 'Utils/routingUtils';
import {isThereExternalLocations} from 'Utils/routingUtils';
import * as mapUtils from 'Utils/explorer/mapUtils';
import isPlainObject from 'Utils/isPlainObject';
import destinationMarker from 'Utils/destinationMarker';
import startMarker from 'Utils/startMarker';
import {getHeadsUpSignRotation} from 'Utils/data/dsmDataUtils';
import selectRouteOption from 'Data/actionCreators/kiosk/selectRouteOption';
import setIsSearchingRoute from 'Data/actionCreators/journey/setIsSearchingRoute';
import * as analytics from 'Utils/analytics';
import {getFeaturesInGroup} from 'Utils/routeManager';
import createDestinationMarker from 'Utils/explorer/createDestinationMarker';
import createStartMarker from 'Utils/explorer/createStartMarker';

export const updateRoutes = async ({zoomToResult, dispatch, state, bearing = null}) => {
    mapManager.clearRoutes();

    let _bearing = bearing;
    if (
        typeof state.pageState?.layout?.isExplorer !== 'undefined' &&
        !state.pageState?.layout?.isExplorer
    ) {
        const _mapConfigBearing =
            state.pageState.layout?.config?.signConfig?.withInheritance?.mapConfig?.bearing;
        const mapConfigBearing = isAngle(_mapConfigBearing) ? _mapConfigBearing : null;
        _bearing = isAngle(bearing) ? bearing : mapConfigBearing;
    }

    if (state.pageState?.layout?.isExplorer) {
        _bearing =
            state.pageState.layout?.config?.navMapWithLocations.config.defaultRotation ??
            NAV_MAP_DEFAULT_CONFIG.defaultRotation;
    }

    const {
        pageState: {
            explorerMap: {
                journey: {stops},
                routeParameters: {travelMode, wheelchairOnly, safeOnly},
            },
            layout: {rootVenueDisplayText},
        },
    } = state;

    const layoutConfig = state.pageState.layout.config;
    const externalLocations =
        layoutConfig?.signConfig?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.externalLocations;
    const externalDirectionsEnabled = externalLocations?.enabled;
    const externalPedestrianAndCyclingDirectionsDisabled =
        externalLocations?.enabled && externalLocations?.disablePedestrianAndCyclingDirections;
    const mapCenter = layoutConfig?.settings?.mapCenter;
    const units = layoutConfig?.settings?.units;

    const mapBoundsFeature =
        mapCenter &&
        turfBboxPolygon(
            mapUtils.getBboxByRadiusFromPoint(
                turfHelpers.point([mapCenter.lng, mapCenter.lat]),
                layoutConfig.navMapWithLocations?.config?.mapBoundaryRadius ??
                    NAV_MAP_DEFAULT_CONFIG.mapBoundaryRadius,
                layoutConfig.settings.units
            )
        );

    if (
        mapBoundsFeature &&
        ((stops[0] && !turfBooleanContains(mapBoundsFeature, stops[0])) ||
            (stops[1] && !turfBooleanContains(mapBoundsFeature, stops[1])))
    ) {
        if (externalLocations && units) {
            // check if any stops (due to mylocation outside search area)
            // are out of map's bounds. if yes, remove that stop & end the process
            const updatedStops = stops;
            stops.forEach((stop, index) => {
                if (stop && turfBooleanContains(mapBoundsFeature, stop)) {
                    mapManager.setFeatureState({
                        feature: stop,
                        selected: true,
                        isStartLocation: index === 0,
                    });
                    mapManager.addPointsToMap({
                        points: [stop.properties.displayPoint || stop],
                        markerLayer: MARKER_LAYERS.SEARCH_LOCATIONS,
                        zoomToResult: false,
                        customMarker: index === 0 ? startMarker : destinationMarker,
                    });
                } else if (stop && !turfBooleanContains(mapBoundsFeature, stop)) {
                    updatedStops[index] = null;
                    dispatch({
                        type: ACTION_TYPES.SET_JOURNEY_STOPS,
                        data: updatedStops,
                    });
                }
            });

            return;
        }
    }

    if (state.device.deviceType === 'CONNECT') {
        // kiosk
        if (!stops[0] || !stops[1]) {
            return;
        }
        mapManager.setFeatureState({feature: stops[1], selected: true, isStartLocation: false});
        mapManager.addPointsToMap({
            points: [stops[0], stops[1].properties.displayPoint || stops[1]],
            markerLayer: MARKER_LAYERS.SEARCH_LOCATIONS,
            zoomToResult: false,
            customMarker: destinationMarker,
        });
    } else {
        // explorer
        // for explorer, bearing is set by `take me there` click handler
        if (!stops[0] && !stops[1]) {
            return;
        }

        let _zoomToResult = zoomToResult;

        if (
            mapBoundsFeature &&
            ((stops[0] && !turfBooleanContains(mapBoundsFeature, stops[0])) ||
                (stops[1] && !turfBooleanContains(mapBoundsFeature, stops[1])))
        ) {
            _zoomToResult = false;
        }

        stops.forEach((stop, index) => {
            if (stop) {
                mapManager.setFeatureState({
                    feature: stop,
                    selected: true,
                    isStartLocation: index === 0,
                });
            }
        });
        stops.forEach((stop, index) => {
            if (stop) {
                let pointsToAdd;
                if (stop.properties?.routeViaGroupId) {
                    const featuresInGroup = getFeaturesInGroup(stop.properties.routeViaGroupId);
                    pointsToAdd = featuresInGroup
                        ? featuresInGroup.map(
                              featureInGroup =>
                                  featureInGroup.properties.displayPoint || featureInGroup
                          )
                        : [];
                } else {
                    pointsToAdd = [stop.properties.displayPoint || stop];
                }

                mapManager.addPointsToMap({
                    points: pointsToAdd,
                    markerLayer: MARKER_LAYERS.SEARCH_LOCATIONS,
                    zoomToResult: _zoomToResult,
                    customMarker:
                        index === 0
                            ? createStartMarker()
                            : createDestinationMarker({showLabel: true}),
                    maxZoom: 18,
                });
            }
        });
    }

    if (!stops[0] || !stops[1]) {
        return;
    }

    setIsSearchingRoute(true)(dispatch);

    const areStopsRoutedViaTheSameGroup =
        stops[0].properties.routeViaGroupId &&
        stops[1].properties.routeViaGroupId &&
        stops[0].properties.routeViaGroupId === stops[1].properties.routeViaGroupId;
    let routeResponse;

    if (!areStopsRoutedViaTheSameGroup) {
        routeResponse = await routingUtils.getRoute({
            start: stops[0],
            end: stops[1],
            travelMode,
            wheelchairOnly,
            safeOnly,
            rootVenueDisplayText,
            externalPedestrianAndCyclingDirectionsDisabled,
            externalDirectionsEnabled,
        });

        const _routeParts = [];

        if (safeOnly) {
            // A route contains multiple parts (eg: mapbox & pam)
            // Only pick the part that's marked to be split
            let hasNightSafeSegment = false;
            routeResponse?.routeParts.forEach(routePart => {
                if (routePart?.properties?.splitRouteToDraw) {
                    hasNightSafeSegment = routePart?.properties?.segmentsNightSafeList.find(
                        Boolean
                    );
                    turfMeta.segmentEach(
                        routePart,
                        (
                            currentSegment,
                            featureIndex,
                            multiFeatureIndex,
                            geometryIndex,
                            segmentIndex
                        ) => {
                            const segment = turfHelpers.lineString(
                                currentSegment.geometry.coordinates
                            );
                            segment.properties = {...routePart.properties};
                            segment.properties.isNightSafe =
                                routePart.properties.segmentsNightSafeList[segmentIndex];
                            _routeParts.push(segment);
                        }
                    );
                } else _routeParts.push(routePart);
            });

            dispatch({
                type: ACTION_TYPES.SET_NIGHT_SAFE_PATH_SEARCH,
                data: {nightSafePathSearch: true},
            });

            // if we asked for night safe & there is no
            // night safe segment, bail & show error
            if (!hasNightSafeSegment) {
                dispatch({
                    type: ACTION_TYPES.SET_PATH_FOUND,
                    data: {pathFound: false},
                });

                setIsSearchingRoute(false)(dispatch);
                return;
            }

            if (routeResponse?.routeParts) routeResponse.routeParts = _routeParts;
        } else {
            dispatch({
                type: ACTION_TYPES.SET_NIGHT_SAFE_PATH_SEARCH,
                data: {nightSafePathSearch: false},
            });
        }
    }

    // Render the routes to the page
    if (routeResponse) {
        mapManager.getMap().resize();

        mapManager.removeMarkers(MARKER_LAYERS.SEARCH_LOCATIONS);

        mapManager.addPointsToMap({
            points: [
                routeResponse.startLocationUsed || stops[0].properties.displayPoint || stops[0],
            ],
            markerLayer: MARKER_LAYERS.SEARCH_LOCATIONS,
            zoomToResult: false,
            customMarker: createStartMarker(),
        });

        mapManager.addPointsToMap({
            points: [routeResponse.endLocationUsed || stops[1].properties.displayPoint || stops[1]],
            markerLayer: MARKER_LAYERS.SEARCH_LOCATIONS,
            zoomToResult: false,
            customMarker: createDestinationMarker({showLabel: true}),
            markerFeature: stops[1],
        });

        mapManager.renderRoute(routeResponse.routeParts);

        const routeLineSegmentCoordinates = [];

        routeResponse.routeParts.filter(f => !f?.properties?.isThreshold).forEach(f => {
            routeLineSegmentCoordinates.push(...f.geometry.coordinates);
        });
        const routeLineSegmentFeature = turf.feature(
            {
                type: 'LineString',
                coordinates: routeLineSegmentCoordinates,
            },
            {},
            {id: 'routeString'}
        );

        dispatch({
            type: ACTION_TYPES.SET_TRAVEL_TIME,
            data: routeResponse.travelTimeMins,
        });

        dispatch({
            type: ACTION_TYPES.SET_DISTANCE,
            data: routeResponse.distance,
        });

        dispatch({
            type: ACTION_TYPES.SET_ROUTE_LINESTRING,
            data: routeLineSegmentFeature,
        });

        dispatch({
            type: ACTION_TYPES.SET_PATH_FOUND,
            data: {pathFound: true},
        });

        mapManager.fitToGeoJson(
            stops,
            {
                bottomPadding: 50,
                leftPadding: 50,
                rightPadding: 50,
                topPadding: 50,
                substituteRouteViaGroupFeatures: true,
                ...(isPlainObject(zoomToResult) ? zoomToResult : undefined),
            },
            {
                pitch: 0,
                bearing: _bearing,
            }
        );
    } else if (areStopsRoutedViaTheSameGroup) {
        mapManager.getMap().resize();
        mapManager.removeMarkers(MARKER_LAYERS.SEARCH_LOCATIONS);

        const featuresInGroup = getFeaturesInGroup(stops[1].properties.routeViaGroupId);
        const pointsToAdd = featuresInGroup
            ? featuresInGroup.map(
                  featureInGroup => featureInGroup.properties.displayPoint || featureInGroup
              )
            : [];

        mapManager.addPointsToMap({
            points: pointsToAdd,
            markerLayer: MARKER_LAYERS.SEARCH_LOCATIONS,
            zoomToResult: false,
            customMarker: createDestinationMarker({showLabel: true}),
        });

        dispatch({
            type: ACTION_TYPES.SET_PATH_FOUND,
            data: {pathFound: true},
        });
    } else {
        dispatch({
            type: ACTION_TYPES.SET_PATH_FOUND,
            data: {pathFound: false},
        });
    }

    const selectedRouteOption = store.getState().pageState.explorerMap.selectedRouteOption;

    analytics.sendPamFeatureTrackingEvent(
        analytics.ACTIONS.TOUCH,
        `ROUTE OPTION: ${selectedRouteOption} | EXISTS: ${!!routeResponse} | START NAME: ${
            stops[0]?.properties?.displayName
        } | START ID: ${stops[0]?.properties?.tenantId} || ${stops[0]?.properties?.locationId}`,
        '',
        stops[1]
    );

    setIsSearchingRoute(false)(dispatch);
};

// Add, remove or change a stop
// Note: this implementation assumes an array of stops, to allow for future 'journey planner'
// functionality. But the current implementation only allows two stops, a 'to' and 'from'
const setJourneyStop = ({
    index,
    feature,
    reset = false,
    zoomToResult = true,
    useDefaultRouteOption = false,
    updateRoute = true,
}) => (dispatch, getState) => {
    const state = getState();
    const prevStops = state.pageState.explorerMap.journey.stops;

    mapManager.removeMarkers(MARKER_LAYERS.SEARCH_LOCATIONS);

    if (state.pageState.explorerMap.searchLocation) {
        mapManager.setFeatureState({
            feature: state.pageState.explorerMap.searchLocation,
            selected: false,
        });
    }

    // Reset routes, highlighting and markers on each change
    prevStops.forEach(stop => {
        mapManager.setFeatureState({
            feature: stop,
            selected: false,
        });
    });

    if (reset) {
        dispatch({type: ACTION_TYPES.RESET_JOURNEY});

        updateRoutes({dispatch, state: getState(), zoomToResult});

        return;
    }

    let nextStops = prevStops.slice();

    // Edit the state with the requested change
    nextStops[index] = feature;
    const bearing =
        nextStops[0] && nextStops[0].properties
            ? getHeadsUpSignRotation(nextStops[0].properties.rotation)
            : null;

    // We don't want a situation where one stop is changed but another stop already has visited=true
    nextStops = nextStops.map(stop => {
        if (stop === null || 'visited' in stop) return stop;
        return {
            ...stop,
            visited: false,
        };
    });

    dispatch({
        type: ACTION_TYPES.SET_JOURNEY_STOPS,
        data: nextStops,
    });

    if (useDefaultRouteOption) {
        // set default route option
        // selectRouteOption will update the route
        selectRouteOption(
            isThereExternalLocations(nextStops) ? ROUTE_OPTIONS.DRIVE : ROUTE_OPTIONS.WALK
        )(dispatch, getState);
    } else if (updateRoute) {
        updateRoutes({dispatch, state: getState(), zoomToResult, bearing});
    }
};

export default setJourneyStop;
