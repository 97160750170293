import pickFp from 'lodash/fp/pick';
import sort from 'alphanum-sort';
import {PARKING_STATUSES} from 'Components/layouts/kiosk/kioskLayoutConstants';
import {SIGN_TYPES, SIGNS_CONCAT_CHARS} from 'Components/layouts/hpkDds/ddsLayoutConstants';
import {CONFIG_DATA_BASE_KEYS, SLOT_ITEM} from 'Utils/constants';
import log from 'Utils/log';
import getOtherSlotConfigurations from 'Utils/layout/getOtherSlotConfigurations';
import {
    cloneDeep as lodashCloneDeep,
    get as lodashGet,
    set as lodashSet,
    unset as lodashUnset,
    isArray as lodashIsArray,
} from 'lodash';
import {KIOSK_LAYOUT_GENERAL_TAB_PROP_PATHS} from 'Components/layouts/kiosk/KioskConfigPanel/ConfigComponentsConstants';
import {dsmFeatureParentsIterator} from './featureParentsIterator';

export const getDefaultScene = ({scenes}) => scenes.find(({isDefault}) => isDefault);
/**
 * Make the array of objects in to an object
 * Input: [{id: 1, name: 'Albert'}, {id: 2, name: 'Einstein'}]
 * Output: {"1": {id: 1, name: 'Albert'}, "2": {id: 2, name: 'Einstein'}}
 *
 * For an easy object search without looping
 * o['1'] will return {id: 1, name: 'Albert'}
 *
 * @param array - an array of objects
 * @param key - the key in the object which is going to be the key in the new object
 * @returns {*}
 */
export const convertArrayToObject = (array, key = 'id') => {
    const initialValue = {};
    return array.reduce(
        (obj, item) => ({
            ...obj,
            [item[key]]: item,
        }),
        initialValue
    );
};

/**
 * Transform a list of numbers/strings into a list of ranges of consecutive numbers/string
 *
 * @param rawArrayOfCharacters
 * @param concatChar
 * @returns {string[]|*}
 */
// eslint-disable-next-line no-unused-vars
const convertToRanges = (rawArrayOfCharacters, concatChar) => {
    let numberCount = 0;
    let stringCount = 0;
    const arrayOfCharacters = rawArrayOfCharacters.map(c => {
        if (c.length === 1) {
            // isNan is a way to check for numbers in string,
            // !Number.isNaN(Number('10')) will return true
            if (!Number.isNaN(Number(c))) {
                numberCount++;
                return parseInt(c, 10);
            }
            if (typeof c === 'string') {
                stringCount++;
            }
        } else if (c.length > 1) {
            const castedNumber = parseInt(c, 10).toString(); // Casting it to int then converting it back to string

            // Only numbers are allowed to be more than 1 char, because 10, 100, 1000
            // We need additional to check to the casted number' equality to the original number,
            // because in some cases, !Number.isNaN(Number('5E-4') will return true.
            // That is because in javascript 'E-' means adding decimal places 5E-4 is actually 0.0005,
            // exponent (7.3e9) is another case.
            if (castedNumber === c && !Number.isNaN(Number(c))) {
                numberCount++;
                return parseInt(c, 10);
            }
        }

        return c;
    });

    const allNumbers = numberCount === rawArrayOfCharacters.length;
    const allStrings = stringCount === rawArrayOfCharacters.length;

    if (!allNumbers && !allStrings) {
        // we can only process the conversion if the elements are of the same type
        return arrayOfCharacters;
    }

    let str = arrayOfCharacters;
    if (allStrings) {
        // convert the characters into unicode number
        str = arrayOfCharacters.map(c => c.charCodeAt());
    }

    // split the string at the commas and map it to an array of ints
    let pieces = str.map(Number);
    if (allNumbers) {
        // The numbers must be in ascending order
        pieces = str.sort((a, b) => a - b);
    }
    // ranges will be an array of arrays
    // each inner array will have 2 dimensions, representing the start/end
    // of a range
    // we want to initialize our first range to pieces[0], pieces[0],
    // or (only the first element)
    const ranges = [[pieces[0], pieces[0]]];
    // last index we accessed (so we know which range to update)
    let lastIndex = 0;

    for (let i = 1; i < pieces.length; i++) {
        // if the current element is 1 away from the end of whichever range
        // we're currently in
        if (pieces[i] - ranges[lastIndex][1] === 1) {
            // update the end of that range to be this number
            ranges[lastIndex][1] = pieces[i];
        } else {
            // otherwise, add a new range to ranges
            ranges[++lastIndex] = [pieces[i], pieces[i]];
        }
    }

    let concatenator;
    switch (concatChar) {
        case SIGNS_CONCAT_CHARS.TO:
            concatenator = ' to ';
            break;
        case SIGNS_CONCAT_CHARS.DASH:
        default:
            concatenator = '-';
            break;
    }

    let convertedRanges = ranges.map(number => {
        if (number[0] === number[1]) {
            // If start and end range are the same, no need to put a dash
            return number[0];
        }

        return number.join(concatenator);
    });

    if (allStrings) {
        // Convert back the number to string
        convertedRanges = ranges.map(number => {
            if (number[0] === number[1]) {
                // If start and end range are the same, no need to put a dash
                return String.fromCharCode(number[0]);
            }

            return number.map(n => String.fromCharCode(n)).join(concatenator);
        });
    }

    // join the array of converted ranges with comma
    // groupText is expected to be an array, return it with one element
    return [convertedRanges.join(', ')];
};

/**
 * In config, Try to find the location id in the list of locations and get more details from it
 *
 * @param config - sign or layout configuration
 * @param locationFeatures - navmap features with locations, events and parking info
 * @param messagingDictionary
 * @param events
 * @param parkingLotConfig
 * @returns {{slots: *}|null|*}
 */
export const populateContentDetails = ({
    config,
    locationFeatures,
    messagingDictionary = [],
    events = [],
    parkingLotConfig = [],
}) => {
    // Checks emptiness of the config, return null if it is
    // This prevents unintentional inheritance override, config is just null or a JSON object
    if (
        !config ||
        (Array.isArray(config) && !config.length) ||
        (Array.isArray(config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]) &&
            !config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.length)
    ) {
        return null;
    }

    function getLocationDetails(location, isHeaderLocation = false) {
        // we're simply matching the id from the object key
        const locationFeature = locationFeatures.find(feature => {
            if (location?.id && location?.clientId) {
                return (
                    feature.properties.locationId === location?.id &&
                    feature.clientId === location?.clientId
                );
            }
            return feature.properties.locationId === location?.id;
        });

        if (locationFeature) {
            const {
                properties: {
                    locationId: id,
                    displayText,
                    searchText,
                    disabled,
                    copyChangeDisplayText,
                    backgroundColor,
                    textColor,
                    iconUrl,
                    clientId,
                },
                geometry,
            } = locationFeature;

            const eventNameOnFeature =
                !!locationFeature.properties.events?.length &&
                locationFeature.properties.events[0].name.toUpperCase();

            const _displayText = isHeaderLocation ? displayText : eventNameOnFeature || displayText; // Use the event name if it's available
            const locationEvents = events.filter(
                ({locationId}) => locationId?.toString() === id?.toString()
            );

            const ancestorIds = locationFeature?.properties.ancestors?.map(item => item.id) || [];
            const ancestorFeatures = locationFeatures.filter(
                feature => ancestorIds.indexOf(parseInt(feature.properties.locationId, 10)) >= 0
            );

            return {
                id: id ? id.toString() : id,
                type: SLOT_ITEM.LOCATION,
                backgroundColor,
                copyChangeDisplayText,
                clientId,
                disabled:
                    disabled ||
                    !!ancestorFeatures.find(feature => feature.properties.disableDescendants),
                displayText: _displayText,
                geometry,
                iconUrl,
                searchText: searchText || displayText,
                textColor,
                parkingLotConfig: locationFeature.properties?.parkingLotConfig,
                parkingLotConfigs:
                    locationFeature.properties?.parkingLotConfig ??
                    parkingLotConfig.filter(({locationId}) => locationId.toString() === id),
                ...(locationEvents.length > 0 ? {events: locationEvents} : {}),
            };
        }
        return null;
    }

    function getLocationsDetails(locations, isHeaderLocation = false) {
        const detailedLocations = [];
        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (const i in locations) {
            const detailedLocation = getLocationDetails(locations[i], isHeaderLocation);
            if (detailedLocation) {
                detailedLocations.push(detailedLocation);
            }
        }

        return detailedLocations;
    }

    function getMessageDetails(slotItem) {
        const dictionaryItem = messagingDictionary.find(message => {
            if (slotItem?.id && slotItem?.clientId) {
                return message.id === slotItem?.id && message.clientId === slotItem.clientId;
            }
            return message.id === slotItem?.id;
        });
        if (dictionaryItem) {
            dictionaryItem.type = SLOT_ITEM.MESSAGE;
        }
        return dictionaryItem;
    }

    function getContentDetailsForSlot(slotConfig) {
        const slotConfigWithContent = {};
        if (slotConfig?.locationArray) {
            slotConfigWithContent.locationArray = slotConfig?.locationArray
                .map(slotItem => {
                    if (slotItem.type === SLOT_ITEM.LOCATION) {
                        return getLocationDetails(slotItem);
                    }
                    return getMessageDetails(slotItem);
                })
                .filter(slotItem => slotItem); // remove null values
        }
        return {...slotConfigWithContent, ...getOtherSlotConfigurations(slotConfig)};
    }

    function getContentDetailsForSlots(slots = {}) {
        const configWithContent = {};
        // Loop thru all the slots
        for (const [slotName, slotConfig] of Object.entries(slots)) {
            configWithContent[slotName] = {
                ...slotConfig,
                ...getContentDetailsForSlot(slotConfig),
            };
        }
        return configWithContent;
    }

    let _config = config;
    if (config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]?.parkingsLocationArray) {
        const withInheritance = config?.[CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE];
        // This is top-level locationArray for parking config
        _config = {
            ...config,
            [CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]: {
                ...withInheritance,
                parkingsLocationArray: [
                    ...getLocationsDetails(withInheritance?.parkingsLocationArray),
                ],
            },
        };
    }

    if (config?.[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]?.header) {
        let withoutInheritance = config?.[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE];
        if (withoutInheritance.header?.directions) {
            withoutInheritance = {
                ...withoutInheritance,
                header: {
                    ...withoutInheritance?.header,
                    directions: getContentDetailsForSlots(withoutInheritance?.header?.directions),
                },
            };
        }

        if (withoutInheritance.header?.location) {
            if (withoutInheritance?.header?.location?.locationArray) {
                withoutInheritance = {
                    ...withoutInheritance,
                    header: {
                        ...withoutInheritance?.header,
                        location: getLocationsDetails(
                            withoutInheritance?.header?.location?.locationArray,
                            true
                        ),
                    },
                };
            }
        }

        return {
            ...config,
            withoutInheritance,
        };
    }

    if (_config?.[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]) {
        let withoutInheritance = _config?.[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE];

        if (withoutInheritance?.slots) {
            withoutInheritance = {
                ...withoutInheritance,
                slots: getContentDetailsForSlots(
                    _config?.[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]?.slots
                ),
            };
        }
        if (withoutInheritance?.titleSlot) {
            withoutInheritance = {
                ...withoutInheritance,
                titleSlot: getContentDetailsForSlot(
                    _config?.[CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]?.titleSlot
                ),
            };
        }

        return {
            ..._config,
            withoutInheritance,
        };
    }

    return _config;
};

/**
 * Get the word frequency
 *
 * @param displayTexts
 * @returns {*}
 */
function firstWordCounters(displayTexts) {
    // @TODO displayText.split(' ') just check for spaces, next time we can check for -dashes-
    const matchedWords = displayTexts.map(displayText => {
        const wordParts = displayText.split(' ');
        if (wordParts.length > 1) {
            return wordParts.slice(0, wordParts.length - 1).join(' ');
        }

        return wordParts.join(' ');
    });

    /* The Array.prototype.reduce method assists us in producing a single value from an
       array. In this case, we're going to use it to output an object with results. */
    return matchedWords.reduce((stats, word) => {
        /* `stats` is the object that we'll be building up over time.
           `word` is each individual entry in the `matchedWords` array */
        if (word in stats) {
            /* `stats` already has an entry for the current `word`.
               As a result, let's increment the count for that `word`. */
            // eslint-disable-next-line no-param-reassign
            stats[word] += 1;
        } else {
            /* `stats` does not yet have an entry for the current `word`.
               As a result, let's add a new entry, and set count to 1. */
            // eslint-disable-next-line no-param-reassign
            stats[word] = 1;
        }

        /* Because we are building up `stats` over numerous iterations,
           we need to return it for the next pass to modify it. */
        return stats;
    }, {});
}

/**
 * Get the multiple common prefixes in an array of text
 * I.e. ["PARKING A", "PARKING B", "PARKING C", "VIP 1", "VIP 2"]
 *
 * @param displayTexts
 * @returns {string|*}
 */
const getCommonPrefixes = displayTexts => {
    /* Now that `counts` has our object, we can log it. */
    const wordCount = Object.entries(firstWordCounters(displayTexts));

    return wordCount.map(word => word[0]);
};

/**
 * Group the locations based on the common prefix
 *
 * @param locationArray
 * @param concatChar
 * @param lineBreak
 * @returns {string|*}
 */
export const getGroupedLocation = (
    locationArray,
    concatChar = SIGNS_CONCAT_CHARS.COMMA,
    lineBreak = false
) => {
    if (!locationArray) return '';

    const isSlotItemEnabled = (
        {disabled, disabledByParent, displayText, parkingLotConfig} = {undefined}
    ) =>
        !disabled &&
        !disabledByParent &&
        !!displayText &&
        parkingLotConfig?.status !== PARKING_STATUSES.CLOSED;

    /**
     * Check if there is at least one location item enabled in the slot:
     *  - hide the slot if there is no location items enabled even if there are messaging items there
     *  - slot should be shown if there are no location items at all, but there are messaging items there
     * */
    let someLocationItemsEnabled = false;
    let isLocationItemExists = false;

    locationArray.forEach(item => {
        if (item && item.type === SLOT_ITEM.LOCATION) {
            isLocationItemExists = true;
            if (isSlotItemEnabled(item)) {
                someLocationItemsEnabled = true;
            }
        }
    });
    if (!someLocationItemsEnabled && isLocationItemExists) {
        return '';
    }

    const displayTexts = locationArray.filter(isSlotItemEnabled).map(({displayText, type}) => {
        return {displayText, type};
    });

    // There's nothing to group when only one array element is present
    if (displayTexts.length < 2) {
        return displayTexts.map(({displayText}) => displayText).join('');
    }

    /**
     * Group display texts by type (location/message)
     * for example: [
     *  {displayText: 'entry 1', type: 'L'},
     *  {displayText: 'entry 2', type: 'L'},
     *  {displayText: 'next right', type: 'M'},
     *  {displayText: 'then left', type: 'M'},
     *  {displayText: 'entry 3', type: 'L'},
     *  {displayText: 'next left', type: 'M'},
     * ]
     * will be grouped like this:
     * [
     *  [
     *      {displayText: 'entry 1', type: 'L'},
     *      {displayText: 'entry 2', type: 'L'},
     *  ],
     *  [
     *      {displayText: 'next right', type: 'M'},
     *      {displayText: 'then left', type: 'M'}
     *  ],
     *  [
     *      {displayText: 'entry 3', type: 'L'}
     *  ],
     *  [
     *      {displayText: 'next left', type: 'M'}
     *  ],
     * ]
     * */
    const displayTextGroups = [];
    let currentGroup = [];
    let prevItem = null;
    displayTexts.forEach(item => {
        if ((prevItem && prevItem.type === item.type) || !prevItem) {
            currentGroup.push(item);
        } else {
            displayTextGroups.push(currentGroup);
            currentGroup = [item];
        }
        prevItem = item;
    });
    if (currentGroup && currentGroup.length > 0) {
        displayTextGroups.push(currentGroup);
    }

    const result = [];
    displayTextGroups.forEach(displayTextGroup => {
        const displayTextsInGroup = displayTextGroup.map(({displayText}) => displayText);

        if (displayTextGroup[0].type === SLOT_ITEM.LOCATION) {
            // apply the logic of grouping by prefix to location items
            const commonPrefixes = getCommonPrefixes(displayTextsInGroup);
            const groupedPrefixTexts = [];

            commonPrefixes.sort().forEach(prefix => {
                const delimiter = prefix ? ', ' : ',';

                let groupedText = sort(displayTextsInGroup)
                    .map(text => {
                        // In this mapping, we will get the texts with prefix matches
                        const _text = text.substr(0, prefix.length);

                        // The displayText doesn't match the current prefix
                        if (
                            _text !== prefix ||
                            (text.length > prefix.length && text[prefix.length] !== ' ')
                        ) {
                            return '';
                        }

                        return text;
                    })
                    .filter(text => !!text) // removed <empty strings>
                    .map(text => text.replace(prefix, '').trim()); // remove the prefix from the displayText

                if ([SIGNS_CONCAT_CHARS.DASH, SIGNS_CONCAT_CHARS.TO].includes(concatChar)) {
                    groupedText = convertToRanges(groupedText, concatChar);
                }

                // Empty element means the displayText only has the prefix
                const emptyElementPrefix =
                    [...new Set(groupedText)].find(text => !text) !== undefined;

                // Only return the unique elements in the groupedTexts
                const uniqueTexts = [...new Set(groupedText)]
                    .filter(text => !!text)
                    .join(delimiter);

                // Two prefixes. 1 for empty element, and one for the grouped texts
                let finalPrefix =
                    emptyElementPrefix && uniqueTexts ? `${prefix}, ${prefix}` : prefix;

                // This is to fix grouping TOILET - FEMALE, TOILET - MALE
                const dashTest = finalPrefix.substr(prefix.length - 2);
                if (dashTest === ' -') {
                    finalPrefix = finalPrefix.substr(0, finalPrefix.length - 2);
                }

                // Add the prefix to the grouped unique texts
                groupedPrefixTexts.push(`${finalPrefix} ${uniqueTexts}`.trim());
            });

            result.push(groupedPrefixTexts.join(', '));
        } else {
            // don't apply the logic of grouping by prefix to messaging items
            result.push(displayTextsInGroup.join(lineBreak ? '\r\n' : ' '));
        }
    });

    if (lineBreak) {
        return result.join('\r\n');
    }
    return result.join(' ');
};

/**
 * Get the sign types for a layout
 *
 * @param layoutId
 * @returns {({name: string, width: number, id: string, layoutId: string, height: number})[]}
 */
export const getSignTypesByLayout = layoutId =>
    Object.values(SIGN_TYPES).filter(signType => signType.layoutId === layoutId);

/**
 * Check if the number is in between range
 *
 * @param number
 * @param min
 * @param max
 * @returns {boolean|boolean}
 */
export const isInBetween = (number, min, max) => number >= min && number <= max;

/**
 * Get the kiosk layout global config
 * This is usually used to determine the config overrides for kiosk
 *
 * @param config
 * @returns {*}
 */
export const getKioskLayoutGlobalConfigs = config =>
    pickFp(KIOSK_LAYOUT_GENERAL_TAB_PROP_PATHS, config);

/**
 * Get the heads-up rotation, this is simple adding 180 to the current location
 * and modulo it with 360 because it's a circle
 *
 * @param {number} rotation - the sign's bearing
 * @returns {number}
 */
export const getHeadsUpSignRotation = (rotation = 0) => (rotation + 180) % 360;

const overrideNavMap = ({navMap, scenes, navMapOverrides}) => {
    const activeScene = scenes.find(scene => scene.isActive);

    const activeSceneData = navMapOverrides.find(sceneData => sceneData.sceneId === activeScene.id);
    // if we are not able to find an active scene... we resort to base one
    if (!activeSceneData) {
        log.warn("couldn't find an active scene data");
        return navMap;
    }

    return {
        ...navMap,
        features: navMap.features.map(feature => {
            const activeFeature =
                activeSceneData.overrides.find(
                    feat => feat.featureId === feature.properties.featureId
                ) || false;
            if (!activeFeature) {
                // no matching feature in active scene
                return feature;
            }
            // check if geometry needs to be reversed
            let coordinates = feature.geometry && feature.geometry.coordinates;
            if (activeFeature?.properties?.reverseOneWay && coordinates) {
                coordinates = feature.geometry.coordinates.reverse();
            }
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    ...activeFeature.properties,
                },
                geometry: {
                    ...feature.geometry,
                    coordinates,
                },
            };
        }),
    };
};

export const getNavMap = (sceneId, navMap, navMapOverrides, scenes, allSigns = []) => {
    const updatedNavMap = navMapOverrides
        ? overrideNavMap({
              navMap,
              scenes: scenes.map(scene => ({
                  ...scene,
                  isActive: scene.id === sceneId,
              })),
              navMapOverrides,
          })
        : navMap;
    const featureParentsIterator = dsmFeatureParentsIterator(updatedNavMap.features);
    const features = updatedNavMap.features.map(feature => {
        const signFeature = allSigns.find(sign => sign.id === feature.properties.featureId);
        return {
            ...feature,
            properties: {
                ...feature.properties,
                disabled:
                    feature.properties.disabled ||
                    !!featureParentsIterator(feature).find(
                        parentFeature => parentFeature.properties.disableDescendants
                    ),
                doNotDraw: signFeature ? signFeature.properties?.doNotDraw : false,
            },
        };
    });

    return {...updatedNavMap, features};
};

const cleanFeature = feature => ({
    ...feature,
    properties: {
        ...feature.properties,
        featureType: {
            ...feature.properties.featureType,
            style: {},
        },
    },
});

export const mergeLocationAndFeaturesInEvents = ({events, navMapWithLocations, settings}) => {
    if (lodashIsArray(events)) {
        return events.map(event => {
            const feature = navMapWithLocations.features.find(
                feat =>
                    !feat.properties.isDisplayPoint &&
                    !!event.locationId &&
                    feat.properties.locationId === event.locationId.toString()
            );
            return feature
                ? {
                      ...event,
                      feature: cleanFeature(feature),
                      timeZone: event.timeZone || settings.timeZone,
                  }
                : {...event, timeZone: event.timeZone || settings.timeZone};
        });
    }

    return [];
};

/**
 * Remove extra data which no need to pass to API or store to file
 * @param layoutConfigs array
 * @param useMocks bool
 */
export const removeExtraDataFromLayoutConfigs = (layoutConfigs, useMocks) => {
    return layoutConfigs.map(layoutConfig => {
        const imageFilePaths = [
            'config.withInheritance.idleScreen.welcomeImage',
            'config.withInheritance.idleScreen.ctaImage',
        ];
        const imageNames = imageFilePaths
            .map(path => ({
                path,
                name: lodashGet(layoutConfig, `${path}.file.name`),
            }))
            .filter(item => item.name !== undefined);
        if (imageNames.length === 0) {
            return layoutConfig;
        }

        const updatedLayoutConfig = lodashCloneDeep(layoutConfig);
        imageNames.forEach(item => {
            if (useMocks) {
                lodashSet(updatedLayoutConfig, `${item.path}.file`, item.name);
            }
            lodashUnset(updatedLayoutConfig, `${item.path}.url`);
        });
        return updatedLayoutConfig;
    });
};
