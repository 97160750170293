import IMDF_FEATURE_TYPES from 'Utils/imdfFeatureTypes';
import * as mapEditorLayers from 'Styles/mapEditorLayers';
import * as explorerLayers from 'Styles/explorerLayers';
import * as dsmLayers from 'Styles/dsmLayers';
import * as parkingManagerLayers from 'Styles/parkingManagerLayers';

export const FEATURE_TYPE_ICONS = {
    entrance: '/images/icon-entrance-outline.svg',
    parking: '/images/icon-parking-alt-outline.svg',
    restroom: '/images/icon-restroom-outline.svg',
    event: '/images/icon-event-outline.svg',
    pin: '/images/icon-pin-outline.svg',
    myLocation: '/images/icon-myLocation.svg',
};

// Note that the order here doesn't affect Mapbox layers, that's defined elsewhere.
const FEATURE_TYPES = {
    stadium: {
        name: 'Stadium',
        code: 'stadium',
        imdfFeatureType: IMDF_FEATURE_TYPES.venue,
        imdfCategory: IMDF_FEATURE_TYPES.venue.categories.stadium,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canForcePamRoutes: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.venue.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.venue.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#ece6cd',
                    borderColor: '#bbb',
                    fillOpacity: 0.1,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    university: {
        name: 'University',
        code: 'university',
        imdfFeatureType: IMDF_FEATURE_TYPES.venue,
        imdfCategory: IMDF_FEATURE_TYPES.venue.categories.university,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canForcePamRoutes: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.venue.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.venue.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#ece6cd',
                    borderColor: '#575a6c',
                    fillOpacity: 0.1,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    retailStore: {
        name: 'Retail Store',
        code: 'retailStore',
        imdfFeatureType: IMDF_FEATURE_TYPES.venue,
        imdfCategory: IMDF_FEATURE_TYPES.venue.categories.retailstore,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canForcePamRoutes: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.venue.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.venue.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillEditorColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderEditorColour,
                    fillOpacity: 0.1,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    shoppingCenter: {
        name: 'Shopping Center',
        code: 'shoppingCenter',
        imdfFeatureType: IMDF_FEATURE_TYPES.venue,
        imdfCategory: IMDF_FEATURE_TYPES.venue.categories.shoppingcenter,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canForcePamRoutes: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.venue.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.venue.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillEditorColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderEditorColour,
                    fillOpacity: 0.1,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    village: {
        name: 'Village/Camp',
        code: 'village',
        imdfFeatureType: IMDF_FEATURE_TYPES.venue,
        imdfCategory: IMDF_FEATURE_TYPES.venue.categories.village,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canForcePamRoutes: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.venue.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.venue.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.venue.style.fillEditorColour,
                    borderColor: IMDF_FEATURE_TYPES.venue.style.borderEditorColour,
                    fillOpacity: 0.1,
                    borderWidth: IMDF_FEATURE_TYPES.venue.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    area: {
        name: 'Area',
        code: 'area',
        imdfFeatureType: IMDF_FEATURE_TYPES.geofence,
        imdfCategory: IMDF_FEATURE_TYPES.geofence.categories.geofence,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: true,
        canForcePamRoutes: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#3e3e3e',
                    borderColor: IMDF_FEATURE_TYPES.geofence.style.borderDarkColour,
                    borderWidth: IMDF_FEATURE_TYPES.geofence.style.explorerBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.geofence.style.explorerFillOpacity,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#f4f3f1',
                    borderColor: IMDF_FEATURE_TYPES.geofence.style.borderLightColour,
                    borderWidth: IMDF_FEATURE_TYPES.geofence.style.explorerBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.geofence.style.explorerFillOpacity,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#f9e9f9',
                    borderColor: '#bbb',
                    borderWidth: IMDF_FEATURE_TYPES.geofence.style.editorBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.geofence.style.editorFillOpacity,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    building: {
        name: 'Building',
        code: 'building',
        imdfFeatureType: IMDF_FEATURE_TYPES.building,
        imdfCategory: IMDF_FEATURE_TYPES.building.categories.unspecified,
        canHaveDictionaryItem: true,
        defaultExtrusion: 1,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#3e3e3e',
                    borderColor: IMDF_FEATURE_TYPES.building.style.borderDarkColour,
                    borderWidth: IMDF_FEATURE_TYPES.building.style.explorerBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.building.style.explorerFillOpacity,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#f4f3f1',
                    borderColor: IMDF_FEATURE_TYPES.building.style.borderLightColour,
                    borderWidth: IMDF_FEATURE_TYPES.building.style.explorerBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.building.style.explorerFillOpacity,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#e0e2d2',
                    borderColor: '#685063',
                    borderWidth: IMDF_FEATURE_TYPES.building.style.editorBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.building.style.editorFillOpacity,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    footprint: {
        name: 'Footprint',
        code: 'footprint',
        imdfFeatureType: IMDF_FEATURE_TYPES.footprint,
        imdfCategory: IMDF_FEATURE_TYPES.footprint.categories.ground,
        canHaveDictionaryItem: false,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
    },
    multiLevelCarpark: {
        name: 'Multi-level carpark',
        code: 'multiLevelCarpark',
        imdfFeatureType: IMDF_FEATURE_TYPES.building,
        imdfCategory: IMDF_FEATURE_TYPES.building.categories.parking,
        canHaveDictionaryItem: true,
        defaultExtrusion: 1,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#454545',
                    borderColor: IMDF_FEATURE_TYPES.building.style.borderDarkColour,
                    borderWidth: IMDF_FEATURE_TYPES.building.style.explorerBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.building.style.explorerFillOpacity,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#e6e4e0',
                    borderColor: IMDF_FEATURE_TYPES.building.style.borderLightColour,
                    borderWidth: IMDF_FEATURE_TYPES.building.style.explorerBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.building.style.explorerFillOpacity,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#e0e2d2',
                    borderColor: '#e0e2d2',
                    borderWidth: IMDF_FEATURE_TYPES.building.style.editorBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.building.style.editorFillOpacity,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
        searchListIcon: FEATURE_TYPE_ICONS.parking,
    },
    parking: {
        name: 'Parking',
        code: 'parking',
        imdfFeatureType: IMDF_FEATURE_TYPES.level,
        imdfCategory: IMDF_FEATURE_TYPES.level.categories.parking,
        canHaveDictionaryItem: true,
        canBeGrouped: true,
        canBeSaved: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#434343',
                    borderColor: IMDF_FEATURE_TYPES.level.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.level.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.level.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#dbdbdb',
                    borderColor: IMDF_FEATURE_TYPES.level.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.level.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.level.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#cccccc',
                    borderColor: '#cccccc',
                    fillOpacity: IMDF_FEATURE_TYPES.level.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.level.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
        searchListIcon: FEATURE_TYPE_ICONS.parking,
    },
    level: {
        name: 'Level',
        code: 'level',
        imdfFeatureType: IMDF_FEATURE_TYPES.level,
        imdfCategory: IMDF_FEATURE_TYPES.level.categories.unspecified,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.level.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.level.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.level.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.level.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.level.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.level.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.level.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.level.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#ffeee1',
                    borderColor: '#444B8E',
                    fillOpacity: IMDF_FEATURE_TYPES.level.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.level.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    sectionSeating: {
        name: 'Seating section',
        code: 'sectionSeating',
        imdfFeatureType: IMDF_FEATURE_TYPES.section,
        imdfCategory: IMDF_FEATURE_TYPES.section.categories.seating,
        canHaveDictionaryItem: true,
        defaultExtrusion: 1,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#61d8e5',
                    borderColor: '#43a4ba',
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    fitnessComplex: {
        name: 'Recreation',
        code: 'fitnessComplex',
        imdfFeatureType: IMDF_FEATURE_TYPES.section,
        imdfCategory: IMDF_FEATURE_TYPES.section.categories.recreation,
        canHaveDictionaryItem: true,
        defaultExtrusion: 1,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#95f0fa',
                    borderColor: '#43a4ba',
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    retail: {
        name: 'Retail',
        code: 'retail',
        imdfFeatureType: IMDF_FEATURE_TYPES.section,
        imdfCategory: IMDF_FEATURE_TYPES.section.categories.retail,
        canHaveDictionaryItem: true,
        defaultExtrusion: 1,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#88dde6',
                    borderColor: '#43a4ba',
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    checkin: {
        name: 'Check-in',
        code: 'checkin',
        imdfFeatureType: IMDF_FEATURE_TYPES.section,
        imdfCategory: IMDF_FEATURE_TYPES.section.categories.checkin,
        canHaveDictionaryItem: true,
        defaultExtrusion: 1,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#bbf5fb',
                    borderColor: '#43a4ba',
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    exhibit: {
        name: 'Exhibit',
        code: 'exhibit',
        imdfFeatureType: IMDF_FEATURE_TYPES.section,
        imdfCategory: IMDF_FEATURE_TYPES.section.categories.exhibit,
        canHaveDictionaryItem: true,
        defaultExtrusion: 1,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#43a4ba',
                    borderColor: '#999',
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    foodCourt: {
        name: 'Food Court',
        code: 'foodCourt',
        imdfFeatureType: IMDF_FEATURE_TYPES.section,
        imdfCategory: IMDF_FEATURE_TYPES.section.categories.eatingdrinking,
        canHaveDictionaryItem: true,
        defaultExtrusion: 1,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillEditorColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderEditorColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    natureStrip: {
        name: 'Nature Strip / Flower Bed',
        code: 'natureStrip',
        imdfFeatureType: IMDF_FEATURE_TYPES.section,
        imdfCategory: IMDF_FEATURE_TYPES.section.categories.vegetation,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.05,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#2b4d34',
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#bcddb8',
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#bcddb8',
                    borderColor: '#488741',
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    road: {
        name: 'Road',
        code: 'road',
        imdfFeatureType: IMDF_FEATURE_TYPES.section,
        imdfCategory: IMDF_FEATURE_TYPES.section.categories.road,
        canHaveDictionaryItem: true,
        defaultExtrusion: 1,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.section.style.fillEditorColour,
                    borderColor: IMDF_FEATURE_TYPES.section.style.borderEditorColour,
                    fillOpacity: IMDF_FEATURE_TYPES.section.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.section.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    auditorium: {
        name: 'Auditorium',
        code: 'auditorium',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.auditorium,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#fdc599',
                    borderColor: '#b48c6d',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    library: {
        name: 'Library',
        code: 'library',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.library,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#fdb67f',
                    borderColor: '#bbb',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    foodOutlet: {
        name: 'Food outlet',
        code: 'foodOutlet',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.foodservice,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#fedbbf',
                    borderColor: '#b48c6d',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    room: {
        name: 'Room',
        code: 'room',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.room,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#6f6f6f',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#efefef',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#ffeee1',
                    borderColor: '#b48c6d',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    kiosk: {
        name: 'Kiosk',
        code: 'kiosk',
        imdfFeatureType: IMDF_FEATURE_TYPES.kiosk,
        imdfCategory: null,
        canHaveDictionaryItem: true,
        defaultExtrusion: 2,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.kiosk.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.kiosk.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.kiosk.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.kiosk.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.kiosk.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.kiosk.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.kiosk.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.kiosk.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#ffeee1',
                    borderColor: '#bbb',
                    fillOpacity: IMDF_FEATURE_TYPES.kiosk.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.kiosk.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    bathroom: {
        name: 'Bathroom',
        code: 'bathroom',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.restroom,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#6d6286',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#e6ddee',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#fdbcac',
                    borderColor: '#fd9f86',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
        searchListIcon: FEATURE_TYPE_ICONS.restroom,
    },
    shower: {
        name: 'Shower',
        code: 'shower',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.shower,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#6d6286',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#e6ddee',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#fd9f86',
                    borderColor: '#b48c6d',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
        searchListIcon: FEATURE_TYPE_ICONS.restroom,
    },
    void: {
        name: 'Void',
        code: 'void',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.opentobelow,
        canHaveDictionaryItem: false,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#656565',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#ece4d4',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#b48c6d',
                    borderColor: '#b48c6d',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    sportsField: {
        name: 'Sports Field',
        code: 'sportsField',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.fieldofplay,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.05,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#325f44',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#c3e4c5',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#b9ddb1',
                    borderColor: '#4a893b',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    gym: {
        name: 'Gym',
        code: 'gym',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.fitnessroom,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillEditorColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderEditorColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    movieTheatre: {
        name: 'Movie Theatre',
        code: 'movieTheatre',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.movietheatre,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#842d2d',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#d9aaaa',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#d9aaaa',
                    borderColor: '#843b3b',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    lawn: {
        name: 'Lawn',
        code: 'lawn',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.vegetation,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.05,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#35523d',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#c4e0ba',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#c4e0ba',
                    borderColor: '#538c3f',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    column: {
        name: 'Column',
        code: 'column',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.column,
        canHaveDictionaryItem: true,
        defaultExtrusion: 4,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillEditorColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderEditorColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    walkway: {
        name: 'Walkway',
        code: 'walkway',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.walkway,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillEditorColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderEditorColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    elevator: {
        name: 'Elevator',
        code: 'elevator',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.elevator,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#4eb1a5',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#8fd6ce',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#8fd6ce',
                    borderColor: '#2f8178',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    escalator: {
        name: 'Escalator',
        code: 'escalator',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.escalator,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#4eb1a5',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#8fd6ce',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#8fd6ce',
                    borderColor: '#2f8178',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    stairs: {
        name: 'Stairs',
        code: 'stairs',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.stairs,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#4eb1a5',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#8fd6ce',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#8fd6ce',
                    borderColor: '#2f8178',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    backOfHouse: {
        name: 'Back Of House',
        code: 'backOfHouse',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.nonpublic,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.unit.style.fillEditorColour,
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderEditorColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    concrete: {
        name: 'Concrete',
        code: 'concrete',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.concrete,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#2e2d2c',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#e5e7e8',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#e5e7e8',
                    borderColor: '#6b7479',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    glass: {
        name: 'Glass',
        code: 'glass',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.glass,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#619fb2',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: 0.2,
                    extrudeOpacity: 0.2,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#73cde8',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: 0.5,
                    extrudeOpacity: 0.5,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#73cde8',
                    borderColor: '#187793',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    wood: {
        name: 'Wood',
        code: 'wood',
        imdfFeatureType: IMDF_FEATURE_TYPES.unit,
        imdfCategory: IMDF_FEATURE_TYPES.unit.categories.wood,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#7c6746',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#d0bfa5',
                    borderColor: IMDF_FEATURE_TYPES.unit.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#d0bfa5',
                    borderColor: '#79623f',
                    fillOpacity: IMDF_FEATURE_TYPES.unit.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.unit.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    freeForm: {
        name: 'Free-form/landscape',
        code: 'freeForm',
        imdfFeatureType: IMDF_FEATURE_TYPES.freeForm,
        imdfCategory: null,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#3e3e3e',
                    borderColor: IMDF_FEATURE_TYPES.geofence.style.borderDarkColour,
                    borderWidth: IMDF_FEATURE_TYPES.geofence.style.explorerBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.geofence.style.explorerFillOpacity,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#f4f3f1',
                    borderColor: IMDF_FEATURE_TYPES.geofence.style.borderLightColour,
                    borderWidth: IMDF_FEATURE_TYPES.geofence.style.explorerBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.geofence.style.explorerFillOpacity,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#ccccee',
                    borderColor: '#dbdeeb',
                    borderWidth: IMDF_FEATURE_TYPES.geofence.style.editorBorderWidth,
                    fillOpacity: IMDF_FEATURE_TYPES.geofence.style.editorFillOpacity,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    wall: {
        name: 'Wall',
        code: 'wall',
        imdfFeatureType: IMDF_FEATURE_TYPES.fixture,
        imdfCategory: IMDF_FEATURE_TYPES.fixture.categories.wall,
        canHaveDictionaryItem: true,
        defaultExtrusion: 2,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.fixture.style.fillDarkColour,
                    borderColor: IMDF_FEATURE_TYPES.fixture.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.fixture.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.fixture.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: IMDF_FEATURE_TYPES.fixture.style.fillLightColour,
                    borderColor: IMDF_FEATURE_TYPES.fixture.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.fixture.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.fixture.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makeWallEditLayer,
                props: {color: '#b48c6d'},
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    planterBox: {
        name: 'Planter Box',
        code: 'planterBox',
        imdfFeatureType: IMDF_FEATURE_TYPES.fixture,
        imdfCategory: IMDF_FEATURE_TYPES.fixture.categories.vegetation,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.5,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#2b4d34',
                    borderColor: IMDF_FEATURE_TYPES.fixture.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.fixture.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.fixture.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#bcddb8',
                    borderColor: IMDF_FEATURE_TYPES.fixture.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.fixture.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.fixture.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#bcddb8',
                    borderColor: '#488741',
                    fillOpacity: IMDF_FEATURE_TYPES.fixture.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.fixture.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    water: {
        name: 'Water',
        code: 'water',
        imdfFeatureType: IMDF_FEATURE_TYPES.fixture,
        imdfCategory: IMDF_FEATURE_TYPES.fixture.categories.water,
        canHaveDictionaryItem: true,
        defaultExtrusion: 0.05,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#054070',
                    borderColor: IMDF_FEATURE_TYPES.fixture.style.borderDarkColour,
                    fillOpacity: IMDF_FEATURE_TYPES.fixture.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.fixture.style.explorerBorderWidth,
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePolygonLayers,
                props: {
                    color: '#9bd1f2',
                    borderColor: IMDF_FEATURE_TYPES.fixture.style.borderLightColour,
                    fillOpacity: IMDF_FEATURE_TYPES.fixture.style.explorerFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.fixture.style.explorerBorderWidth,
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {
                    color: '#9bd1f2',
                    borderColor: '#1674ae',
                    fillOpacity: IMDF_FEATURE_TYPES.fixture.style.editorFillOpacity,
                    borderWidth: IMDF_FEATURE_TYPES.fixture.style.editorBorderWidth,
                },
            },
            DSM: {creatorFunction: dsmLayers.makePolygonLayers},
            PM: {creatorFunction: parkingManagerLayers.makePolygonLayers},
        },
    },
    carEntrance: {
        name: 'Car entrance',
        code: 'carEntrance',
        imdfFeatureType: IMDF_FEATURE_TYPES.opening,
        imdfCategory: IMDF_FEATURE_TYPES.opening.categories.automobile,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makeLineLayer,
                props: {color: '#96493d', lineWidth: 2},
            },
        },
        searchListIcon: FEATURE_TYPE_ICONS.entrance,
    },
    emergencyExit: {
        name: 'Emergency exit',
        code: 'emergencyExit',
        imdfFeatureType: IMDF_FEATURE_TYPES.opening,
        imdfCategory: IMDF_FEATURE_TYPES.opening.categories.emergencyexit,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makeLineLayer,
                props: {color: '#96493d', lineWidth: 2},
            },
        },
        searchListIcon: FEATURE_TYPE_ICONS.entrance,
    },
    doorway: {
        name: 'Doorway',
        code: 'doorway',
        imdfFeatureType: IMDF_FEATURE_TYPES.opening,
        imdfCategory: IMDF_FEATURE_TYPES.opening.categories.pedestrian,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makeLineLayer,
                props: {color: '#96493d', lineWidth: 2},
            },
        },
        searchListIcon: FEATURE_TYPE_ICONS.entrance,
    },
    venueEntrance: {
        name: 'Venue entrance',
        code: 'venueEntrance',
        imdfFeatureType: IMDF_FEATURE_TYPES.opening,
        imdfCategory: IMDF_FEATURE_TYPES.opening.categories.pedestrianPrincipal,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makeLineLayer,
                props: {color: '#96493d', lineWidth: 2},
            },
        },
        searchListIcon: FEATURE_TYPE_ICONS.entrance,
    },
    object3d: {
        name: '3D model',
        code: 'object3d',
        imdfFeatureType: IMDF_FEATURE_TYPES.object3d,
        imdfCategory: null,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        defaultProps: {
            object3dDisplayStartZoom: 12,
            object3dDisplayEndZoom: 24,
            object3dMaxOpacity: 100,
            object3dFadeStart: true,
            object3dFadeEnd: false,
            object3dHide: false,
            object3dScale: (0.0000000301).toFixed(10),
            object3dRotation: 0,
        },
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePointLayer,
                props: {color: '#ff5b45', borderColor: '#fff'},
            },
        },
    },
    point: {
        name: 'Point',
        code: 'point',
        imdfFeatureType: IMDF_FEATURE_TYPES.anchor,
        imdfCategory: null,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: true,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        canBeRoutedViaGroup: true,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePointLayer,
                props: {color: '#f40057', borderColor: '#fdfffd'},
            },
        },
    },
    group: {
        name: 'Group',
        code: 'group',
        imdfFeatureType: IMDF_FEATURE_TYPES.group,
        supportsDisplayPoint: false,
        imdfCategory: null,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePointLayer,
                props: {color: '#81d4fa', borderColor: '#fff'},
            },
        },
    },
    routeSegment: {
        name: 'Route segment',
        code: 'routeSegment',
        imdfFeatureType: IMDF_FEATURE_TYPES.routeSegment,
        imdfCategory: null,
        canHaveDictionaryItem: false,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        defaultProps: {
            routeAllowsPedestrians: true,
            routeAllowsVehicles: false,
            routeIsNightSafe: false,
            routeIsSteps: false,
            routeIsWheelchairAccessible: true,
            oneWay: false,
            reverseOneWay: false,
            disabled: false,
        },
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makeRouteLayers,
                props: {},
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makeRouteLayers,
                props: {},
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makeRouteLayers,
                props: {color: '#00bcd4'},
            },
            DSM: {
                creatorFunction: dsmLayers.makeRouteLayers,
                props: {color: '#d4d22e'},
            },
            PM: {
                creatorFunction: parkingManagerLayers.makeRouteLayers,
                props: {color: '#d4d22e'},
            },
        },
    },
    digitalSign: {
        name: 'Digital sign',
        code: 'digitalSign',
        imdfFeatureType: IMDF_FEATURE_TYPES.amenity,
        imdfCategory: IMDF_FEATURE_TYPES.amenity.categories.information,
        canHaveDictionaryItem: true,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        defaultProps: {
            isDoubleSidedSign: false,
            rotation: 0,
        },
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePointLayer,
                props: {
                    color: '#666',
                    borderColor: '#fff',
                    radius: 5,
                },
            },
            // Digital signs aren't rendered as GeoJSON features,
            // They're rendered as mapboxGl.Markers, so don't exist here
        },
    },
    threshold: {
        name: 'Threshold',
        code: 'threshold',
        imdfFeatureType: IMDF_FEATURE_TYPES.threshold,
        imdfCategory: null,
        canHaveDictionaryItem: false,
        canBeSaved: true,
        canBeGrouped: false,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        canBeBlockedForExternalRouting: true,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePointLayer,
                props: {
                    color: '#ddd',
                    borderColor: '#0088ff',
                    borderColorBlockExternalRouting: '#f11A27',
                    borderColorOtherClient: '#808080',
                    radius: 5,
                    borderRadius: 3,
                },
            },
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makePointLayer,
                props: {color: '#ddd', radius: 6, borderRadius: 3},
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makePointLayer,
                props: {color: '#ddd', radius: 6, borderRadius: 3},
            },
        },
    },
    halfwayPoint: {
        name: 'Halfway point [1]',
        code: 'halfwayPoint',
        imdfFeatureType: IMDF_FEATURE_TYPES.halfwayPoint,
        imdfCategory: null,
        canHaveDictionaryItem: false,
        canBeSaved: false,
        canBeGrouped: false,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePointLayer,
                props: {color: '#909290', borderColor: '#909290'},
            },
        },
    },
    displayPoint: {
        name: 'Display point',
        code: 'displayPoint',
        imdfFeatureType: IMDF_FEATURE_TYPES.displayPoint,
        imdfCategory: null,
        canHaveDictionaryItem: false,
        canBeSaved: false,
        canBeGrouped: false,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePointLayer,
                props: {color: '#44ff16', borderColor: '#fdfffd'},
            },
        },
    },
    venueLabelPoint: {
        name: 'Venue Label Point',
        code: 'venueLabelPoint',
        imdfFeatureType: IMDF_FEATURE_TYPES.venueLabelPoint,
        imdfCategory: null,
        canHaveDictionaryItem: false,
        canBeSaved: false,
        canBeGrouped: false,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
    },
    importedPoint: {
        name: 'Imported point',
        code: 'importedPoint',
        imdfFeatureType: IMDF_FEATURE_TYPES.importedPoint,
        supportsDisplayPoint: false,
        imdfCategory: null,
        canHaveDictionaryItem: false,
        canBeSaved: false,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePointLayer,
                props: {color: '#0fcf7c', borderColor: '#fff'},
            },
        },
    },
    importedLine: {
        name: 'Imported line',
        code: 'importedLine',
        imdfFeatureType: IMDF_FEATURE_TYPES.importedLine,
        supportsDisplayPoint: false,
        imdfCategory: null,
        canHaveDictionaryItem: false,
        canBeSaved: false,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makeLineLayer,
                props: {color: '#0fcf7c'},
            },
        },
    },
    importedPolygon: {
        name: 'Imported polygon',
        code: 'importedPolygon',
        imdfFeatureType: IMDF_FEATURE_TYPES.importedPolygon,
        supportsDisplayPoint: false,
        imdfCategory: null,
        canHaveDictionaryItem: false,
        canBeSaved: false,
        canBeGrouped: false,
        canBeSwapped: true,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePolygonLayers,
                props: {color: '#fff', borderColor: '#0fcf7c'},
            },
        },
    },
    snapPoint: {
        name: 'Snap point',
        code: 'snapPoint',
        imdfFeatureType: IMDF_FEATURE_TYPES.snapPoint,
        imdfCategory: null,
        canHaveDictionaryItem: false,
        canBeSaved: false,
        canBeGrouped: false,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makePointLayer,
                props: {
                    color: '#ff0000',
                    borderColor: '#fff',
                    radius: 7,
                },
            },
        },
    },
    snapLine: {
        name: 'Snap line',
        code: 'snapLine',
        imdfFeatureType: IMDF_FEATURE_TYPES.snapLine,
        imdfCategory: null,
        canHaveDictionaryItem: false,
        canBeSaved: false,
        canBeGrouped: false,
        canBeSwapped: false,
        canBeDisplayedAsBoundary: false,
        style: {
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makeLineLayer,
                props: {
                    color: '#ff0000',
                    lineDashArray: [5, 5],
                },
            },
        },
    },
    // The below entries are special as they're never used to create GeoJSON features
    // They're only used to create Mapbox layers
    osmBuildings: {
        code: 'osmBuildings',
        canHaveDictionaryItem: false,
        canBeSaved: false,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makeOSMBuildingLayer,
                props: {color: '#888888'},
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makeOSMBuildingLayer,
                props: {color: '#ebe6e6'},
            },
        },
    },
    labels: {
        code: 'labels',
        canHaveDictionaryItem: false,
        canBeSaved: false,
        canBeDisplayedAsBoundary: false,
        style: {
            EXPLORER_DARK: {
                creatorFunction: explorerLayers.makeLabelLayers,
                props: {
                    haloWidth: 2,
                    textColor: '#FFFFFF',
                    haloColor: '#222222',
                },
            },
            EXPLORER_LIGHT: {
                creatorFunction: explorerLayers.makeLabelLayers,
                props: {
                    haloWidth: 2,
                    textColor: '#222222',
                    haloColor: '#FFFFFF',
                },
            },
            MAP_EDITOR: {
                creatorFunction: mapEditorLayers.makeLabelLayers,
                props: {
                    majorColor: '#5a4332',
                    minorColor: '#664f3d',
                    haloColor: '#fff',
                },
            },
            DSM: {
                creatorFunction: dsmLayers.makeLabelLayers,
                props: {
                    majorColor: '#999',
                    minorColor: '#888',
                    signLabelColor: '#444',
                    haloColor: '#fff',
                },
            },
            PM: {
                creatorFunction: parkingManagerLayers.makeLabelLayers,
                props: {
                    majorColor: '#999',
                    minorColor: '#888',
                    signLabelColor: '#444',
                    haloColor: '#fff',
                },
            },
        },
    },
};

export const FEATURE_TYPE_GROUPS = {
    entrances: [
        FEATURE_TYPES.doorway,
        FEATURE_TYPES.carEntrance,
        FEATURE_TYPES.venueEntrance,
        FEATURE_TYPES.emergencyExit,
    ],
    parking: [FEATURE_TYPES.parking, FEATURE_TYPES.multiLevelCarpark],
    restrooms: [FEATURE_TYPES.shower, FEATURE_TYPES.bathroom],
};

export default FEATURE_TYPES;
