import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ExplorerPreloader from './ExplorerPreloader';

const PreloaderWrapper = ({explorerLoaded}) => {
    if (!explorerLoaded) {
        return <ExplorerPreloader />;
    }
    return '';
};

PreloaderWrapper.propTypes = {
    explorerLoaded: PropTypes.bool,
};

const mapStateToProps = state => ({
    explorerLoaded: state.pageState.explorerMap?.explorerLoaded,
});

export default connect(
    mapStateToProps,
    {}
)(PreloaderWrapper);
