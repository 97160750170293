import {ACTION_TYPES, MAP_EDITOR_TABS} from 'Utils/constants';

const initialState = {
    readOnly: true,
    isBaseNavMapReadOnly: false,
    selectedTab: MAP_EDITOR_TABS.EDIT,
};

const mapEditorPageStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.ME.SET_READONLY:
            return {
                ...state,
                readOnly: action.data || false,
            };
        case ACTION_TYPES.ME.SET_IS_BASE_NAVMAP_READONLY:
            return {
                ...state,
                isBaseNavMapReadOnly: action.data || false,
            };
        case ACTION_TYPES.ME.SET_SELECTED_FEATURE:
            return {
                ...state,
                selectedFeatureId: action.data || null,
            };
        case ACTION_TYPES.ME.SET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.data,
            };
        default:
            return state;
    }
};

export default mapEditorPageStateReducer;
