import {CARD_NAV_LOCATION, TRAVEL_MODES} from 'Utils/constants';
import {KIOSK_PROP_PATHS} from 'Components/layouts/kiosk/KioskConfigPanel/ConfigComponentsConstants';

/**
 * navKey: {
 *     id: navKey, // should match navKey value
 *     menuItemId: string, // must match an id in KIOSK_ICONS,
 *     card: {
 *         key: string, // must match a key in CARD_COMPONENTS, didn't use component here as it will throw dependency cycle for more complex use
 *         isTitleHidden: bool, // hide card title section
 *         title: string, // name of card
 *         isClosedButtonHidden: bool,
 *         showBackButton: bool,
 *         hasSubHeading: bool,
 *         isCompact: bool,
 *         showMapControls: bool, // to show map controls for the card
 *         hideSecondaryMapControls: bool // to show secondary map controls on the card
 *     },
 * }
 */

export const EXPLORER_NAVIGATION = {
    search: {
        menuItemId: 'search',
        card: {key: 'searchCard', isTitleHidden: true, showMapControls: false},
        id: 'search',
    },

    searchEvent: {
        menuItemId: 'events',
        card: {key: 'eventDetailsCard', isTitleHidden: true, showMapControls: false},
        id: 'searchEvent',
    },
    explore: {
        menuItemId: 'explore',
        card: {
            key: 'explore',
            title: '',
            showMapControls: true,
        },
        id: 'explore',
    },
    tenants: {
        menuItemId: 'tenants',
        card: {
            key: 'directoryCard',
            title: 'Directory',
            isClosedButtonHidden: true,
            showBackButton: false,
            hasSubHeading: true,
            showMapControls: false,
        },

        id: 'tenants',
    },
    featureSummary: {
        menuItemId: 'explore',
        card: {
            key: 'featureSummaryCard',
            isCompact: true,
            isTitleHidden: true,
            showMapControls: true,
        },
        id: CARD_NAV_LOCATION,
    },
    featureDetailsCard: {
        card: {isTitleHidden: true, key: 'featureDetailsCard', showMapControls: false},
        id: 'featureDetailsCard',
        menuItemId: 'explore',
    },
    directions: {
        menuItemId: 'explore',
        desktopMenuItemId: 'directions',
        labelPropPath: KIOSK_PROP_PATHS.MENU_TAB.MENU_LABEL_DIRECTIONS,
        card: {
            key: 'directions',
            title: 'Directions',
            isClosedButtonHidden: true,
            isCompact: true,
            showMapControls: false,
        },
        id: 'directions',
    },
    journey: {
        menuItemId: 'explore',
        card: {
            key: 'journeyCard',
            isCompact: true,
            isTitleHidden: true,
            showMapControls: true,
            hideSecondaryMapControls: true,
        },
        id: CARD_NAV_LOCATION,
    },
    events: {
        menuItemId: 'events',
        card: {
            key: 'eventsList',
            title: 'Experiences',
            isClosedButtonHidden: true,
            showBackButton: false,
            hasSubHeading: true,
            showMapControls: false,
        },

        id: 'events',
    },
    eventDetails: {
        menuItemId: 'events',
        card: {key: 'eventDetailsCard', isTitleHidden: true, showMapControls: false},
        id: 'eventDetails',
    },
    promotions: {
        menuItemId: 'promotions',
        card: {
            key: 'promotionsList',
            title: 'Promotions',
            isClosedButtonHidden: true,
            showBackButton: false,
            hasSubHeading: true,
            showMapControls: false,
        },
        id: 'promotions',
    },
    promotionsDetailsCard: {
        card: {isTitleHidden: true, key: 'promotionsDetailsCard', showBackButton: true},
        id: 'promotionsDetailsCard',
        menuItemId: 'promotions',
    },
};

export const LOCATION_TOO_FAR_MODES = {
    MY_LOCATION: 1,
    OTHER_LOCATION: 2,
};

export const EXPLORER_NAVIGATION_LABELS = {
    explore: 'explore',
    search: 'search',
    tenants: 'tenants',
    events: 'events',
    promotions: 'promotions',
    directions: 'directions',
};

export const PULL_DOWN_THRESHOLD = 250; // screen px

export const LIVE_LOCATION_ZOOM = {
    [TRAVEL_MODES.DRIVING]: 17.8,
    [TRAVEL_MODES.CYCLING]: 19,
    [TRAVEL_MODES.WALKING]: 20,
};

export const LIVE_LOCATION_PITCH = 45;

export const LIVE_LOCATION_ANIMATION_DURATION_MS = 500;

export const EXPLORER_SEARCH_VIEWS = {
    SEARCH_VIEW: 'SEARCH-VIEW',
    MAP_VIEW: 'MAP-VIEW',
};

export const DESKTOP_QUICK_LINKS_SIZE = '32';
