import {ACTION_TYPES} from 'Utils/constants';

const pamOsEnabledFeaturesReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOADED_PAM_OS_ENABLED_FEATURES:
            return action.data || {};
        default:
            return state;
    }
};

export default pamOsEnabledFeaturesReducer;
