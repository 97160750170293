import {ACTION_TYPES} from 'Utils/constants';

/**
 * @typedef Scene
 * @property {string} id
 * @property {string} name
 * @property {boolean} isDefault
 * @property {boolean} isActive
 */

/**
 * @param {Array<Scene>} state
 * @param action
 * @return {Array<Scene>}
 */
const scenesStateReducer = (state = [], action) => {
    switch (action.type) {
        case ACTION_TYPES.LOADED_SCENES:
            return action.data || [];
        case ACTION_TYPES.CHANGE_ACTIVE_SCENE: {
            return state.map(scene => ({...scene, isActive: scene.id === action.data}));
        }
        default:
            return state;
    }
};

export default scenesStateReducer;
