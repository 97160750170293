import lodashOmit from 'lodash/omit';
import config from 'config.json';
import {Accessible, DirectionsBike, DirectionsCar, DirectionsWalk} from '@material-ui/icons';

// The below access token and style belong to the pamsupport Mapbox account
export const MAPBOX_ACCESS_TOKEN = config.mapboxAccessToken;

// this the folder name from `public/mocks` from where the app picks up mocks for navmap

export const MOCK_CLIENT_NAME = 'hpk';

export const MOCK_CLIENT_ID = 'hpk001';

export const MOCK_TEST_CLIENT_ID = 'hpk001';

export const INSIGHTS_METADATA = {
    TYPES: {
        MAP: 'map',
        EXPERIENCES: 'experiences',
        PROMOTIONS: 'promotions',
        ROUTE: 'route',
        TENANT: 'tenant',
        LOCATION: 'location',
    },
    ACTIONS: {
        SCAN: 'scan',
        SHARE: 'share',
        OPEN: 'open',
    },
};

export const MAPBOX_EXTERNAL_LOCATION = {
    RESULT_LIMIT: 5,
    SEARCH_PLACEHOLDER: 'SEARCH_PLACEHOLDER',
    ACTIVATE_ON_CHAR_COUNT: 3,
    RESULT_TYPES: [
        'region',
        'postcode',
        'district',
        'place',
        'locality',
        'neighborhood',
        'address',
        'poi',
        'poi.landmark',
    ],
};

export const MAP_BOUNDARY = {
    MAX_RADIUS: 3500, // km or miles depending on length units
    MIN_RADIUS: 0.5,
};

export const MAP_THEMES = {
    EXPLORER_DARK: 'EXPLORER_DARK',
    EXPLORER_LIGHT: 'EXPLORER_LIGHT',
    MAP_EDITOR: 'MAP_EDITOR',
    DSM: 'DSM',
    PM: 'PM',
};

export const MAPBOX_STYLES_TYPES = {
    EDITOR: 'editor',
    LIGHT: 'light',
    DARK: 'dark',
};
/* MAPSTYLES:
 * googlemaps = 'mapbox://styles/pamsupport/ck37xdi9s01wt1clsxxd7qr0u',
 * monochrome = 'mapbox://styles/pamsupport/ck75ghs6x1o4f1illjqv4de3d',
 * dark = 'mapbox://styles/pamsupport/cjt3ownqj2xx31fqswd86aai6'
 */
export const MAPBOX_STYLES = {
    [MAPBOX_STYLES_TYPES.EDITOR]: 'mapbox://styles/pamsupport/ck75ghs6x1o4f1illjqv4de3d',
    [MAPBOX_STYLES_TYPES.LIGHT]: 'mapbox://styles/pamsupport/ck381k78z00ri1ctdvgvavwpj',
    [MAPBOX_STYLES_TYPES.DARK]: 'mapbox://styles/pamsupport/cjt3ownqj2xx31fqswd86aai6',
};

export const MAP_LIGHT_INTENSITY = {
    EXPLORER_DARK: 1.7,
    EXPLORER_LIGHT: 2.35,
    MAP_EDITOR: 2.35,
};

export const DEVICE_TYPES = {
    CONNECT: 'CONNECT', // a fixed device owned by a client
    USER: 'EXPLORER', // a user's phone or computer
    PREVIEW: 'Preview', // preview mode, launched from PAM OS
};

export const ACTION_TYPES = {
    SET_MAPBOX_STYLES: 'SET_MAPBOX_STYLES',
    ADD_TO_REDO: 'ADD_TO_REDO',
    ADD_TO_UNDO: 'ADD_TO_UNDO',
    SET_CLIENT_RELATIONSHIP: 'SET_CLIENT_RELATIONSHIP',
    ME: {
        SET_READONLY: 'ME.SET_READONLY',
        SET_IS_BASE_NAVMAP_READONLY: 'ME.SET_IS_BASE_NAVMAP_READONLY',
        SET_SELECTED_FEATURE: 'ME.SET_SELECTED_FEATURE',
        SET_SELECTED_TAB: 'ME.SET_SELECTED_TAB',
        SET_MAP_CENTER_LNGLAT: 'ME.SET_MAP_CENTER_LNGLAT',
    },
    DSM: {
        SET_AUTOCOMPLETE_LOCATIONS: 'DSM.SET_AUTOCOMPLETE_LOCATIONS',
        SET_CONTENT_SOURCES: 'DSM.SET_CONTENT_SOURCES',
        SET_PAGE_STATE: 'DSM.SET_PAGE_STATE',
        SET_SELECTED_LAYOUT: 'DSM.SET_SELECTED_LAYOUT',
        TOGGLE_MAP_CONFIG_POSITIONING: 'DSM.TOGGLE_MAP_CONFIG_POSITIONING',
        UPDATE_LAYOUT: 'DSM.UPDATE_LAYOUT',
        UPDATE_SIGN: 'DSM.UPDATE_SIGN',
        UPDATE_SIGN_PROPS: 'DSM.UPDATE_SIGN_PROPS',
        SET_ADVISORY_MESSAGING_DICTIONARY: 'DSM.SET_ADVISORY_MESSAGING_DICTIONARY',
        SET_READONLY: 'DSM.SET_READONLY',
        SET_PREVIEW_DATA: 'DSM.SET_PREVIEW_DATA',
        SET_PREVIEW_IS_READY: 'SET_PREVIEW_IS_READY',
        SET_PREVIEW_REACTIVE_CONTENT: 'SET_PREVIEW_REACTIVE_CONTENT',
        SET_SAVE_DISABLED: 'DSM.SET_SAVE_DISABLED',
    },
    LOADED_DICTIONARY_ITEMS: 'LOADED_DICTIONARY_ITEMS',
    LOADED_TENANTS: 'LOADED_TENANTS',
    LOADED_SEARCH_FILTERS: 'LOADED_SEARCH_FILTERS',
    LOADED_LAYOUT_CONFIG: 'LOADED_LAYOUT_CONFIG',
    FAILED_LOADING_LAYOUT_CONFIG: 'FAILED_LOADING_LAYOUT_CONFIG',
    LOADED_LOCATIONS: 'LOADED_LOCATIONS',
    LOADED_NAV_MAP: 'LOADED_NAV_MAP',
    LOADED_CLIENT_NAV_MAP: 'LOADED_CLIENT_NAV_MAP',
    UPDATE_NAV_MAP: 'UPDATE_NAV_MAP',
    SET_MAP_CENTER: 'SET_MAP_CENTER',
    LOADED_NAV_MAP_OVERRIDES: 'LOADED_NAV_MAP_OVERRIDES',
    LOADED_PAM_OS_ENABLED_FEATURES: 'LOADED_PAM_OS_ENABLED_FEATURES',
    LOADED_PARKING_LOT_CONFIG: 'LOADED_PARKING_LOT_CONFIG',
    LOADED_SCENES: 'LOADED_SCENES',
    PM: {
        SET_PAGE_STATE: 'PM.SET_PAGE_STATE',
        UPDATE_PARKING_CONFIGS: 'PM.UPDATE_PARKING_CONFIGS',
        UPDATE_PARKING_SIGN_FEATURES: 'PM.UPDATE_PARKING_SIGN_FEATURES',
        SET_READONLY: 'PM.SET_READONLY',
    },
    EXPLORER: {
        SET_LOADING: 'SET LOADING',
        SET_LOCATION_TOO_FAR: 'SET_LOCATION_TOO_FAR',
        CLEAR_PROMOTIONS: 'CLEAR PROMOTIONS',
        CLEAR_PROMOTIONS_COUNT: 'CLEAR PROMOTIONS COUNT',
        ADD_PROMOTIONS: 'ADD PROMOTIONS',
        SET_SELECTED_PROMOTION: 'SET_SELECTED_PROMOTION',
        SET_SELECTED_CONTENT_SOURCE: 'SET_SELECTED_CONTENT_SOURCE',
        RESET_SELECTED_CONTENT_SOURCE: 'RESET_SELECTED_CONTENT_SOURCE',
        ADD_PROMOTIONS_COUNT: 'ADD PROMOTIONS COUNT',
        SET_EXPLORER_LOADED: 'SET_EXPLORER_LOADED',
        USER_LOCATION_AVAILABLE: 'USER LOCATION AVAILABLE',
    },
    RESET_JOURNEY: 'RESET_JOURNEY',
    ROUTING_READY: 'ROUTING_READY',
    SET_ACTIVE_SCENE: 'SET_ACTIVE_SCENE',
    CHANGE_ACTIVE_SCENE: 'CHANGE_ACTIVE_SCENE',
    SET_DEVICE_DATA: 'SET_DEVICE_DATA',
    SET_EDIT_MODE: 'SET_EDIT_MODE',
    PUSH_NAVIGATION_HISTORY: 'PUSH_NAVIGATION_HISTORY',
    POP_NAVIGATION_HISTORY: 'POP_NAVIGATION_HISTORY',
    SET_HAS_UNSAVED_CHANGES: 'SET_HAS_UNSAVED_CHANGES',
    SET_INITIAL_MAP_THEME: 'SET_INITIAL_MAP_THEME',
    SET_IS_MAP_UPDATE_AVAILABLE: 'SET_IS_MAP_UPDATE_AVAILABLE',
    SET_JOURNEY_END: 'SET_JOURNEY_END',
    SET_IS_SEARCHING_ROUTE: 'SET_IS_SEARCHING_ROUTE',
    SET_JOURNEY_STAGE: 'SET_JOURNEY_STAGE',
    SET_JOURNEY_STOPS: 'SET_JOURNEY_STOPS',
    SET_LAST_KNOWN_FEATURES: 'SET_LAST_KNOWN_FEATURES',
    SET_NAV_MAP_CONFIG: 'SET_NAV_MAP_CONFIG',
    SET_NAV_MODE: 'SET_NAV_MODE',
    SET_SELECTED_MENU: 'SET_SELECTED_MENU',
    SET_PROP_ON_STOP: 'SET_PROP_ON_STOP',
    SET_REDO_STACK: 'SET_REDO_STACK',
    SET_ROUTE_PARAMETERS: 'SET_ROUTE_PARAMETERS',
    SET_SEARCH_LOCATION: 'SET_SEARCH_LOCATION',
    SET_SELECTED_SCENE: 'SET_SELECTED_SCENE',
    SET_STOP_VISITED: 'SET_STOP_VISITED',
    SET_TRAVEL_TIME: 'SET_TRAVEL_TIME',
    SET_DISTANCE: 'SET_DISTANCE',
    SET_ROUTE_LINESTRING: 'SET_ROUTE_LINESTRING',
    SET_TAKE_ME_THERE_EVENT_ID: 'SET_TAKE_ME_THERE_EVENT_ID',
    SET_UNDO_STACK: 'SET_UNDO_STACK',
    PUSH_TO_CHANGE_STACK: 'PUSH_TO_CHANGE_STACK',
    POP_FROM_CHANGE_STACK: 'POP_FROM_CHANGE_STACK',
    SET_CHANGE_STACK: 'SET_CHANGE_STACK',
    UNDOABLE_ENHANCER: {
        INIT: 'UNDOABLE_ENHANCER.INIT',
        REDO: 'UNDOABLE_ENHANCER.REDO',
        UNDO: 'UNDOABLE_ENHANCER.UNDO',
    },
    NAVIGATE: 'NAVIGATE',
    SET_SEARCH: 'SET_SEARCH',
    SET_SCAN_LOCATION: 'SET_SCAN_LOCATION',
    SET_QUICKLINK: 'SET_QUICKLINK',
    HIDE_CARD: 'HIDE_CARD',
    RESET_BY_TIMEOUT: 'RESET_BY_TIMEOUT',
    SELECT_ROUTE_OPTION: 'SELECT_ROUTE_OPTION',
    SET_PATH_FOUND: 'SET_PATH_FOUND',
    SET_NIGHT_SAFE_PATH_SEARCH: 'SET_NIGHT_SAFE_PATH_SEARCH',
    SET_SESSION_EXPIRED: 'SET_SESSION_EXPIRED',
    SET_COMMON_SIGN_DATA: 'SET_COMMON_SIGN_DATA',
    SET_DIGITAL_SIGN_DATA: 'SET_DIGITAL_SIGN_DATA',
};

// Used @@ in front to make the key difficult to access or overwritten.
// Only used in undoableEnhancer, not important in kiosk
export const UNDOABLE_PROP_PATH_KEY = '@@PROP_PATH';

export const SOURCES = {
    LABELS: 'LABELS',
    NAV_MAP: 'NAV_MAP',
    ROUTES: 'ROUTES',
    ENTRANCE_LABELS: 'ENTRANCE_LABELS',
    DISTANCE_RING: 'DISTANCE_RING',
    MAP_BOUNDARY: 'MAP_BOUNDARY',
    SEARCH_BOUNDARY: 'SEARCH_BOUNDARY',
    SELECTED_FEATURES: 'SELECTED_FEATURES',
};

export const MAP_LAYERS = {
    LABELS: 'LABELS',
    PARKING_LABELS_FROM_ANOTHER_CLIENT: 'PARKING_LABELS_FROM_ANOTHER_CLIENT',
    PARKING_LABELS_FROM_ANOTHER_CLIENT_HAS_CONFIG: 'PARKING_LABELS_FROM_ANOTHER_CLIENT_HAS_CONFIG',
    DIGITAL_SIGNS: 'DIGITAL_SIGNS',
    OBJECT3D: 'OBJECT3D',
    SIGN_LABELS: 'SIGN_LABELS',
    SIGN_LABELS_FROM_ANOTHER_CLIENT: 'SIGN_LABELS_FROM_ANOTHER_CLIENT',
    ENTRANCE_LABELS: 'ENTRANCE_LABELS',
    MAP_BOUNDARY: 'MAP_BOUNDARY',
    SEARCH_BOUNDARY: 'SEARCH_BOUNDARY',
    SEARCH_BOUNDARY_FILL: 'SEARCH_BOUNDARY_FILL',
    SEARCH_BOUNDARY_LABEL: 'SEARCH_BOUNDARY_LABEL',
    SELECTED_FEATURE_LINE: 'SELECTED_FEATURE_LINE',
    SELECTED_FEATURE_FILL: 'SELECTED_FEATURE_FILL',
    SELECTED_FEATURE_FILL_EXTRUSION: 'SELECTED_FEATURE_FILL_EXTRUSION',
};

export const MARKER_LAYERS = {
    SEARCH_LOCATIONS: 'SEARCH_LOCATIONS',
    SEARCH_LOCATION_LIST: 'SEARCH_LOCATION_LIST',
    SCAN_LOCATION: 'SCAN_LOCATION',
    KIOSK_LOCATION: 'KIOSK_LOCATION',
};

// Used for PAM and Mapbox routing, but the strings must be a valid Mapbox directions 'profile'
export const TRAVEL_MODES = {
    DRIVING: 'driving-traffic',
    CYCLING: 'cycling',
    WALKING: 'walking',
};

export const ROUTE_OPTIONS_TO_TRAVEL_MODES = {
    CYCLE: TRAVEL_MODES.CYCLING,
    DRIVE: TRAVEL_MODES.DRIVING,
    WALK: TRAVEL_MODES.WALKING,
    WHEELCHAIR: TRAVEL_MODES.WALKING,
};

export const ROUTE_STYLES = {
    WALKING_ROUTE: 'WALKING_ROUTE', // Includes cycling, skateboarding, rollerskating, etc
    DRIVING_ROUTE: 'DRIVING_ROUTE',
};

export const JOURNEY_STAGES = {
    VIEW: 'VIEW', // The user can see the journey route on the screen
    EN_ROUTE: 'EN_ROUTE', // The user is following the directions to the destination
    FINISHED: 'FINISHED', // The user has indicated that they have arrived at the destination
};

export const LOCATION_TYPES = {
    PAM: 'PAM', // a location from the PAM database
    MAPBOX: 'MAPBOX', // a location from Mapbox
};

export const MAP_ELEMENT_ID = 'explorer-map';
export const EDIT_ELEMENT_ID = 'map-editor-map';

export const KEYS = {
    ENTER: 13,
    TAB: 9,
    ESC: 27,
    LEFT: 37,
    DOWN: 40,
    RIGHT: 39,
    UP: 38,
    ONE: 49,
    TWO: 50,
    THREE: 51,
    Z: 90,
    DELETE: 46,
    BACKSPACE: 8,
};

export const GEOMETRY_TYPES = {
    POLYGON: 'Polygon',
    LINE_STRING: 'LineString',
    MULTI_LINE_STRING: 'MultiLineString',
    POINT: 'Point',
};

export const KEYBOARD_POSITIONS = {
    BOTTOM_LEFT: 'bottom-left',
};

export const DSM_TABS = {
    LAYOUTS: 'LAYOUTS',
    SIGN_MAP: 'SIGN_MAP',
};

export const MAP_EDITOR_TABS = {
    EDIT: 'EDIT',
    PREVIEW: 'PREVIEW',
};

export const DSM_PREVIEW_MODE = {
    AS_INSTALLED: 'AS_INSTALLED',
    REQUESTED_CHANGES: 'REQUESTED_CHANGES',
};

export const NAV_MODES = {
    SEARCH: 'SEARCH',
    DIRECTIONS: 'DIRECTIONS',
};

export const PAM_OS_MESSAGE_TYPES = {
    MAP_EDITOR_LOAD_DATA: 'MAP_EDITOR_LOAD_DATA',
    MAP_EDITOR_LOAD_NAVMAP: 'MAP_EDITOR_LOAD_NAVMAP',
    MAP_EDITOR_SAVE_DATA: 'MAP_EDITOR_SAVE_DATA',
    DSM_SAVE_DATA: 'DSM_SAVE_DATA',
    DSM_LOAD_DATA: 'DSM_LOAD_DATA',
    LOAD_SCENES: 'LOAD_SCENES',
    PARKING_MANAGER_SAVE_DATA: 'PARKING_MANAGER_SAVE_DATA',
    PARKING_MANAGER_LOAD_DATA: 'PARKING_MANAGER_LOAD_DATA',
    CHANGE_ACTIVE_SCENE: 'CHANGE_ACTIVE_SCENE',
    UNMOUNT: 'UNMOUNT',
    DSM_PREVIEW: 'DSM_PREVIEW',
    STAY_LOGGED_IN: 'STAY_LOGGED_IN',
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    SET_SELECTED_ITEM_ID: 'SET_SELECTED_ITEM_ID',
    SET_SELECTED_SCENE_ID: 'SET_SELECTED_SCENE_ID',
};

export const STORAGE_KEYS = {
    ROUTE_PARAMETERS: 'ROUTE_PARAMETERS',
};

export const LABEL_DISPLAY_TYPES = {
    BOTH: 'both',
    ICON: 'icon',
    TEXT: 'text',
    NONE: 'none',
};

export const NAV_MAP_DEFAULT_CONFIG = {
    navMapShowZoom: 10,
    worldHideZoom: 24,
    defaultNavMapZoom: 16,
    defaultNavMapPitch: 45,
    defaultRotation: 0,
    maxNavMapZoom: 24,
    minNavMapZoom: 12,
    center: '',
    mapBoundaryRadius: '5',
};

export const ERRORS = {
    WEB_GL_NOT_SUPPORTED: 'WEB_GL_NOT_SUPPORTED',
    INVALID_SIGN_DATA: 'INVALID_SIGN_DATA',
    INVALID_PARKING_SIGN_TYPE: 'INVALID_PARKING_SIGN_TYPE',
};

export const INPUT_EL_TYPES = {
    CHECKBOX: 'CHECKBOX',
    INPUT: 'INPUT',
    INPUT_AUTOCOMPLETE: 'INPUT_AUTOCOMPLETE',
    INPUT_COLOR_PICKER: 'INPUT_COLOR_PICKER',
    INPUT_IMAGE: 'INPUT_IMAGE',
    INPUT_SLOT: 'INPUT_SLOT',
    LOCATIONS: 'LOCATIONS',
    RADIO_GROUP: 'RADIO_GROUP',
    SELECT: 'SELECT',
    SELECT_ICON: 'SELECT_ICON',
    SLIDER: 'SLIDER',
    SWITCH: 'SWITCH',
};

export const COLOUR_PICKER_TYPES = {
    SWATCH: 'SWATCH',
    SVG: 'SVG',
};

export const INDENT_VALUES = {
    INCREASED: 'INCREASED',
    DECREASED: 'DECREASED',
};

export const FONT_SIZES = {
    DEFAULT: 'default',
    LARGE: 'lg',
    SMALL: 'sm',
};

// Layout IDs, as they exist in the database. All layout IDs must exist here
// WARNING Don't change these values
// @see Layouts entity for more details
export const LAYOUT_IDS = {
    KIOSK: '15f9aa18-e61e-4888-8da1-62ebd45646e8',
    HPK_DDS: '25f9aa18-e61e-4888-8da1-62ebd45646e8',
    HPK_PARKING_LOT: '35f9aa18-e61e-4888-8da1-62ebd45646e8',
    EXPERIENCES: '45f9aa18-e61e-4888-8da1-62ebd45646e8',
    QR_CODE: '55f9aa18-e61e-4888-8da1-62ebd45646e8',
};

export const NO_SIGN_ID = 'no-sign-id';

export const POLL_TIMEOUT = 5000; // This is usually 10000, but for the demo it's 5000

export const PARKING_STATUS_OPTION = [
    {code: 'OPEN', name: 'OPEN'},
    {code: 'FULL', name: 'FULL'},
    {code: 'CLOSED', name: 'CLOSED'},
    {code: 'RESERVED', name: 'RESERVED'},
];

export const PARKING_RATE_PERIOD_OPTIONS = [{code: 'hr', name: 'hr'}, {code: 'day', name: 'day'}];

export const PARKING_MANAGER_STATUS_COLOURS = {
    OPEN: {
        label: 'OPEN',
        color: '#00984A',
    },
    CLOSED: {
        label: 'CLOSED',
        color: '#D88D1C',
    },
    FULL: {
        label: 'FULL',
        color: '#D81C1C',
    },
    RESERVED: {
        label: 'RESERVED',
        color: '#2184CE',
    },
};

export const DSM_STATUS_COLOURS = {
    OK: {
        label: 'OK',
        color: '#000',
    },
    PENDING_CHANGES: {
        label: 'PENDING CHANGES',
        color: '#FA8632',
    },
    NOT_CONFIGURED: {
        label: 'NOT CONFIGURED',
        color: '#D81C1C',
    },
    SCENE_OVERRIDE: {
        label: 'SCENE OVERRIDE',
        color: '#0BAD5C',
    },
};

export const EXPLORER_PARKING_STATUS_COLOURS = {
    CLOSED: '#D88D1C',
    RESERVED: '#2184CE',
    FULL: '#D81C1C',
    OPEN: '#00984A',
};

export const DEFAULT_PARKING_LOT_CONFIG = {
    availableSpaces: 1,
    status: PARKING_STATUS_OPTION[0].code,
    rates: {
        rate0: {
            rate: 10,
            ratePeriod: PARKING_RATE_PERIOD_OPTIONS[0].code,
        },
        rate1: {
            rate: 10,
            ratePeriod: PARKING_RATE_PERIOD_OPTIONS[0].code,
        },
    },
};

export const TEST_SIGN_IDS = {
    KIOSK: '8cc0b913-5561-409b-89ba-f8fe2b89b776',
    LANDSCAPE_KIOSK: '9cc0b913-5561-409b-89ba-f8fe2b89b776',
    KIOSK_WITH_DIRECTIONS: '939145df-c120-4708-9207-df2dn1b93711',
    HPK_DDS: '9990b913-5561-409b-89ba-f8fe2b89b776',
    HPK_DDS_ARROW_INHERITANCE: '8880b913-5561-409b-89ba-f8fe2b89b776',
    HPK_DDS_NO_DICTIONARY: `${NO_SIGN_ID}-7`,
    HPK_PARKING_LOT: '71979039-cd7a-4708-8e35-5c1b3879c505',
};

export const LIVE_OVERRIDE_MODE = {
    id: 'live-overrides',
    isLiveOverride: true,
    isActive: false,
    isDefault: false,
    name: 'Live Overrides',
};

export const LIVE_OVERRIDE_PARKING_PROPERTIES = ['availableSpaces', 'status'];

export const PAM_OS_ENABLED_FEATURES_KEYS = {
    MAPBOX_EXTRUDE_BUILDINGS_LAYER: 'mapbox.extrude_buildings_layer',
};

export const TEST_PAM_VERSION = 'pam-v4';

export const CONFIG_DATA_BASE_KEYS = {
    WITH_INHERITANCE: 'withInheritance',
    WITHOUT_INHERITANCE: 'withoutInheritance',
};

export const EXPLORER_MENU_DEFAULT_COLOR = {
    CTA: '#0783DE',
};

export const MENU_DEFAULT_LABELS = {
    SEARCH: 'Search',
    EXPLORE: 'Explore',
    EVENTS: 'Experiences',
    TENANTS: 'Directory',
    PROMOTIONS: 'Promotions',
    DIRECTIONS: 'Directions',
};

export const MENU_DEFAULT_LABELS_BG_COLOURS = {
    SEARCH: '#3385FF',
    EXPLORE: '#000000',
    EVENTS: '#FF8933',
    TENANTS: '#6633FF',
    PROMOTIONS: '#FF3370',
    DIRECTIONS: '#9933FF',
};

export const RENDER_NAV_MAP_TO_MAP_ACTIONS = {
    INITIALISE: 'INITIALISE',
    UPDATE: 'UPDATE',
};

export const ROUTE_OPTIONS = {
    CYCLE: 'CYCLE',
    DRIVE: 'DRIVE',
    WALK: 'WALK',
    WHEELCHAIR: 'WHEELCHAIR',
};

export const ROUTE_OPTIONS_INTERNAL = Object.keys(lodashOmit(ROUTE_OPTIONS, [ROUTE_OPTIONS.CYCLE]));

export const ROUTE_OPTIONS_EXTERNAL = Object.keys(ROUTE_OPTIONS);

export const IMPERIAL = 'imperial';

export const METRIC = 'metric';

export const CARD_NAV_LOCATION = 'featureSummary';

export const RELATIONSHIP_TYPE = {
    NONE: 'NONE',
    PARENT: 'PARENT',
    CHILD: 'CHILD',
    INVALID: 'INVALID RELATIONSHIP',
};

export const WALK_SPEED_KPH = 5;

export const WALK_SPEED_MPS = (WALK_SPEED_KPH * 1000) / (60 * 60);

export const LOCATION_CONTROL_TYPES = {
    DIRECTION: 'DIRECTION',
    MESSAGE: 'MESSAGE',
};

export const routeOptionsWithIcon = [
    {type: ROUTE_OPTIONS.DRIVE, icon: DirectionsCar},
    {type: ROUTE_OPTIONS.CYCLE, icon: DirectionsBike},
    {type: ROUTE_OPTIONS.WALK, icon: DirectionsWalk},
    {type: ROUTE_OPTIONS.WHEELCHAIR, icon: Accessible},
];

export const PAM_WELCOME_LOGO = 'images/preloadingLogo.svg';

export const PAM_BANNER_LOGO = 'images/pam-logo-2.svg';

export const PORTRAIT_ONLY = 'images/portraitOnly.svg';

export const MY_LOCATION_FEATURE_ID = 'myLocationFeatureID';

export const SELECTED_LOCATION_FEATURE_ID = 'selectedLocationFeatureID';

export const MY_LOCATION_DISPLAY_NAME = 'My Location';

export const STARTING_LOCATION_DISPLAY_NAME = 'Starting Location';

export const SELECTED_LOCATION_DISPLAY_NAME = 'Selected Location';

export const START_LOCATION_DISPLAY_NAME = 'Scanned Location';

export const SEARCH_FILTERS_MAX = 8;

export const SEARCH_BOUNDARY_RADIUS_RATIO = 0.9;

export const MAPBOX_MAX_PITCH = 65;

export const PAGE_WRAPPER_CONSTANTS = {
    DSM: {
        COMPONENT_NAME: 'DSM',
        TITLE: 'Digital Signs',
    },
    MAP_EDITOR: {
        COMPONENT_NAME: 'MapEditor',
        TITLE: 'MAP EDITOR',
    },
    PARKING_MANAGER: {
        COMPONENT_NAME: 'ParkingManager',
        TITLE: 'Parking',
    },
};

export const SLOT_ITEM = {
    MESSAGE: 'M',
    LOCATION: 'L',
};

export const MY_LOCATION_MARKER_PADDING_BOTTOM = 72 * 3;
export const DISTANCE_TO_END_JOURNEY_THRESHOLD_METERS = 15;

export const PAM_SELECTED_LOCATION_ZOOM = 18;
export const MAPBOX_SELECTED_LOCATION_ZOOM = 16;

export const LABEL_SIZES = {
    REGULAR: 'regular',
    MEDIUM: 'medium',
    LARGE: 'large',
};
