import fetchFromApi from './fetchFromApi';

/**
 * This function is for fetching mock data from array of mock files
 * @param urls array of strings - urls to mock files
 * @param oldEtags array of strings - ETags for each files or null if there is no ETags
 * @returns {Promise<{responses: *[], etags: *[]}|{etags: *[]}>}
 */
const fetchMocks = async (urls, oldEtags = null) => {
    let responses = await Promise.all(
        urls.map((url, index) => fetchFromApi(url, oldEtags ? {etag: oldEtags[index]} : {}))
    );

    let etags = responses.map(response => (response.headers ? response.headers.etag : null));

    if (responses.map(response => response.body).every(body => !body)) {
        return {etags};
    }
    if (responses.map(response => response.body).every(body => !!body)) {
        return {responses, etags};
    }

    responses = await Promise.all(urls.map(url => fetchFromApi(url)));

    etags = responses.map(response => (response.headers ? response.headers.etag : null));

    return {responses, etags};
};

export default fetchMocks;
