import mapboxGl from 'mapbox-gl';
import lodashDifferenceBy from 'lodash/differenceBy';
import {MAP_LAYERS, MAP_LIGHT_INTENSITY, MAP_THEMES} from 'Utils/constants';
import {degreesToRadians} from 'Utils/math';
import * as mapManager from 'Utils/explorer/mapManager';
import log from 'Utils/log';
import {prependDigitalAssetsUrl} from 'Utils/prependDigitalAssetsUrl';

// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxGl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

let scenes3d = [];
let camera;
let renderer;
let THREE;
let GLTFLoader;
let featureCollections = [];

export const get3dObjects = () => scenes3d;
/**
 * this function tells rest of the app if 3d dependencies are loaded
 */
export const is3dInitialized = () => !!THREE && !!GLTFLoader;

// eslint-disable-next-line no-return-assign
export const setFeatureCollections = _featureCollections =>
    (featureCollections = _featureCollections);

const getObject3dFeatures = () =>
    featureCollections.features.filter(feature => feature.properties.isObject3d);

const getModelTransform = feature => {
    const {properties} = feature;
    const modelOrigin = feature.geometry.coordinates;
    const translateX = mapboxGl.MercatorCoordinate.fromLngLat(modelOrigin, 0).x;
    const translateY = mapboxGl.MercatorCoordinate.fromLngLat(modelOrigin, 0).y;
    const translateZ = mapboxGl.MercatorCoordinate.fromLngLat(modelOrigin, 0).z;
    return {
        translateX,
        translateY,
        translateZ,
        rotateX: degreesToRadians(90),
        rotateY: degreesToRadians(properties.object3dRotation),
        rotateZ: 0,
        scale: properties.object3dScale,
    };
};

const resizeImage = image => {
    const scale = 1;
    const width = THREE.Math.floorPowerOfTwo(scale * image.width);
    const height = THREE.Math.floorPowerOfTwo(scale * image.height);
    if (width === image.width && height === image.height) {
        return image;
    }
    if (
        (typeof HTMLImageElement !== 'undefined' && image instanceof HTMLImageElement) ||
        (typeof HTMLCanvasElement !== 'undefined' && image instanceof HTMLCanvasElement) ||
        (typeof ImageBitmap !== 'undefined' && image instanceof ImageBitmap)
    ) {
        document.createElementNS('http://www.w3.org/1999/xhtml', 'canvas');

        const canvas = document.createElementNS('http://www.w3.org/1999/xhtml', 'canvas');

        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext('2d', {willReadFrequently: true});
        context.drawImage(image, 0, 0, width, height);
        return canvas;
    }
    return image;
};

const get3dObjectScene = (feature, mapTheme = MAP_THEMES.MAP_EDITOR) => {
    const scene = new THREE.Scene();
    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x666666, MAP_LIGHT_INTENSITY[mapTheme]);
    hemiLight.position.set(0, 500, 1000);

    scene.add(hemiLight);
    const loader = new GLTFLoader();
    loader.load(prependDigitalAssetsUrl(feature.properties.object3dUrl), modelFile => {
        // eslint-disable-next-line
        modelFile.scene.name = 'model';
        const model = modelFile.scene.getObjectByName('model');

        model.traverse(element => {
            if ('material' in element) {
                if (element.material.map && element.material.map.image) {
                    // eslint-disable-next-line
                    element.material.map.image = resizeImage(element.material.map.image);
                } else if (element.material.length > 1) {
                    element.material.forEach(m => {
                        if (m.map && m.map.image) {
                            // eslint-disable-next-line
                            m.map.image = this.resizeImage(m.map.image);
                        }
                    });
                }
            }
        });

        scene.add(modelFile.scene);
    });
    scene.hemiLight = hemiLight;
    return scene;
};

export const update3dFeatureObject = feature => {
    // update scene3d object here, for the feature being updated
    scenes3d = scenes3d.map(sceneFeature => {
        if (sceneFeature.id === feature.id) {
            // eslint-disable-next-line
            sceneFeature.transform = getModelTransform(feature);
            // eslint-disable-next-line
            sceneFeature.properties = feature.properties;
        }
        return sceneFeature;
    });
};

export const add3dFeatureObject = (feature, mapTheme) => {
    const scene = get3dObjectScene(feature, mapTheme);
    const modelTransform = getModelTransform(feature);

    scenes3d.push({
        id: feature.id,
        scene,
        transform: modelTransform,
        properties: feature.properties,
        geometry: feature.geometry,
    });
};

export const delete3dFeatureObject = feature => {
    scenes3d = scenes3d.filter(item => item.id !== feature.id);
};

// /**
//  * This function loads checks and loads 3d model for selected feature on map editor
//  * @param {object} feature
//  */

export const maybeLoad3dModelForEditor = feature => {
    if (feature.properties.object3dUrl && !feature.properties.object3dHide) {
        add3dFeatureObject(feature);
    }
};

/**
 * This function loads 3d models only for object3d features
 * in view
 * @param mapTheme
 */
const load3dModelsInViewport = mapTheme => {
    getObject3dFeatures().forEach(feature => {
        if (
            // add only if feature has model url
            feature.properties.object3dUrl &&
            // add only if in view
            mapManager.mapContainsCoordinates(feature.geometry.coordinates)
        ) {
            let currentFeature3d = scenes3d.find(sceneObject => sceneObject.id === feature.id);

            const hasObject3dUrlChanged =
                currentFeature3d?.properties?.id &&
                currentFeature3d?.properties?.id === feature?.properties?.id &&
                currentFeature3d?.properties?.object3dUrl !== feature?.properties?.object3dUrl;

            if (hasObject3dUrlChanged) {
                delete3dFeatureObject(currentFeature3d);
                add3dFeatureObject(feature);
                currentFeature3d = scenes3d.find(sceneObject => sceneObject.id === feature.id);
            }

            if (currentFeature3d) {
                currentFeature3d.scene.hemiLight.intensity = MAP_LIGHT_INTENSITY[mapTheme];
            } else {
                // add only if the model isn't already loaded
                add3dFeatureObject(feature, mapTheme);
            }
        }
    });
};

const load3dDependencies = async () => {
    await import('Utils/explorer/map3dModule')
        .then(module => {
            ({THREE, GLTFLoader} = module.default);
        })
        .catch(e => {
            log.warn('Could not load 3d dependencies', e);
        });
};

const getObjectMatrix = (matrix, transform) => {
    const rotationX = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(1, 0, 0),
        transform.rotateX
    );
    const rotationY = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(0, 1, 0),
        transform.rotateY
    );
    const rotationZ = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(0, 0, 1),
        transform.rotateZ
    );

    const inputMatrix = new THREE.Matrix4().fromArray(matrix);
    const transformedMatrix = new THREE.Matrix4()
        .makeTranslation(transform.translateX, transform.translateY, transform.translateZ)
        .scale(new THREE.Vector3(transform.scale, -transform.scale, transform.scale))
        .multiply(rotationX)
        .multiply(rotationY)
        .multiply(rotationZ);
    return {inputMatrix, transformedMatrix};
};

const setSceneOpacity = (scene, properties, zoom) => {
    /* eslint-disable no-param-reassign */
    const model = scene.getObjectByName('model');

    if (model) {
        if (
            (properties.object3dFadeStart && zoom - properties.object3dDisplayStartZoom < 1) ||
            (properties.object3dFadeEnd && properties.object3dDisplayEndZoom - zoom < 1)
        ) {
            model.traverse(element => {
                if ('material' in element) {
                    element.material.transparent = true;
                    element.material.opacity =
                        ((zoom - properties.object3dDisplayStartZoom < 1
                            ? zoom - properties.object3dDisplayStartZoom
                            : properties.object3dDisplayEndZoom - zoom) /
                            100) *
                        properties.object3dMaxOpacity;
                }
            });
        } else if (
            zoom > properties.object3dDisplayStartZoom &&
            zoom < properties.object3dDisplayEndZoom
        ) {
            model.traverse(element => {
                if ('material' in element) {
                    element.material.transparent = true;
                    element.material.opacity = properties.object3dMaxOpacity / 100;
                }
            });
        }
    }
    /* eslint-enable no-param-reassign */
};

/**
 * This function loads 3d dependencies, if not already & also adds 3d layer
 *
 * @param {object} props
 * @param {object} props.mapObject
 * @param {boolean} [props.isMapEditor]
 */
export const maybeInitializeAndAdd3dLayer = async ({mapObject, isMapEditor = false}) => {
    if (!is3dInitialized()) {
        await load3dDependencies();
    }
    try {
        if (!mapObject.getLayer(MAP_LAYERS.OBJECT3D)) {
            const onObject3dAdd = (map, gl) => {
                camera = new THREE.Camera();
                renderer = new THREE.WebGLRenderer({
                    canvas: map.getCanvas(),
                    context: gl,
                });
                renderer.autoClear = false;
            };

            const object3dRender = (gl, matrix) => {
                const zoom = mapObject.getZoom();
                if (scenes3d.length) {
                    renderer.state.reset();
                    scenes3d.forEach(({scene, transform, properties, geometry}) => {
                        if (
                            // for mapEditor, we bail out if a 3d model is marked
                            // hidden by map author
                            (isMapEditor && (properties.object3dHide || properties.doNotDraw)) ||
                            // we also bail out if a 3d model's center is not in viewport
                            !mapManager.mapContainsCoordinates(geometry.coordinates) ||
                            (!isMapEditor && properties.object3dHide) ||
                            properties.disabledByParent
                        ) {
                            return;
                        }
                        setSceneOpacity(scene, properties, zoom);

                        if (
                            zoom > properties.object3dDisplayStartZoom &&
                            zoom < properties.object3dDisplayEndZoom
                        ) {
                            const {inputMatrix, transformedMatrix} = getObjectMatrix(
                                matrix,
                                transform
                            );

                            camera.projectionMatrix.elements = matrix;
                            camera.projectionMatrix = inputMatrix.multiply(transformedMatrix);
                            renderer.render(scene, camera);
                        }
                    });
                }
            };

            // add object3dLayer
            mapObject.addLayer({
                id: MAP_LAYERS.OBJECT3D,
                source: 'composite',
                type: 'custom',
                renderingMode: '3d',
                onAdd: onObject3dAdd,
                render: object3dRender,
            });
        }
    } catch (e) {
        //
    }
};

/**
 * This function loads & initializes 3d dependencies, if not already.
 * Also adds 3d layer
 * And loads 3d models from feature collection
 *
 * @param {object} props
 * @param {featureCollections} props.featureCollection
 * @param {boolean} props.isMapEditor
 * @param {string} props.mapTheme
 */
export const maybeInitializeAndLoad3dModels = async ({
    featureCollection,
    isMapEditor = false,
    mapTheme,
}) => {
    setFeatureCollections(featureCollection);
    const mapObject = mapManager.getMap();

    if (mapObject && getObject3dFeatures().length) {
        await maybeInitializeAndAdd3dLayer({mapObject, isMapEditor});
        if (!isMapEditor) {
            // if explorer, we bind a load handler to `moveend`
            mapManager.getMap().on('moveend', () => {
                load3dModelsInViewport(mapTheme);
            });
            load3dModelsInViewport(mapTheme);
        }
    }
};

/**
 * This function add, updates and removed the 3d dependencies
 *
 * @param {object} props
 * @param {featureCollections} props.featureCollection
 * @param {string} props.mapTheme
 */
export const update3dModels = async ({featureCollection, mapTheme}) => {
    setFeatureCollections(featureCollection);
    if (getObject3dFeatures().length) {
        await maybeInitializeAndAdd3dLayer({mapObject: mapManager.getMap()});
        load3dModelsInViewport(mapTheme);
    }

    const scene3dIds = scenes3d.map(s => s.id);

    // When a 3d object is updated with an empty 3d object
    const featuresWithoutObject3dUrl = featureCollections.features.filter(
        feature => scene3dIds.includes(feature.id) && !feature.properties.object3dUrl
    );

    // returns features where scenes3d object not in getObject3dFeatures(), they are disabled from MapEditor
    const disabledFeaturesInScenes3d = lodashDifferenceBy(scenes3d, getObject3dFeatures(), 'id');

    const object3dFeaturesToBeRemoved = featuresWithoutObject3dUrl.concat(
        disabledFeaturesInScenes3d
    );

    object3dFeaturesToBeRemoved.forEach(feature => delete3dFeatureObject(feature));
};
