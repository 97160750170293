import {CONFIG_DATA_BASE_KEYS, LAYOUT_IDS, MAP_THEMES} from 'Utils/constants';

export const SIGN_TYPE_ID_KEY = `${CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE}.signTypeId`;

export const SIGNS_CONCAT_CHARS = {
    DASH: 'dash',
    COMMA: 'comma',
    TO: 'to',
};

export const SIGNS_CONCAT_CHAR_DEFAULT = SIGNS_CONCAT_CHARS.COMMA;

export const KIOSK_MAP_CONFIG_MAP_STYLES = {
    LIGHT: MAP_THEMES.EXPLORER_LIGHT,
    DARK: MAP_THEMES.EXPLORER_DARK,
};

export const EXPERIENCE_BANNER_SIZES = {
    QUARTER: 'quarter',
    HALF: 'half',
    FULL_SCREEN: 'fs',
    NONE: 'none',
};

export const EXPERIENCE_BANNER_SIZES_OPTIONS = [
    {id: EXPERIENCE_BANNER_SIZES.QUARTER, name: 'Small - 1/4 Screen'},
    {id: EXPERIENCE_BANNER_SIZES.HALF, name: 'Medium - 1/2 Screen'},
    {id: EXPERIENCE_BANNER_SIZES.FULL_SCREEN, name: 'Large - Full Screen'},
    {id: EXPERIENCE_BANNER_SIZES.NONE, name: 'None'},
];

export const QR_CODE_COLORS = {
    INHERIT: 'na',
    BLACK: 'black',
    WHITE: 'white',
};

export const QR_CODE_COLORS_OPTIONS = [
    {id: QR_CODE_COLORS.INHERIT, name: 'Inherit Color'},
    {id: QR_CODE_COLORS.BLACK, name: 'Black - White Border'},
    {id: QR_CODE_COLORS.WHITE, name: 'White - Black Border'},
];

export const DEFAULT_BANNER_CONFIG = {
    isImageAvailable: true,
    isTitleAvailable: true,
    isLocationAvailable: true,
    isDateAvailable: true,
    isQrAvailable: false,
};

export const SIGN_TYPES = {
    /** === DIGITAL DIRECTIONAL SIGNS === */
    // XPS080 panels 4x5
    V_3_1: {
        id: 'V_3_1',
        name: 'V3.1',
        width: 128, // 4 x 32px
        height: 180, // 5 x 36px
        availableSlots: 12,
        layoutId: LAYOUT_IDS.HPK_DDS,
        bannerConfig: {
            [EXPERIENCE_BANNER_SIZES.FULL_SCREEN]: {
                ...DEFAULT_BANNER_CONFIG,
                isTitleAvailable: false,
                isLocationAvailable: false,
                isDateAvailable: false,
                isQrAvailable: true,
                qrSize: 58,
                qrBorder: 2,
            },
            [EXPERIENCE_BANNER_SIZES.QUARTER]: {
                ...DEFAULT_BANNER_CONFIG,
            },
        },
    },
    // XPS080 panels 4x7
    V_3_2: {
        id: 'V_3_2',
        name: 'V3.2',
        width: 128, // 4 x 32px
        height: 252, // 7 x 36px
        availableSlots: 18,
        layoutId: LAYOUT_IDS.HPK_DDS,
        bannerConfig: {
            [EXPERIENCE_BANNER_SIZES.FULL_SCREEN]: {
                ...DEFAULT_BANNER_CONFIG,
                isQrAvailable: true,
                qrSize: 58,
                qrBorder: 2,
            },
            [EXPERIENCE_BANNER_SIZES.HALF]: {
                ...DEFAULT_BANNER_CONFIG,
                isQrAvailable: true,
                qrSize: 58,
                qrBorder: 2,
            },
            [EXPERIENCE_BANNER_SIZES.QUARTER]: {
                ...DEFAULT_BANNER_CONFIG,
            },
        },
    },
    // XPS060 panels 3x6
    P_3_1: {
        id: 'P_3_1',
        name: 'P3.1',
        width: 96, // 3 x 32px
        height: 192, // 6 x 32px
        availableSlots: 16,
        layoutId: LAYOUT_IDS.HPK_DDS,
        bannerConfig: {
            [EXPERIENCE_BANNER_SIZES.FULL_SCREEN]: {
                ...DEFAULT_BANNER_CONFIG,
                isTitleAvailable: false,
                isLocationAvailable: false,
                isDateAvailable: false,
                isQrAvailable: true,
                qrSize: 58,
                qrBorder: 2,
            },
            [EXPERIENCE_BANNER_SIZES.QUARTER]: {
                ...DEFAULT_BANNER_CONFIG,
            },
        },
    },
    // XPS060 panels 3x6
    P_3_2: {
        id: 'P_3_2',
        name: 'P3.2',
        width: 96, // 3 x 32px
        height: 192, // 6 x 32px
        availableSlots: 16,
        layoutId: LAYOUT_IDS.HPK_DDS,
        bannerConfig: {
            [EXPERIENCE_BANNER_SIZES.FULL_SCREEN]: {
                ...DEFAULT_BANNER_CONFIG,
                isTitleAvailable: false,
                isLocationAvailable: false,
                isDateAvailable: false,
                isQrAvailable: true,
                qrSize: 58,
                qrBorder: 2,
            },
            [EXPERIENCE_BANNER_SIZES.QUARTER]: {
                ...DEFAULT_BANNER_CONFIG,
            },
        },
    },

    /** === PARKING LOT SIGNS === */
    // XPS100 panels 2x6
    P_5_2: {
        id: 'P_5_2',
        name: 'P5.2',
        width: 96,
        height: 64,
        layoutId: LAYOUT_IDS.HPK_PARKING_LOT,
    },
    P_4_2: {
        id: 'P_4_2',
        name: 'P4.2',
        width: 128, // @TODO get the real size, do we even need it?
        height: 180,
        layoutId: LAYOUT_IDS.KIOSK,
    },
    // XPS080 panels 4x5
    V_6: {
        id: 'V_6',
        name: 'V6',
        width: 128,
        height: 180,
        layoutId: LAYOUT_IDS.HPK_PARKING_LOT,
    },
    V_6_2: {
        id: 'V_6_2',
        name: 'V6.2',
        width: 128,
        height: 180,
        layoutId: LAYOUT_IDS.HPK_PARKING_LOT,
        bannerConfig: {
            [EXPERIENCE_BANNER_SIZES.FULL_SCREEN]: {
                ...DEFAULT_BANNER_CONFIG,
                imageOrientation: 'landscapeImageUrl',
                isTitleAvailable: false,
                isLocationAvailable: false,
                isDateAvailable: false,
                isQrAvailable: true,
                qrSize: 58,
                qrBorder: 2,
            },
            [EXPERIENCE_BANNER_SIZES.QUARTER]: {
                ...DEFAULT_BANNER_CONFIG,
                isImageAvailable: false,
            },
        },
    },
};

export const SIGN_TYPE_TO_SVG_TEMPLATE = {
    V_3_1: 'XPS080_4x5',
    V_3_2: 'XPS080_4x7',
    V_3_1_PIXEL: 'XPS080_4x5_PIXEL',
    V_3_2_PIXEL: 'XPS080_4x7_PIXEL',
    V_6: 'XPS080_4x5_PARKING',
    V_6_PIXEL: 'XPS080_4x5_PIXEL_PARKING',
    V_6_2: 'XPS080_4x5_PARKING',
    V_6_2_PIXEL: 'XPS080_4x5_PIXEL_PARKING',
    P_3_1: 'XPS060_3x6',
    P_3_2: 'XPS060_3x6',
    P_3_1_PIXEL: 'XPS060_PIXEL',
    P_3_2_PIXEL: 'XPS060_PIXEL',
    P_5_2: 'XPS100_6x2',
    P_5_2_PIXEL: 'XPS100_6x2_PIXEL',
};

export const SVG_TEMPLATE_CONFIGS = {
    default: {
        textFormat: {
            sm: {
                fontSizePx: 8,
                fontHeightPx: 7,
                fontClippingPercent: 0.3,
                letterSpacing: -0.125,
                lineHeight: 8,
                fontFamily: 'Dogica_Pixel',
                fontWeight: '400',
                textTransform: 'uppercase',
                arrow: '#din-arrow',
                arrow45: '#din-arrow',
                arrowSize: 75,
                arrowX: 46,
            },
            lg: {
                fontSizePx: 13,
                fontHeightPx: 13,
                fontClippingPercent: 0.3,
                letterSpacing: 0,
                lineHeight: 14,
                fontFamily: 'DIXEL',
                fontWeight: '400',
                textTransform: 'uppercase',
                marginLeft: 1,
                arrow: '#din-arrow',
                arrow45: '#din-arrow',
                arrowSize: 75,
                arrowX: 46,
            },
        },
        layout: {
            width: 1056, // 264mm x 4
            height: 1485, // 297mm x 5
            pixelPitch: 8.255, // 32 x 36 per panel
            pixelSize: 2, // mm
            pixelsWide: 128,
            pixelsHigh: 180,
            backgroundColour: '#000000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 65,
            marginLeft: 0,
            copyMarginLeft: 149,
            copyMarginRight: 15,
            // we use #rrggbb hex colour notation to simplify colours comparison
            fontColour: '#ffffff',
            verticalSlotSpacing: 123,
            slotHorizontalHeight: 5,
            slotHorizontalColor: '#777777',
            slotHorizontalOpacity: 1,
            slotHorizontalMarginRight: 82.5, // 3 1/4"
            slotHorizontalMarginLeft: 98.4, // 3 7/8"
            iconSize: 100,
            iconSpacing: 20,
            copyIndent: 32,
        },
        rules: {
            hasFontSizeOption: true,
            hasIndentOption: true,
            hasSlotColoursOption: true,
            groupedArrows: true,
            pictogramAlign: 'left',
            customLetterSpacing: [], // custom letter spacing override eg: [['YOUTUBE THEATRE',-0.065],['SOMETHING ELSE',0.025],
            showParkingStatus: false,
            forceLineBreak: [],
        },
    },
    XPS080_4x5: {
        name: 'XPS080_4x5',
        description: 'For V3.1',
        textFormat: {
            sm: {
                fontSizePx: 11,
                fontHeightPx: 9,
                fontClippingPercent: 0,
                letterSpacing: 0,
                lineHeight: 11,
                fontFamily: 'DINMedium',
                fontWeight: 'normal',
                textTransform: 'uppercase',
                arrow: '#din-arrow',
                arrow45: '#din-arrow',
                arrowSize: 9,
                arrowX: 6,
                verticalSlotSpacing: 4,
            },
            lg: {
                fontSizePx: 16,
                fontHeightPx: 12,
                fontClippingPercent: 0,
                letterSpacing: 0,
                lineHeight: 15,
                fontFamily: 'DINMedium',
                fontWeight: 'normal',
                textTransform: 'uppercase',
                arrow: '#din-arrow',
                arrow45: '#din-arrow',
                arrowSize: 13,
                arrowX: 4,
                verticalSlotSpacing: 8,
            },
        },
        layout: {
            width: 128,
            height: 180,
            pixelPitch: 1, // 32 x 36 per panel
            pixelSize: 1, // mm
            pixelsWide: 128,
            pixelsHigh: 180,
            backgroundColour: '#000000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 3,
            marginLeft: 0,
            copyMarginLeft: 22,
            copyMarginRight: 4,

            slotHorizontalHeight: 1, // used for displaying the height of the stroke
            slotHorizontalColor: '#777777',
            slotHorizontalOpacity: 1,
            slotHorizontalMarginRight: 4, // 3 1/4"
            slotHorizontalMarginLeft: 4, // 3 7/8"
            iconSize: 9,
            iconSpacing: 2,
            copyIndent: 4,
        },
        rules: {
            hasFontSizeOption: true,
            hasIndentOption: true,
            hasSlotColoursOption: true,
            groupedArrows: true,
            pictogramAlign: 'left',
            customLetterSpacing: [], // custom letter spacing override eg: [['YOUTUBE THEATRE',-0.065],['SOMETHING ELSE',0.025],
            showParkingStatus: true,
            pixelLayout: false,
            forceLineBreak: [],
        },
    },
    XPS080_4x7: {
        name: 'XPS080_4x7',
        description: 'For V3.2',
        textFormat: {
            sm: {
                fontSizePx: 11,
                fontHeightPx: 9,
                fontClippingPercent: 0,
                letterSpacing: 0,
                lineHeight: 11,
                fontFamily: 'DINMedium',
                fontWeight: 'normal',
                textTransform: 'uppercase',
                arrow: '#din-arrow',
                arrow45: '#din-arrow',
                arrowSize: 9,
                arrowX: 6,
                verticalSlotSpacing: 4,
            },
            lg: {
                fontSizePx: 16,
                fontHeightPx: 12,
                fontClippingPercent: 0,
                letterSpacing: 0,
                lineHeight: 15,
                fontFamily: 'DINMedium',
                fontWeight: 'normal',
                textTransform: 'uppercase',
                arrow: '#din-arrow',
                arrow45: '#din-arrow',
                arrowSize: 13,
                arrowX: 4,
                verticalSlotSpacing: 8,
            },
        },
        layout: {
            width: 128,
            height: 252,
            pixelPitch: 1, // 32 x 36 per panel
            pixelSize: 1, // mm
            pixelsWide: 128,
            pixelsHigh: 252,
            backgroundColour: '#000000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 3,
            marginLeft: 0,
            copyMarginLeft: 22,
            copyMarginRight: 4,

            slotHorizontalHeight: 1, // used for displaying the height of the stroke
            slotHorizontalColor: '#777777',
            slotHorizontalOpacity: 1,
            slotHorizontalMarginRight: 4, // 3 1/4"
            slotHorizontalMarginLeft: 4, // 3 7/8"
            iconSize: 9,
            iconSpacing: 2,
            copyIndent: 4,
        },
        rules: {
            hasFontSizeOption: true,
            hasIndentOption: true,
            hasSlotColoursOption: true,
            groupedArrows: true,
            pictogramAlign: 'left',
            customLetterSpacing: [], // custom letter spacing override eg: [['YOUTUBE THEATRE',-0.065],['SOMETHING ELSE',0.025],
            showParkingStatus: true,
            pixelLayout: false,
            forceLineBreak: [],
        },
    },
    XPS060_3x6: {
        name: 'XPS060_3x6',
        description: 'For P3.1, P3.2',
        textFormat: {
            sm: {
                fontSizePx: 9,
                fontHeightPx: 6.5,
                fontClippingPercent: 0,
                letterSpacing: 0,
                lineHeight: 8,
                fontFamily: 'DINMedium',
                fontWeight: 'normal',
                textTransform: 'uppercase',
                arrow: '#din-arrow',
                arrow45: '#din-arrow',
                arrowSize: 7,
                arrowX: 3,
                verticalSlotSpacing: 5,
            },
            lg: {
                fontSizePx: 10,
                fontHeightPx: 7,
                fontClippingPercent: 0,
                letterSpacing: 0,
                lineHeight: 9,
                fontFamily: 'DINMedium',
                fontWeight: 'normal',
                textTransform: 'uppercase',
                arrow: '#din-arrow',
                arrow45: '#din-arrow',
                arrowSize: 7,
                arrowX: 3,
                verticalSlotSpacing: 5,
            },
        },
        layout: {
            width: 96,
            height: 192,
            pixelPitch: 1, // 32 x 36 per panel
            pixelSize: 1, // mm
            pixelsWide: 96,
            pixelsHigh: 192,
            backgroundColour: '#000000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 3,
            marginLeft: 0,
            copyMarginLeft: 13,
            copyMarginRight: 2,
            verticalSlotSpacing: 6,
            slotHorizontalHeight: 0.25,
            slotHorizontalColor: '#777777',
            slotHorizontalOpacity: 1,
            slotHorizontalMarginRight: 5, // 3 1/4"
            slotHorizontalMarginLeft: 5, // 3 7/8"
            iconSize: 9,
            iconSpacing: 2,
            copyIndent: 4,
        },
        rules: {
            hasFontSizeOption: true,
            hasIndentOption: true,
            hasSlotColoursOption: true,
            groupedArrows: true,
            pictogramAlign: 'left',
            customLetterSpacing: [], // custom letter spacing override eg: [['YOUTUBE THEATRE',-0.065],['SOMETHING ELSE',0.025],
            showParkingStatus: false,
            pixelLayout: false,
            forceLineBreak: [],
        },
    },
    XPS060_PIXEL: {
        name: 'XPS060_PIXEL',
        description: 'For P3.1, P3.2 pixel design',
        textFormat: {
            sm: {
                fontSizePx: 8,
                fontHeightPx: 7,
                fontClippingPercent: 0.125,
                letterSpacing: -0.125,
                lineHeight: 9,
                fontFamily: 'Dogica_Pixel',
                fontWeight: '400',
                textTransform: 'uppercase',
                arrow: '#pixel-arrow-7',
                arrow45: '#pixel-arrow-7-45',
                arrowSize: 7,
                arrowX: 4,
            },
            lg: {
                fontSizePx: 8,
                fontHeightPx: 7,
                fontClippingPercent: 0.125,
                letterSpacing: -0.125,
                lineHeight: 9,
                fontFamily: 'Dogica_Pixel',
                fontWeight: '400',
                textTransform: 'uppercase',
                arrow: '#pixel-arrow-7',
                arrow45: '#pixel-arrow-7-45',
                arrowSize: 7,
                arrowX: 4,
            },
        },
        layout: {
            width: 96,
            height: 192,
            pixelPitch: 1, // 32 x 36 per panel
            pixelSize: 1, // mm
            pixelsWide: 96,
            pixelsHigh: 192,
            backgroundColour: '#000000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 3,
            marginLeft: 0,
            copyMarginLeft: 13,
            copyMarginRight: 2,
            verticalSlotSpacing: 6,
            slotHorizontalHeight: 1,
            slotHorizontalColor: '#777777',
            slotHorizontalOpacity: 1,
            slotHorizontalMarginRight: 2, // 3 1/4"
            slotHorizontalMarginLeft: 2, // 3 7/8"
            iconSize: 9,
            iconSpacing: 2,
            copyIndent: 4,
        },
        rules: {
            hasFontSizeOption: false,
            hasIndentOption: true,
            hasSlotColoursOption: true,
            groupedArrows: true,
            pictogramAlign: 'left',
            customLetterSpacing: [], // custom letter spacing override eg: [['YOUTUBE THEATRE',-0.065],['SOMETHING ELSE',0.025],
            showParkingStatus: false,
            pixelLayout: true,
            forceLineBreak: [],
        },
    },
    XPS080_4x7_PIXEL: {
        name: 'XPS080_4x7_PIXEL',
        description: 'For V3.2 pixel design',
        textFormat: {
            sm: {
                fontSizePx: 16,
                fontHeightPx: 9,
                fontClippingPercent: 0,
                letterSpacing: 0,
                lineHeight: 11,
                fontFamily: 'Pixel_Operator',
                fontWeight: '400',
                textTransform: 'uppercase',
                arrow: '#pixel-arrow-9',
                arrow45: '#pixel-arrow-9-45',
                arrowSize: 9,
                arrowX: 6,
                verticalSlotSpacing: 4,
            },
            lg: {
                fontSizePx: 13,
                fontHeightPx: 13,
                fontClippingPercent: 0,
                letterSpacing: 0,
                lineHeight: 16,
                fontFamily: 'Pixel_Operator_13',
                fontWeight: '400',
                textTransform: 'uppercase',
                arrow: '#pixel-arrow-13',
                arrow45: '#pixel-arrow-13-45',
                arrowSize: 13,
                arrowX: 4,
                verticalSlotSpacing: 8,
            },
        },
        layout: {
            width: 128,
            height: 252,
            pixelPitch: 1, // 32 x 36 per panel
            pixelSize: 1, // mm
            pixelsWide: 128,
            pixelsHigh: 252,
            backgroundColour: '#000000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 3,
            marginLeft: 0,
            copyMarginLeft: 20,
            copyMarginRight: 4,

            slotHorizontalHeight: 1,
            slotHorizontalColor: '#777777',
            slotHorizontalOpacity: 1,
            slotHorizontalMarginRight: 4, // 3 1/4"
            slotHorizontalMarginLeft: 4, // 3 7/8"
            iconSize: 9,
            iconSpacing: 2,
            copyIndent: 4,
        },
        rules: {
            hasFontSizeOption: true,
            hasIndentOption: true,
            hasSlotColoursOption: true,
            groupedArrows: true,
            pictogramAlign: 'left',
            customLetterSpacing: [], // custom letter spacing override eg: [['YOUTUBE THEATRE',-0.065],['SOMETHING ELSE',0.025],
            showParkingStatus: true,
            pixelLayout: true,
            forceLineBreak: [],
        },
    },
    XPS080_4x5_PARKING: {
        name: 'XPS080_4x5_PARKING',
        description: 'For V6, V6.2',
        textFormat: {
            sm: {
                fontSizePx: 12,
                fontHeightPx: 9,
                letterSpacing: 0,
                lineHeight: 11,
                fontFamily: 'DINMedium',
                fontWeight: '400',
                textTransform: 'uppercase',
            },
            lg: {
                fontSizePx: 17,
                fontHeightPx: 13,
                letterSpacing: 0,
                lineHeight: 14,
                fontFamily: 'DINMedium',
                fontWeight: '400',
                textTransform: 'uppercase',
            },
        },
        layout: {
            width: 128,
            height: 180,
            pixelPitch: 1, // 32 x 36 per panel
            pixelSize: 1, // mm
            pixelsWide: 128,
            pixelsHigh: 180,
            backgroundColor: '#000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 3,
            marginLeft: 0,
            copyMarginLeft: 4,
            copyMarginRight: 4,
            verticalSlotSpacing: 4,
            slotHorizontalHeight: 1,
            slotHorizontalColor: '#fff',
            slotHorizontalOpacity: 0.5,
            slotHorizontalMarginRight: 4, // 3 1/4"
            slotHorizontalMarginLeft: 4, // 3 7/8"
            iconSize: 64,
            iconSpacing: 2,
            copyIndent: 4,
            iconBottomOffset: 4,
            iconYStart: 4,
        },
        rules: {
            hasFontSizeOption: true,
            groupedArrows: true,
            pictogramAlign: 'left',
            customLetterSpacing: [], // custom letter spacing override eg: [['YOUTUBE THEATRE',-0.065],['SOMETHING ELSE',0.025],
            showParkingStatus: false,
            pixelLayout: true,
            forceLineBreak: [],
        },
    },
    XPS080_4x5_PIXEL_PARKING: {
        name: 'XPS080_4x5_PIXEL_PARKING',
        description: 'For V6, V6.2 pixel design',
        textFormat: {
            sm: {
                fontSizePx: 16,
                fontHeightPx: 9,
                letterSpacing: 0,
                lineHeight: 11,
                fontFamily: 'Pixel_Operator',
                fontWeight: '400',
                textTransform: 'uppercase',
            },
            lg: {
                fontSizePx: 13,
                fontHeightPx: 13,
                letterSpacing: 0,
                lineHeight: 14,
                fontFamily: 'DIXEL',
                fontWeight: '400',
                textTransform: 'uppercase',
            },
        },
        layout: {
            width: 128,
            height: 180,
            pixelPitch: 1, // 32 x 36 per panel
            pixelSize: 1, // mm
            pixelsWide: 128,
            pixelsHigh: 180,
            backgroundColor: '#000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 3,
            marginLeft: 0,
            copyMarginLeft: 4,
            copyMarginRight: 4,
            verticalSlotSpacing: 4,
            slotHorizontalHeight: 1,
            slotHorizontalColor: '#fff',
            slotHorizontalOpacity: 0.5,
            slotHorizontalMarginRight: 4, // 3 1/4"
            slotHorizontalMarginLeft: 4, // 3 7/8"
            iconSize: 64,
            iconSpacing: 2,
            copyIndent: 4,
            iconBottomOffset: 4,
            iconYStart: 4,
        },
        rules: {
            hasFontSizeOption: true,
            groupedArrows: true,
            pictogramAlign: 'left',
            customLetterSpacing: [], // custom letter spacing override eg: [['YOUTUBE THEATRE',-0.065],['SOMETHING ELSE',0.025],
            showParkingStatus: false,
            pixelLayout: true,
            forceLineBreak: [],
        },
    },
    XPS080_4x5_PIXEL: {
        name: 'XPS080_4x5_PIXEL',
        description: 'For V3.1 pixel design',
        textFormat: {
            sm: {
                fontSizePx: 16,
                fontHeightPx: 9,
                fontClippingPercent: 0,
                letterSpacing: 0,
                lineHeight: 11,
                fontFamily: 'Pixel_Operator',
                fontWeight: '400',
                textTransform: 'uppercase',
                arrow: '#pixel-arrow-9',
                arrow45: '#pixel-arrow-9-45',
                arrowSize: 9,
                arrowX: 6,
                verticalSlotSpacing: 4,
            },
            lg: {
                fontSizePx: 13,
                fontHeightPx: 13,
                fontClippingPercent: 0,
                letterSpacing: 0,
                lineHeight: 15,
                fontFamily: 'Pixel_Operator_13',
                fontWeight: '400',
                textTransform: 'uppercase',
                arrow: '#pixel-arrow-13',
                arrow45: '#pixel-arrow-13-45',
                arrowSize: 13,
                arrowX: 4,
                verticalSlotSpacing: 8,
            },
        },
        layout: {
            width: 128,
            height: 180,
            pixelPitch: 1, // 32 x 36 per panel
            pixelSize: 1, // mm
            pixelsWide: 128,
            pixelsHigh: 180,
            backgroundColour: '#000000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 3,
            marginLeft: 0,
            copyMarginLeft: 22,
            copyMarginRight: 4,

            slotHorizontalHeight: 1, // used for displaying the height of the stroke
            slotHorizontalColor: '#777777',
            slotHorizontalOpacity: 1,
            slotHorizontalMarginRight: 4, // 3 1/4"
            slotHorizontalMarginLeft: 4, // 3 7/8"
            iconSize: 9,
            iconSpacing: 2,
            copyIndent: 4,
        },
        rules: {
            hasFontSizeOption: true,
            hasIndentOption: true,
            hasSlotColoursOption: true,
            groupedArrows: true,
            pictogramAlign: 'left',
            customLetterSpacing: [], // custom letter spacing override eg: [['YOUTUBE THEATRE',-0.065],['SOMETHING ELSE',0.025],
            showParkingStatus: true,
            pixelLayout: true,
            forceLineBreak: [],
        },
    },
    XPS100_6x2: {
        name: 'XPS100_6x2',
        description: 'For P5.2',
        textFormat: {
            sm: {
                fontSizePx: 10,
                fontHeightPx: 7,
                letterSpacing: 0,
                lineHeight: 8,
                fontFamily: 'DINMedium',
                fontWeight: '400',
                textTransform: 'uppercase',
            },
            lg: {
                fontSizePx: 18,
                fontHeightPx: 13,
                letterSpacing: 0,
                lineHeight: 15,
                fontFamily: 'DINMedium',
                fontWeight: '400',
                textTransform: 'uppercase',
            },
        },
        layout: {
            width: 96,
            height: 64,
            pixelPitch: 1, // 32 x 36 per panel
            pixelSize: 1, // mm
            pixelsWide: 96,
            pixelsHigh: 64,
            backgroundColour: '#000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 3,
            marginLeft: 0,
            copyMarginLeft: 2,
            copyMarginRight: 2,

            verticalSlotSpacing: 2,
            slotHorizontalHeight: 1,
            slotHorizontalColor: '#777',
            slotHorizontalOpacity: 1,
            slotHorizontalMarginRight: 2, // 3 1/4"
            slotHorizontalMarginLeft: 2, // 3 7/8"
            iconSize: 9,
            iconSpacing: 2,
            copyIndent: 4,
            iconBottomOffset: 2,
            iconYStart: 2,
        },
        rules: {
            hasFontSizeOption: true,
            groupedArrows: true,
            contentSlots: {
                type: 'messaging',
            },
            forceLineBreak: [],
            pixelLayout: 1,
        },
    },
    XPS100_6x2_PIXEL: {
        name: 'XPS100_PIXEL',
        description: 'For P5.2 pixel design',
        textFormat: {
            sm: {
                fontSizePx: 8,
                fontHeightPx: 7,
                letterSpacing: -0.125,
                lineHeight: 8,
                fontFamily: 'Dogica_Pixel',
                fontWeight: '400',
                textTransform: 'uppercase',
            },
            lg: {
                fontSizePx: 13,
                fontHeightPx: 13,
                letterSpacing: 0,
                lineHeight: 14,
                fontFamily: 'DIXEL',
                fontWeight: '400',
                textTransform: 'uppercase',
                marginLeft: 1,
            },
        },
        layout: {
            width: 96,
            height: 64,
            pixelPitch: 1, // 32 x 36 per panel
            pixelSize: 1, // mm
            pixelsWide: 96,
            pixelsHigh: 64,
            backgroundColour: '#000',
            marginTop: 0,
            marginRight: 0,
            marginBottom: 3,
            marginLeft: 0,
            copyMarginLeft: 2,
            copyMarginRight: 2,

            verticalSlotSpacing: 2,
            slotHorizontalHeight: 1,
            slotHorizontalColor: '#777',
            slotHorizontalOpacity: 1,
            slotHorizontalMarginRight: 2, // 3 1/4"
            slotHorizontalMarginLeft: 2, // 3 7/8"
            iconSize: 9,
            iconSpacing: 2,
            iconBottomOffset: 2,
            iconYStart: 2,
            copyIndent: 4,
        },
        rules: {
            hasFontSizeOption: true,
            groupedArrows: true,
            contentSlots: {
                type: 'messaging',
            },
            forceLineBreak: [],
            pixelLayout: 1,
        },
    },
};
