// These constants are magic numbers to mimic the behaviour of Google Maps
const HORIZONTAL_THRESHOLD_DEGREES = 45;
const TOUCH_MOVE_THRESHOLD = 25;
const ANGLE_CHANGE_THRESHOLD = 7;

/**
 * This takes two points and returns the difference between the angle of the line that
 * passes through these two points and a horizontal line.
 *
 * @param {Array<{x: number, y: number}>} points
 * @return {number} The number of degrees, between 0 and 90°
 */
const getAngleFromHorizontal = ([p1, p2]) => {
    let angleInDegrees = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;

    // We don't know which point is which. E.g. -30 is the same as 150
    if (angleInDegrees < 0) angleInDegrees += 180;

    // We don't care if the line is clockwise or anti-clockwise from horizontal
    if (angleInDegrees > 90) angleInDegrees = 180 - angleInDegrees;

    return angleInDegrees;
};

const getDistanceBetween = (p1, p2) => {
    const diffY = p1.y - p2.y;
    const diffX = p1.x - p2.x;
    return Math.sqrt(diffX ** 2 + diffY ** 2);
};

/**
 * Mapbox doesn't support pitch out of the box, so we implement the functionality here.
 *
 * @param map - the Mapbox map object to attach the listeners to
 */
const setupTwoFingerPitch = map => {
    let initialPoint;
    let initialPitch;
    let initialTouchPoints;
    let initialTouchDistance;
    let initialAngle;
    let isHorizontalTouch = false;
    let hasMovedFarEnough = false;
    let twoFingerTouchInProgress = false;
    let allowPitch = false;

    // Shorthand functions to make these more readable
    const pan = {
        enable: () => map.dragPan.enable(),
        disable: () => map.dragPan.disable(),
    };
    const zoom = {
        enable: () => map.touchZoomRotate.enable(),
        disable: () => map.touchZoomRotate.disable(),
    };
    const rotate = {
        enable: () => map.touchZoomRotate.enableRotation(),
        disable: () => map.touchZoomRotate.disableRotation(),
    };
    const pitch = {
        enable: () => {
            allowPitch = true;
        },
        disable: () => {
            allowPitch = false;
        },
    };

    const enterPitchMode = () => {
        // We disable some interactions, but there seems to be an issue in Mapbox.
        // It will sometimes allow two-finger panning and rotating even with this is disabled
        // Possibly to do with the .disable() not getting registered if the map is already moving
        pan.disable();
        zoom.disable();
        rotate.disable();
        pitch.enable();
        hasMovedFarEnough = true;
    };

    const enterZoomMode = () => {
        pan.enable();
        zoom.enable();
        rotate.disable();
        pitch.disable();
        hasMovedFarEnough = true;
    };

    const enterTwistMode = () => {
        pan.enable();
        zoom.enable();
        rotate.enable();
        pitch.disable();
        hasMovedFarEnough = true;
    };

    const exitAllModes = () => {
        if (!twoFingerTouchInProgress) return;

        twoFingerTouchInProgress = false;
        pan.enable();
        zoom.enable();
        rotate.enable();
        pitch.enable();
    };

    const initialiseTwoFingerOperation = e => {
        twoFingerTouchInProgress = true;
        hasMovedFarEnough = false;
        initialAngle = getAngleFromHorizontal(e.points);
        isHorizontalTouch = initialAngle < HORIZONTAL_THRESHOLD_DEGREES;
        initialPoint = e.point;
        initialTouchPoints = e.points;
        initialPitch = map.getPitch();
        initialTouchDistance = getDistanceBetween(initialTouchPoints[0], initialTouchPoints[1]);
    };

    map.on('touchstart', e => {
        if (e.points.length === 2) initialiseTwoFingerOperation(e);
    });

    map.on('touchmove', e => {
        e.preventDefault();
        e.originalEvent.preventDefault(); // prevent browser refresh on pull down

        if (e.points.length !== 2) {
            // If the user has lifted a finger, revert to normal operation
            if (twoFingerTouchInProgress) exitAllModes();
            return;
        }

        // This wasn't previously a two finger operation, but now it is
        if (!twoFingerTouchInProgress) {
            initialiseTwoFingerOperation(e);
        }

        if (!hasMovedFarEnough) {
            if (
                Math.abs(initialAngle - getAngleFromHorizontal(e.points)) > ANGLE_CHANGE_THRESHOLD
            ) {
                // Fingers twisting around a point
                enterTwistMode();
            } else if (
                isHorizontalTouch &&
                Math.abs(initialPoint.y - e.point.y) > TOUCH_MOVE_THRESHOLD
            ) {
                // Horizontal fingers moving vertically
                enterPitchMode();
            } else if (getDistanceBetween(initialPoint, e.point) > TOUCH_MOVE_THRESHOLD) {
                // Both fingers moved together in any direction
                enterZoomMode();
            } else if (
                Math.abs(initialTouchDistance - getDistanceBetween(e.points[0], e.points[1])) >
                TOUCH_MOVE_THRESHOLD
            ) {
                // Fingers are spreading
                enterZoomMode();
            }
        }

        if (allowPitch) {
            const verticalDiff = (initialPoint.y - e.point.y) * 0.5;
            map.setPitch(initialPitch + verticalDiff);
        }
    });

    map.on('touchend', exitAllModes);
    map.on('touchcancel', exitAllModes);
};

export default setupTwoFingerPitch;
