import {ACTION_TYPES, ROUTE_OPTIONS_TO_TRAVEL_MODES, ROUTE_OPTIONS} from 'Utils/constants';
import setRouteParameters from 'Data/actionCreators/journey/setRouteParameters';

const selectRouteOption = (selectedRouteOption, safeOnly = false) => (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.SELECT_ROUTE_OPTION,
        data: {
            selectedRouteOption,
            safeOnly,
        },
    });
    setRouteParameters({
        travelMode: ROUTE_OPTIONS_TO_TRAVEL_MODES[selectedRouteOption],
        wheelchairOnly: selectedRouteOption === ROUTE_OPTIONS.WHEELCHAIR,
        safeOnly,
    })(dispatch, getState);
};

export default selectRouteOption;
