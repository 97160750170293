import {ACTION_TYPES} from 'Utils/constants';

const setIsSearchingRoute = isSearchingRoute => dispatch => {
    dispatch({
        type: ACTION_TYPES.SET_IS_SEARCHING_ROUTE,
        data: isSearchingRoute,
    });
};

export default setIsSearchingRoute;
