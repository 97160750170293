import {MAP_LAYERS, SOURCES} from 'Utils/constants';
import {makeFeatureTypeBool} from 'Utils/explorer/mapUtils';

/**
 * Returns two layers. One for digital sign labels, and another for all the other labels.
 *
 * @param props
 * @return {Array<object>}
 */
export const makeLabelLayers = props => [
    {
        id: MAP_LAYERS.LABELS,
        type: 'symbol',
        source: SOURCES.LABELS,
        filter: ['!=', ['get', 'isDigitalSign'], true],
        layout: {
            'text-field': '{mapLabelText}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
            'text-letter-spacing': 0.1,
            'text-max-width': 8,
            'text-padding': 5,
            // prettier-ignore
            'text-size': [
                'step',
                ['get', 'prominence'],
                9, // for prominence 0
                4, 10, // prominence 4
                5, 11, // you get the idea
            ]
        },
        paint: {
            'text-color': props.minorColor,
            'text-halo-color': props.haloColor,
            'text-halo-width': 2,
            'text-halo-blur': 0.5,
        },
    },
    {
        id: MAP_LAYERS.SIGN_LABELS,
        type: 'symbol',
        source: SOURCES.LABELS,
        filter: ['all', ['==', ['get', 'isDigitalSign'], true], ['!=', ['get', 'doNotDraw'], true]],
        layout: {
            'text-field': '{mapLabelText}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
            'text-letter-spacing': 0.1,
            'text-size': 14,
            'text-justify': 'auto',
            // Note that additional layout properties are set with setLayoutProperty() elsewhere
        },
        paint: {
            'text-color': props.signLabelColor,
            'text-halo-color': props.haloColor,
            'text-halo-width': 2,
            'text-halo-blur': 0.5,
        },
    },
];

// Most polygons are rendered as light grey in DSM
export const makePolygonLayers = props => {
    const filter = ['==', ['get', makeFeatureTypeBool(props)], true];

    return [
        {
            id: `pam-${props.code}-fill`,
            source: SOURCES.NAV_MAP,
            type: 'fill',
            filter,
            paint: {
                'fill-color': '#fff',
                'fill-opacity': 0.4,
            },
        },
        {
            id: `pam-${props.code}-line`,
            source: SOURCES.NAV_MAP,
            type: 'line',
            filter,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': '#bbb',
                'line-width': 0.5,
            },
        },
    ];
};

export const makeRouteLayers = () => ({
    id: 'pam-route-segment-line',
    source: SOURCES.NAV_MAP,
    filter: ['==', ['get', 'isRouteSegment'], true],
    type: 'line',
    layout: {
        'line-cap': 'round',
        'line-join': 'round',
    },
    paint: {
        'line-color': '#e5e5e5',
        'line-width': 2,
        'line-dasharray': [3, 3],
    },
});
