import React from 'react';

const EventIcon = () => (
    <svg
        id="list-icon-event"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="-4 -4 28 28"
    >
        <rect id="bbox-20" width="20" height="20" fill="none" />
        <path
            id="icon-star"
            fill="currentColor"
            d="M237.032-2839.837a.965.965,0,0,1-.626-.406.961.961,0,0,1-.13-.826l1.3-4.364-3.615-2.768a.96.96,0,0,1-.373-.644.964.964,0,0,1,.192-.719.967.967,0,0,1,.748-.382l4.552-.112,1.513-4.293a.967.967,0,0,1,.5-.554.964.964,0,0,1,.745-.037.967.967,0,0,1,.59.59l.007.022,1.507,4.272,4.55.112a.977.977,0,0,1,.949,1,.965.965,0,0,1-.382.749l-3.613,2.767,1.3,4.361a.977.977,0,0,1-.656,1.211.978.978,0,0,1-.829-.132l-3.748-2.583-3.749,2.583a.961.961,0,0,1-.55.173A.981.981,0,0,1,237.032-2839.837Zm4.995-4.19,2.939,2.026-1.019-3.421a.918.918,0,0,1,.322-.988l2.834-2.167-3.569-.089a.917.917,0,0,1-.839-.61l-1.187-3.365-1.187,3.365a.919.919,0,0,1-.84.61l-3.568.089,2.836,2.168a.921.921,0,0,1,.32.987L238.051-2842l2.939-2.026a.913.913,0,0,1,.518-.16A.915.915,0,0,1,242.027-2844.027Z"
            transform="translate(-231.455 2857.156)"
        />
    </svg>
);

export default EventIcon;
