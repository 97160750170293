import {ACTION_TYPES, NAV_MAP_DEFAULT_CONFIG} from 'Utils/constants';

const initialState = {
    navMap: {
        type: 'FeatureCollection',
        features: [],
        bbox: [],
        config: NAV_MAP_DEFAULT_CONFIG,
    },
    navMapOverrides: null,
    isNavMapLoaded: false,
    isRelatedClientNavMapsLoaded: {},
    routingReady: false,
    mapboxStyles: {},
};

const mapLayersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_MAPBOX_STYLES:
            return {
                ...state,
                mapboxStyles: action.data,
            };
        case ACTION_TYPES.LOADED_NAV_MAP:
            return {
                ...state,
                navMap: action.data,
                isNavMapLoaded: true,
            };
        case ACTION_TYPES.LOADED_CLIENT_NAV_MAP:
            return {
                ...state,
                navMap: {
                    ...state.navMap,
                    features: state.navMap.features.concat(action.data.navMap.features),
                },
                isRelatedClientNavMapsLoaded: {
                    ...state.isRelatedClientNavMapsLoaded,
                    [action.data.clientId]: true,
                },
            };
        case ACTION_TYPES.UPDATE_NAV_MAP:
            return {
                ...state,
                navMap: {
                    ...state.navMap,
                    features: action.data,
                },
            };
        case ACTION_TYPES.SET_NAV_MAP_CONFIG: {
            return {
                ...state,
                navMap: {
                    ...state.navMap,
                    config: {
                        ...state.navMap.config,
                        ...action.data,
                    },
                },
            };
        }
        case ACTION_TYPES.ROUTING_READY:
            return {
                ...state,
                routingReady: true,
            };

        case ACTION_TYPES.LOADED_NAV_MAP_OVERRIDES:
            return {
                ...state,
                navMapOverrides: action.data,
            };
        default:
            return state;
    }
};

export default mapLayersReducer;
