import {ACTION_TYPES} from 'Utils/constants';

const parkingLotConfigsReducer = (state = [], action) => {
    switch (action.type) {
        case ACTION_TYPES.LOADED_PARKING_LOT_CONFIG:
            return action.data || [];
        default:
            return state;
    }
};

export default parkingLotConfigsReducer;
