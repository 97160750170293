import {ACTION_TYPES} from 'Utils/constants';

/**
 * @typedef explorerPromotionsReducer
 * @property {?string} layoutId
 * @property {?object} config - in whatever shape the layout requires
 */

/** @type {explorerPromotionsReducer} */
const initialState = {
    promotions: [],
    isLoading: false,
    count: 0,
    selectedPromotion: null,
    hasMore: true,
    lastEvaluatedKey: {},
    error: null,
};

const explorerPromotionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.EXPLORER.ADD_PROMOTIONS: {
            // prevent adding the same promotion twice
            // TODO: cancel incomplete request to Promotions API when user leaves promotions list card
            const statePromotions = state?.promotions || [];
            const newPromotions = action.data?.data || [];
            newPromotions.forEach(newPromotion => {
                if (
                    statePromotions.findIndex(
                        existingPromotion => existingPromotion.id === newPromotion.id
                    ) === -1
                ) {
                    statePromotions.push(newPromotion);
                }
            });

            return {
                ...state,
                promotions: statePromotions,
                lastEvaluatedKey: action.data.lastEvaluatedKey,
                hasMore: !!action.data.lastEvaluatedKey,
                isLoading: false,
            };
        }
        case ACTION_TYPES.EXPLORER.ADD_PROMOTIONS_COUNT: {
            return {
                ...state,
                count: action.data?.data?.count || 0,
                isLoading: false,
            };
        }
        case ACTION_TYPES.EXPLORER.CLEAR_PROMOTIONS_COUNT: {
            return {
                ...state,
                count: 0,
                isLoading: false,
            };
        }
        case ACTION_TYPES.EXPLORER.CLEAR_PROMOTIONS: {
            return {
                ...state,
                promotions: [],
                lastEvaluatedKey: {},
                selectedPromotion: null,
                hasMore: true,
                isLoading: true,
            };
        }
        case ACTION_TYPES.EXPLORER.SET_SELECTED_PROMOTION: {
            return {
                ...state,
                selectedPromotion: action.data?.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};

export default explorerPromotionsReducer;
