import {updateRoutes} from 'Data/actionCreators/journey/setJourneyStop';
import {ACTION_TYPES, STORAGE_KEYS} from 'Utils/constants';
import storage from 'Utils/storage';

const setRouteParameters = data => (dispatch, getState) => {
    // We first clear the travel time, so the wrong time doesn't show for the newly-selected
    // travel mode
    dispatch({
        type: ACTION_TYPES.SET_TRAVEL_TIME,
        data: -1,
    });

    dispatch({
        type: ACTION_TYPES.SET_DISTANCE,
        data: -1,
    });

    // Order is important here. We must set the new parameters before updating the routes
    dispatch({
        type: ACTION_TYPES.SET_ROUTE_PARAMETERS,
        data,
    });

    storage.setItem(STORAGE_KEYS.ROUTE_PARAMETERS, data);

    // This will also re-set the travel time once the new routes are generated
    updateRoutes({dispatch, state: getState()});
};

export default setRouteParameters;
