import {ACTION_TYPES} from 'Utils/constants';

const initialState = {
    colors: {},
    deviceType: null,
    isKeyboardOpen: false, // referring to touch device on-screen keyboards
    settings: {},
    trackingUser: false,
    userLocation: null,
    useMocks: false,
    IP: 'false',
};

const deviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_DEVICE_DATA:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};

export default deviceReducer;
