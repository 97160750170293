import React from 'react';
import ReactDom from 'react-dom';
import StartMarker from 'Components/common/MapboxMap/StartMarker';
import {connect, Provider} from 'react-redux';
import store from 'Data/store';

const mapStateToProps = state => {
    let colors = state.pageState.layout.config?.settings?.mapConfig?.startPin?.colors;
    if (!colors) {
        colors = {text: '#FFFFFF', background: '#1492FF'};
    }
    return {
        textColor: colors.text,
        backgroundColor: colors.background,
    };
};

const createStartMarker = () => ({feature}) => {
    const StartMarkerContainer = connect(mapStateToProps)(StartMarker);
    const startMarker = document.createElement('div');

    ReactDom.render(
        <Provider store={store}>
            <StartMarkerContainer feature={feature} />
        </Provider>,
        startMarker
    );

    return startMarker;
};

export default createStartMarker;
