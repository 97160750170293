import {GEOMETRY_TYPES} from 'Utils/constants';
// This file defines the subset of IMDF that PAM 360 will use for navigation maps
// https://register.apple.com/resources/imdf/
// And also extends it with routing types, which IMDF does not define

// You should NOT refer to this file directly, except when loading or saving a navMap
// In all other places, refer to PAM featureTypes instead.
const IMDF_FEATURE_TYPES = {
    venue: {
        code: 'venue',
        name: 'venue',
        geometryType: GEOMETRY_TYPES.POLYGON,
        supportsDisplayPoint: true,
        canBeEnabledAsContentSource: true,
        defaultEnabledAsContentSource: true,
        categories: {
            stadium: {name: 'Stadium', code: 'stadium'},
            university: {name: 'University', code: 'university'},
            retailstore: {name: 'Retail Store', code: 'retailstore'},
            shoppingcenter: {name: 'Shopping Center', code: 'shoppingcenter'},
            village: {name: 'Village', code: 'village'},
        },
        allowedChildrenTypes: ['geofence', 'level', 'unit', 'section', 'building'],
        style: {
            fillDarkColour: '#555555',
            fillLightColour: '#E8E8E8',
            fillEditorColour: '#E8E8E8',
            borderDarkColour: '#272727',
            borderLightColour: '#DDDDDD',
            borderEditorColour: '#DDDDDD',
            explorerFillOpacity: 0,
            explorerBorderWidth: 0,
            editorFillOpacity: 0.5,
            editorBorderWidth: 0.75,
            editorBorderOpacity: 1,
            currentClient: {
                editorBorderWidth: 4,
                editorBorderOpacity: 0.5,
                editorBorderColour: '#11C871',
            },
            otherClient: {
                editorBorderWidth: 4,
                editorBorderOpacity: 0.5,
                editorBorderColour: '#E30C5F',
            },
        },
    },
    geofence: {
        code: 'geofence',
        name: 'geofence',
        geometryType: GEOMETRY_TYPES.POLYGON,
        supportsDisplayPoint: true,
        canBeEnabledAsContentSource: true,
        categories: {
            geofence: {name: 'geofence', code: 'geofence'},
        },
        allowedChildrenTypes: [
            'building',
            'level',
            'amenity',
            'anchor',
            'object3d',
            'geofence',
            'unit',
            'section',
        ],
        style: {
            fillDarkColour: '#555555',
            fillLightColour: '#E8E8E8',
            fillEditorColour: '#E8E8E8',
            borderDarkColour: '#272727',
            borderLightColour: '#DDDDDD',
            borderEditorColour: '#DDDDDD',
            explorerFillOpacity: 1,
            explorerBorderWidth: 0,
            editorFillOpacity: 0.5,
            editorBorderWidth: 0.75,
        },
    },
    building: {
        code: 'building',
        name: 'buildings',
        geometryType: GEOMETRY_TYPES.POLYGON,
        supportsDisplayPoint: true,
        canBeEnabledAsContentSource: true,
        categories: {
            parking: {name: 'parking', code: 'parking'},
            unspecified: {name: 'Other', code: 'unspecified'},
        },
        allowedChildrenTypes: ['level', 'amenity', 'anchor', 'object3d', 'group'],
        style: {
            fillDarkColour: '#555555',
            fillLightColour: '#E8E8E8',
            fillEditorColour: '#E8E8E8',
            borderDarkColour: '#272727',
            borderLightColour: '#DDDDDD',
            borderEditorColour: '#DDDDDD',
            explorerFillOpacity: 0,
            explorerBorderWidth: 0,
            editorFillOpacity: 0.5,
            editorBorderWidth: 0.75,
        },
    },
    level: {
        code: 'level',
        name: 'levels',
        supportsDisplayPoint: true,
        canBeEnabledAsContentSource: true,
        geometryType: GEOMETRY_TYPES.POLYGON,
        categories: {
            parking: {name: 'parking', code: 'parking'},
            unspecified: {name: 'Other', code: 'unspecified'},
        },
        allowedChildrenTypes: [
            'section',
            'unit',
            'kiosk',
            'freeForm',
            'opening',
            'fixture',
            'amenity',
            'anchor',
            'object3d',
            'group',
        ],
        style: {
            fillDarkColour: '#555555',
            fillLightColour: '#E8E8E8',
            fillEditorColour: '#E8E8E8',
            borderDarkColour: '#272727',
            borderLightColour: '#DDDDDD',
            borderEditorColour: '#DDDDDD',
            explorerFillOpacity: 1,
            explorerBorderWidth: 0,
            editorFillOpacity: 0.5,
            editorBorderWidth: 0.75,
        },
    },
    unit: {
        code: 'unit',
        name: 'units',
        geometryType: GEOMETRY_TYPES.POLYGON,
        supportsDisplayPoint: true,
        supportsTenant: true,
        categories: {
            room: {name: 'Room', code: 'room'},
            opentobelow: {name: 'Open To Below', code: 'opentobelow'},
            foodservice: {name: 'Food Service', code: 'foodservice'},
            auditorium: {name: 'Auditorium', code: 'auditorium'},
            library: {name: 'Library', code: 'library'},
            restroom: {name: 'Restroom', code: 'restroom'},
            shower: {name: 'Shower', code: 'shower'},
            fieldofplay: {name: 'Field Of Play', code: 'fieldofplay'},
            fitnessroom: {name: 'Fitness Room', code: 'fitnessroom'},
            movietheatre: {name: 'Movie Theatre', code: 'movietheatre'},
            vegetation: {name: 'Vegetation', code: 'vegetation'},
            column: {name: 'Column', code: 'column'},
            walkway: {name: 'Walkway', code: 'walkway'},
            elevator: {name: 'Elevator', code: 'elevator'},
            escalator: {name: 'Escalator', code: 'escalator'},
            stairs: {name: 'Stairs', code: 'stairs'},
            nonpublic: {name: 'Non Public', code: 'nonpublic'},
            concrete: {name: 'Concrete', code: 'concrete'},
            glass: {name: 'Glass', code: 'glass'},
            wood: {name: 'Wood', code: 'wood'},
        },
        allowedChildrenTypes: ['amenity', 'anchor'],
        style: {
            fillDarkColour: '#555555',
            fillLightColour: '#E8E8E8',
            fillEditorColour: '#e8e8e8',
            borderDarkColour: '#272727',
            borderLightColour: '#DDDDDD',
            borderEditorColour: '#DDDDDD',
            explorerFillOpacity: 1,
            explorerBorderWidth: 0,
            editorFillOpacity: 0.5,
            editorBorderWidth: 0.75,
        },
    },
    section: {
        code: 'section',
        name: 'sections',
        geometryType: GEOMETRY_TYPES.POLYGON,
        supportsDisplayPoint: true,
        categories: {
            seating: {name: 'Seating', code: 'seating'},
            recreation: {name: 'Recreation', code: 'recreation'},
            retail: {name: 'Retail', code: 'retail'},
            checkin: {name: 'Checkin', code: 'checkin'},
            exhibit: {name: 'Exhibit', code: 'exhibit'},
            eatingdrinking: {name: 'Eating Drinking', code: 'eatingdrinking'},
            vegetation: {name: 'Vegetation', code: 'vegetation'},
            road: {name: 'road', code: 'road'},
        },
        allowedChildrenTypes: ['unit'],
        style: {
            fillDarkColour: '#555555',
            fillLightColour: '#E8E8E8',
            fillEditorColour: '#E8E8E8',
            borderDarkColour: '#272727',
            borderLightColour: '#DDDDDD',
            borderEditorColour: '#DDDDDD',
            explorerFillOpacity: 1,
            explorerBorderWidth: 0,
            editorFillOpacity: 0.5,
            editorBorderWidth: 0.75,
        },
    },
    kiosk: {
        code: 'kiosk',
        name: 'kiosk',
        geometryType: GEOMETRY_TYPES.POLYGON,
        supportsDisplayPoint: true,
        supportsTenant: true,
        categories: null,
        allowedChildrenTypes: [],
        style: {
            fillDarkColour: '#555555',
            fillLightColour: '#E8E8E8',
            fillEditorColour: '#E8E8E8',
            borderDarkColour: '#272727',
            borderLightColour: '#DDDDDD',
            borderEditorColour: '#DDDDDD',
            explorerFillOpacity: 1,
            explorerBorderWidth: 0,
            editorFillOpacity: 0.5,
            editorBorderWidth: 0.75,
        },
    },
    opening: {
        code: 'opening',
        name: 'entrances',
        geometryType: GEOMETRY_TYPES.LINE_STRING,
        supportsDisplayPoint: false, // Technically true, but we don't want to support it
        categories: {
            pedestrian: {name: 'Pedestrian', code: 'pedestrian'},
            pedestrianPrincipal: {name: 'Pedestrian', code: 'pedestrian.principal'},
            automobile: {name: 'Pedestrian', code: 'automobile'},
            emergencyexit: {name: 'Emergency Exit', code: 'emergencyexit'},
        },
        allowedChildrenTypes: [],
    },
    footprint: {
        code: 'footprint',
        name: 'footprint',
        geometryType: GEOMETRY_TYPES.POLYGON,
        supportsDisplayPoint: false,
        categories: {
            ground: {name: 'Ground', code: 'ground'},
        },
        allowedChildrenTypes: [],
    },
    anchor: {
        code: 'anchor',
        name: 'point',
        geometryType: GEOMETRY_TYPES.POINT,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: ['anchor'],
    },
    amenity: {
        code: 'amenity',
        name: 'amenity',
        geometryType: GEOMETRY_TYPES.POINT,
        supportsDisplayPoint: false,
        categories: {
            information: {name: 'Information', code: 'information'},
        },
        allowedChildrenTypes: [],
    },
    fixture: {
        code: 'fixture',
        name: 'fixture',
        geometryType: GEOMETRY_TYPES.POLYGON,
        supportsDisplayPoint: false,
        categories: {
            wall: {name: 'Wall', code: 'wall'},
            vegetation: {name: 'Vegetation', code: 'vegetation'},
            water: {name: 'Water', code: 'water'},
        },
        allowedChildrenTypes: [],
        style: {
            fillDarkColour: '#555555',
            fillLightColour: '#E8E8E8',
            fillEditorColour: '#E8E8E8',
            borderDarkColour: '#272727',
            borderLightColour: '#DDDDDD',
            borderEditorColour: '#DDDDDD',
            explorerFillOpacity: 1,
            explorerBorderWidth: 0,
            editorFillOpacity: 0.5,
            editorBorderWidth: 0.75,
        },
    },
    /* Extensions to IMDF */
    // These extensions specify types based on imdf specs for additional
    // functionality in detailing features on map
    snapLine: {
        code: 'snapLine',
        name: 'snap line',
        geometryType: GEOMETRY_TYPES.LINE_STRING,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    snapPoint: {
        code: 'snapPoint',
        name: 'snap point',
        geometryType: GEOMETRY_TYPES.POINT,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    displayPoint: {
        code: 'displayPoint',
        name: 'display point',
        geometryType: GEOMETRY_TYPES.POINT,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    venueLabelPoint: {
        code: 'venueLabelPoint',
        name: 'venue label point',
        geometryType: GEOMETRY_TYPES.POINT,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    halfwayPoint: {
        code: 'halfwayPoint',
        name: 'halfway point',
        geometryType: GEOMETRY_TYPES.POINT,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    routeSegment: {
        code: 'routeSegment',
        name: 'route segment',
        geometryType: GEOMETRY_TYPES.LINE_STRING,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    object3d: {
        code: 'object3d',
        name: '3d Objects',
        geometryType: GEOMETRY_TYPES.POINT,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    freeForm: {
        code: 'freeForm',
        name: 'free form',
        geometryType: GEOMETRY_TYPES.POLYGON,
        supportsDisplayPoint: true,
        categories: null,
        allowedChildrenTypes: [],
    },
    threshold: {
        code: 'threshold',
        name: 'threshold',
        geometryType: GEOMETRY_TYPES.POINT,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    group: {
        code: 'group',
        name: 'groups',
        geometryType: GEOMETRY_TYPES.POINT,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    importedLine: {
        name: 'Imported Line',
        code: 'importedLine',
        geometryType: GEOMETRY_TYPES.LINE_STRING,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    importedPoint: {
        name: 'Imported Point',
        code: 'importedPoint',
        geometryType: GEOMETRY_TYPES.POINT,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
    importedPolygon: {
        name: 'Imported Polygon',
        code: 'importedPolygon',
        geometryType: GEOMETRY_TYPES.POLYGON,
        supportsDisplayPoint: false,
        categories: null,
        allowedChildrenTypes: [],
    },
};

export default IMDF_FEATURE_TYPES;
