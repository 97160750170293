import {SOURCES} from 'Utils/constants';
import {makeFeatureTypeBool} from 'Utils/explorer/mapUtils';

// prettier-ignore
export const makeLabelLayers = props => [{
    id: 'symbols',
    type: 'symbol',
    source: SOURCES.LABELS,
    filter: [
        'all',
        ['!=', ['get', 'isDoorway'], true],
        ['!=', ['get', 'isCarEntrance'], true],
        ['!=', ['get', 'isEmergencyExit'], true],
        ['!=', ['get', 'isVenueEntrance'], true]
    ],
    layout: {
        'symbol-z-order': 'source',
        'symbol-sort-key': [
            'case',
            ['==', ['get', 'isDigitalSign'], true],
            0,
            100
        ],
        'text-field': '{mapLabelText}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-max-width': 12,
        'text-size': 12,
        'text-anchor': [
            'case',
            ['==', ['get', 'isDigitalSign'], true],
            'left',
            'center',
        ],
        'text-justify': 'auto',
        'text-radial-offset': 2,
        'text-optional': true,
        'icon-image': [
            'case',
            ['==', ['get', 'isDoubleSidedSign'], true],
            'images/double-arrow-32.png',
            ['==', ['get', 'isDigitalSign'], true],
            'images/single-arrow-32.png',
            '',
        ],
        'icon-rotation-alignment': 'map', // so digital sign icons point in the right direction
        'icon-rotate': {
            type: 'identity',
            property: 'rotation',
        },
        'icon-size': [
            'case',
            ['!=', ['get', 'clientId'], props.currentClientId],
            0.8,
            1
        ],
    },
    paint: {
        'text-color': [
            'match',
            ['get', 'prominence'],
            5, props.minorColor,
            props.majorColor,
        ],
        'text-halo-color': props.haloColor,
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
        'text-opacity': [
            'case',
            ['==', ['get', 'doNotDraw'], true],
            0,
            ['!=', ['get', 'clientId'], props.currentClientId],
            0.2,
            // if isSceneOverride is set false, i.e feature doesn't have override properties for selected scene
            ['==', ['get', 'isSceneOverride'], false],
            0.4,
            // if isSceneOverride is set true, i.e. feature has override properties for selected scene
            // or is unset, as in case of BASE_NAV_MAP mode
            0.9,
        ],
        'icon-opacity': [
            'case',
            ['==', ['get', 'doNotDraw'], true],
            0,
            ['!=', ['get', 'clientId'], props.currentClientId],
            0.2,
            ['==', ['get', 'isSceneOverride'], false],
            0.4,
            0.9,
        ],
    },
},
{
    id: 'openings-labels',
    type: 'symbol',
    source: SOURCES.LABELS,
    filter: [
        'any',
        ['==', ['get', 'isDoorway'], true],
        ['==', ['get', 'isCarEntrance'], true],
        ['==', ['get', 'isEmergencyExit'], true],
        ['==', ['get', 'isVenueEntrance'], true]
    ],
    layout: {
        'symbol-z-order': 'source',
        'text-field': '{mapLabelText}',
        "symbol-placement": 'line-center',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-max-width': 12,
        'text-size': 12,
        'text-anchor': 'center',
        'text-justify': 'auto',
        'text-radial-offset': 2
    },
    paint: {
        'text-color': [
            'match',
            ['get', 'prominence'],
            5, props.minorColor,
            props.majorColor,
        ],
        'text-halo-color': props.haloColor,
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
        'text-opacity': [
            'case',
            // if isSceneOverride is set false, i.e feature doesn't have override properties for selected scene
            ['==', ['get', 'isSceneOverride'], false],
            0.4,
            // if isSceneOverride is set true, i.e. feature has override properties for selected scene
            // or is unset, as in case of BASE_NAV_MAP mode
            0.9,
        ],
    },
}

];

const getFeatureTypeFilter = props => [
    'all',
    ['==', ['get', 'active'], 'false'],
    ['==', ['get', `user_${makeFeatureTypeBool(props)}`], true],
    ['!=', ['get', `user_doNotDraw`], true],
];

export const makePointLayer = props => ({
    id: `pam-${props.code}-border`,
    type: 'circle',
    filter: getFeatureTypeFilter(props),
    paint: {
        'circle-radius': props.radius ?? 5,
        'circle-color': [
            'case',
            // if isSceneOverride is set false, i.e feature doesn't have override properties for selected scene
            ['==', ['get', 'user_isSceneOverride'], false],
            '#bbb',
            // if isSceneOverride is set true, i.e. feature has override properties for selected scene
            // or is unset, as in case of BASE_NAV_MAP mode
            props.color,
        ],
        'circle-stroke-width': props.borderRadius ?? 2,
        'circle-stroke-color': [
            'case',
            ['==', ['get', 'user_isSceneOverride'], false],
            '#fff',
            ['!=', ['get', 'user_clientId'], props.currentClientId],
            props.borderColorOtherClient ?? props.borderColor,
            ['==', ['get', 'user_blockExternalRouting'], true],
            props.borderColorBlockExternalRouting ?? props.borderColor,
            props.borderColor,
        ],
        'circle-opacity': [
            'case',
            ['!=', ['get', 'user_clientId'], props.currentClientId],
            0.25,
            1,
        ],
    },
});

// prettier-ignore
export const makeLineLayer = props => ({
    id: `pam-${props.code}-line`,
    type: 'line',
    filter: getFeatureTypeFilter(props),
    paint: {
        'line-color': [
            'case',
            ['==', ['get', 'user_isSceneOverride'], false],
            '#bbb',
            props.color
        ],
        'line-width': props.lineWidth ?? 1,
        ...(props.lineDashArray && {'line-dasharray': props.lineDashArray}),
    },
});

export const makePolygonLayers = props => {
    const layers = [
        {
            id: `pam-${props.code}-fill`,
            type: 'fill',
            filter: [
                ...getFeatureTypeFilter(props),
                ['!=', ['get', 'user_displayAsBoundary'], true],
            ],
            paint: {
                'fill-color': [
                    'case',
                    ['!=', ['get', 'user_clientId'], props.currentClientId],
                    '#fff',
                    ['==', ['get', 'user_isSceneOverride'], false],
                    '#fff',
                    ['==', ['get', 'user_disabled'], true],
                    '#999',
                    ['==', ['get', 'user_disabledByParent'], true],
                    '#999',
                    props.color,
                ],
                'fill-opacity': [
                    'case',
                    ['!=', ['get', 'user_clientId'], props.currentClientId],
                    0.1,
                    ['==', ['get', 'user_isSceneOverride'], false],
                    0.2,
                    ['==', ['get', 'user_disabled'], true],
                    0.38,
                    ['==', ['get', 'user_disabledByParent'], true],
                    0.38,

                    props.fillOpacity ?? 0.5,
                ],
            },
        },
    ];

    if (props.borderColor) {
        layers.push({
            id: `pam-${props.code}-line`,
            type: 'line',
            filter: getFeatureTypeFilter(props),
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': [
                    'case',
                    ['!=', ['get', 'user_clientId'], props.currentClientId],
                    '#aaa',
                    ['==', ['get', 'user_disabled'], true],
                    '#aaa',
                    ['==', ['get', 'user_disabledByParent'], true],
                    '#aaa',
                    ['==', ['get', 'user_displayAsBoundary'], true],
                    '#b00',
                    ['==', ['get', 'user_isSceneOverride'], false],
                    '#bbb',
                    props.borderColor,
                ],
                'line-width': [
                    'case',
                    ['!=', ['get', 'user_clientId'], props.currentClientId],
                    0.5,
                    ['==', ['get', 'user_displayAsBoundary'], true],
                    1.25,
                    props.borderWidth ?? 1,
                ],
                'line-opacity': 1,
            },
        });
    }

    return layers;
};

// prettier-ignore
export const makeRouteLayers = props => {
    // We have different types of routes
    const definitions = [
        {
            id: 'default',
            filters: [
                ['==', ['get', 'user_routeIsSteps'], false],
                ['==', ['get', 'user_routeAllowsVehicles'], false],
            ],
            lineWidth: 2,
            lineDashArray: [2, 2],
        },
        {
            id: 'vehicles-allowed',
            filters: [
                ['==', ['get', 'user_routeAllowsVehicles'], true]
            ],
            lineWidth: 4,
            lineDashArray: [4, 4],
        },
        {
            id: 'steps',
            filters: [
                ['==', ['get', 'user_routeIsSteps'], true]
            ],
            lineWidth: 10,
            lineDashArray: [0.4, 0.4],
        },
    ];

    const layers = [];

    // Each route is made of two colours
    definitions.forEach(definition => {
        layers.push({
            id: `pam-routeSegment-${definition.id}-base-line`,
            type: 'line',
            filter: [
                ...getFeatureTypeFilter(props),
                ...definition.filters,
            ],
            paint: {
                'line-color': [
                    'case',
                    ['==', ['get', 'user_routeIsNightSafe'], true],
                    '#fdce31',
                    '#ccc',
                ],
                'line-width': definition.lineWidth,
                'line-opacity': [
                    'case',
                    ['!=', ['get', 'user_clientId'], props.currentClientId],
                    0.25,
                    ['==', ['get', 'user_isSceneOverride'], false],
                    0.25,
                    1
                ],
            },
        });
        layers.push({
            id: `pam-routeSegment-${definition.id}-line`,
            type: 'line',
            filter: [...getFeatureTypeFilter(props), ...definition.filters],
            paint: {
                'line-color': [
                    'case',
                    ['==', ['get', 'user_isSceneOverride'], false],
                    '#bbb',
                    props.color,
                ],
                'line-width': definition.lineWidth,
                'line-dasharray': definition.lineDashArray,
                'line-opacity': [
                    'case',
                    ['!=', ['get', 'user_clientId'], props.currentClientId],
                    0.25,
                    ['==', ['get', 'user_isSceneOverride'], false],
                    0.5,
                    1
                ],
            },
        });
    });

    layers.push({
        id: 'pam-one-way-arrows',
        type: 'symbol',
        filter: [
            'all',
            // Note that this shows even when the line is active
            ['==', ['get', `user_${makeFeatureTypeBool(props)}`], true],
            ['==', ['get', 'user_oneWay'], true],
            ['!=', ['get', `user_doNotDraw`], true],
        ],
        layout: {
            'icon-image': 'oneway-large',
            'icon-size': ['interpolate', ['linear'], ['zoom'], 17, 0.8, 19, 2],
            'symbol-placement': 'line',
            'symbol-spacing': 50,
        },
    });

    return layers;
};

export const makeWallEditLayer = props => ({
    id: `pam-${props.code}-line`,
    type: 'line',
    filter: getFeatureTypeFilter(props),
    // prettier-ignore
    paint: {
        'line-width': [
            'interpolate',
            ['exponential', 2], ['zoom'],
            14, 1, // at transition zoom(), width of wall is 1px and its displayed as a line
            24, 24, // at MAX ZOOM, width is 24px (10cm) and is displayed as a thick stroke
        ],
        'line-color': [
            'case',
            ['==', ['get', 'user_isSceneOverride'], false],
            '#bbb',
            props.color
        ],
    }
});

/**
 * Add 'venue' feature type layers to distinguish own and other client venues on the map:
 * - 'line' layer expects that:
 *  - 'isOwnVenue' property should be added to a venue features of navmap having the value:
 *   - true - for own venue feature
 *   - false - for other client venue feature
 *   - other value, or missed property - for all other feature types
 * - 'symbol' layer for the venue labels on the navmap
 * @param {object} imdfVenueType
 * @returns {object}
 */
export const getVenueLayers = imdfVenueType => {
    const style = imdfVenueType.style;

    const makeCaseExpression = (ownValue, otherClientValue, defaultValue) => [
        'case',
        ['==', ['get', 'user_isOwnVenue'], true],
        ownValue,
        ['==', ['get', 'user_isOwnVenue'], false],
        otherClientValue,
        defaultValue,
    ];

    return [
        {
            id: `pam-venue-boundary-line`,
            type: 'line',
            filter: [
                'all',
                ['==', ['get', 'active'], 'false'],
                ['!=', ['get', 'user_doNotDraw'], true],
                makeCaseExpression(true, true, false),
            ],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': makeCaseExpression(
                    style.currentClient.editorBorderColour,
                    style.otherClient.editorBorderColour,
                    style.borderEditorColour
                ),
                'line-width': makeCaseExpression(
                    style.currentClient.editorBorderWidth,
                    style.otherClient.editorBorderWidth,
                    style.editorBorderWidth
                ),
                'line-opacity': makeCaseExpression(
                    style.currentClient.editorBorderOpacity,
                    style.otherClient.editorBorderOpacity,
                    style.editorBorderOpacity
                ),
            },
        },
        {
            id: `pam-venue-label`,
            type: 'symbol',
            filter: [
                'all',
                ['==', ['get', 'user_isVenueLabelPoint'], true],
                ['!=', ['get', 'user_doNotDraw'], true],
            ],
            layout: {
                'text-field': ['get', 'user_name'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
                'text-max-width': 30,
                'text-size': 18,
                'text-allow-overlap': true,
                'text-justify': 'auto',
                'text-transform': 'uppercase',
                'text-radial-offset': 2,
                'text-variable-anchor': ['left'],
                'icon-allow-overlap': true,
                'icon-image': 'images/icon-link-white-72.png',
                'icon-offset': [110, -2],
                'icon-size': 0.25,
            },
            paint: {
                'icon-color': '#fff',
                'icon-halo-color': '#000',
                'icon-halo-width': 4,
                'text-color': '#fff',
                'text-halo-color': '#000',
                'text-halo-width': 5,
            },
        },
    ];
};
