const getList = key => {
    return JSON.parse(localStorage.getItem(key)) || {};
};

export default {
    getItem(key) {
        try {
            const item = localStorage.getItem(key);

            if (!item) return undefined;

            return JSON.parse(item);
        } catch (err) {
            console.error(err);
            return undefined;
        }
    },

    setItem(key, value) {
        try {
            const valueString = JSON.stringify(value);
            localStorage.setItem(key, valueString);
        } catch (err) {
            console.error(err);
        }
    },

    saveListItem(type, boolValue, key) {
        const list = getList(key);
        list[type] = boolValue;
        localStorage.setItem(key, JSON.stringify(list));
    },

    getListItem(type, key, defaultVal = true) {
        const list = getList(key);
        if (list[type] === undefined) return defaultVal;
        return list[type];
    },

    getList,

    getIsDebug() {
        return (
            localStorage.getItem('jypfdtpac') && localStorage.getItem('jypfdtpac') === 'eestarAnde'
        );
    },
};
