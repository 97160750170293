import {ACTION_TYPES} from 'Utils/constants';

const initialState = {
    parkings: [],
    selectedParkingId: null,
    selectedSceneId: null,
    readOnly: true,
};

const parkingManagerPageStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.PM.SET_PAGE_STATE:
            return {...state, ...action.data};
        case ACTION_TYPES.PM.UPDATE_PARKING_CONFIGS:
            return {...state, parkings: action.data};
        case ACTION_TYPES.SET_SELECTED_SCENE: {
            return {...state, selectedSceneId: action.data};
        }
        case ACTION_TYPES.PM.SET_READONLY:
            return {...state, readOnly: action.data};
        default:
            return state;
    }
};

export default parkingManagerPageStateReducer;
