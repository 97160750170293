import {ACTION_TYPES} from 'Utils/constants';

/**
 * @typedef LayoutPageState
 * @property {?string} layoutId
 * @property {?object} config - in whatever shape the layout requires
 */

/** @type {LayoutPageState} */
const initialState = {
    config: null,
    debug: false,
    fitWidth: false,
    layoutId: null,
    parkingLotConfig: null,
    error: null,
    settings: null,
};

const layoutPageStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOADED_LAYOUT_CONFIG: {
            return action.data;
        }
        case ACTION_TYPES.FAILED_LOADING_LAYOUT_CONFIG: {
            return {
                ...state,
                error: action.data.error,
            };
        }
        default:
            return state;
    }
};

export default layoutPageStateReducer;
