import {MAP_LAYERS, PARKING_MANAGER_STATUS_COLOURS, SOURCES} from 'Utils/constants';
import {makeFeatureTypeBool} from 'Utils/explorer/mapUtils';

// convert to PARKING_MANAGER_STATUS_COLOURS to this format [{status: 'hexcolor'}]
const parkingManagerStatusColours = Object.fromEntries(
    Object.entries(PARKING_MANAGER_STATUS_COLOURS).map(item => [item[0], item[1].color])
);

/**
 * Returns two layers. One for digital sign labels, and another for all the other labels.
 *
 * @param props
 * @return {Array<object>}
 */
export const makeLabelLayers = props => [
    {
        id: MAP_LAYERS.LABELS,
        type: 'symbol',
        source: SOURCES.LABELS,
        filter: ['!=', ['has', 'parkingLotConfig'], true],
        layout: {
            'text-field': '{mapLabelText}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
            'text-letter-spacing': 0.1,
            'text-max-width': 8,
            'text-padding': 5,
            // prettier-ignore
            'text-size': [
                'step',
                ['get', 'prominence'],
                9, // for prominence 0
                4, 10, // prominence 4
                5, 11, // you get the idea
            ]
        },
        paint: {
            'text-color': props.minorColor,
            'text-halo-color': props.haloColor,
            'text-halo-width': 2,
            'text-halo-blur': 0.5,
        },
    },
    {
        id: MAP_LAYERS.SIGN_LABELS,
        type: 'symbol',
        source: SOURCES.LABELS,
        filter: [
            'all',
            ['has', 'parkingLotConfig'],
            ['!=', ['get', 'doNotDraw'], true],
            ['==', ['get', 'isMyOwnFeature'], true],
        ],
        layout: {
            'icon-anchor': 'center',
            'icon-image': 'images/dot.png', // show parking status
            'icon-offset': [0, -17],
            'icon-optional': false,
            'icon-size': 1.5,
            'symbol-z-order': 'source',
            'text-field': [
                'case',
                ['has', 'status', ['get', 'parkingLotConfig']], // if have parkingLotConfig,
                [
                    'format',
                    ['concat', ['get', 'mapLabelText']],
                    {
                        'font-scale': 0.8,
                    },
                    [
                        'case',
                        [
                            'all',
                            ['has', 'hasOverride', ['get', 'parkingLotConfig']],
                            ['get', 'hasOverride', ['get', 'parkingLotConfig']],
                        ],
                        '*',
                        '',
                    ],
                    {
                        'font-scale': 1,
                        'text-font': ['literal', ['DIN Offc Pro Bold', 'Arial Unicode MS Regular']],
                    },
                    '\n',
                    [
                        'case',
                        ['boolean', ['has', 'parkingLotConfig'], false], // if have parkingLotConfig, show availableSpaces
                        [
                            'concat',
                            '(',
                            ['get', 'availableSpaces', ['get', 'parkingLotConfig']],
                            ')',
                        ],
                        '',
                    ],
                    {
                        'font-scale': 0.7,
                    },
                ],
                ['get', 'mapLabelText'],
            ],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
            'text-letter-spacing': 0.1,
            'text-size': 14,
            'text-justify': 'auto',
            // Note that additional layout properties are set with setLayoutProperty() elsewhere
        },
        paint: {
            'text-color': [
                'case',
                ['has', 'status', ['get', 'parkingLotConfig']],
                props.signLabelColor,
                '#be1a4c',
            ],
            'text-halo-color': props.haloColor,
            'text-halo-width': 0.5,
            'text-halo-blur': 0.5,
            'icon-color': [
                'case',
                ['has', 'status', ['get', 'parkingLotConfig']],
                [
                    'get',
                    ['get', 'status', ['get', 'parkingLotConfig']],
                    ['literal', parkingManagerStatusColours],
                ],
                ['rgba', 0, 0, 0, 0],
            ],
        },
    },
    {
        id: MAP_LAYERS.SIGN_LABELS_FROM_ANOTHER_CLIENT,
        type: 'symbol',
        source: SOURCES.LABELS,
        filter: ['all', ['has', 'parkingLotConfig'], ['!=', ['get', 'doNotDraw'], true]],
        layout: {
            'icon-anchor': 'center',
            'icon-image': 'images/dot.png', // show parking status
            'icon-offset': [0, -17],
            'icon-optional': false,
            'icon-size': 1.5,
            'symbol-z-order': 'source',
            'text-field': [
                'case',
                ['has', 'status', ['get', 'parkingLotConfig']], // if have parkingLotConfig,
                [
                    'format',
                    ['concat', ['get', 'mapLabelText']],
                    {
                        'font-scale': 0.8,
                    },
                    [
                        'case',
                        [
                            'all',
                            ['has', 'hasOverride', ['get', 'parkingLotConfig']],
                            ['get', 'hasOverride', ['get', 'parkingLotConfig']],
                        ],
                        '*',
                        '',
                    ],
                    {
                        'font-scale': 1,
                        'text-font': ['literal', ['DIN Offc Pro Bold', 'Arial Unicode MS Regular']],
                    },
                    '\n',
                    [
                        'case',
                        ['boolean', ['has', 'parkingLotConfig'], false], // if have parkingLotConfig, show availableSpaces
                        [
                            'concat',
                            '(',
                            ['get', 'availableSpaces', ['get', 'parkingLotConfig']],
                            ')',
                        ],
                        '',
                    ],
                    {
                        'font-scale': 0.7,
                    },
                ],
                ['get', 'mapLabelText'],
            ],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
            'text-letter-spacing': 0.1,
            'text-size': 14,
            'text-justify': 'auto',
            // Note that additional layout properties are set with setLayoutProperty() elsewhere
        },
        paint: {
            'text-color': props.signLabelColor,
            'text-halo-color': props.haloColor,
            'text-halo-width': 0.5,
            'text-halo-blur': 0.5,
            'icon-color': [
                'case',
                ['has', 'status', ['get', 'parkingLotConfig']],
                [
                    'get',
                    ['get', 'status', ['get', 'parkingLotConfig']],
                    ['literal', parkingManagerStatusColours],
                ],
                ['rgba', 0, 0, 0, 0],
            ],
        },
    },
    {
        id: MAP_LAYERS.PARKING_LABELS_FROM_ANOTHER_CLIENT,
        type: 'symbol',
        source: SOURCES.LABELS,
        filter: [
            'all',
            ['==', ['get', 'isParking'], true],
            ['==', ['get', 'isMyOwnFeature'], false],
        ],
        layout: {
            'text-field': '{mapLabelText}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
            'text-letter-spacing': 0.1,
            'text-max-width': 8,
            'text-padding': 5,
            // prettier-ignore
            'text-size': [
                'step',
                ['get', 'prominence'],
                9, // for prominence 0
                4, 10, // prominence 4
                5, 11, // you get the idea
            ]
        },
        paint: {
            'text-color': props.signLabelColor,
        },
    },
];

const selectedColor = 'rgb(189,225,251)'; // '#5EB5F5' with 40% opacity

// Most polygons are rendered as light grey in PM
export const makePolygonLayers = props => {
    const filter = {
        nonParkingFeatures: [
            'all',
            ['==', ['get', makeFeatureTypeBool(props)], true],
            ['!', ['has', 'parkingLotConfig']],
        ],
        isOverrideFilter: [
            'all',
            ['has', 'parkingLotConfig'],
            ['has', 'hasOverride', ['get', 'parkingLotConfig']],
            ['get', 'hasOverride', ['get', 'parkingLotConfig']],
        ],
    };

    return [
        {
            // fill for overrides
            id: `pam-${props.code}-fill-overrides`,
            source: SOURCES.NAV_MAP,
            type: 'fill',
            filter: ['all', ['!=', ['get', 'doNotDraw'], true], [...filter.isOverrideFilter]],
            paint: {
                'fill-color': [
                    'case',
                    ['!', ['to-boolean', ['feature-state', 'selected']]], // so the colours here are manually calculated // for some unknown reason opacity have no effect here
                    'rgb(162,236,204)',
                    selectedColor,
                ],
            },
        },
        {
            id: `pam-${
                props.code // '#0fcf7c' with 40% opacity
            }-fill-non-parking-features`,
            source: SOURCES.NAV_MAP,
            type: 'fill',
            filter: filter.nonParkingFeatures,
            paint: {'fill-color': '#fff', 'fill-opacity': 0.7},
        },
        {
            // fill for selected and parking feature
            id: `pam-${props.code}-fill`,
            source: SOURCES.NAV_MAP,
            type: 'fill',
            filter: [
                'all',
                ['has', 'parkingLotConfig'],
                ['!=', ['get', 'doNotDraw'], true],
                ['!', filter.isOverrideFilter],
                ['==', ['get', 'isMyOwnFeature'], true],
            ],
            paint: {
                'fill-color': [
                    'case',
                    ['boolean', ['feature-state', 'selected'], false],
                    selectedColor,
                    '#E5E5E5',
                ],
                'fill-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'selected'], false],
                    0.4,
                    0.7,
                ],
            },
        },
        {
            // fill parking from another client
            id: `pam-${props.code}-fill-parking-from-another-client`,
            source: SOURCES.NAV_MAP,
            type: 'fill',
            filter: [
                'all',
                ['==', ['get', 'isParking'], true],
                ['==', ['get', 'isMyOwnFeature'], false],
            ],
            paint: {
                'fill-color': '#D5D5D5',
            },
        },
        {
            // fill parking from another client
            id: `pam-${props.code}-line-parking-from-another-client`,
            source: SOURCES.NAV_MAP,
            type: 'line',
            filter: [
                'all',
                ['==', ['get', 'isParking'], true],
                ['==', ['get', 'isMyOwnFeature'], false],
            ],
            paint: {
                'line-color': '#E30C5F',
                'line-width': 1,
                'line-dasharray': [2, 2],
            },
        },
        {
            // border for overrides // if selected // make it this color
            id: `pam-${props.code}-line-overrides`,
            source: SOURCES.NAV_MAP,
            type: 'line',
            filter: ['all', ['!=', ['get', 'doNotDraw'], true], [...filter.isOverrideFilter]],
            layout: {'line-cap': 'round', 'line-join': 'round'},
            paint: {
                'line-color': [
                    'case',
                    ['!', ['to-boolean', ['feature-state', 'selected']]],
                    '#098952',
                    ['to-boolean', ['feature-state', 'selected']],
                    '#3A83B9',
                    '#fff',
                ],
                'line-width': 1,
            },
        },
        {
            id: `pam-${
                props.code // if selected
            }-line-nonParkingFeatures`,
            source: SOURCES.NAV_MAP,
            type: 'line',
            filter: filter.nonParkingFeatures,
            layout: {'line-cap': 'round', 'line-join': 'round'},
            paint: {'line-color': '#bbb', 'line-width': 0.5},
        },
        {
            id: `pam-${props.code}-line`,
            source: SOURCES.NAV_MAP,
            type: 'line',
            filter: ['has', 'parkingLotConfig'],
            layout: {'line-cap': 'round', 'line-join': 'round'},
            paint: {
                'line-color': [
                    'case',
                    ['boolean', ['feature-state', 'selected'], false],
                    '#3A83B9',
                    ['has', 'parkingLotConfig'],
                    '#9E9E9E',
                    '#bbb',
                ],
                'line-width': ['case', ['boolean', ['feature-state', 'selected'], false], 1, 0.5],
            },
        },
    ]; // if selected // make it this color // if selected
};

export const makeRouteLayers = () => ({
    id: 'pam-route-segment-line',
    source: SOURCES.NAV_MAP,
    filter: ['==', ['get', 'isRouteSegment'], true],
    type: 'line',
    layout: {
        'line-cap': 'round',
        'line-join': 'round',
    },
    paint: {
        'line-color': '#e5e5e5',
        'line-width': 2,
        'line-dasharray': [3, 3],
    },
});
