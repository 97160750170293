import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import lodashOmit from 'lodash/omit';
import {LIVE_OVERRIDE_MODE, MAP_THEMES} from 'Utils/constants';
import {renderNavMapToMap} from 'Utils/explorer/mapManager';
import {configMergeWith} from 'Data/actionCreators/dsm/updateConfig';

export const hasOverride = ({parkingLotConfig, defaultConfig}) =>
    JSON.stringify(lodashOmit(parkingLotConfig, 'hasOverride')) !==
    JSON.stringify(lodashOmit(defaultConfig, 'hasOverride'));

export const getParkingDetails = ({
    activeSceneId,
    defaultSceneId,
    parkingLocationId,
    parkingLotConfigs,
}) => {
    // parkingLotConfigs is undefined on a fresh client and throwing an error on find below
    if (!parkingLotConfigs) {
        return {};
    }

    // Find the config for parking from active scene
    const activeSceneConfig = parkingLotConfigs.find(
        config =>
            // @TODO for parkingLocationId, the one from the digital-api is a number,
            //  the one in node-api/mocks is a string, we're just making then of the same type here,
            //  we can improve this later
            config.locationId?.toString() === parkingLocationId?.toString() &&
            config.sceneId === activeSceneId
    );

    // Find the config for parking from default scene
    const baseConfig = parkingLotConfigs.find(
        config =>
            config.locationId.toString() === parkingLocationId.toString() &&
            config.sceneId === defaultSceneId
    );

    // live Overrides will supersede the active scene config
    const liveOverrides = parkingLotConfigs.find(
        config => config.locationId.toString() === parkingLocationId.toString() && !config?.sceneId
    );

    if (!activeSceneConfig && !baseConfig && !liveOverrides) {
        // Nothing to display, there's no config
        return {};
    }

    // An activeScene sign might not have a scene config yet, therefore it will be {}
    const configFromMergedWith = configMergeWith(baseConfig, activeSceneConfig || {});

    // Smoosh them all together
    return configMergeWith(configFromMergedWith, liveOverrides || {});
};

export const updateParkingStatusOnMap = ({
    clientRelationship,
    navMap,
    parkingFeatures,
    selectedSceneId,
    defaultSceneId,
    selectedParkingId,
}) => {
    const updatedNavMap = {...navMap};
    if (selectedSceneId) {
        parkingFeatures.forEach(park => {
            const parkingFeature = updatedNavMap.features.find(
                feature => `${feature.properties.id}` === `${park.properties.id}`
            );

            if (
                (selectedParkingId && park.id !== selectedParkingId) ||
                parkingFeature.properties.displayPoint
            ) {
                return;
            }

            if (park.sceneConfigs[selectedSceneId]?.config) {
                parkingFeature.properties.parkingLotConfig =
                    park.sceneConfigs[selectedSceneId].config;
            }

            // when there is no config and if the current scene is not a default scene
            // pick up default configs
            if (
                selectedSceneId !== defaultSceneId &&
                (!park.sceneConfigs[selectedSceneId]?.config ||
                    Object.keys(park.sceneConfigs[selectedSceneId]?.config).length === 0)
            ) {
                parkingFeature.properties.parkingLotConfig =
                    park.sceneConfigs[defaultSceneId].config;
            }

            // the active overrides config is a merge between the default scene config
            // and the active overrides.
            if (selectedSceneId === LIVE_OVERRIDE_MODE.id) {
                parkingFeature.properties.parkingLotConfig = {
                    ...park.sceneConfigs[defaultSceneId].config,
                    ...park.sceneConfigs[selectedSceneId].config,
                };
            }
            if (selectedSceneId !== defaultSceneId) {
                parkingFeature.properties.parkingLotConfig.hasOverride = hasOverride({
                    parkingLotConfig: parkingFeature.properties.parkingLotConfig,
                    defaultConfig: park.sceneConfigs[defaultSceneId].config,
                });
            }
            if (parkingFeature.properties.isDisplayPoint) {
                const actualParkingFeature = updatedNavMap.features.find(
                    feature => feature.properties.displayPoint && feature.id === parkingFeature.id
                );
                actualParkingFeature.properties.parkingLotConfig =
                    parkingFeature.properties.parkingLotConfig;
            }
        });
    }

    updatedNavMap.features = updatedNavMap.features.map(feature => {
        const isMyOwnFeature = clientRelationship.id === feature.clientId;

        if (feature.properties.isMultiLevelCarpark || feature.properties.isParking) {
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    isMyOwnFeature,
                    isParking:
                        feature.properties.isMultiLevelCarpark || feature.properties.isParking,
                    doNotDraw:
                        parkingFeatures.find(park => park.id === feature.id)?.properties
                            ?.doNotDraw || false,
                },
            };
        }

        if (feature.properties.isDisplayPoint) {
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    isMyOwnFeature,
                    doNotDraw:
                        parkingFeatures.find(
                            park => park.properties.featureId === feature.properties.parentFeatureId
                        )?.properties?.doNotDraw || false,
                },
            };
        }

        return {
            ...feature,
            properties: {
                ...feature.properties,
                isMyOwnFeature,
            },
        };
    });

    renderNavMapToMap({
        navMap: updatedNavMap,
        mapTheme: MAP_THEMES.PM,
    });
};
