import lodashMergeWith from 'lodash/mergeWith';
import lodashFpSet from 'lodash/fp/set';
import {ACTION_TYPES} from 'Utils/constants';
import pruneObject from 'Utils/pruneObject';
import getChangedLayoutConfig from 'Utils/dsm/getChangedLayoutConfig';

/*
 * Merge array single values rather than deep objects
 * Example with _.merge where it fails
 * x = {locationArray: [{id: 1}, {id:3}]};
 * update = {locationArray: [{id:3}]}; // in the autocomplete, we removed the id=1
 * _.merge(x, update)
 * actual result: {"locationArray":[{"id":3},{"id":3}]}
 * Desired result: {"locationArray":[{"id":3}]}
 */
export const configMergeWith = (baseConfig, ...overrideConfig) => {
    // eslint-disable-next-line consistent-return
    const customizer = (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
            return srcValue;
        }
    };

    // {} in front will prevent inadvertent mutation
    return lodashMergeWith({}, baseConfig, ...overrideConfig, customizer);
};

/**
 * Merges objects together and removes any unused properties from the result, except for empty array
 *
 * @param currentSceneConfig
 * @param nextConfigProps
 *
 * @returns {object}
 */
const mergeAndPrune = (currentSceneConfig, nextConfigProps) =>
    pruneObject(configMergeWith(currentSceneConfig, nextConfigProps), false);

/**
 * Change the config for a layout or sign. If value is null,
 * the property is removed from the config object
 *
 * @param {object} nextConfigProps - the properties to update in the config
 * @param {string} propPath - the path in the config that's being updated
 * @return {ThunkFunction}
 */
const updateConfig = (nextConfigProps, propPath) => (dispatch, getState) => {
    const state = getState();
    const {
        selectedSignId,
        selectedLayoutId,
        layouts,
        signs,
        rawLayoutConfigs,
        changedLayoutConfigs,
    } = state.pageState.dsm.present;
    const {selectedSceneId} = state.pageState.dsm.present;
    const defaultSceneId = state.scenes.find(scene => scene.isDefault).id;

    const updateSignOrLayout = (items, selectedItemId) => {
        const item = items.find(({id}) => id === selectedItemId);

        // Get the config object for the selected scene
        const currentSceneConfig = item.sceneConfigs[selectedSceneId].config;

        // Merge in the new props
        const newConfig = mergeAndPrune(currentSceneConfig, nextConfigProps);

        let data = lodashFpSet(`sceneConfigs.${selectedSceneId}.config`, newConfig, item);

        const configLayoutId = data.sceneConfigs[defaultSceneId]?.layoutId;
        if (configLayoutId) {
            // If available, add the layoutId in the sceneConfig
            data = lodashFpSet(`sceneConfigs.${selectedSceneId}.layoutId`, configLayoutId, data);
        }

        // Immutably update the config for this scene
        return {
            data: {
                changedLayoutConfigs: getChangedLayoutConfig({
                    sign: data,
                    sceneId: selectedSceneId,
                    changedLayoutConfigs,
                    rawLayoutConfigs,
                    selectedLayoutId,
                    selectedSignId,
                }),
                config: data,
            },
            propPath: `sceneConfigs.${selectedSceneId}.config.${propPath}`,
        };
    };

    let configData;
    if (selectedLayoutId) {
        configData = {
            ...updateSignOrLayout(layouts, selectedLayoutId),
            type: ACTION_TYPES.DSM.UPDATE_LAYOUT,
        };
    } else if (selectedSignId) {
        configData = {
            ...updateSignOrLayout(signs, selectedSignId),
            type: ACTION_TYPES.DSM.UPDATE_SIGN,
        };
    }

    dispatch({...configData});
};

export default updateConfig;
