import {isAngle} from 'Utils/math';

export const NO_ARROW_VALUE = -1;
export const ARROW_SHADE = {
    DARK: 'dark',
    DISABLED: 'disabled',
};

export const DIRECTIONS_TO_DEGREES = {
    N: 0,
    NE: 45,
    E: 90,
    SE: 135,
    S: 180,
    SW: 225,
    W: 270,
    NW: 315,
};

export const ARROW_DIRECTION_LIST = [
    NO_ARROW_VALUE,
    DIRECTIONS_TO_DEGREES.N,
    DIRECTIONS_TO_DEGREES.NE,
    DIRECTIONS_TO_DEGREES.E,
    DIRECTIONS_TO_DEGREES.SE,
    DIRECTIONS_TO_DEGREES.S,
    DIRECTIONS_TO_DEGREES.SW,
    DIRECTIONS_TO_DEGREES.W,
    DIRECTIONS_TO_DEGREES.NW,
];

// This is the correct way to check if arrowDirection is not empty
// We'll use a single function because it's prone to error.
// Example where it can go wrong
// Expression: arrowDirection || NO_ARROW_VALUE will return NO_ARROW_VALUE if arrowDirection is 0
export const isArrowDirectionNotEmpty = arrowDirection =>
    arrowDirection === NO_ARROW_VALUE || isAngle(arrowDirection);
