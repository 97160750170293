import lodashIsNull from 'lodash/isNull';
import lodashIsUndefined from 'lodash/isUndefined';
import fetchFromApi from 'Data/services/fetchFromApi';
import log from 'Utils/log';
import config from 'config.json';

export default async function fetchLayoutDataByNavmapId({navmapId, layoutId, oldLayoutDataEtag}) {
    try {
        let layoutDataEtag = oldLayoutDataEtag;
        const options = {sendNewRelicParams: true};
        if (oldLayoutDataEtag) {
            options.etag = oldLayoutDataEtag;
        }

        const explorerData = await fetchFromApi(
            `${config.nodeApiUrl}/layout-data-by-navmapId/${navmapId}/${layoutId}`,
            options
        );

        if (lodashIsNull(explorerData?.body) || lodashIsUndefined(explorerData?.body)) {
            // This is where is goes when it's HTTP 304 or 404
            return null;
        }

        if (explorerData.headers) {
            layoutDataEtag = explorerData.headers.etag ? explorerData.headers.etag : null;
        }

        // @TODO Add a dummy config to display in the screen instead of an error page
        // If it's not a valid json, it will throw an error
        JSON.parse(JSON.stringify(explorerData.body));

        if (explorerData.body?.error) {
            return {explorerData: explorerData.body};
        }

        // Prevent unnecessary refresh when the version is not available
        if (!explorerData.body?.version) {
            log('Missing version from the API response');
            return {error: `Missing version in API response. [Deployment in progress?]`};
        }

        if (explorerData.body.version !== window.getReleaseVersion()) {
            // @TODO We can add a logging code here for sending the previous and new version
            // to google-analytics or new relic
            log('Reloading due to new version detected');
            window.location.reload();
        }

        return {data: explorerData.body, layoutDataEtag};
    } catch (error) {
        return {error: error.message || error};
    }
}
