import {ACTION_TYPES} from 'Utils/constants';

const clientRelationshipReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_CLIENT_RELATIONSHIP:
            return action.data || {};
        default:
            return state;
    }
};

export default clientRelationshipReducer;
