/** keep this file light, and dont use/import other files
 * this file is needed to be loaded
 *  and initialized before rest of the application
 */

import React from 'react';
import PropTypes from 'prop-types';

const PreloaderComponent = ({progress}) => {
    const radius = 44;
    const stroke = 4;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <svg height={radius * 2} width={radius * 2}>
            <circle
                stroke="#fff"
                fill="transparent"
                strokeWidth={stroke}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />

            <g id="PamLogo-2022" transform="translate(2 2) scale(.74)">
                <path
                    d="M84.572,86.008h0l-28.2,16.066,28.2-61.528,28.2,61.528Z"
                    transform="translate(-26.756 -19.246)"
                    fill="#3f6"
                />
            </g>

            <circle
                className="preloader-circle"
                stroke="#3f6"
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={`${circumference} ${circumference}`}
                style={{strokeDashoffset}}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
        </svg>
    );
};

PreloaderComponent.propTypes = {
    progress: PropTypes.number,
};

export default PreloaderComponent;
