import {ACTION_TYPES} from 'Utils/constants';
import {HISTORY_EVENTS} from 'Utils/mapEditor/mapEditorConstants';
import lodashFlatten from 'lodash/flatten';
/**
 * @typedef SharedPageState
 * @property {object} editHistory
 * @property {boolean} hasUnsavedChanges
 * @property {?string} selectedSceneId
 */

/** @type {SharedPageState} */
const initialState = {
    editHistory: {
        lastKnownFeatures: [], // so we can compare current/previous when a feature updates
        undoStack: [],
        redoStack: [],
        changeStack: [],
    },
    hasUnsavedChanges: false, // @TODO use the setHasUnsavedChanges() action when changing the state
    selectedSceneId: null,
    isMapUpdateAvailable: false,
};

const nonChangeScenesActions = actions =>
    lodashFlatten(actions).filter(action => action.action !== HISTORY_EVENTS.CHANGE_SCENE);

const sharedPageStateReducer = (state = initialState, action) => {
    switch (action.type) {
        // Any action in DSM or Map Editor that results in a save-able change should be handled here
        // DSM doesn't have undo yet, so we catch individual update actions
        case ACTION_TYPES.DSM.UPDATE_LAYOUT:
        case ACTION_TYPES.DSM.UPDATE_SIGN:
            return {
                ...state,
                hasUnsavedChanges: true,
            };
        case ACTION_TYPES.PM.UPDATE_PARKING_CONFIGS:
            return {
                ...state,
                hasUnsavedChanges: true,
            };
        case ACTION_TYPES.PM.UPDATE_PARKING_SIGN_FEATURES:
            return {
                ...state,
            };
        case ACTION_TYPES.SET_HAS_UNSAVED_CHANGES:
            return {
                ...state,
                hasUnsavedChanges: action.data,
            };
        case ACTION_TYPES.SET_LAST_KNOWN_FEATURES: {
            return {
                ...state,
                editHistory: {
                    ...state.editHistory,
                    lastKnownFeatures: action.data,
                },
            };
        }
        case ACTION_TYPES.ADD_TO_UNDO: {
            return {
                ...state,
                editHistory: {
                    ...state.editHistory,
                    undoStack: state.editHistory.undoStack.concat([action.data]),
                },
                hasUnsavedChanges: !!nonChangeScenesActions(action.data).length,
            };
        }
        case ACTION_TYPES.SET_UNDO_STACK: {
            return {
                ...state,
                editHistory: {
                    ...state.editHistory,
                    undoStack: action.data,
                },
                hasUnsavedChanges: !!nonChangeScenesActions(action.data).length,
            };
        }
        case ACTION_TYPES.PUSH_TO_CHANGE_STACK: {
            const newChangeStack = [...state.editHistory.changeStack, action.data];
            return {
                ...state,
                editHistory: {
                    ...state.editHistory,
                    changeStack: newChangeStack,
                },
                hasUnsavedChanges: !!nonChangeScenesActions(action.data).length,
            };
        }
        case ACTION_TYPES.POP_FROM_CHANGE_STACK: {
            const _changeStack = [...state.editHistory.changeStack];
            _changeStack.pop();
            return {
                ...state,
                editHistory: {
                    ...state.editHistory,
                    changeStack: _changeStack,
                },
                hasUnsavedChanges: !!nonChangeScenesActions(action.data).length,
            };
        }
        case ACTION_TYPES.SET_CHANGE_STACK: {
            return {
                ...state,
                editHistory: {
                    ...state.editHistory,
                    changeStack: action.data,
                },
                hasUnsavedChanges: !!nonChangeScenesActions(action.data).length,
            };
        }
        case ACTION_TYPES.ADD_TO_REDO: {
            return {
                ...state,
                editHistory: {
                    ...state.editHistory,
                    redoStack: state.editHistory.redoStack.concat([action.data]),
                },
            };
        }
        case ACTION_TYPES.SET_REDO_STACK: {
            return {
                ...state,
                editHistory: {
                    ...state.editHistory,
                    redoStack: action.data,
                },
            };
        }
        case ACTION_TYPES.SET_SELECTED_SCENE: {
            return {
                ...state,
                selectedSceneId: action.data,
            };
        }
        case ACTION_TYPES.SET_IS_MAP_UPDATE_AVAILABLE: {
            return {
                ...state,
                isMapUpdateAvailable: action.data,
            };
        }
        default:
            return state;
    }
};

export default sharedPageStateReducer;
