import lodashIsPlainObject from 'lodash/isPlainObject';
import log from 'Utils/log';
import config from 'config.json';

const fetchPromotions = clientId => {
    const url = `${config.serverlessApiUrl}/promotions/${clientId}`;
    return fetch(url)
        .then(response => {
            if (!response.ok && response.status !== 304) {
                throw Error(`${response.status} - ${response.statusText}`);
            }

            return response.status !== 304
                ? response.json()
                : {
                      data: [],
                      etag: null,
                  };
        })
        .catch(err => {
            // Errors like fetch TypeError will return a string and not a JSON
            const errorMessage = lodashIsPlainObject(err) ? JSON.stringify(err) : err;
            log.error(`${errorMessage} - ${new URL(url).pathname}`);
            return {};
        });
};

export default fetchPromotions;
