import lodashIsNull from 'lodash/isNull';
import lodashIsUndefined from 'lodash/isUndefined';
import fetchFromApi from 'Data/services/fetchFromApi';
import store from 'Data/store';
import log from 'Utils/log';
import config from 'config.json';
import {ACTION_TYPES} from 'Utils/constants';

export default async function fetchDigitalSignData({
    digitalSignId,
    oldDigitalSignDataEtag,
    oldCommonSignDataEtag,
    skipReduxStore,
}) {
    try {
        let newDigitalSignDataEtag = oldDigitalSignDataEtag;
        let newCommonSignDataEtag = oldCommonSignDataEtag;

        let isDigitalSignDataUpdated = false;
        let isCommonSignDataUpdated = false;

        let digitalSignData = await fetchFromApi(
            `${config.nodeApiUrl}/digital-sign-data/${digitalSignId}`,
            {
                sendNewRelicParams: true,
                ...(oldDigitalSignDataEtag && {etag: oldDigitalSignDataEtag}),
            }
        );

        if (lodashIsNull(digitalSignData?.body) || lodashIsUndefined(digitalSignData?.body)) {
            digitalSignData = {body: store.getState().pageState.digitalSignData};
        } else {
            if (digitalSignData.body?.error) {
                return {
                    data: digitalSignData.body,
                };
            }

            // Prevent unnecessary refresh when the version is not available
            if (!digitalSignData.body?.version) {
                log('Missing version from the API response');
                return {
                    error: `Missing version in API response. [Deployment in progress?]`,
                };
            }

            // eslint-disable-next-line no-console
            const windowVersion = window.getReleaseVersion();

            if (digitalSignData.body.version !== windowVersion) {
                // @TODO We can add a logging code here for sending the previous and new version
                // to google-analytics or new relic
                log('Reloading due to new version detected');
                window.location.reload();
            }

            isDigitalSignDataUpdated = true;
            if (!skipReduxStore) {
                store.dispatch({
                    type: ACTION_TYPES.SET_DIGITAL_SIGN_DATA,
                    data: digitalSignData.body,
                });
            }
        }

        const clientId = digitalSignData.body.clientId;

        let commonSignData = await fetchFromApi(
            `${config.nodeApiUrl}/common-sign-data/${clientId}`,
            {
                sendNewRelicParams: false,
                ...(oldCommonSignDataEtag && {etag: oldCommonSignDataEtag}),
            }
        );

        if (lodashIsNull(commonSignData?.body) || lodashIsUndefined(commonSignData?.body)) {
            commonSignData = {body: store.getState().pageState.commonSignData};
        } else if (commonSignData.body?.error) {
            return {
                data: commonSignData.body,
            };
        } else {
            isCommonSignDataUpdated = true;
            if (!skipReduxStore) {
                store.dispatch({
                    type: ACTION_TYPES.SET_COMMON_SIGN_DATA,
                    data: commonSignData.body,
                });
            }
        }

        if (!isDigitalSignDataUpdated && !isCommonSignDataUpdated) {
            // both endpoints returned 304
            return null;
        }

        if (digitalSignData.headers) {
            newDigitalSignDataEtag = digitalSignData.headers.etag
                ? digitalSignData.headers.etag
                : null;
        }
        if (commonSignData.headers) {
            newCommonSignDataEtag = commonSignData.headers.etag
                ? commonSignData.headers.etag
                : null;
        }

        const data = {...commonSignData.body, ...digitalSignData.body};
        return {
            data,
            digitalSignDataEtag: newDigitalSignDataEtag,
            commonSignDataEtag: newCommonSignDataEtag,
        };
    } catch (error) {
        return {error: error.message || error};
    }
}
