import {ACTION_TYPES} from 'Utils/constants';

const searchFiltersReducer = (state = [], action) => {
    switch (action.type) {
        case ACTION_TYPES.LOADED_SEARCH_FILTERS:
            return action.data || [];
        default:
            return state;
    }
};

export default searchFiltersReducer;
