import {isArrowDirectionNotEmpty} from 'Components/common/DirectionalArrow/directionalArrowUtils';

/**
 * Return all other slot configuration if they're available
 * @param slotConfig
 * @returns {{}}
 */
function getOtherSlotConfigurations(slotConfig) {
    const configWithLocations = {};

    if (isArrowDirectionNotEmpty(slotConfig?.arrowDirection)) {
        configWithLocations.arrowDirection = slotConfig.arrowDirection;
    }

    if (typeof slotConfig?.hasLine !== 'undefined') {
        configWithLocations.hasLine = slotConfig?.hasLine;
    }

    if (typeof slotConfig?.lineBreak !== 'undefined') {
        configWithLocations.lineBreak = slotConfig?.lineBreak;
    }

    if (typeof slotConfig?.indent !== 'undefined') {
        configWithLocations.indent = slotConfig.indent;
    }

    if (typeof slotConfig?.fontSize !== 'undefined') {
        configWithLocations.fontSize = slotConfig.fontSize;
    }

    if (typeof slotConfig?.textColor !== 'undefined') {
        configWithLocations.textColor = slotConfig.textColor;
    }

    if (typeof slotConfig?.backgroundColor !== 'undefined') {
        configWithLocations.backgroundColor = slotConfig.backgroundColor;
    }

    return configWithLocations;
}

export default getOtherSlotConfigurations;
