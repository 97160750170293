import {
    CONFIG_DATA_BASE_KEYS,
    LAYOUT_IDS,
    EXPLORER_MENU_DEFAULT_COLOR,
    NAV_MAP_DEFAULT_CONFIG,
    MENU_DEFAULT_LABELS,
    MENU_DEFAULT_LABELS_BG_COLOURS,
} from 'Utils/constants';
import {
    KIOSK_MAP_CONFIG_MAP_STYLES,
    SIGN_TYPES,
    SIGNS_CONCAT_CHAR_DEFAULT,
} from 'Components/layouts/hpkDds/ddsLayoutConstants';
import throwIfInvalidId from 'Components/layouts/common/throwIfInvalidId';
import {EXPERIENCES} from '../experiencesLayout/experienceLayoutConstants';
import {KIOSK_MODES} from '../kiosk/kioskLayoutConstants';
import {KIOSK_ORIENTATION} from '../kiosk/KioskConfigPanel/ConfigComponentsConstants';

/**
 * Get the object to use when first creating a new config record
 *
 * @param {string} layoutId
 * @return {object} config - the default config
 */
export default layoutId => {
    throwIfInvalidId(layoutId);

    const CONFIG_DEFAULTS = {
        [LAYOUT_IDS.KIOSK]: {
            [CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]: {
                orientation: KIOSK_ORIENTATION.PORTRAIT,
                header: {showLocation: true, showDirections: true},
                widgets: {showClock: true},
                mapConfig: {
                    mapStyle: KIOSK_MAP_CONFIG_MAP_STYLES.LIGHT,
                    pitch: 45,
                    compassPitch: 0,
                    zoom: NAV_MAP_DEFAULT_CONFIG.defaultNavMapZoom,
                    bearing: null,
                    displayMapStyleControl: true,
                    displayNightSafe: true,
                },
                concatGroup: {P_4_2: SIGNS_CONCAT_CHAR_DEFAULT},
                idleScreen: {
                    ctaPrompt: 'Tap Screen to find your way',
                    qrPrompt: 'SCAN TO EXPLORE',
                    mode: KIOSK_MODES.IDLE_ADVERTISMENT,
                    showExplorerQR: true,
                    ctaColour: EXPLORER_MENU_DEFAULT_COLOR.CTA,
                    ctaTitleText: "Let's get you there!",
                },
                menuTab: {
                    enableExperiences: true,
                    enableDirectoryWithKiosk: true,
                    enablePromotionsWithKiosk: false,
                    enableDirectionsOnExplorer: true,
                    landingPage: 'explore',
                    menuLabelExplore: MENU_DEFAULT_LABELS.EXPLORE,
                    menuLabelSearch: MENU_DEFAULT_LABELS.SEARCH,
                    menuLabelExperiences: MENU_DEFAULT_LABELS.EVENTS,
                    menuLabelDirectory: MENU_DEFAULT_LABELS.TENANTS,
                    menuLabelPromotions: MENU_DEFAULT_LABELS.PROMOTIONS,
                    menuLabelDirections: MENU_DEFAULT_LABELS.DIRECTIONS,
                    labelColourDark: '#ffffff',
                    labelColourLight: '#222222',
                    menuColourSearch: MENU_DEFAULT_LABELS_BG_COLOURS.SEARCH,
                    menuColourExplore: MENU_DEFAULT_LABELS_BG_COLOURS.EXPLORE,
                    menuColourExperiences: MENU_DEFAULT_LABELS_BG_COLOURS.EVENTS,
                    menuColourDirectory: MENU_DEFAULT_LABELS_BG_COLOURS.TENANTS,
                    menuColourPromotions: MENU_DEFAULT_LABELS_BG_COLOURS.PROMOTIONS,
                    menuColourDirections: MENU_DEFAULT_LABELS_BG_COLOURS.DIRECTIONS,
                    showLabelsOnKiosk: false,
                    showLabelsBgOnKiosk: false,
                    showLabelsAsInlineOnKiosk: false,
                },
                internalLocations: {
                    resultLimit: 10,
                },
                externalLocations: {
                    searchBoundary: {},
                },
            },
        },
        [LAYOUT_IDS.HPK_DDS]: {
            [CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]: {
                signTemplateSize: {
                    V_3_1: {width: SIGN_TYPES.V_3_1.width, height: SIGN_TYPES.V_3_1.height},
                    V_3_2: {width: SIGN_TYPES.V_3_2.width, height: SIGN_TYPES.V_3_2.height},
                    P_3_1: {width: SIGN_TYPES.P_3_1.width, height: SIGN_TYPES.P_3_1.height},
                    P_3_2: {width: SIGN_TYPES.P_3_2.width, height: SIGN_TYPES.P_3_2.height},
                },
                usePixelDesign: false,
                bannerSlides: {
                    secondsPerSlide: 6,
                },
            },
            [CONFIG_DATA_BASE_KEYS.WITHOUT_INHERITANCE]: {
                slots: {},
                bannerSlides: {
                    size: 'none',
                    hiddenExperiencesIds: [],
                },
            },
        },
        [LAYOUT_IDS.HPK_PARKING_LOT]: {
            [CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]: {
                signTemplateSize: {
                    P_5_2: {
                        width: SIGN_TYPES.P_5_2.width,
                        height: SIGN_TYPES.P_5_2.height,
                        iconSize: SIGN_TYPES.P_5_2.width / 2,
                    },
                    V_6: {
                        width: SIGN_TYPES.V_6.width,
                        height: SIGN_TYPES.V_6.height,
                        iconSize: SIGN_TYPES.V_6.width / 2,
                    },
                    V_6_2: {
                        width: SIGN_TYPES.V_6_2.width,
                        height: SIGN_TYPES.V_6_2.height,
                        iconSize: SIGN_TYPES.V_6_2.width / 2,
                    },
                },
                concatGroup: {
                    P_5_2: 'to',
                    V_6: 'to',
                    V_6_2: 'to',
                },
                availableSpaces: {
                    label: 'Available',
                    textColor: '#FFF',
                },
                rates: {
                    textColor: '#FDC427',
                },
                showParkingStatus: true,
                showParkingRates: true,
                showParkingAvailableSpaces: true,
                usePixelDesign: true,
            },
        },
        [LAYOUT_IDS.EXPERIENCES]: {
            [CONFIG_DATA_BASE_KEYS.WITH_INHERITANCE]: {
                slideshowSpeed: EXPERIENCES.DEFAULT_SPEED_IN_SECONDS,
                showBrandLogo: true,
            },
        },
    };

    return CONFIG_DEFAULTS[layoutId];
};
