import getCompassRotation from 'Utils/compassUtil';

class CompassButton {
    constructor({map, mapBearing, clickHandler}) {
        this.mapBearing = mapBearing;
        this.map = map;
        this.clickHandler = clickHandler;
    }

    onAdd() {
        const _this = this;
        this.map.on('rotate', e => {
            const rotation = getCompassRotation(e.target.getBearing());
            _this._btn.style.transform = `rotate(${rotation}deg)`;
        });
        this._btn = document.createElement('button');
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-compass';
        this._btn.style.transform = `rotate(${getCompassRotation(this.mapBearing)}deg)`;
        this._btn.type = 'button';
        this._btn['aria-label'] = 'Center the map';
        this._btn.onclick = () => {
            _this.clickHandler();
        };

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this.map = undefined;
    }
}

export default CompassButton;
