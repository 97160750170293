import {ACTION_TYPES} from 'Utils/constants';

const setPathFound = ({pathFound}) => dispatch => {
    dispatch({
        type: ACTION_TYPES.SET_PATH_FOUND,
        data: pathFound,
    });
};

export default setPathFound;
