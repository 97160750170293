import {insert, newHistory, presentStateValidator} from 'Utils/undoableUtils';
import {ACTION_TYPES, UNDOABLE_PROP_PATH_KEY} from 'Utils/constants';

/**
 * Higher order reducer to add undo/redo functionality to redux state containers.
 * The idea is from https://redux.js.org/recipes/implementing-undo-history/
 * and slightly modified according to our needs.
 *
 * @param reducer
 * @param groupBy
 * @returns {Function}
 */
function undoableEnhancer(reducer, groupBy = () => null) {
    // Call the reducer with empty action to populate the initial state
    const initialState = {
        past: [],
        present: reducer(undefined, {}),
        future: [],
    };

    // Return a reducer that handles undo and redo
    return (state = initialState, action = {}) => {
        if (action.type === ACTION_TYPES.UNDOABLE_ENHANCER.INIT) {
            return presentStateValidator({
                ...state,
                past: [],
            });
        }

        const {past, present, future} = state;
        const history = state;

        switch (action.type) {
            case ACTION_TYPES.UNDOABLE_ENHANCER.UNDO:
                return presentStateValidator({
                    past: past.slice(0, past.length - 1),
                    present: past[past.length - 1],
                    future: [present, ...future],
                });
            case ACTION_TYPES.UNDOABLE_ENHANCER.REDO:
                return presentStateValidator({
                    past: [...past, present],
                    present: future[0],
                    future: future.slice(1),
                });
            default:
                /* eslint-disable no-case-declarations */
                const previousPresent = reducer(history.present, action);
                const group = groupBy(action, previousPresent, history);
                /* eslint-enable no-case-declarations */

                // propPath is the path in the config, i.e. locations.slot2, darkTheme
                // Selecting different signs consecutively will group them to avoid unnecessary change in history
                if ('propPath' in action || action.type === ACTION_TYPES.DSM.SET_PAGE_STATE) {
                    // We're checking if we need to create a new undoable history because we're grouping actions
                    if (
                        group != null &&
                        group === history.group &&
                        (action.propPath === present[UNDOABLE_PROP_PATH_KEY] ||
                            action.type === ACTION_TYPES.DSM.SET_PAGE_STATE) &&
                        history.past.length > 0
                    ) {
                        return presentStateValidator(
                            newHistory(
                                history.past,
                                previousPresent,
                                history.future,
                                group,
                                action.propPath
                            )
                        );
                    }

                    if (
                        action.propPath !== present[UNDOABLE_PROP_PATH_KEY] &&
                        history.future.length > 0
                    ) {
                        // This block of code will run when doing an action after group undo.
                        // This is to make sure that different propPath will trigger a newHistory
                        history.past.push(present);
                        return presentStateValidator(
                            newHistory(history.past, previousPresent, [], group, action.propPath)
                        );
                    }
                }

                // eslint-disable-next-line no-case-declarations
                const newState = presentStateValidator(
                    insert(history, previousPresent, group, action.propPath)
                );

                // When selecting a sign there is an extra DSM.SET_PREVIEW_IS_READY action
                // This should action should NOT be part of the history.
                if (action.type === ACTION_TYPES.DSM.SET_PREVIEW_IS_READY) {
                    newState.past.pop();
                }
                return newState;
        }
    };
}

export default undoableEnhancer;
