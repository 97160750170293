import * as turfHelpers from '@turf/helpers';
import turfCenter from '@turf/center';
import turfPointsWithinPolygon from '@turf/points-within-polygon';
import polyline from '@mapbox/polyline';
import directionsService from '@mapbox/mapbox-sdk/services/directions';
import {
    GEOMETRY_TYPES,
    LOCATION_TYPES,
    MAPBOX_ACCESS_TOKEN,
    ROUTE_STYLES,
    TRAVEL_MODES,
} from 'Utils/constants';
import * as routeManager from 'Utils/routeManager';
import * as mapUtils from 'Utils/explorer/mapUtils';
import log from 'Utils/log';
import * as mapEditorUtils from 'Utils/mapEditor/mapEditorUtils';
import FEATURE_TYPES from 'Utils/featureTypes';
import * as locationUtils from 'Utils/locationUtils';
import {KIOSK_ROUTE_MAP} from 'Components/layouts/kiosk/kioskLayoutConstants';
import {areNodesIntersect, getFeaturesInGroup, SPEEDS} from 'Utils/routeManager';
import turfDistance from '@turf/distance';
import IMDF_FEATURE_TYPES from './imdfFeatureTypes';

const directionsClient = directionsService({accessToken: MAPBOX_ACCESS_TOKEN});

const MAX_SEARCH_DEPTH = 2;

const getRouteStyle = travelMode =>
    travelMode === TRAVEL_MODES.DRIVING ? ROUTE_STYLES.DRIVING_ROUTE : ROUTE_STYLES.WALKING_ROUTE;

export const isThereExternalLocations = stops =>
    stops
        .filter(stop => stop)
        .find(({properties: {locationType}}) => locationType === LOCATION_TYPES.MAPBOX);

/**
 * Gets the PAM part of a journey
 *
 * @param {object} props
 * @param {Array<Feature>} props.startNodes - the possible start points
 * @param {Array<Feature>} props.endNodes - the possible end points
 * @param {boolean} props.wheelchairOnly
 * @param {boolean} props.safeOnly
 * @param {string} props.travelMode
 * @param {Feature} props.vStartPoint
 * @param {Feature} props.vEndPoint
 * @param {boolean} props.ignoreMaxWalkingDistance
 * @return {object|undefined} the generated route
 */
const getPamRoute = ({
    startNodes,
    endNodes,
    wheelchairOnly,
    safeOnly,
    travelMode,
    vStartPoint,
    vEndPoint,
    ignoreMaxWalkingDistance,
}) => {
    const route = routeManager.getRoute({
        startNodes,
        endNodes,
        edgeFilter: (node, edge) => {
            if (wheelchairOnly && !edge.routeIsWheelchairAccessible) {
                return false;
            }
            if (travelMode === TRAVEL_MODES.WALKING && !edge.routeAllowsPedestrians) {
                return false;
            }
            return !(
                travelMode === TRAVEL_MODES.DRIVING &&
                !edge.routeAllowsVehicles &&
                !edge.routeAllowsPedestrians &&
                !edge.isTransitionPoint
            );
        },
        /* Not using this property to filter the routes,
           as per DEV-7900 we need to use routeIsNightSafe to only highlight
           a night safe segment in a route
           || (safeOnly && !props.routeIsNightSafe) */
        highlightNightSafeSegments: safeOnly,
        travelMode,
        vStartPoint,
        vEndPoint,
        ignoreMaxWalkingDistance,
    });

    if (!route) {
        return undefined;
    }

    route.routeLegs.forEach((routeLeg, index) => {
        route.routeLegs[index].properties.routeStyle = getRouteStyle(
            routeLeg.properties.travelMode
        );
    });

    route.fullRoute.properties.routeStyle = getRouteStyle(travelMode);
    return route;
};

const getThresholdUsed = ({route, isOutbound}) => {
    let result = null;
    const coords = route.geometry.coordinates;
    if (isOutbound) {
        result = coords[coords.length - 1];
    } else {
        result = coords[0];
    }

    return turfHelpers.point(result);
};

const getPamToThresholdRoute = ({
    pamFeature,
    isOutbound,
    wheelchairOnly,
    safeOnly,
    travelMode,
    vStartPoint,
    vEndPoint,
}) => {
    let startNodes;
    let endNodes;

    const thresholdNodes = routeManager
        .getThresholds()
        .map(
            threshold =>
                routeManager.getNodesInFeature(threshold, travelMode, false).nodesInFeatures
        )
        .filter(node => !!node)
        .flat();

    // If there's no drop-off points it means no driving.
    // So we can just get a walking route from feature to threshold
    const nodesInFeatureResult = routeManager.getNodesInFeature(pamFeature, travelMode, isOutbound);
    const nodeIdsByFeatureId = nodesInFeatureResult.nodeIdsByFeatureId;
    if (isOutbound) {
        startNodes = nodesInFeatureResult.nodesInFeatures;
        endNodes = thresholdNodes;
    } else {
        startNodes = thresholdNodes;
        endNodes = nodesInFeatureResult.nodesInFeatures;
    }

    if (!startNodes || !endNodes || startNodes.length === 0 || endNodes.length === 0) {
        return undefined;
    }

    const pamRoute = getPamRoute({
        startNodes,
        endNodes,
        travelMode,
        wheelchairOnly,
        safeOnly,
        vStartPoint,
        vEndPoint,
        ignoreMaxWalkingDistance: true,
    });

    if (!pamRoute) {
        return undefined;
    }

    const thresholdUsed = getThresholdUsed({route: pamRoute.fullRoute, isOutbound});
    let locationUsed;

    if (pamFeature.properties.routeViaGroupId) {
        const featuresInGroup = getFeaturesInGroup(pamFeature.properties.routeViaGroupId);
        if (featuresInGroup) {
            const nodeId = isOutbound ? pamRoute.firstNodeId : pamRoute.lastNodeId;
            locationUsed = featuresInGroup.find(featureInGroup => {
                return (
                    nodeIdsByFeatureId[featureInGroup.id] &&
                    nodeIdsByFeatureId[featureInGroup.id].includes(nodeId)
                );
            });
        }
    }

    return {
        pamRoute,
        thresholdUsed,
        locationUsed,
    };
};

/**
 * Returns list of parent features starting from the feature itself up to MAX_SEARCH_DEPTH levels up in the locations hierarchy:
 * [
 *     feature,
 *     parentFeature,
 *     parentParentFeature,
 *     ...
 * ]
 * Or until the 'venue' parent feature is reached
 * @param feature
 * @returns []
 */
const getClosestParents = feature => {
    let currentDepth = 0;
    let currentNode = feature;
    const result = [currentNode];
    while (currentNode && currentNode.properties.parentId && currentDepth < MAX_SEARCH_DEPTH) {
        currentNode = routeManager.getParentFeature(currentNode.properties.parentId);
        if (currentNode) {
            if (
                currentNode.properties.featureType.imdfFeatureType.code ===
                IMDF_FEATURE_TYPES.venue.code
            ) {
                break;
            }
            result.push(currentNode);
            currentDepth++;
        }
    }
    return result;
};

/**
 * Returns list of possible direction search items taking into account parent locations.
 * List is ordered starting from start and end locations traversing 2 levels up in the locations hierarchy:
 * [
 *     {start, end},
 *     {start: startParent, end},
 *     {start, end: endParent},
 *     {start: startParent, end: endParent},
 *     ...
 *     {start: startParentParent, end: endParentParent}
 * ]
 * @param start
 * @param end
 * @returns []
 */
const getDirectionSearchList = (start, end) => {
    const directionSearchOrder = [];
    // if both locations have the same parent there is no reason to check paths to the closest parent locations
    if (start.properties.parentId === end.properties.parentId) {
        directionSearchOrder.push({start, end});
    } else {
        const startNodes = getClosestParents(start);
        const endNodes = getClosestParents(end);
        for (let sum = 0; sum < startNodes.length + endNodes.length - 1; sum++) {
            for (let i = 0; i < startNodes.length && i <= sum; i++) {
                for (let j = 0; j < endNodes.length && j <= sum; j++) {
                    if (i + j === sum) {
                        directionSearchOrder.push({start: startNodes[i], end: endNodes[j]});
                    }
                }
            }
        }
    }
    return directionSearchOrder;
};

/**
 * Get a transition point for the route to the closest parent locations
 * @param {Feature} feature
 * @param {Feature} pamRoute
 * @param {boolean} isStart
 * @return {Feature}
 */
const getClosestParentTransitionPoint = (feature, pamRoute, isStart) => {
    const transitionPoint = isStart
        ? pamRoute.geometry.coordinates[0]
        : pamRoute.geometry.coordinates[pamRoute.geometry.coordinates.length - 1];
    return mapEditorUtils.makeFeature({
        featureType: FEATURE_TYPES.threshold,
        properties: {
            isRouteTransition: true,
            tooltipText: feature.properties.displayText,
        },
        coordinates: transitionPoint,
        locked: true,
    });
};

/**
 * @param pamFeature
 * @param isOutbound
 * @param wheelchairOnly
 * @param safeOnly
 * @param travelMode
 * @param vStartPoint
 * @param vEndPoint
 * @returns @returns {{
 *  pamRoute: Object,
 *  thresholdUsed: Feature<Point, Properties>,
 *  closestParentTransitionPoint: Feature<Point, Properties>
 * }|undefined}
 */
const getClosestParentToThresholdRoute = ({
    pamFeature,
    isOutbound,
    wheelchairOnly,
    safeOnly,
    travelMode,
    vStartPoint,
    vEndPoint,
}) => {
    const closestParents = getClosestParents(pamFeature);
    let pamToThresholdRoute;
    let closestParentUsed;
    closestParents.every(feature => {
        pamToThresholdRoute = getPamToThresholdRoute({
            pamFeature: feature,
            isOutbound,
            wheelchairOnly,
            safeOnly,
            travelMode:
                travelMode === TRAVEL_MODES.DRIVING ? TRAVEL_MODES.DRIVING : TRAVEL_MODES.WALKING,
            vStartPoint,
            vEndPoint,
        });
        closestParentUsed = feature;
        return !pamToThresholdRoute;
    });

    if (pamToThresholdRoute) {
        if (
            pamFeature.properties.featureId !== closestParentUsed.properties.featureId &&
            !pamFeature.properties.routeViaGroupId
        ) {
            pamToThresholdRoute.closestParentTransitionPoint = getClosestParentTransitionPoint(
                closestParentUsed,
                pamToThresholdRoute.pamRoute.fullRoute,
                isOutbound
            );
        } else {
            pamToThresholdRoute.closestParentTransitionPoint = undefined;
        }
    }
    return pamToThresholdRoute;
};

/**
 * Gets the Mapbox part of a journey
 *
 * @param {object} props
 * @param {Feature} props.start - the start point
 * @param {Feature} props.end - the end point
 * @param {string} props.travelMode
 * @return {Promise<LineString|undefined>} the generated route
 */
const getMapboxRoute = async ({start, end, travelMode}) => {
    try {
        const directionsParams = {
            profile: travelMode,
            waypoints: [
                {coordinates: start.geometry.coordinates},
                {coordinates: end.geometry.coordinates},
            ],
            overview: 'full',
            annotations: ['duration'],
        };
        if (travelMode === TRAVEL_MODES.DRIVING) {
            const excludedThresholds = routeManager.getExcludedThresholds();
            if (excludedThresholds.length > 0) {
                directionsParams.exclude = excludedThresholds.reduce(
                    (result, threshold) =>
                        `${result ? `${result},` : ''}point(${threshold.geometry.coordinates[0]} ${
                            threshold.geometry.coordinates[1]
                        })`,
                    ''
                );
            }
        }
        const response = await directionsClient.getDirections(directionsParams).send();

        if (response.body.routes.length) {
            const route = response.body.routes[0];
            // A route from Mapbox is a GeoJSON feature, but with a lot of junk, so we strip out
            // all but the geometry
            const lineStringGeometry = polyline.toGeoJSON(route.geometry);

            // In some edge cases, Mapbox will return two of the same point,
            // resulting in an invalid LineString.
            if (lineStringGeometry.coordinates.length === 2) {
                const [pointA, pointB] = lineStringGeometry.coordinates;
                if (mapUtils.areSameCoords(pointA, pointB)) return undefined;
            }

            const lineString = mapUtils.removeDuplicatePoints(
                turfHelpers.feature(lineStringGeometry)
            );

            lineString.properties = {
                routeStyle: getRouteStyle(travelMode),
                distance: route.distance,
                travelTimeMins: route.duration ? Math.round(route.duration / 60) : 0,
            };

            return lineString;
        }

        log.error(
            `could not find a route between ${start.properties.name} and ${end.properties.name}`
        );

        return undefined;
    } catch (err) {
        log.error(`Error getting route from Mapbox: ${err.message}`);

        return undefined;
    }
};

/**
 * @param {Array<Feature>} routeLegs
 * @param {Array<Feature>} routeParts
 */
const preparePamRouteParts = (routeLegs, routeParts) => {
    routeLegs.forEach((routeLeg, index) => {
        if (index > 0) {
            const mode =
                routeLeg.properties.travelMode === TRAVEL_MODES.WALKING ? 'Walking' : 'Driving';
            routeParts.push(
                mapEditorUtils.makeFeature({
                    featureType: FEATURE_TYPES.threshold,
                    properties: {
                        isRouteTransition: true,
                        tooltipText: `Transition to ${mode}`,
                    },
                    coordinates: routeLeg.geometry.coordinates[0],
                    locked: true,
                })
            );
        }
        routeParts.push(routeLeg);
    });
};

/**
 * Gets the best route between PAM locations connected with Mapbox route
 * in case if there is no direct PAM route between those locations:
 * PAM -> Mapbox -> PAM
 *
 * @param {object} props
 * @param {Feature} props.start
 * @param {Feature} props.end
 * @param {string} props.travelMode
 * @param {boolean} props.wheelchairOnly
 * @param {string} props.rootVenueDisplayText
 * @return {object|undefined} the generated route, or undefined when a route can't be found
 */
const getInterVenueRoute = async ({
    start,
    end,
    travelMode,
    wheelchairOnly,
    safeOnly,
    rootVenueDisplayText,
}) => {
    const vStartPoint = turfCenter(turfHelpers.featureCollection([start]));
    const vEndPoint = turfCenter(turfHelpers.featureCollection([end]));
    const routeParts = [];

    // Get PAM route from the start point to the venue threshold
    const startVenueRouteResponse = getClosestParentToThresholdRoute({
        pamFeature: start,
        isOutbound: true,
        wheelchairOnly,
        safeOnly,
        travelMode,
        vStartPoint,
        vEndPoint,
    });

    log.debug('routingUtils.getInterVenueRoute.startVenueRouteResponse', startVenueRouteResponse);

    if (!startVenueRouteResponse) {
        return undefined;
    }

    const {
        pamRoute: startVenueRoute,
        thresholdUsed: startVenueThresholdUsed,
        closestParentTransitionPoint: startVenueClosestParentTransitionPoint,
    } = startVenueRouteResponse;

    // Get PAM route from the venue threshold to the end point
    const endVenueRouteResponse = getClosestParentToThresholdRoute({
        pamFeature: end,
        isOutbound: false,
        wheelchairOnly,
        safeOnly,
        travelMode,
        vStartPoint,
        vEndPoint,
    });

    log.debug('routingUtils.getInterVenueRoute.endVenueRouteResponse', endVenueRouteResponse);

    if (!endVenueRouteResponse) {
        return undefined;
    }
    const {
        pamRoute: endVenueRoute,
        thresholdUsed: endVenueThresholdUsed,
        closestParentTransitionPoint: endVenueClosestParentTransitionPoint,
    } = endVenueRouteResponse;

    // Get Mapbox route between start and end thresholds found on the previous steps
    const interVenueMapboxRoute = await getMapboxRoute({
        start: startVenueThresholdUsed,
        end: endVenueThresholdUsed,
        travelMode,
    });

    log.debug('routingUtils.getInterVenueRoute.interVenueMapboxRoute', interVenueMapboxRoute);

    if (!interVenueMapboxRoute) {
        return undefined;
    }

    // The Mapbox and PAM routes might not align nicely, so we replace the first/last
    // coordinate in the Mapbox route with the threshold points of the PAM route
    interVenueMapboxRoute.geometry.coordinates.splice(
        0,
        1,
        startVenueThresholdUsed.geometry.coordinates
    );
    interVenueMapboxRoute.geometry.coordinates.splice(
        -1,
        1,
        endVenueThresholdUsed.geometry.coordinates
    );

    /**
     * Combine all route parts:
     * - closest parent location transition point for the route start point if required
     * - internal PAM route from the start point to the venue threshold
     * - threshold point connecting PAM route -> Mapbox route
     * - intervenue Mapbox route
     * - threshold point connecting Mapbox route -> PAM route
     * - internal PAM route from the venue threshold to the end point
     * - closest parent location transition point for the route end point if required
     * */
    if (startVenueClosestParentTransitionPoint) {
        routeParts.push(startVenueClosestParentTransitionPoint);
    }
    preparePamRouteParts(startVenueRoute.routeLegs, routeParts);
    routeParts.push(
        mapEditorUtils.makeFeature({
            featureType: FEATURE_TYPES.threshold,
            properties: {
                isRouteTransition: true,
                tooltipText: rootVenueDisplayText,
            },
            coordinates: startVenueThresholdUsed.geometry.coordinates,
            locked: true,
        })
    );
    routeParts.push(interVenueMapboxRoute);
    routeParts.push(
        mapEditorUtils.makeFeature({
            featureType: FEATURE_TYPES.threshold,
            properties: {
                isRouteTransition: true,
                tooltipText: rootVenueDisplayText,
            },
            coordinates: endVenueThresholdUsed.geometry.coordinates,
            locked: true,
        })
    );
    preparePamRouteParts(endVenueRoute.routeLegs, routeParts);
    if (endVenueClosestParentTransitionPoint) {
        routeParts.push(endVenueClosestParentTransitionPoint);
    }

    const result = {
        travelTimeMins:
            startVenueRoute.fullRoute.properties.time * 60 +
            interVenueMapboxRoute.properties.travelTimeMins +
            endVenueRoute.fullRoute.properties.time * 60,
        distance: routeParts.reduce((a, c) => (c?.properties?.distance || 0) + a, 0),
        routeParts,
        startLocationUsed: startVenueRouteResponse.locationUsed,
        endLocationUsed: endVenueRouteResponse.locationUsed,
    };

    log.debug('routingUtils.getInterVenueRoute.result', result);

    return result;
};

/**
 * Returns internal PAM route
 * @param start
 * @param end
 * @param travelMode
 * @param wheelchairOnly
 * @param safeOnly
 * @return {{distance: any, travelTimeMins: number, routeParts: *[]}|Object|undefined}
 */
const getInternalRoute = ({start, end, travelMode, wheelchairOnly, safeOnly}) => {
    let pamRoute;

    if (
        start.properties.routeViaGroupId &&
        end.properties.routeViaGroupId &&
        start.properties.routeViaGroupId === end.properties.routeViaGroupId
    ) {
        return {noRouteBetweenFeaturesWithSameRouteViaGroupId: true};
    }

    const directionSearchOrder = getDirectionSearchList(start, end);

    log.debug('routingUtils.getInternalRoute.directionSearchOrder', directionSearchOrder);

    let startLocationUsed;
    let endLocationUsed;
    let isShortRoute = false;
    directionSearchOrder.every(direction => {
        const {
            nodesInFeatures: startNodes,
            nodeIdsByFeatureId: startNodeIdsByFeatureId,
        } = routeManager.getNodesInFeature(direction.start, travelMode, true);

        const {
            nodesInFeatures: endNodes,
            nodeIdsByFeatureId: endNodeIdsByFeatureId,
        } = routeManager.getNodesInFeature(direction.end, travelMode, false);

        if (
            startNodes &&
            endNodes &&
            areNodesIntersect({
                startNodeIds: startNodes.map(node => node.id),
                endNodeIds: endNodes.map(node => node.id),
            }) &&
            start.geometry.type === GEOMETRY_TYPES.POINT &&
            end.geometry.type === GEOMETRY_TYPES.POINT
        ) {
            isShortRoute = true;
            // start and end nodes are too close, so return straight path
            const lineString = turfHelpers.lineString([
                start.geometry.coordinates,
                end.geometry.coordinates,
            ]);
            lineString.properties.distance = turfDistance(
                start.geometry.coordinates,
                end.geometry.coordinates
            );
            lineString.properties.time =
                lineString.properties.distance /
                (travelMode === TRAVEL_MODES.DRIVING ? SPEEDS.DRIVING : SPEEDS.WALKING);
            lineString.properties.travelMode = travelMode;
            lineString.properties.routeStyle = getRouteStyle(travelMode);
            pamRoute = {
                fullRoute: lineString,
            };
        } else {
            pamRoute = getPamRoute({
                startNodes,
                endNodes,
                wheelchairOnly,
                safeOnly,
                travelMode,
            });
        }

        if (pamRoute && start.properties.routeViaGroupId) {
            const featuresInGroup = getFeaturesInGroup(start.properties.routeViaGroupId);
            if (featuresInGroup) {
                const firstNodeId = pamRoute.firstNodeId;
                startLocationUsed = featuresInGroup.find(featureInGroup => {
                    return (
                        startNodeIdsByFeatureId[featureInGroup.id] &&
                        startNodeIdsByFeatureId[featureInGroup.id].includes(firstNodeId)
                    );
                });
            }
        } else {
            startLocationUsed = direction.start;
        }

        if (pamRoute && end.properties.routeViaGroupId) {
            const featuresInGroup = getFeaturesInGroup(end.properties.routeViaGroupId);
            if (featuresInGroup) {
                const lastNodeId = pamRoute.lastNodeId;
                endLocationUsed = featuresInGroup.find(featureInGroup => {
                    return (
                        endNodeIdsByFeatureId[featureInGroup.id] &&
                        endNodeIdsByFeatureId[featureInGroup.id].includes(lastNodeId)
                    );
                });
            }
        } else {
            endLocationUsed = direction.end;
        }

        return !pamRoute;
    });

    log.debug('routingUtils.getInternalRoute.pamRoute', pamRoute);

    if (pamRoute) {
        if (isShortRoute) {
            return {
                routeParts: [pamRoute.fullRoute],
                distance: pamRoute.fullRoute.properties.distance,
                travelTimeMins: pamRoute.fullRoute.properties.time * 60,
            };
        }

        const routeParts = [];
        if (
            start.properties.featureId !== startLocationUsed.properties.featureId &&
            !start.properties.routeViaGroupId
        ) {
            routeParts.push(
                getClosestParentTransitionPoint(startLocationUsed, pamRoute.fullRoute, true)
            );
        }
        preparePamRouteParts(pamRoute.routeLegs, routeParts);
        if (
            end.properties.featureId !== endLocationUsed.properties.featureId &&
            !end.properties.routeViaGroupId
        ) {
            routeParts.push(
                getClosestParentTransitionPoint(endLocationUsed, pamRoute.fullRoute, false)
            );
        }

        return {
            routeParts,
            distance: pamRoute.fullRoute.properties.distance,
            travelTimeMins: pamRoute.fullRoute.properties.time * 60,
            startLocationUsed,
            endLocationUsed,
        };
    }
    return undefined;
};

/**
 * Returns combined PAM -> Mapbox or Mapbox -> PAM route
 * @param start
 * @param end
 * @param startIsPam
 * @param startIsMapbox
 * @param wheelchairOnly
 * @param safeOnly
 * @param travelMode
 * @param rootVenueDisplayText
 * @return {Promise<undefined|{distance: *, travelTimeMins: *, routeParts: *[]}>}
 */
async function getCombinedRoute({
    start,
    end,
    startIsPam,
    startIsMapbox,
    wheelchairOnly,
    safeOnly,
    travelMode,
    rootVenueDisplayText,
}) {
    const vStartPoint = turfCenter(turfHelpers.featureCollection([start]));
    const vEndPoint = turfCenter(turfHelpers.featureCollection([end]));
    const routeParts = [];
    const pamRouteResponse = getClosestParentToThresholdRoute({
        pamFeature: startIsPam ? start : end,
        isOutbound: startIsPam,
        wheelchairOnly,
        safeOnly,
        travelMode,
        vStartPoint,
        vEndPoint,
    });

    if (!pamRouteResponse) {
        return undefined;
    }

    const {pamRoute, thresholdUsed, closestParentTransitionPoint, locationUsed} = pamRouteResponse;
    const pamTravelTimeMins = pamRoute.fullRoute.properties.time * 60;
    const pamRouteParts = [];

    preparePamRouteParts(pamRoute.routeLegs, pamRouteParts);

    let mapboxRouteStart;
    let mapboxRouteEnd;
    let transitionPoint;

    if (startIsMapbox) {
        mapboxRouteStart = start;
        mapboxRouteEnd = thresholdUsed;
        transitionPoint = mapboxRouteEnd;
    } else {
        mapboxRouteStart = thresholdUsed;
        mapboxRouteEnd = end;
        transitionPoint = mapboxRouteStart;
    }

    const mapboxRoute = await getMapboxRoute({
        start: mapboxRouteStart,
        end: mapboxRouteEnd,
        travelMode,
    });

    if (!mapboxRoute) {
        return undefined;
    }

    // The Mapbox and PAM routes might not align nicely, so we replace the first/last
    // coordinate in the Mapbox route with the last/first point of the PAM route
    // so they meed exactly (the provided 'thresholdUsed' point is actually never used)
    if (startIsMapbox) {
        // Replace the last Mapbox route coords with the first of the PAM route coords
        mapboxRoute.geometry.coordinates.splice(-1, 1, thresholdUsed.geometry.coordinates);
    } else {
        // Replace the first Mapbox route coords with the last PAM route coords
        mapboxRoute.geometry.coordinates.splice(0, 1, thresholdUsed.geometry.coordinates);
    }

    if (startIsMapbox) {
        routeParts.push(mapboxRoute);
        routeParts.push(...pamRouteParts);
        if (closestParentTransitionPoint) routeParts.push(closestParentTransitionPoint);
    }

    if (startIsPam) {
        if (closestParentTransitionPoint) routeParts.push(closestParentTransitionPoint);
        routeParts.push(...pamRouteParts);
        routeParts.push(mapboxRoute);
    }

    // mark mapbox route starting point with a circle
    routeParts.push(
        mapEditorUtils.makeFeature({
            featureType: FEATURE_TYPES.threshold,
            properties: {
                isRouteTransition: true,
                tooltipText: rootVenueDisplayText,
            },
            coordinates: transitionPoint.geometry.coordinates,
            locked: true,
        })
    );

    return {
        travelTimeMins: pamTravelTimeMins + mapboxRoute.properties.travelTimeMins,
        distance: routeParts.reduce((a, c) => (c?.properties?.distance || 0) + a, 0),
        routeParts,
        startLocationUsed: startIsPam ? locationUsed : start,
        endLocationUsed: startIsPam ? end : locationUsed,
    };
}

/**
 * Gets the best route between PAM and/or Mapbox locations
 *
 * @param {object} props
 * @param {Feature} props.start
 * @param {Feature} props.end
 * @param {string} props.travelMode
 * @param {boolean} props.wheelchairOnly
 * @param {boolean} props.safeOnly
 * @param {string} props.rootVenueDisplayText
 * @param {boolean} props.externalPedestrianAndCyclingDirectionsDisabled
 * @param {boolean} props.externalDirectionsEnabled
 * @return {object|undefined} the generated route, or undefined when a route can't be found
 */
export const getRoute = async ({
    start,
    end,
    travelMode,
    wheelchairOnly,
    safeOnly,
    rootVenueDisplayText,
    externalPedestrianAndCyclingDirectionsDisabled,
    externalDirectionsEnabled,
}) => {
    let startIsPam;
    let endIsPam;

    // Check if start/end location lays within a PAM venue/area with forced PAM routes
    if (start.properties.locationType === LOCATION_TYPES.MAPBOX) {
        const startPointsInPolygon = turfPointsWithinPolygon(
            start,
            routeManager.getForcePamRoutesFeatureCollection()
        );
        startIsPam = startPointsInPolygon.features.length > 0;
    } else {
        startIsPam =
            start.properties.locationType === LOCATION_TYPES.PAM || !start.properties.locationType;
    }

    if (end.properties.locationType === LOCATION_TYPES.MAPBOX) {
        const endPointsInPolygon = turfPointsWithinPolygon(
            end,
            routeManager.getForcePamRoutesFeatureCollection()
        );
        endIsPam = endPointsInPolygon.features.length > 0;
    } else {
        endIsPam =
            end.properties.locationType === LOCATION_TYPES.PAM || !end.properties.locationType;
    }

    const startIsMapbox = !startIsPam;
    const endIsMapbox = !endIsPam;

    // Get a PAM-only route or PAM > Mapbox > PAM route
    if (startIsPam && endIsPam) {
        const internalRouteResponse = getInternalRoute({
            start,
            end,
            wheelchairOnly,
            safeOnly,
            travelMode:
                travelMode === TRAVEL_MODES.DRIVING ? TRAVEL_MODES.DRIVING : TRAVEL_MODES.WALKING,
            rootVenueDisplayText,
        });
        if (internalRouteResponse) {
            if (internalRouteResponse.noRouteBetweenFeaturesWithSameRouteViaGroupId) {
                // TODO: should it be a special response for the route between locations with the same 'routeViaGroupId'?
                return false;
            }
            log.debug('internalRouteResponse', internalRouteResponse);
            return internalRouteResponse;
        }

        // if externalRouting is disabled, do not show inter venue route
        if (!externalDirectionsEnabled) {
            return false;
        }
        const interVenueRouteResponse = await getInterVenueRoute({
            start,
            end,
            travelMode,
            wheelchairOnly,
            safeOnly,
            rootVenueDisplayText,
        });
        log.debug('interVenueRouteResponse', interVenueRouteResponse);
        return interVenueRouteResponse;
    }

    // Get a Mapbox-only route
    if (startIsMapbox && endIsMapbox) {
        if (
            externalPedestrianAndCyclingDirectionsDisabled &&
            (travelMode === TRAVEL_MODES.WALKING || travelMode === TRAVEL_MODES.CYCLING)
        ) {
            return undefined;
        }

        const mapboxRoute = await getMapboxRoute({start, end, travelMode});

        if (!mapboxRoute) {
            return undefined;
        }
        return {
            routeParts: [mapboxRoute],
            distance: mapboxRoute.properties.distance,
            travelTimeMins: mapboxRoute.properties.travelTimeMins,
            startLocationUsed: start,
            endLocationUsed: end,
        };
    }

    // Get a combined PAM and Mapbox route
    if ((startIsMapbox && endIsPam) || (startIsPam && endIsMapbox)) {
        const combinedRouteResponse = getCombinedRoute({
            start,
            end,
            startIsPam,
            startIsMapbox,
            wheelchairOnly,
            safeOnly,
            travelMode,
            rootVenueDisplayText,
        });
        log.debug('combinedRouteResponse', combinedRouteResponse);
        return combinedRouteResponse;
    }

    return undefined;
};

export const generateRouteParams = ({start, end, routeOptions}) => {
    const minimalLocations = [start, end]
        .filter(feature => feature)
        .map(locationUtils.convertFullToMinimal);
    const params = {};
    params.routeOption = routeOptions;
    params.route = JSON.stringify({
        stops: minimalLocations,
        routeParams: KIOSK_ROUTE_MAP[routeOptions],
    });

    return params;
};
