import * as turfMeta from '@turf/meta/index';
import turfPointToLineDistance from '@turf/point-to-line-distance/index';
import * as turfHelpers from '@turf/helpers/index';
import turfDistance from '@turf/distance';
import turfPolygonToLine from '@turf/polygon-to-line';
import lodashIsNumber from 'lodash/isNumber';
import uuid from 'uuid/v4';
import * as mapUtils from 'Utils/explorer/mapUtils';
import {GEOMETRY_TYPES} from 'Utils/constants';
import FEATURE_TYPES from 'Utils/featureTypes';
import {ONE_MM} from 'Utils/mapEditor/mapEditorConstants';
import store from 'Data/store';
import IMDF_FEATURE_TYPES from 'Utils/imdfFeatureTypes';

/**
 * Return a feature with the same ID and geometry, but all other details removed
 *
 * @param {Feature} fullFeature
 * @return {Feature}
 */
export const getMinimalFeature = fullFeature => {
    if (fullFeature.geometry.type !== GEOMETRY_TYPES.POINT) {
        throw Error('Only points are supported at the moment');
    }
    const minimalFeature = turfHelpers.point(fullFeature.geometry.coordinates);
    minimalFeature.id = fullFeature.id;
    return minimalFeature;
};

const getScenes = () =>
    store.getState().scenes.reduce(
        (scenes, {id}) => ({
            ...scenes,
            ...{[id]: {}},
        }),
        {}
    );

export const getClientRelationship = () => store.getState().clientRelationship;

/**
 * Creates a GeoJSON feature
 *
 * @param {object} props
 * @param {object} props.featureType
 * @param {string} [props.geometryType] - allow overriding the featureType's geometry
 * @param {object} [props.properties]
 * @param {string} [props.id]
 * @param {array} [props.coordinates]
 * @param {boolean} [props.locked]
 * @returns {Feature}
 */
export const makeFeature = ({
    featureType,
    geometryType = featureType.imdfFeatureType.geometryType,
    properties = {},
    id = uuid(),
    coordinates = null,
    locked = false,
    clientId = null,
}) => {
    const geometry = {
        type: geometryType,
        coordinates: coordinates || (geometryType === GEOMETRY_TYPES.POINT ? [] : [[]]),
    };

    const venueProperties = {};
    if (
        featureType.imdfFeatureType?.code === IMDF_FEATURE_TYPES.venue.code &&
        getClientRelationship()?.relatedClients?.length > 1
    ) {
        venueProperties.isOwnVenue = true;
    }

    const baseGeometry =
        featureType.code === FEATURE_TYPES.routeSegment.code ? {baseGeometry: geometry} : {};
    return {
        id,
        type: 'Feature',
        properties: {
            featureType,
            childrenIds: [],
            disabled: false,
            locked,
            [mapUtils.makeFeatureTypeBool(featureType)]: true,
            ...featureType.defaultProps,
            ...properties,
            sceneProperties: {
                ...getScenes(),
                ...{baseProperties: {}},
                ...{...baseGeometry},
            },
            ...venueProperties,
            clientId: clientId || getClientRelationship()?.id,
        },
        geometry,
    };
};

/**
 * Looks for point on a line that's very close to the passed in point.
 * Returns the point if found, else undefined
 *
 * @param line
 * @param point
 * @param threshold
 * @returns {undefined|Array<Number>} The point on the line
 */
export const getCoordsOnLineIfItExists = (line, point, threshold = ONE_MM) => {
    let matchingPoint;
    turfMeta.coordEach(line, coords => {
        const dist = turfDistance(coords, point.geometry.coordinates);

        if (dist < threshold) matchingPoint = coords;
    });
    return matchingPoint;
};

/**
 * Checks if a line contains an EXACT point in its coordinates array
 *
 * @param {LineString} line
 * @param {Array<Number>} coords
 * @returns {boolean}
 */
export const lineHasCoords = (line, coords) => {
    let isPointOnLine = false;
    turfMeta.coordEach(line, currentCoords => {
        const doesMatch = mapUtils.areSameCoords(currentCoords, coords);

        if (doesMatch) isPointOnLine = true;
    });
    return isPointOnLine;
};
/**
 * Return the two-vertex LineString from a feature that is closest to the provided point
 *
 * @param {Feature} point
 * @param {Feature} feature
 * @return {LineString}
 */
export const getNearestSegment = (point, feature) => {
    const line =
        feature.geometry.type === GEOMETRY_TYPES.POLYGON ? turfPolygonToLine(feature) : feature;
    let closestSegment = null;
    let closestSegmentDistance = Infinity;
    turfMeta.segmentEach(line, segment => {
        const dist = turfPointToLineDistance(point, segment);

        if (dist < closestSegmentDistance) {
            closestSegment = segment;
            closestSegmentDistance = dist;
        }
    });
    return closestSegment;
};

const geometryPointFeatureIsInvalid = feature =>
    feature?.geometry?.type === GEOMETRY_TYPES.POINT &&
    (feature?.geometry?.coordinates.length !== 2 || // invalid, there's no pair of lon/lat
        (feature?.geometry?.coordinates.length === 2 && // There's a lat/lon but they are not numbers
            (!lodashIsNumber(feature?.geometry?.coordinates[0]) ||
                !lodashIsNumber(feature?.geometry?.coordinates[1]))));

const geometryLineStringOnlyFeatureIsInvalid = feature => {
    const coordinatesLength = feature.geometry?.coordinates?.length;
    const isGeometryLineStringOnly = feature?.geometry?.type === GEOMETRY_TYPES.LINE_STRING;

    return (
        isGeometryLineStringOnly &&
        (coordinatesLength <= 1 || // invalid, coords is on point or less
            (coordinatesLength === 2 && // invalid, the start and end points are the same if there's only two points
                feature?.geometry?.coordinates?.[0]?.toString() ===
                    feature?.geometry?.coordinates?.[1]?.toString()))
    );
};

const geometryPolygonFeatureIsInvalid = feature => {
    const coordinatesLength = feature?.geometry?.coordinates?.[0].length;
    const isGeometryPolygon = feature?.geometry?.type === GEOMETRY_TYPES.POLYGON;

    return (
        isGeometryPolygon &&
        (coordinatesLength <= 3 || // invalid, there are 3 or less points
            (coordinatesLength > 1 && // invalid, the start and end points are the same
                feature?.geometry?.coordinates?.[0]?.[0].toString() !==
                    feature?.geometry?.coordinates?.[0]?.[coordinatesLength - 1].toString()))
    );
};

export const isFeatureInvalid = feature =>
    geometryLineStringOnlyFeatureIsInvalid(feature) ||
    geometryPointFeatureIsInvalid(feature) ||
    geometryPolygonFeatureIsInvalid(feature);
