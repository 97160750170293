class LabelSizeButton {
    constructor(clickHandler) {
        this.clickHandler = clickHandler;
    }

    onAdd() {
        this._btn = document.createElement('button');

        this._btn.className = `mapboxgl-ctrl-icon map-box-label-size`;
        this._btn.type = 'button';
        this._btn.setAttribute('aria-label', `Change map labels size`);
        this._btn.onclick = this.clickHandler;

        this._container = document.createElement('div');
        this._container.className = `mapboxgl-ctrl mapboxgl-ctrl-group`;
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
    }
}

export default LabelSizeButton;
