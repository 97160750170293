/**
 * This file stores information about the route mesh and responds to requests to get the best routes
 */
import * as turfHelpers from '@turf/helpers';
import * as turfMeta from '@turf/meta';
import turfTruncate from '@turf/truncate';
import turfDistance from '@turf/distance';
import turfPoint from 'turf-point';
import turfBuffer from '@turf/buffer';
import turfCenter from '@turf/center';
import turfBearing from '@turf/bearing';
import turfPointsWithinPolygon from '@turf/points-within-polygon';
import {GEOMETRY_TYPES, TRAVEL_MODES} from 'Utils/constants';
import log from 'Utils/log';
import OrderedQueue from 'Utils/OrderedQueue';
import turfPointToLineDistance from '@turf/point-to-line-distance';
import turfNearestPoint from '@turf/nearest-point';
import FEATURE_TYPES from './featureTypes';

/**
 * Typical speed (km/h) for different travel modes
 * @type {{DRIVING: number, WALKING: number}}
 */
export const SPEEDS = {
    DRIVING: 20,
    WALKING: 5,
};

const TRANSITION_TIME = 0.01; // in hours

// admissible distance for walking to driving route = how long user might walk till they start driving
const MAX_WALKING_TILL_DRIVING_DISTANCE = 100; // in meters

// maximum number of transitions driving <-> walking allowed for the driving route
const MAX_TRAVEL_MODE_TRANSITIONS = 2;

let routeMeshReady = false;
const routeMesh = new Map();
const routeMeshPoints = {
    [TRAVEL_MODES.DRIVING]: [],
    [TRAVEL_MODES.WALKING]: [],
};
const dropOffPointMap = new Map();
const thresholds = [];
const excludedThresholds = [];
// List of navmap features which have forced PAM routes
let forcePamRoutesFeatureCollection;

const routeRequestQueue = [];
const nodesInFeaturesCache = new Map();
const parentFeaturesCache = new Map();
const featureGroupsCache = new Map();

const VIRTUAL_IDS = {
    START: 'VIRTUAL_IDS.START',
    END: 'VIRTUAL_IDS.END',
};

// Mapbox Directions API allows to exclude up to 50 points when retrieving directions between waypoints
const MAPBOX_EXCLUDE_POINTS_LIMIT = 50;

export const getThresholds = () => thresholds;
export const getExcludedThresholds = () => excludedThresholds;
export const getForcePamRoutesFeatureCollection = () => forcePamRoutesFeatureCollection;

export const getFeaturesInGroup = groupId => featureGroupsCache.get(groupId);

const getRouteTime = (distanceInMeters, travelMode) => {
    return (
        distanceInMeters /
        (1000 * (travelMode === TRAVEL_MODES.DRIVING ? SPEEDS.DRIVING : SPEEDS.WALKING))
    );
};

// We round routes, thresholds, and drop-off points to 1cm so everything lines up
const truncate = geoJson => turfTruncate(geoJson, {precision: 7});

const pointToString = point => String(truncate(point).geometry.coordinates);

const coordinatesToString = coordinates => pointToString(turfPoint(coordinates));

const getNodeId = (coordString, travelMode) =>
    travelMode === TRAVEL_MODES.DRIVING ? `${coordString}-d` : `${coordString}-w`;

export const getNodeByCoordString = (coordString, travelMode) => {
    return routeMesh.get(getNodeId(coordString, travelMode));
};

const getClosestNodeInRouteMesh = (point, travelMode) => {
    let shortestDistance = Infinity;
    let closestPoint;
    let closestNode;

    if (routeMeshPoints[travelMode].length > 0) {
        const routeMeshFeatureCollection = turfHelpers.featureCollection(
            routeMeshPoints[travelMode]
        );
        closestPoint = turfNearestPoint(point, routeMeshFeatureCollection);
        if (closestPoint) {
            shortestDistance = turfDistance(point, closestPoint);
            closestNode = getNodeByCoordString(pointToString(closestPoint), travelMode);
        }
    }

    if (travelMode === TRAVEL_MODES.DRIVING) {
        if (closestNode) {
            closestNode.edges.forEach(edge => {
                const otherNode = routeMesh.get(edge.otherNodeId);
                if (otherNode) {
                    const segment = turfHelpers.lineString([closestNode.coords, otherNode.coords]);
                    const distanceToDrivingSegment = turfPointToLineDistance(point, segment);
                    if (distanceToDrivingSegment < shortestDistance) {
                        shortestDistance = distanceToDrivingSegment;
                    }
                }
            });
        }

        if (routeMeshPoints[TRAVEL_MODES.WALKING].length > 0) {
            const walkingRouteMeshFeatureCollection = turfHelpers.featureCollection(
                routeMeshPoints[TRAVEL_MODES.WALKING]
            );
            const walkingClosestPoint = turfNearestPoint(point, walkingRouteMeshFeatureCollection);
            if (walkingClosestPoint) {
                const walkingShortestDistance = turfDistance(point, walkingClosestPoint);
                if (walkingShortestDistance < shortestDistance) {
                    closestPoint = walkingClosestPoint;
                    closestNode = getNodeByCoordString(
                        pointToString(closestPoint),
                        TRAVEL_MODES.WALKING
                    );
                }
            }
        }
    }

    return closestNode;
};

const getNodesInNonGroupFeature = (feature, travelMode, isStartNode = true) => {
    if (feature.geometry.type === GEOMETRY_TYPES.POINT) {
        // If the feature is a point, but not on the route mesh, we'll return the closest point
        // on the route mesh
        const node = getClosestNodeInRouteMesh(feature, travelMode);
        if (node) {
            return [node];
        }
        return undefined;
    }

    if (feature.geometry.type === GEOMETRY_TYPES.POLYGON) {
        // We use buffer to pick up any points within 10cm of the outside of a feature
        const points = turfPointsWithinPolygon(
            turfHelpers.featureCollection(routeMeshPoints[travelMode]),
            turfHelpers.featureCollection([turfBuffer(feature, 0.0001)]) // buffer is a workaround for this bug: https://github.com/Turfjs/turf/issues/1597
        ).features;

        let nodes = points
            .map(point => {
                let node = getNodeByCoordString(pointToString(point), travelMode);
                if (!node && travelMode === TRAVEL_MODES.DRIVING && !isStartNode) {
                    node = getNodeByCoordString(pointToString(point), TRAVEL_MODES.WALKING);
                }
                return node;
            })
            .filter(node => !!node);

        // In case if there is no suitable driving route intersection with polygon feature
        // try to get walking route points laying within the polygon feature
        if (nodes.length === 0 && travelMode === TRAVEL_MODES.DRIVING) {
            const walkingPoints = turfPointsWithinPolygon(
                turfHelpers.featureCollection(routeMeshPoints[TRAVEL_MODES.WALKING]),
                turfHelpers.featureCollection([turfBuffer(feature, 0.0001)])
            ).features;

            nodes = walkingPoints
                .map(point => {
                    return getNodeByCoordString(pointToString(point), TRAVEL_MODES.WALKING);
                })
                .filter(node => !!node);
        }
        return nodes;
    }
    return undefined;
};

/**
 * Get the points on the route mesh that fall within a feature
 *
 * @param {Feature} feature
 * @param travelMode
 * @param isStartNode
 * @returns {{nodesInFeatures: *[], nodeIdsByFeatureId: {}}}
 */
export const getNodesInFeature = (feature, travelMode, isStartNode = true) => {
    const features = [];

    let featureGroupId;
    if (feature.properties?.featureType?.code === FEATURE_TYPES.group.code) {
        featureGroupId = feature.properties.featureId;
    } else if (feature.properties?.routeViaGroupId) {
        featureGroupId = feature.properties.routeViaGroupId;
    }

    if (featureGroupId) {
        const featuresInGroup = featureGroupsCache.get(featureGroupId);
        if (featuresInGroup) {
            features.push(...featuresInGroup);
        }
    } else {
        features.push(feature);
    }
    const nodesInFeatures = [];
    const nodeIdsByFeatureId = {};
    features.forEach(currentFeature => {
        const nodesInFeature = getNodesInNonGroupFeature(currentFeature, travelMode, isStartNode);
        if (nodesInFeature) {
            nodeIdsByFeatureId[currentFeature.id] = nodesInFeature.map(node => node.id);
            nodesInFeatures.push(...nodesInFeature);
        }
    });
    return {nodesInFeatures, nodeIdsByFeatureId};
};

export const areNodesIntersect = ({startNodeIds, endNodeIds}) => {
    let areIntersect = false;
    startNodeIds.forEach(startNodeId => {
        endNodeIds.forEach(endNodeId => {
            if (startNodeId === endNodeId) areIntersect = true;
        });
    });
    return areIntersect;
};

// Perform checks for any bad parameters
const areValidRouteParams = ({startNodeIds, endNodeIds}) =>
    !areNodesIntersect({startNodeIds, endNodeIds});

/**
 * Returns the best route between points on the PAM route mesh
 *
 * @param {Array<Point>} startNodes - one or more points
 * @param {Array<Point>} endNodes - one or more points
 * @param {function} edgeFilter
 * @param {boolean} highlightNightSafeSegments
 * @param {string} travelMode
 * @param {Point} vStartPoint
 * @param {Point} vEndPoint
 * @param {boolean} ignoreMaxWalking
 * @return {LineString|undefined} the route, or undefined
 */
export const getRoute = ({
    startNodes,
    endNodes,
    edgeFilter,
    highlightNightSafeSegments,
    travelMode,
    vStartPoint,
    vEndPoint,
    ignoreMaxWalkingDistance,
}) => {
    let result;
    try {
        const startNodeIds = startNodes.map(node => node.id);
        const endNodeIds = endNodes.map(node => node.id);

        const virtualEndPoint =
            vEndPoint ||
            turfCenter(turfHelpers.featureCollection(endNodes.map(node => turfPoint(node.coords))));

        // It's possible that invalid parameters will be passed
        if (!areValidRouteParams({startNodeIds, endNodeIds})) return undefined;

        // Initialise the queues used to hold nodes while processing the route mesh
        const queues = {
            unordered: new Map(), // All nodes start here
            ordered: new OrderedQueue('score'), // Moved here when some length has been calculated
            visited: new Map(), // then moved here once they've been visited
        };

        // The final step in getting a route. This walks backwards through the shortest path to
        // construct a line string.
        const constructCompletePath = endNode => {
            const nodesTravelled = [];

            const getNext = id => {
                const previousNode = queues.visited.get(id);

                nodesTravelled.push(previousNode);

                if (previousNode.via && previousNode.via !== VIRTUAL_IDS.START) {
                    getNext(previousNode.via);
                }
            };

            getNext(endNode.via);

            // This can happen e.g. if a threshold and a drop-off point are in the same place
            if (nodesTravelled.length === 1) return undefined;

            const reversedNodes = nodesTravelled.reverse();

            log.debug('routeManager.constructCompletePath.reversedNodes', reversedNodes);

            const routeLegFeatures = [];
            let firstNode;
            let prevNode;
            let currentLegCoords;
            let segmentsNightSafeList;
            reversedNodes.forEach(node => {
                if (prevNode) {
                    if (prevNode.travelMode !== node.travelMode) {
                        // skip route leg containing a single node
                        if (currentLegCoords.length > 1) {
                            const routeLegFeature = turfHelpers.lineString(currentLegCoords);
                            routeLegFeature.properties.travelMode = prevNode.travelMode;
                            routeLegFeature.properties.distance =
                                prevNode.routeLengthToNode - firstNode.routeLengthToNode;
                            routeLegFeature.properties.time = getRouteTime(
                                routeLegFeature.properties.distance,
                                routeLegFeature.properties.travelMode
                            );
                            routeLegFeature.properties.segmentsNightSafeList = segmentsNightSafeList;
                            routeLegFeature.properties.splitRouteToDraw =
                                highlightNightSafeSegments && segmentsNightSafeList?.length;
                            routeLegFeatures.push(routeLegFeature);

                            firstNode = node;
                            currentLegCoords = [firstNode.coords];
                            segmentsNightSafeList = [];
                        }
                    } else {
                        currentLegCoords.push(node.coords);

                        // Mapbox doesnt allow a single linestring feature to be
                        // data driven styled with multiple colours,
                        // so we keep an index for each segment of a route to be nightSafe
                        // Later, we break the linestring into linestring per segment & colour
                        // it in explorerLayers.js
                        if (highlightNightSafeSegments) {
                            const currentEdge = prevNode.edges.find(
                                edge => edge.otherNodeId === node.id
                            );
                            segmentsNightSafeList.push(!!currentEdge?.properties?.routeIsNightSafe);
                        }
                    }
                } else {
                    firstNode = node;
                    currentLegCoords = [firstNode.coords];
                    segmentsNightSafeList = [];
                }
                prevNode = node;
            });
            if (prevNode && currentLegCoords && currentLegCoords.length > 1) {
                const routeLegFeature = turfHelpers.lineString(currentLegCoords);
                routeLegFeature.properties.travelMode = prevNode.travelMode;
                routeLegFeature.properties.distance =
                    prevNode.routeLengthToNode - firstNode.routeLengthToNode;
                routeLegFeature.properties.time = getRouteTime(
                    routeLegFeature.properties.distance,
                    routeLegFeature.properties.travelMode
                );
                routeLegFeature.properties.segmentsNightSafeList = segmentsNightSafeList;
                routeLegFeature.properties.splitRouteToDraw =
                    highlightNightSafeSegments && segmentsNightSafeList?.length;
                routeLegFeatures.push(routeLegFeature);
            }

            const coords = reversedNodes.map(node => node.coords);

            const lineString = turfHelpers.lineString(coords);
            lineString.properties.distance = routeLegFeatures.reduce(
                (a, c) => (c?.properties?.distance || 0) + a,
                0
            );
            lineString.properties.time = routeLegFeatures.reduce(
                (a, c) => (c?.properties?.time || 0) + a,
                0
            );

            return {
                fullRoute: lineString,
                routeLegs: routeLegFeatures,
                firstNodeId: reversedNodes[0].id,
                lastNodeId: reversedNodes[reversedNodes.length - 1].id,
            };
        };

        // We often want to get the shortest route from one of many
        // start or end points (e.g. doorways, drop-off points, thresholds)
        // So we create virtual start and end points.
        // Multiple start points become the second set of points in the mesh.
        // Multiple end points become the second last set of points.
        const virtualStartNode = {
            id: VIRTUAL_IDS.START,
            edges: [],
            routeLengthToNode: 0,
            routeTimeToNode: 0,
            transitions: 0,
            routeLegLength: 0,
        };

        startNodes.forEach(startNode => {
            const lengthKm = vStartPoint
                ? turfDistance(vStartPoint.geometry.coordinates, startNode.coords)
                : 0;
            const length = lengthKm * 1000;
            const time =
                lengthKm /
                (startNode.travelMode === TRAVEL_MODES.DRIVING ? SPEEDS.DRIVING : SPEEDS.WALKING);
            virtualStartNode.edges.push({
                travelMode: startNode.travelMode,
                otherNodeId: startNode.id,
                length,
                time,
                properties: {},
                bearing: 0,
            });
        });

        const virtualEndNode = {
            id: VIRTUAL_IDS.END,
            coords: virtualEndPoint.geometry.coordinates,
            edges: [],
            routeLengthToNode: Infinity,
            routeTimeToNode: Infinity,
            distanceToEnd: 0,
            timeToEnd: 0,
            transitions: 0,
            routeLegLength: 0,
        };

        for (const node of routeMesh.values()) {
            // We might want to filter out non-wheelchair friendly routes, or use route segments
            // that allow vehicles only. We apply that filter here
            const filteredEdges = edgeFilter
                ? node.edges.filter(edge => edgeFilter(node, edge.properties))
                : node.edges.slice();

            // We have a lot of dead end nodes (e.g. an edge ending in a doorway)
            // If a dead end node isn't one of the start or end points,
            // we don't need it
            const isDeadEnd =
                filteredEdges.length === 1 &&
                !startNodeIds.includes(node.id) &&
                !endNodeIds.includes(node.id);

            if (filteredEdges.length && !isDeadEnd) {
                // We must not hold a reference to the original routeMesh object
                // So we clone the node
                const distanceToEndKm = turfDistance(node.coords, virtualEndNode.coords);
                queues.unordered.set(node.id, {
                    ...node,
                    edges: filteredEdges,
                    // We use A* search algorithm to find the shortest path between the start and end points.
                    // At each iteration for the current node we select a neighbor node n (i.e. the node connected by the edge with the current node)
                    // which has a minimal value of f(n) = g(n) + h(n), where
                    // g(n) - is the length of shortest path from the start node to node n,
                    // h(n) - is a heuristic function that estimates the length of the shortest path from node n to the end node.
                    //
                    // We use a 'direct distance to end' for each node n (in meters) as the value of h(n)
                    // and store it as 'distanceToEnd' property of the node.
                    // Value of g(n) is stored in 'routeLengthToNode' property of the node.
                    // See visitNode() function above.
                    //
                    distanceToEnd: distanceToEndKm * 1000,
                    timeToEnd: distanceToEndKm / SPEEDS.DRIVING, // in hours
                    transitions: 0,
                    routeLegLength: 0,
                });
            }
        }

        queues.unordered.set(virtualEndNode.id, virtualEndNode);

        endNodeIds.forEach(endNodeId => {
            const endNode = queues.unordered.get(endNodeId);
            if (!endNode) {
                return;
            }
            endNode.edges.push({
                travelMode: endNode.travelMode,
                otherNodeId: VIRTUAL_IDS.END,
                length: 0,
                time: 0,
                properties: {},
            });
            queues.unordered.set(endNode.id, endNode);
        });

        // Update the details of a node in the ordered queue
        const updateInQueue = ({
            node,
            routeLengthToNode,
            routeTimeToNode,
            via,
            bearingToPreviousNode,
            edgeBearing,
            transitions,
            routeLegLength,
        }) => {
            let adjustedLengthToNode = routeLengthToNode;
            let adjustedTimeToNode = routeTimeToNode;
            let adjustedLegLength = routeLegLength;
            // We have a slight preference for straighter routes
            if (edgeBearing && bearingToPreviousNode) {
                let bearingPenalty;
                // Straight through is 0, 90 degree angle is 1
                // TODO (davidg): this doesn't handle bends greater than 90°
                bearingPenalty = (Math.abs(edgeBearing - bearingToPreviousNode) % 90) / 90;

                // Turning 90 degrees onto a 1m long segment will treat
                // that segment as though it were 1.01m long
                bearingPenalty *= 0.01;
                adjustedLengthToNode *= 1 + bearingPenalty;
                adjustedTimeToNode *= 1 + bearingPenalty;
                adjustedLegLength *= 1 + bearingPenalty;
            }
            const score = adjustedTimeToNode + node.timeToEnd;

            const nextRecord = {
                ...node,
                routeLengthToNode: adjustedLengthToNode,
                routeTimeToNode: adjustedTimeToNode,
                via,
                bearingToNode: edgeBearing,
                score,
                transitions,
                routeLegLength: adjustedLegLength,
            };

            // Once a node has had a length calculated it must be removed from the unordered queue
            // and moved to the ordered queue
            queues.unordered.delete(node.id);
            queues.ordered.upsert(nextRecord);
        };

        let currentNode = virtualStartNode;
        while (currentNode) {
            if (currentNode.id === VIRTUAL_IDS.END) {
                result = constructCompletePath(currentNode);
                break;
            }

            // Loop over the edges attached to this node. In other words, visit the neighbours
            for (const edge of currentNode.edges) {
                // Get the node at the other end of this edge
                const otherNode =
                    queues.unordered.get(edge.otherNodeId) || queues.ordered.get(edge.otherNodeId);

                // If a dead end node has been removed, otherNode may not exist
                // If this edge is looking 'backwards' to an already-visited node, skip it.
                if (otherNode && !queues.visited.get(edge.otherNodeId)) {
                    const lengthViaThisNode = currentNode.routeLengthToNode + edge.length;
                    const timeViaThisNode = currentNode.routeTimeToNode + edge.time;

                    if (timeViaThisNode < otherNode.routeTimeToNode) {
                        const otherNodeTransitions = edge.properties.isTransitionPoint
                            ? currentNode.transitions + 1
                            : currentNode.transitions;

                        const routeLegLength = edge.properties.isTransitionPoint
                            ? 0
                            : currentNode.routeLegLength + edge.length;

                        let isUpdateAllowed = true;
                        if (travelMode === TRAVEL_MODES.DRIVING) {
                            // don't allow more than MAX_TRAVEL_MODE_TRANSITIONS
                            isUpdateAllowed = otherNodeTransitions <= MAX_TRAVEL_MODE_TRANSITIONS;
                            // don't allow driving -> walking -> driving route
                            if (
                                otherNodeTransitions === MAX_TRAVEL_MODE_TRANSITIONS &&
                                otherNode.travelMode === TRAVEL_MODES.DRIVING
                            ) {
                                isUpdateAllowed = false;
                                // don't allow long walking distances till driving start
                            } else if (
                                otherNodeTransitions === 0 &&
                                otherNode.travelMode === TRAVEL_MODES.WALKING &&
                                (!ignoreMaxWalkingDistance &&
                                    routeLegLength > MAX_WALKING_TILL_DRIVING_DISTANCE)
                            ) {
                                isUpdateAllowed = false;
                            }
                        }

                        if (isUpdateAllowed) {
                            updateInQueue({
                                node: otherNode,
                                routeLengthToNode: lengthViaThisNode,
                                routeTimeToNode: timeViaThisNode,
                                via: currentNode.id,
                                bearingToPreviousNode: currentNode.bearingToNode,
                                edgeBearing: edge.bearing,
                                transitions: otherNodeTransitions,
                                routeLegLength,
                            });
                        }
                    }
                }
            }
            queues.visited.set(currentNode.id, currentNode);
            currentNode = queues.ordered.takeNext();
        }
    } catch (err) {
        log.error(err);
    }
    log.debug('routeManager.getRoute', result);
    return result;
};

// If a node has one edge that allows vehicles and another edge that does not allow vehicles,
// it is considered a 'drop-off' point
const buildDropOffPoints = segment => {
    segment.geometry.coordinates.forEach(coord => {
        const key = coordinatesToString(coord);

        const sharedCoord = dropOffPointMap.get(key) || turfHelpers.point(coord);

        if (sharedCoord.properties.isDropOffPoint) return; // We know enough, you can go

        if (segment.properties.routeAllowsVehicles === true) {
            sharedCoord.properties.isOnVehicleRoute = true;
        }
        if (segment.properties.routeAllowsVehicles === false) {
            sharedCoord.properties.isOnPedestrianOnlyRoute = true;
        }
        if (
            sharedCoord.properties.isOnVehicleRoute &&
            sharedCoord.properties.isOnPedestrianOnlyRoute
        ) {
            sharedCoord.properties.isDropOffPoint = true;
            delete sharedCoord.properties.isOnVehicleRoute;
            delete sharedCoord.properties.isOnPedestrianOnlyRoute;
        }
        dropOffPointMap.set(key, sharedCoord);
    });
};

const buildRouteMesh = segment => {
    const startCoords = segment.geometry.coordinates[0];
    const endCoords = segment.geometry.coordinates[1];

    const segmentLengthKm = turfDistance(startCoords, endCoords); // in km
    const segmentLength = segmentLengthKm * 1000; // in meters

    const startNodeId = coordinatesToString(startCoords);
    const endNodeId = coordinatesToString(endCoords);
    const bearing = turfBearing(startCoords, endCoords) + 180;

    const startNodeIdWalking = getNodeId(startNodeId, TRAVEL_MODES.WALKING);
    const endNodeIdWalking = getNodeId(endNodeId, TRAVEL_MODES.WALKING);
    const startNodeIdDriving = getNodeId(startNodeId, TRAVEL_MODES.DRIVING);
    const endNodeIdDriving = getNodeId(endNodeId, TRAVEL_MODES.DRIVING);

    let startNodeWalking;
    let endNodeWalking;
    let startNodeDriving;
    let endNodeDriving;

    // Note: it would be nice to exclude the end-to-start edge for one-way routes. But
    // when calculating a route we optimize by removing dead-ends, and if a one-way route meets a
    // two-way route at a node, and there are no other edges, the node will be considered a dead
    // end and be removed. So we keep the edge, but set the length to Infinity
    const endNodeEdgesLengthKm = segment.properties.oneWay ? Infinity : segmentLengthKm;
    const endNodeEdgesLength = segment.properties.oneWay ? Infinity : segmentLength;

    if (segment.properties.routeAllowsPedestrians) {
        startNodeWalking = routeMesh.get(startNodeIdWalking) || {
            id: startNodeIdWalking,
            coords: startCoords,
            edges: [],
            routeLengthToNode: Infinity,
            routeTimeToNode: Infinity,
            travelMode: TRAVEL_MODES.WALKING,
        };
        startNodeWalking.edges.push({
            travelMode: TRAVEL_MODES.WALKING,
            otherNodeId: endNodeIdWalking,
            length: segmentLength,
            time: segmentLengthKm / SPEEDS.WALKING,
            bearing,
            properties: {
                ...segment.properties,
                routeAllowsPedestrians: true,
                routeAllowsVehicles: false,
            },
        });
        routeMesh.set(startNodeIdWalking, startNodeWalking);

        endNodeWalking = routeMesh.get(endNodeIdWalking) || {
            id: endNodeIdWalking,
            coords: endCoords,
            edges: [],
            routeLengthToNode: Infinity,
            routeTimeToNode: Infinity,
            travelMode: TRAVEL_MODES.WALKING,
        };
        endNodeWalking.edges.push({
            travelMode: TRAVEL_MODES.WALKING,
            otherNodeId: startNodeIdWalking,
            length: endNodeEdgesLength,
            time: endNodeEdgesLengthKm / SPEEDS.WALKING,
            bearing,
            properties: segment.properties,
        });
        routeMesh.set(endNodeIdWalking, endNodeWalking);
    }

    if (segment.properties.routeAllowsVehicles) {
        startNodeDriving = routeMesh.get(startNodeIdDriving) || {
            id: startNodeIdDriving,
            coords: startCoords,
            edges: [],
            routeLengthToNode: Infinity,
            routeTimeToNode: Infinity,
            travelMode: TRAVEL_MODES.DRIVING,
        };
        startNodeDriving.edges.push({
            travelMode: TRAVEL_MODES.DRIVING,
            otherNodeId: endNodeIdDriving,
            length: segmentLength,
            time: segmentLengthKm / SPEEDS.DRIVING,
            bearing,
            properties: {
                ...segment.properties,
                routeAllowsPedestrians: false,
                routeAllowsVehicles: true,
            },
        });
        routeMesh.set(startNodeIdDriving, startNodeDriving);

        endNodeDriving = routeMesh.get(endNodeIdDriving) || {
            id: endNodeIdDriving,
            coords: endCoords,
            edges: [],
            routeLengthToNode: Infinity,
            routeTimeToNode: Infinity,
            travelMode: TRAVEL_MODES.DRIVING,
        };
        endNodeDriving.edges.push({
            travelMode: TRAVEL_MODES.DRIVING,
            otherNodeId: startNodeIdDriving,
            length: endNodeEdgesLength,
            time: endNodeEdgesLengthKm / SPEEDS.DRIVING,
            bearing,
            properties: segment.properties,
        });
        routeMesh.set(endNodeIdDriving, endNodeDriving);
    }
};

const initForcePamRoutesFeatureCollection = navMapFeatures => {
    const featureTypeCodes = Object.values(FEATURE_TYPES)
        .filter(featureType => featureType.canForcePamRoutes)
        .map(featureType => featureType.code);
    const forcePamRoutesFeatures = navMapFeatures.filter(
        feature =>
            feature.properties?.featureType?.code &&
            featureTypeCodes.indexOf(feature.properties?.featureType?.code) !== -1 &&
            (feature.properties.forcePamRoutes || feature.properties.forcePamRoutes === undefined)
    );
    forcePamRoutesFeatureCollection = turfHelpers.featureCollection(forcePamRoutesFeatures);
};

const initParentFeaturesCache = navMapFeatures => {
    navMapFeatures.forEach(feature => {
        if (feature.properties.childrenIds?.length > 0) {
            parentFeaturesCache.set(feature.properties.featureId, feature);
        }
    });
};

export const getParentFeature = featureId => parentFeaturesCache.get(featureId);

const initFeatureGroupsCache = navMapFeatures => {
    navMapFeatures.forEach(feature => {
        if (feature.properties.groupId) {
            if (!featureGroupsCache.has(feature.properties.groupId)) {
                featureGroupsCache.set(feature.properties.groupId, []);
            }
            featureGroupsCache.get(feature.properties.groupId).push(feature);
        }
    });
};

export const init = (routingFeatures, navMapFeatures = []) => {
    routingFeatures.forEach(feature => {
        const truncatedFeature = truncate(feature);

        if (feature.properties.isThreshold) {
            thresholds.push(truncatedFeature);
            if (
                feature.properties.blockExternalRouting &&
                excludedThresholds.length <= MAPBOX_EXCLUDE_POINTS_LIMIT
            ) {
                excludedThresholds.push(truncatedFeature);
            }
        }

        if (!feature.properties.isRouteSegment) return;

        turfMeta.segmentEach(truncatedFeature, segment => {
            buildDropOffPoints(segment);
            buildRouteMesh(segment);
        });
    });

    dropOffPointMap.forEach((point, key) => {
        if (point.properties.isDropOffPoint) {
            const walkingNodeId = getNodeId(key, TRAVEL_MODES.WALKING);
            const drivingNodeId = getNodeId(key, TRAVEL_MODES.DRIVING);

            const walkingNode = routeMesh.get(walkingNodeId);
            walkingNode.edges.push({
                otherNodeId: drivingNodeId,
                length: 0,
                time: TRANSITION_TIME,
                properties: {
                    isTransitionPoint: true,
                },
            });

            const drivingNode = routeMesh.get(drivingNodeId);
            drivingNode.edges.push({
                otherNodeId: walkingNodeId,
                length: 0,
                time: TRANSITION_TIME,
                properties: {
                    isTransitionPoint: true,
                },
            });
        }
    });

    for (const node of routeMesh.values()) {
        routeMeshPoints[node.travelMode].push(turfHelpers.point(node.coords));
    }

    initForcePamRoutesFeatureCollection(navMapFeatures);
    initParentFeaturesCache(navMapFeatures);
    initFeatureGroupsCache(navMapFeatures);

    routeMeshReady = true;

    // Execute any queued functions
    while (routeRequestQueue.length) {
        const func = routeRequestQueue.pop();
        func();
    }
};

export const whenReady = func => {
    if (routeMeshReady) {
        func();
    } else {
        routeRequestQueue.push(func);
    }
};

export const cleanup = () => {
    routeMeshReady = false;
    routeMesh.clear();
    routeMeshPoints[TRAVEL_MODES.DRIVING].length = 0;
    routeMeshPoints[TRAVEL_MODES.WALKING].length = 0;
    dropOffPointMap.clear();
    thresholds.length = 0;
    excludedThresholds.length = 0;
    nodesInFeaturesCache.clear();
    featureGroupsCache.clear();
};

export const isReady = () => routeMeshReady;
