import * as analytics from 'Utils/analytics';

class PitchToggle {
    constructor({map, mapConfig, minPitchZoom = null}) {
        this.mapConfig = mapConfig;
        this.minPitchZoom = minPitchZoom;
        this.map = map;
        this._btn = document.createElement('button');
    }

    handleEaseToStart() {
        const _this = this;
        _this._btn.disabled = 'disabled';
    }

    handleEaseToEnd() {
        const _this = this;
        _this._btn.className =
            _this.map.getPitch() === 0
                ? 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle mapboxgl-ctrl-pitchtoggle-3d'
                : 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle mapboxgl-ctrl-pitchtoggle-2d';
        _this._btn.disabled = false;
    }

    _handleClick() {
        const _this = this;
        const bearing = _this.map.getBearing();

        window.userMovedMap = true;

        if (_this.map.getPitch() === 0) {
            const options = {
                pitch: 45,
                bearing,
            };
            if (_this.minPitchZoom && _this.map.getZoom() > _this.minPitchZoom) {
                options.zoom = _this.map.getZoom();
            }
            _this._btn.disabled = 'disabled';
            _this.map.easeTo(options);

            analytics.sendDeviceEvent(analytics.ACTIONS.TOUCH, '3D');
        } else {
            const options = {
                pitch: 0,
                bearing,
            };
            _this._btn.disabled = 'disabled';
            _this.map.easeTo(options);
            analytics.sendDeviceEvent(analytics.ACTIONS.TOUCH, '2D');
        }
    }

    updateMapConfig(mapConfig) {
        this.mapConfig = mapConfig;
        this._btn.onclick = () => this._handleClick();
    }

    onAdd() {
        this._btn.className =
            this.map.getPitch() === 0
                ? 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle mapboxgl-ctrl-pitchtoggle-3d'
                : 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle mapboxgl-ctrl-pitchtoggle-2d';
        this._btn.type = 'button';
        this._btn['aria-label'] = 'Toggle Pitch';
        this._btn.onclick = () => this._handleClick();
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.prepend(this._btn);
        this.map.on('pitchstart', () => this.handleEaseToStart());
        this.map.on('pitchend', () => this.handleEaseToEnd());

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this.map = undefined;
    }
}

export default PitchToggle;
